import { Drawer, Button, notification } from "antd";
import { useState } from "react";
import moment from "moment";
import BulkPreviewStructure from "./BulkPreviewStructure";
import BulkJsonDownloadButton from "./BulkJsonDownloadButton";
import BulkUploadSection from "./BulkUploadSection";

const BulkQuestionDrawer = ({
    collection,
    handleDrawerClose,
    visibleForm,
    createBulkQuizQuestion
}) => {
    const [width, setWidth] = useState("37.5vw");
    const [previewMode, setPreviewMode] = useState(false);
    const [previewData, setPreviewData] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [jsonData, setJsonData] = useState(null);

    const onClose = () => {
        handleDrawerClose();
        setPreviewMode(false);
        setPreviewData(null);
        setWidth("37.5vw");
    };

    const setDataAndOpenPreviewer = (data) => {
        if (!data) {
            onClose();
            return;
        }
        setPreviewData(data);
        setWidth("67.5vw");
        setPreviewMode(true);
    };

    const parseQuestionPayload = (question) => {
        const {
            questionType,
            title,
            text,
            imageIds,
            timeRange,
            options,
            videoObj
        } = question;

        return {
            questionType,
            title,
            text,
            imageIds,
            options,
            video: videoObj.id,
            startVideoTimestamp: timeRange[0].diff(
                moment("00:00:00", "HH:mm:ss"),
                "seconds"
            ),
            endVideoTimestamp: timeRange[1].diff(
                moment("00:00:00", "HH:mm:ss"),
                "seconds"
            )
        };
    };

    const getFinalPyload = () => {
        const payloadQuestions = [];

        jsonData.forEach((m) => {
            m.videos.forEach((v) => {
                v.questions.forEach((q) => {
                    payloadQuestions.push(parseQuestionPayload(q));
                });
            });
        });

        return payloadQuestions;
    };

    const onSubmit = () => {
        if (!jsonData) return;

        const finalPyload = getFinalPyload();
        if (!finalPyload.length) return;

        setSubmitLoading(true);
        createBulkQuizQuestion(collection.id, finalPyload, () => {
            notification.success({
                message: `Bulk question creation successfull!`
            });
            onClose();
        });
    };

    return (
        <Drawer
            title="Upload Questions"
            placement="right"
            closable={false}
            forceRender={true}
            onClose={() => onClose()}
            visible={visibleForm}
            width={width}
            mask={true}
            maskClosable={false}
            footer={
                <div
                    style={{
                        textAlign: "right"
                    }}>
                    <Button style={{ marginRight: 8 }} onClick={onClose}>
                        Cancel
                    </Button>
                    {previewMode && (
                        <Button
                            type="primary"
                            onClick={onSubmit}
                            disabled={hasErrors}
                            loading={submitLoading}>
                            Save All Questions
                        </Button>
                    )}
                </div>
            }>
            <div className="upload-bulk-questions">
                {previewMode ? (
                    <>
                        <BulkPreviewStructure
                            data={previewData}
                            collection={collection}
                            setHasErrors={setHasErrors}
                            setJsonData={setJsonData}
                            onClose={onClose}
                        />
                    </>
                ) : (
                    <>
                        <p>
                            You can download sample json for this collection
                            with pre-filled module and video data.
                        </p>
                        <BulkJsonDownloadButton
                            collection={collection}
                            setPreviewData={setDataAndOpenPreviewer}
                        />
                        <BulkUploadSection
                            setPreviewData={setDataAndOpenPreviewer}
                        />
                    </>
                )}
            </div>
        </Drawer>
    );
};

export default BulkQuestionDrawer;
