import { LockOutlined, PlayCircleOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Image, Row, Space, Tooltip, Typography } from "antd";
import { useState } from "react";
import { useHistory } from 'react-router-dom';
import PlaceholderImage from '../placeholder-video-cover.png';
import Tour from '../Components/Tour';


const { Paragraph, Text, Title } = Typography;


const initialModules = [
    {
        id: 1,
        name: "Module 1",
        price: 300,
        purchased: false,
        videos: [
            {
                title: "Video 1",
                locked: true
            },
            {
                title: "Video 2",
                locked: true
            }
        ]
    },
    {
        id: 2,
        name: "Module 2",
        price: 100,
        purchased: false,
        videos: [
            {
                title: "Video 3",
                locked: true
            },
        ]
    }
]



const tutorialSteps = [
    {
        target: '#collection-detail',
        content: <div>
                    <p style={{textAlign: 'left'}}>Welcome to a sample course!</p>
       
                    <p style={{margin: 0, textAlign: 'left', fontWeight: 'bold'}}>What is a course?</p>
                    <p style={{textAlign: 'left'}}>A course is a well-structured course, containing videos orderd and grouped by topic (modules).</p>
                </div>,
        placement: "left-start",
        disableBeacon: true,
    },
    {
        target: '#module-1',
        content: <div>
                    <p style={{margin: 0, textAlign: 'left', fontWeight: 'bold'}}>What is a module?</p>
                    <p style={{textAlign: 'left'}}>The module is a subsection of a course. It groups videos into a common topic for easier access.</p>
                </div>,
        placement: "left-start",
        disableBeacon: true,
    },
    {
        target: '#purchase-module-btn-1',
        content: <div>
                    <p style={{textAlign: 'left'}}>Click here to purchase a module.</p>
                    <p style={{textAlign: 'left'}}>By default, the videos are locked. To unlock the videos, you can subscribe to a module individually for permanent access.</p>

                    <p style={{margin: 0, textAlign: 'left', fontWeight: 'bold'}}>What are cupoints?</p>
                    <p style={{textAlign: 'left'}}>Cupoints are credits you will use to subscribe to content on the platform. You can purchase cupoints using USD by going to your wallet page.</p>
                </div>,
        placement: "right-start",
        disableBeacon: true,
    },
    {
        target: '#collection-purchase-btn',
        content: <div>
                    <p style={{textAlign: 'left'}}>Or you can subscribe (gain permanent access) to all modules within the course at once at a bundled discount.</p>
                </div>,
        placement: "right-start",
        disableBeacon: true,
    },
    {
        target: '#class-code-btn',
        content: <div>
                    <p style={{textAlign: 'left'}}>If you have a class code from an instructor, you can gain access to videos for a limited amount of time. You can subscribe for permanent access at any point.</p>
                </div>,
        placement: "right-start",
        disableBeacon: true,
    },
   
    
];

const RenderTutorial = () => {
    const completedTutorial = localStorage.getItem('completedCreateCollectionTutorials');
    if(completedTutorial == null) {
        return <Tour steps={tutorialSteps} tutorialName='completedCreateCollectionTutorial' />
    } else {
        return
    }
}


const Module = ({ module, purchaseModule }) => {

    const ModuleExtraActions = ({ module }) => {
        if(module.purchased){
            return <Space className="ant-space-responsive"><Button disabled >Purchased</Button></Space>
        } else{
            return <Button type="primary" onClick={purchaseModule} id={`purchase-module-btn-${module.id}`}>
                        Subscribe @ {module.price} cupoints
                    </Button>
        }
      
        
    }

    return <>
        <Card>
            <div className="module-header">   
                <div>
                    <Title level={5} style={{margin: 0}}>{module.name}</Title>

                    <>
                        <Text type="secondary">John Smith</Text>
                    </>
                        
                </div>
                <div>
                    
                    <ModuleExtraActions module={module}/>

                </div>
            </div>
        </Card>
        <br/>
        <Row gutter={[16, 16]}>
        { 
            module.videos.map((video, index) => {
                if(module.purchased) {
                    return <Col 
                            xs={24} 
                            sm={12} 
                            md={8} 
                            lg={6} 
                            key={`module-${module.id}-video-${index}`}
                        >
                                <Card
                                    hoverable
                                    className="video-card"
                                    cover={<Image
                                                src={PlaceholderImage}
                                                fallback={PlaceholderImage}
                                                preview={false}
                                                style={{aspectRatio: 16/9}}
                                            />}
                                    style={{animationDelay: `${60 * index}ms`}}
                                >
                                    <div className="play-icon-overlay">
                                        <PlayCircleOutlined />
                                    </div>
                                    <Card.Meta title={`${video.title}`} />
                                </Card>
                        </Col>
                } else {
                    return <Col 
                                xs={24} 
                                sm={12} 
                                md={8} 
                                lg={6} 
                                key={`module-${module.id}-video-${index}`}
                            >
                                <Card
                                    hoverable
                                    className="video-card"
                                    cover={<Image
                                        src={PlaceholderImage}
                                        fallback={PlaceholderImage}
                                        preview={false}
                                        style={{aspectRatio: 16/9}}
                                    />}
                                    style={{animationDelay: `${60 * index}ms`}}
                                >
                                    <Tooltip title="No Access"><LockOutlined style={{fontSize: 20, position: "absolute", top: 10, right: 10, color: "yellow"}} /></Tooltip>
                                    <Card.Meta title={video.title} />
                                    
                                </Card>
                            </Col>
                }
                
            })
        }
        </Row>
    </>
}

const StudentWalkthrough = () => {

    const history = useHistory();
    const [selectedModule, setSelectedModule] = useState(null);
    const [modules, setModules] = useState(initialModules)
    
    const purchaseCollection = () => {
        console.log(modules)
        let updatedModules = []
        modules.forEach(module => {
            updatedModules.push({
                ...module,
                purchased: true
            })
        })
        setModules(updatedModules);
    }
    
        
    const CollectionButtons = ({modules}) => {
     
        if(modules.filter(module => module.purchased).length == 2){
            return <>
                        <Button 
                            type="primary" 
                            id="collection-purchase-btn"
                            icon={<ThunderboltOutlined />}
                            className="collection-info-card-action-button"
                            disabled={true}
                        >
                            Subscribed
                        </Button>
                        <Button id="class-code-btn">
                            Unlock with Class Code
                        </Button>
                    </>
        } else {
    
            return <>
                        <Button 
                            type="primary" 
                            id="collection-purchase-btn"
                            icon={<ThunderboltOutlined />}
                            className="collection-info-card-action-button"
                            onClick={()=>purchaseCollection()}
                        >
                            Subscribe all @ {(400 * 0.85)} cupoints
                        </Button>
                        <Button id="class-code-btn">
                            Unlock with Class Code
                        </Button>
                    </>
        }
    }

    const purchaseModule = (modules, module, index ) => {
        let updatedModule = { ...module, purchased: true }
        let updatedModules = [...modules]
        updatedModules[index] = updatedModule
        setModules(updatedModules)        
    }

    const ModuleList = () => {
        if(selectedModule == null){
            return modules.map((module, index) => <div id={`module-${index + 1}`}><Module module={module} purchaseModule={()=>purchaseModule(modules, module, index)}/><br/></div>)
        } else if(selectedModule == 0){
            return <Module module={modules[0]} />
        } else if(selectedModule == 1){
            return <Module module={modules[1]} />
        }
    }

    return <div className="centered-container">
        <RenderTutorial/>
        <div id="collection-detail">
            <Alert
                message="This is a tutorial"
                description="Any subscriptions/actions you make will not be saved and is just for demonstration purposes only."
                type="warning"
                showIcon
                action={
                    <Button size="small" type="text" onClick={()=> history.goBack()} type="primary">
                      Exit tutorial
                    </Button>
                  }
            />
            <br/>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="collection-info-card-preview-image"><Title level={3}>Course Cover Image</Title></div> 
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Title level={4} className="collection-info-card-title">Sample Course</Title>
                        <Text type="secondary">John Smith</Text>
            
                        <Paragraph className="collection-info-card-description">
                            <Text type="secondary">This course is an example of</Text>
                        </Paragraph>
                        <Paragraph className="collection-info-card-long-description">
                            This course blah blah
                        </Paragraph>
                        <Paragraph>
                            <Text>Total Value: 200 cupoints</Text>
                        </Paragraph>
                    
                        <Space className="ant-space-responsive">
                            <CollectionButtons modules={modules}/>
                        </Space>
                </Col>
            </Row> 
            <br/>
            <Paragraph>
                <Text>Modules</Text>
            </Paragraph>
            <Space wrap={true}>
                <Button className="module-list-button" ghost type={selectedModule == null ? "primary" : "default"} onClick={()=>setSelectedModule(null)}>All</Button>
                {
                    modules.map((module, index) =>  <div key={index} >
                                                        <Tooltip title={module.name}>
                                                            <Button 
                                                                className="module-list-button"
                                                                ghost 
                                                                type={selectedModule == index ? "primary" : "default"} 
                                                                onClick={()=>setSelectedModule(index)}
                                                            >
                                                                {index + 1}
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                    )}
    
            </Space>
            <br/>
            <br/>
            
            <ModuleList />
            
        </div>
    </div>
}

export default StudentWalkthrough;