import { Component } from 'react';
import { Button, Card, List, Skeleton, Space, Tag, Typography } from 'antd';
import { connect } from 'react-redux';
import { fetchVideoQnA } from '../../actions/collection';
import DashboardQnAQuestion from './DashboardQnAQuestion';
import DashboardCreateQnAQuestion from './DashboardCreateQnAQuestion';
import SimpleYouTubePlayer from '../SimpleYouTubePlayer';
import SimpleVimeoPlayer from '../SimpleVimeoPlayer';
import { PlayCircleOutlined } from '@ant-design/icons';


const { Text, Title } = Typography;
class DashboardVideoQnA extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            collectionId: null,
            moduleId: null,
            videoId: null
        }
    }

    componentDidMount() {
        this.setState({
            collectionId: this.props.match.params.collectionId,
            moduleId: this.props.match.params.moduleId,
            videoId: this.props.match.params.videoId
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.collection != this.props.collection && this.props.collection.id != this.state.collectionId){
            this.props.history.push('/dashboard/qna/')
        }
        if(((prevState.collectionId != this.state.collectionId || prevState.moduleId != this.state.moduleId || prevState.videoId != this.state.videoId) && this.props.selectedCoupon) ||  
            (this.state.collectionId != null && prevProps.selectedCoupon != this.props.selectedCoupon)
        ){
           
            this.props.fetchVideoQnA(this.state.collectionId, this.state.moduleId, this.state.videoId, this.props.selectedCoupon.id)
        }
        if(prevProps.selectedCoupon != null && prevProps.selectedCoupon != this.props.selectedCoupon){
            this.props.history.push('/dashboard/qna/')
        }
        if(prevProps.match.params.collectionId != this.props.match.params.collectionId || prevProps.match.params.moduleId != this.props.match.params.moduleId || prevProps.match.params.videoId != this.props.match.params.videoId){
            this.setState({
                collectionId: this.props.match.params.collectionId,
                moduleId: this.props.match.params.moduleId,
                videoId: this.props.match.params.videoId
            })
        }
    }

    handleWatchVideoButtonClick = () => {
        let url = `https://www.cubits.ai/collections/${this.state.collectionId}/modules/${this.state.moduleId}/videos/${this.state.videoId}/`;
        if(this.props.collection.collectionUnlistedKey){
            url += `?key=${this.props.collection.collectionUnlistedKey}`
        }
        window.open(url, "_blank")
    }

    renderVideoTitle = () => {
        
        let foundVideoListObject = this.props.videoList.find(item => {
            return item.videoId == this.state.videoId
        })

        if(foundVideoListObject != null){
            return foundVideoListObject.videoTitle
        }else {
            return
        }
    }

    renderVideoPlayer = () => {
        if(this.props.selectedCoupon.createdById == this.props.currentUser.id){
            let foundVideoObject = this.props.videoList.find(videoObject => videoObject.videoId == this.state.videoId);
            if(foundVideoObject){
                if(foundVideoObject.videoType == "youtube"){
                return <div style={{background: "black", margin: "5px 0px"}}>
                            <SimpleYouTubePlayer
                                embedId={foundVideoObject.embedId}
                            /> 
                        </div>
                } else if(foundVideoObject.videoType == "vimeo"){
                    return <div style={{background: "black"}}>
                                <SimpleVimeoPlayer  
                                    embedId={foundVideoObject.embedId}
                                /> 
                            </div>
                }
            } else {
                return
            }
        }
    }
    render(){
        if(this.props.videoListStatus === "LOADED"){
            return  <Card className="dashboard-card">
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <Space align="baseline" style={{paddingLeft: "5px"}}>
                                <Title level={4}>[Discussion]</Title>
                                <Text>{this.renderVideoTitle()}</Text>
                            </Space>
                            {
                                this.props.selectedCoupon.createdById != this.props.currentUser.id ?
                                    <Button 
                                        type="primary"
                                        icon={<PlayCircleOutlined />}
                                        onClick={this.handleWatchVideoButtonClick}
                                    > 
                                        Watch Video
                                    </Button> :
                                    null
                            }
                            
                        </div>
                        <Space  style={{paddingLeft: "5px"}}>
                            <Tag color="#7694db">Video</Tag>
                        </Space>
                        {this.renderVideoPlayer()}
                        <DashboardCreateQnAQuestion 
                            collectionId={this.state.collectionId}
                            moduleId={this.state.moduleId}
                            videoId={this.state.videoId}
                        />
                        <List
                            itemLayout="horizontal"
                            style={{marginTop: "5px"}}
                            dataSource={this.props.qnaData}
                            renderItem={item => (
                                <DashboardQnAQuestion
                                    question={item} 
                                    selectedDays={this.state.selectedDays}
                                    renderTag={false}
                                    renderNewReplyStatistics={false}
                                />
                            )}
                        />
                    </Card>
        }else {
            return  <Card className="dashboard-card">
                        
                        <Skeleton active />
                    </Card>
        }
        
    }
}

const mapStateToProps = state => {
    return {
        collection: state.dashboardReducer.collection,
        qnaData: state.collectionReducer.videoQnA,
        selectedCoupon: state.dashboardReducer.selectedCoupon,
        currentUser: state.userReducer.user,
        videoList: state.dashboardReducer.videoList,
        videoListStatus: state.dashboardReducer.videoListStatus
    }
}
export default connect(mapStateToProps, { fetchVideoQnA })(DashboardVideoQnA);