import { Breadcrumb, Card, Dropdown, Menu, Skeleton, Typography } from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Component } from 'react';
import { fetchQuizQuestionAttemptDataForVideo } from '../../../actions/dashboard';
import { connect } from "react-redux";
import QuizQuestion from "./QuizQuestion";

const { SubMenu } = Menu;
const { Text } = Typography;


class QuizSectionAttemptsByVideo  extends Component {
    constructor(props){
        super(props)

        this.state = {
            collectionId: props.collection.id,
            moduleId: null,
            videoId: null,
            selectedModuleVideo: "Select Video"
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if(this.state.collectionId != null && this.state.moduleId != null && this.state.videoId != null && this.props.selectedCoupon && 
            (prevState.collectionId != this.state.collectionId || prevState.moduleId != this.state.moduleId || prevState.videoId != this.state.videoId)){
            this.props.fetchQuizQuestionAttemptDataForVideo(this.props.selectedCoupon.code, this.props.currentUser.id, this.state.videoId)
        }
    }
    

    renderCollectionMenu = () => {
        if(this.props.collection){
            return <Menu>
                
                <Menu.ItemGroup title="Modules">
                    {this.props.collection.modules.map(module => {
                        return <SubMenu key={`module-${module.id}`} title={module.name}>
                                    <Menu.ItemGroup title="Videos">
                                        {
                                            module.videos.map(video=> {
                                                return <Menu.Item key={`video-${video.id}`} onClick={()=>{
                                                    this.setState({
                                                        moduleId: module.id,
                                                        videoId: video.id,
                                                        selectedModuleVideo: `${module.name} / ${video.title}`
                                                    })
                                                }}>{video.title}</Menu.Item>
                                            })
                                        }
                                    </Menu.ItemGroup>
                                </SubMenu>
                                
                    })}
                </Menu.ItemGroup>
            </Menu>
        }else {
            return  <Menu>
               <    Menu.Item key="collection">
                        Loading <LoadingOutlined />
                    </Menu.Item>
                </Menu>
        }
    }
    render(){
        if(this.props.quizQuestionAttemptDataStatus === "LOADING"){
            return <>
                        <Breadcrumb>
                            <Breadcrumb.Item>{this.props.collection.name}</Breadcrumb.Item>
                            <Breadcrumb.Item overlay={this.renderCollectionMenu()}>
                                {this.state.selectedModuleVideo}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <br/>
                        <Card><Skeleton active/></Card>
                    </>
        } else if(this.props.quizQuestionAttemptDataStatus === "LOADED"){
            return  <>
                        <Breadcrumb>
                            <Breadcrumb.Item>{this.props.collection.name}</Breadcrumb.Item>
                            <Breadcrumb.Item overlay={this.renderCollectionMenu()}>
                                {this.state.selectedModuleVideo}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <br/>
                        {   this.props.quizQuestionAttemptData.length ?
                            <>
                                <Text>Quiz Questions</Text>
                                {
                                    this.props.quizQuestionAttemptData.map(question => <QuizQuestion question={question} currentUser={this.props.currentUser} selectedCoupon={this.props.selectedCoupon}/>)
                                }
                            </>
                            : <div className="quiz-section-video-row">There are no quiz questions for this video.</div>
                        }
                            </>
        } else {
            return <>
                <Breadcrumb>
                    <Breadcrumb.Item>{this.props.collection.name}</Breadcrumb.Item>
                    <Breadcrumb.Item overlay={this.renderCollectionMenu()}>
                        {this.state.selectedModuleVideo}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <br/>
            </>
        }
    }
    
}

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.user,
        selectedCoupon: state.dashboardReducer.selectedCoupon,
        quizQuestionAttemptData: state.dashboardReducer.quizQuestionAttemptData,
        quizQuestionAttemptDataStatus: state.dashboardReducer.quizQuestionAttemptDataStatus
    }
}
export default connect(mapStateToProps, {fetchQuizQuestionAttemptDataForVideo})(QuizSectionAttemptsByVideo);