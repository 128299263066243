import { Component, useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Divider, Modal, Popconfirm, Skeleton, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, HomeOutlined, QuestionCircleOutlined, SendOutlined, SmallDashOutlined, UploadOutlined } from '@ant-design/icons';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchCertificationRequestList, approveOrRejectCertificate, fetchCertificationRequestStatistics } from '../actions/admin';
import StudentSummaries from './../Dashboard/Students/StudentSummaries';
import CheckboxGrid from '../Dashboard/Students/CheckboxGrid';
import StudentLineChart from '../Dashboard/Students/StudentLineChart';
const { Title, Text } = Typography;

const DetailView = ({ user, studentSummaries, studentSummariesStatus, watchedVideosStatistics, allVideos, maxStatisticsVideoLength }) => {

    const renderModuleName = (module) => {
        let modules = allVideos.filter((x) => {
            return x.moduleId === module[0].moduleId;
        })
        if (modules.length) {
            return modules[0].moduleName
        } else {
            return null
        }
    }

    return (
        <>
            <div >
                <Card className="dashboard-card">
                    <Title level={5}>
                        {user.username}
                    </Title>

                    {/* Student summary on collection */}
                    <StudentSummaries
                        studentSummaries={studentSummaries}
                        studentSummariesStatus={studentSummariesStatus}
                    />

                    <br />
                    <Card className="dashboard-card">
                        <Title level={5}>
                            Overview
                        </Title>

                        <CheckboxGrid watchedVideos={watchedVideosStatistics} />
                    </Card>

                    <br />
                    <Card className="dashboard-card">
                        <Space align="baseline">
                            <Title level={5}>
                                Watch Time Graphs
                            </Title>
                            <Tooltip
                                placement="right"
                                title="The following graphs show the amount of time invested in each minute of every video. The baseline represents what a graph would like if the user were to watch once at 1x speed. If the graph is above the baseline, then user has watched that section more than once or at < 1x speed. If the graph is below the baseline, then the user has watched that section at a speed > 1x."
                            >
                                <QuestionCircleOutlined
                                    style={{ fontSize: "120%" }}
                                />
                            </Tooltip>
                        </Space>
                    </Card>

                    {/* Render modules AND videos as map of maps - allows us to render module title h1s between modules */}
                    {Object.keys(watchedVideosStatistics) ? (
                        Object.values(watchedVideosStatistics).map((module, i) => {
                            return (
                                <Card className="dashboard-card">
                                    <Title level={5}>
                                        Module {module[0].moduleId} -{" "}
                                        {
                                            renderModuleName(module)
                                        }
                                    </Title>
                                    <div>
                                        {module.map((selectedVideo, j) => {
                                            // Example selectedVideo: {title: "Conditionals: The IF Statement", data: Array(26)}
                                            return (
                                                <div>
                                                    {/* Line Chart of student data for given video */}
                                                    <StudentLineChart
                                                        key={j}
                                                        maxVideoDuration={maxStatisticsVideoLength}
                                                        selectedVideo={selectedVideo}
                                                        width="80%"
                                                        height="200px"
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Card>
                            );
                        })
                    ) : (<></>)}
                </Card>
            </div>
        </>
    )
}


class AdminCertificateRequestDetails extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let requestId = parseInt(this.props.match.params.requestId);
        this.props.fetchCertificationRequestStatistics(requestId);
    }

    render() {

        if (!this.props.isAuthenticated) {
            return <Redirect to='/dashboard' />
        } else {
            return <div className="centered-container">
                <Space className="breadcrumbs">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/"><HomeOutlined /></Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/admin">Admin Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/admin/certificate-requests">Certificate Requests</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Details</Breadcrumb.Item>
                    </Breadcrumb>
                </Space>
                <br />
                <br />

                <DetailView
                    user={this.props.user}
                    studentSummaries={this.props.studentSummaries}
                    studentSummariesStatus={this.props.studentSummariesStatus}
                    watchedVideosStatistics={this.props.watchedVideosStatistics}
                    allVideos={this.props.allVideos}
                    maxStatisticsVideoLength={this.props.maxStatisticsVideoLength} />
            </div>
        }
    }

}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.userReducer.isAuthenticated,
        user: state.userReducer.user,
        studentSummaries: state.adminReducer.certificateUserSummary,
        studentSummariesStatus: state.adminReducer.certificateUserSummaryStatus,
        watchedVideosStatistics: state.adminReducer.watchedVideosStatistics,
        allVideos: state.adminReducer.allVideos,
        maxStatisticsVideoLength: state.adminReducer.maxStatisticsVideoLength
        // certificateRequestList: state.adminReducer.certificateRequestList,
        // certificateRequestListStatus: state.adminReducer.certificateRequestListStatus,
        // approveProgress: state.adminReducer.approveProgress,
        // rejectProgress: state.adminReducer.rejectProgress,
    }
}

export default connect(mapStateToProps, { fetchCertificationRequestStatistics: fetchCertificationRequestStatistics })(AdminCertificateRequestDetails); // fetchCertificationRequestList, approveOrRejectCertificate