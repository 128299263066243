import { Button, Form, Input, Space, InputNumber, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { fetchVideoDataFromURL } from "../actions/contentCreation";
import { useDispatch } from "react-redux";
import { parseVimeoData, parseYouTubeData } from "./CollectionCreatorHelper";
import { InfoCircleOutlined } from "@ant-design/icons";

const CollectionDetailsStep = ({
    authorsDict,
    collection,
    currentUser,
    goToNextStep,
    updateState,
    showUrlImport,
    modules,
    importInProgress,
    addNewSampleModule
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const handleOnFinish = (values) => {
        let authorData;
        if (values.primaryAuthorUsername in authorsDict) {
            authorData = authorsDict[values.primaryAuthorUsername];
        } else {
            authorData = {
                id: currentUser.id,
                username: currentUser.username,
                firstName: currentUser.firstName,
                lastName: currentUser.lastName
            };
        }

        updateState("collection", {
            ...collection,
            ...values,
            primaryAuthorUsername: authorData.username,
            primaryAuthor: authorData,
            coAuthors: [],
            coAuthorUsernames: []
        });
        if (showUrlImport) {
            updateState("importInProgress", true);
            importVideos(values.url);
            if (!Boolean(modules.length)) {
                addNewSampleModule(
                    Array.from({ length: values.noOfModules }, (v, k) => k + 1)
                );
            }
        } else {
            continueNextStep();
        }
    };

    const importVideos = (url) => {
        const handleOnNewVideoByURLError = () => {
            updateState("importInProgress", false);
            return form.setFields([
                {
                    name: "url",
                    errors: ["Invalid video or playlist URL"]
                }
            ]);
        };

        dispatch(
            fetchVideoDataFromURL(
                url.trim(),
                parseVimeoData,
                parseYouTubeData,
                handleOnNewVideoByURLError
            )
        ).then((videosToImport) => {
            if (videosToImport) {
                updateState("videoBank", videosToImport);
                continueNextStep();
            }
        });
    };

    const continueNextStep = () => {
        if (showUrlImport) {
            updateState("showUrlImport", false);
        }
        updateState("importInProgress", false);
        goToNextStep();
    };

    const LabelWithInfoIcon = ({ labelText, tooltipText }) => {
        return (
            <>
                {labelText}
                <span className="ml-5">
                    <Tooltip title={tooltipText}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </span>
            </>
        );
    };

    return (
        <div>
            <Form
                name="basic"
                form={form}
                initialValues={collection}
                layout="vertical"
                onFinish={handleOnFinish}
                autoComplete="off">
                <Form.Item
                    label={
                        <LabelWithInfoIcon
                            labelText={"Course Name"}
                            tooltipText={
                                "Give a course name now or later. You can change this anytime"
                            }
                        />
                    }
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please give your course a name!"
                        },
                        {
                            max: 50,
                            message:
                                "Course name can contains maximum 50 characters!"
                        }
                    ]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label={
                        <LabelWithInfoIcon
                            labelText={"Description"}
                            tooltipText={
                                "Give a short description now or later. You can change this anytime"
                            }
                        />
                    }
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: "Please give your course a description!"
                        }
                    ]}>
                    <Input.TextArea />
                </Form.Item>

                {showUrlImport && (
                    <>
                        <Form.Item
                            label={
                                <LabelWithInfoIcon
                                    labelText={"YouTube or Vimeo URL"}
                                    tooltipText={
                                        "CUbits require a youtube/vimeo playlist for curating courses. You can start with one video or a playlist from youtube"
                                    }
                                />
                            }
                            extra={
                                <>
                                    <InfoCircleOutlined /> Use any YouTube
                                    (video/playlist) or Vimeo (video/showcase)
                                    URL
                                </>
                            }
                            name="url"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please give your course YouTube or Vimeo URL."
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={
                                <LabelWithInfoIcon
                                    labelText={
                                        "No of Modules/Chapters (optional)"
                                    }
                                    tooltipText={
                                        "Think of a module as a group of videos that covers a specific topic. This is equivalent to a chapter in a book where the videos can be thought of as sections of a chapter/module. You do not need to decide how many modules are needed now. You can add delete modules anytime. start with one and then add more."
                                    }
                                />
                            }
                            name="noOfModules"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "How many modules would you like to create in this course?"
                                }
                            ]}>
                            <InputNumber min={1} />
                        </Form.Item>
                    </>
                )}

                <br />
                <Space>
                    <Button
                        onClick={() => history.push("/creator/home/")}
                        disabled={importInProgress}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={importInProgress}>
                        {importInProgress ? "Importing Videos..." : "Next"}
                    </Button>
                </Space>
            </Form>
        </div>
    );
};

export default CollectionDetailsStep;
