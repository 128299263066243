/******************************************************************
StudentGrid.js
Written by Adam Gamba, Summer 2021

Renders grid of student cards
******************************************************************/

import StudentCard from "./StudentCard";
import { Avatar, Row, Col, Card, List } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

// Displays all available students in props.roster
const StudentGrid = (props) => {
  // Set number of columns based on screen width
  const NUM_OF_COLUMNS =
    window.innerWidth > 1000 ? 4 : window.innerWidth > 800 ? 3 : 2;

  let indivStudent = props.roster.filter(
    (x) => x.studentId === props.user.id
  )[0];
  // True if user is in roster (non-null filteredRoster) - used for
  // serach filtering
  let userInRoster = indivStudent ? true : false;

  const rosterListSource = () => {
    let currentUserData = [];
    if(!props.user.isContentCreator && userInRoster) {
      currentUserData = props.roster.filter(student => !props.user.isContentCreator && student.studentId == props.user.id)
    }
    let otherUsersData = props.roster
    // Filter out given user if they are the matching student
    .filter((student) => {
      // Example Student: {username: "vds@princeton.edu", studentId: 139, hours: 0}
      return (
        props.user.isContentCreator ||
        student.studentId !== props.user.id
      );
    })
    /*.map((selectedStudent, index) => {
      return (
        <Col span={24 / NUM_OF_COLUMNS}>
          <StudentCard key={index} student={selectedStudent} />
        </Col>
      );
    })*/
    return currentUserData.concat(otherUsersData)
  }
 
    // Renders list of students as a grid with NUM_OF_COLUMNS columns
    // Make sure NUM_OF_COLUMNS is integer divisible by 24 to work
    if(props.user.isContentCreator){
      return <List
                itemLayout="horizontal"
                dataSource={rosterListSource()}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar icon={<UserOutlined />} />}
                      title={<Link to={`/dashboard/students/${item.studentId}/`}>{item.username}</Link>}
                      description={<>
                      {/*<div>Hours watched: {item.hours.toFixed(1) % 1 === 0 ? Math.round(item.hours) : item.hours.toFixed(1)}</div>*/}
                      </>}
                    />
                  </List.Item>
                )}
              />
    }else {
      return <List
        itemLayout="horizontal"
        dataSource={rosterListSource()}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={item.username === props.user.username ? <Link to={`/dashboard/students/${item.studentId}/`}>{item.username}</Link> : item.username}
            />
          </List.Item>
        )}
      />
    }

};

export default StudentGrid;
