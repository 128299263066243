/******************************************************************
VideoGrid.js
Written by Adam Gamba, Summer 2021

Renders grid of video cards
******************************************************************/
import { Card, Col, Divider, Row } from "antd";
import { Link } from 'react-router-dom';

const gridStyle = {
  width: '25%',
  textAlign: 'center',
};

// Displays all available videos in array of videos
const InstructorVideoGrid = ({ videos, sortType }) => {
  // Set number of columns based on screen width
  const NUM_OF_COLUMNS =
    window.innerWidth > 1000 ? 4 : window.innerWidth > 800 ? 3 : 2;

  // Else, sort by module

  // Format videos array into object, with keys being module IDs and
  // values being an array of videos in that module
  let formattedVideos = {};
  for (let video of videos) {
    let moduleId = video.moduleId;
    if (formattedVideos.hasOwnProperty(moduleId)) {
      formattedVideos[moduleId].push(video);
    } else {
      formattedVideos[moduleId] = [video];
    }
  }

  // Account for sorting method - module ascending or descending
  let valuesList = [];
  if (sortType === "sortModuleAscending") {
    valuesList = Object.values(formattedVideos);
  } else {
    valuesList = Object.values(formattedVideos).reverse();
  }
  return (
    // Renders list of videos as a grid with NUM_OF_COLUMNS columns
    // Make sure NUM_OF_COLUMNS is integer divisible by 24 to work
    <div>
      {valuesList.map((module, i) => {
        return (
          <Card 
            title={`[Module ID: ${module[0].moduleId}] - ${module[0].moduleName}`}
            style={{margin: "15px 0px"}}
            className="dashboard-module-list"
          >

      
            {module.map((video, j) => (
              <Link 
                to={`/dashboard/videos/${video.moduleId}/${video.videoId}`}
                className="dashboard-video-list-card-link"
              >
              <Card.Grid style={gridStyle}>
                
                  {`${video.videoTitle}`}
              
              </Card.Grid>
              </Link>
            ))}
    
          </Card>
        );
      })}
    </div>
  );
};

export default InstructorVideoGrid;
