import React from 'react';
import { useHistory, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import { Button, message, Result, Spin } from 'antd';
import { LOGIN_URL, SIGNUP_URL } from './const';


const AuthenticatedRoute = ({ currentUser, fetchingUserStatus, isAuthenticated, ...props}) => {

    let history = useHistory();

    if(fetchingUserStatus === "SUCCESS" && isAuthenticated && currentUser) {
        return <Route {...props} />
    } else if(fetchingUserStatus === "FAILED"){
        
        return  <div className="centered-content">
                    <Result
                        status="403"
                        title="403"
                        subTitle="You must be signed in to access this page"
                        extra={[
                            <Button className='login-btn' type='primary'>
                                <a href={`${SIGNUP_URL}`}>
                                Sign Up
                                </a>
                            </Button>,
                            <Button className='login-btn' ghost>
                                <a href={`${LOGIN_URL}`}>
                                Login
                                </a>
                            </Button>
                        ]}
                    />
                </div>
    } else {
        return <div className="loading-authentication">
                    <Spin />
                </div>
    }
}

const mapStateToProps = (state) => {
    return {
        fetchingUserStatus: state.userReducer.fetchingUserStatus,
        isAuthenticated: state.userReducer.isAuthenticated,
        currentUser: state.userReducer.user
    }
}
export default connect(mapStateToProps, {})(AuthenticatedRoute);