const initialState = {
    categories: [],
    fetchCategoriesStatus: "NOT LOADED",
    demoRequestStatus: "NOT LOADED",
}

export default (state=initialState, action) => {
    switch(action.type){
        case "SET_DEMO_REQUEST_STATUS":
            return Object.assign({}, state, { demoRequestStatus: action.status })
        case "SET_FETCH_CATEGORIES_STATUS":
            return Object.assign({}, state, { fetchCategoriesStatus: action.status })
        case "SET_ISSUE_CATEGORIES":
            return Object.assign({}, state, { categories: action.categories })
        default:
            return state
    }
}