import React from "react";
import "./style.css";
import { AiOutlineUser } from "react-icons/ai";
import { TbRobot } from "react-icons/tb";
import Typewriter from "typewriter-effect";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import Text from "./Text.js";
import Feedback from "./Feedback";

const Chat = ({ message, scrollToBottom }) => {
    const disclaimer =
        "Disclaimer: cuGPT is a beta product, and may use chatGPT, youchat, bard and other generative AI's to create output. The output from AI may not be accurate. When in doubt, please consult course staff for clarification. Also provide ratings, and feedback for the generative AI outputs so we can improve the cuGPT feature. Thank you for helping us expand the capabilities of cubits videos using generative AI.";

    if (message.user == "GPT") {
        return (
            <div>
                <div className="GPT-box">
                    <div className="icon">
                        <TbRobot id="GPT-icon" size={20} />
                    </div>
                    <div className="message-content">
                        {!message.mcq ? (
                            <Text message={message.content} />
                        ) : (
                            <MultipleChoiceQuestion
                                data={message.content}
                                scrollToBottom={scrollToBottom}
                            />
                        )}
                    </div>
                </div>

                {(message.element === 2 || message.element === 3) &&
                    message.elementId &&
                    message.elementId !== -1 && (
                        <div className="feedback">
                            <Feedback
                                element={message.element}
                                elementId={message.elementId}
                            />
                        </div>
                    )}
            </div>
        );
    } else if (message.user == "USER") {
        return (
            <div className="user-box">
                <AiOutlineUser id="user-icon" size={17} />
                <div className="message-content">{message.content}</div>
            </div>
        );
    } else if (message.user == "WELCOME") {
        return (
            <div>
                <div className="GPT-box">
                    <div className="icon">
                        <TbRobot id="GPT-icon" size={20} />
                    </div>

                    <div className="message-content">
                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter.typeString(message.content).start();
                            }}
                            options={{
                                delay: 30
                            }}
                        />

                        <div id="disclaimer">{disclaimer}</div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Chat;
