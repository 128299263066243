import { Button, Carousel, Col, Drawer, Row, Tag, Typography } from 'antd';
import QuizQuestionOption from '../../../Collections/Video/QuizQuestions/QuizQuestionOption';
import RichTextPreview from '../../../Components/RichTextPreview';
import { useState } from 'react';
import { convertFromRaw, EditorState } from 'draft-js';


const { Link } = Typography;

const QuizQuestionAttempt = ({index, attempt, question}) => {

    const [visibleAttempt, setVisibleAttempt] = useState(false);
    const [revealed, setRevealed] = useState();
    const [submitted, setSubmitted] = useState(true);


    const renderQuestionType = () => {
        if(question.questionType == "select_one"){
            return "Select one"
        } else if(question.questionType == "choose_all_that_apply"){
            return "Choose all that apply"
        } else if(question.questionType == "open_ended"){
            return "Your answer"
        }
    }

    const renderDate = (inputDate) => {
        let date = new Date(inputDate);
        return `${date.toDateString()} at ${date.toLocaleTimeString()}`;
    }

    const calculateScore = (attempt, index) => {
        if(index < 3){
            return 100 * Math.pow(0.5, index)
        }else {
            return 0
        }
    }

    const contentState = convertFromRaw(question['text']);
    const editorState = EditorState.createWithContent(contentState);

    return <>
            <div className="quiz-section-video-row">
                <Row gutter={16}>
                    <Col span={2}>
                        {`Attempt ${index + 1}`}
                    </Col>
                    <Col span={4}>
                        {renderDate(attempt.submittedAt)}
                    </Col>
                    <Col span={4}>
                        {attempt.numCorrect}
                    </Col>
                    <Col span={4}>
                        {attempt.numIncorrect}
                    </Col>
                    <Col span={4}>
                        {attempt.maxCorrectAnswer}
                    </Col>
                    <Col span={4}>
                        <Link onClick={()=>setVisibleAttempt(true)}>View Attempt</Link>
                    </Col>
                </Row>
            </div>
            <Drawer
                width={640}
                placement="right"
                closable={false}
                onClose={()=>setVisibleAttempt(false)}
                visible={visibleAttempt}
                title={question.title}
                footer={
                    <div
                        style={{
                        textAlign: 'right',
                        }}
                    >
                        <Button  style={{ marginRight: 8 }} onClick={()=>setVisibleAttempt(false)}>
                            Close Preview
                        </Button>
                    </div>
                }
            >
                <div className="quizquestion-drawer-content-width">
                    <RichTextPreview editorState={editorState}/>
                    <br/>
                    <Carousel style={{background: '#364d79'}} autoplay className="quizquestion-carousel-width">
                        {question.images.map((imageFile, index) => <div key={index}><img alt="example" style={{ width: '100%' }} src={imageFile.image} /></div>)}
                    </Carousel>
                    <br/>
                    <Tag color="geekblue">{renderQuestionType()}</Tag>
                    {
                        question.options.map((option, index) => <QuizQuestionOption key={`option[${index}]`}
                                                                    handleOnClick={() => {}}
                                                                    selected={attempt.selectedAnswers.includes(option.id)} 
                                                                    submitted={submitted}
                                                                    option={option}
                                                                    latestAttempt={attempt}
                                                                />)
                    }
                    </div>
            </Drawer>
        </>
}

export default QuizQuestionAttempt;