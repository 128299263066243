import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Badge, Layout, Tabs, Typography } from "antd";

import VideoTranscript from "./Transcript/VideoTranscript";
import QuizQuestionsContainer from "./QuizQuestions/QuizQuestionsContainer";
import ChatbotContainer from "./CubitsGPT/ChatbotContainer.js";
import PromotionTab from "./Promotion/PromotionTab.js";

const { Title } = Typography;
const { TabPane } = Tabs;

const VideoPageFeatureMenu = ({
    collapsedTranscript,
    currentTimestamp,
    pauseVideo,
    quizQuestions,
    seekOnTranscriptClick,
    seekToVideoTimestamp,
    singleCollection,
    singleVideo,
    currentUser,
    isAuthenticated,
    moduleAccess
}) => {
    const pendingQuestions = () => {
        /*let count = 0;
        quizQuestions.forEach(question => {
            if(currentTimestamp >= question.endVideoTimestamp && question.latestAttempt == null){
                count += 1
                return 
            }else{
                return
            }
        })
        return count*/
        return quizQuestions.length;
    };

    return (
        <Layout.Sider
            className={collapsedTranscript ? "" : "visible-transcript-sider"}
            width={"30vw"}
            collapsedWidth={0}
            collapsible
            collapsed={collapsedTranscript}
            trigger={null}>
            <Tabs
                defaultActiveKey={
                    singleCollection?.promotions?.length > 0
                        ? "4"
                        : singleCollection?.gptEnabled && singleVideo
                        ? "3"
                        : "1"
                }
                id="transcript-tab-container">
                <TabPane
                    tab={
                        <Badge size="small" count={0}>
                            <div style={{ padding: "6px 3px 0px 0px" }}>
                                Transcript
                            </div>
                        </Badge>
                    }
                    key="1"
                    className="transcript-tab">
                    <div id="transcript-tab-content">
                        <VideoTranscript
                            seekOnTranscriptClick={seekOnTranscriptClick}
                        />
                    </div>
                </TabPane>

                <TabPane
                    forceRender
                    tab={
                        <Badge size="small" count={pendingQuestions()}>
                            <div style={{ padding: "6px 3px 0px 0px" }}>
                                Questions
                            </div>
                        </Badge>
                    }
                    key="2"
                    className="transcript-tab">
                    <div id="transcript-tab-content">
                        <QuizQuestionsContainer
                            pauseVideo={pauseVideo}
                            quizQuestions={quizQuestions}
                            seekToVideoTimestamp={seekToVideoTimestamp}
                        />
                    </div>
                </TabPane>

                {singleCollection?.gptEnabled &&
                    singleVideo &&
                    singleCollection && (
                        <TabPane
                            tab={
                                <Badge size="small" count={0}>
                                    <div style={{ padding: "6px 3px 0px 0px" }}>
                                        cuGPT
                                    </div>
                                </Badge>
                            }
                            key="3"
                            className="cugpt-tab">
                            <div id="cugpt-tab-content">
                                <ChatbotContainer
                                    video={singleVideo}
                                    collection={singleCollection}
                                    pauseVideo={pauseVideo}
                                    isCollectionOwner={
                                        singleCollection &&
                                        currentUser &&
                                        singleCollection.primaryAuthor?.id ===
                                            currentUser.id
                                    }
                                />
                            </div>
                        </TabPane>
                    )}

                {singleCollection?.promotions?.length > 0 && (
                    <TabPane
                        tab={
                            <Badge size="small" count={0}>
                                <div className="promo-gradient-btn animation-btn">
                                    Promoted Courses
                                </div>
                            </Badge>
                        }
                        style={{ marginLeft: "auto" }}
                        key="4"
                        className="promotion-tab">
                        <div id="promotion-tab-content">
                            <PromotionTab
                                promotions={singleCollection.promotions}
                                isAuthenticated={isAuthenticated}
                                moduleAccess={moduleAccess}></PromotionTab>
                        </div>
                    </TabPane>
                )}
            </Tabs>
        </Layout.Sider>
    );
};

const mapStateToProps = (state) => {
    return {
        currentTimestamp: state.videoControlReducer.videoTimestamp,
        quizQuestions: state.collectionReducer.quizQuestions,
        singleCollection: state.collectionReducer.singleCollection,
        singleVideo: state.collectionReducer.singleVideo,
        currentUser: state.userReducer.user,
        isAuthenticated: state.userReducer.isAuthenticated,
        moduleAccess: state.collectionReducer.singleCollectionAccess
    };
};

export default connect(mapStateToProps)(VideoPageFeatureMenu);
