import { Button, Typography } from "antd";
import { Link } from "react-router-dom";
import ContactUsForm from "./Components/ContactUsForm";
import { SIGNUP_URL } from "./const";
import { useEffect } from "react";
import { logEvent, trackingEvents } from "./Tracking";

const { Paragraph, Text, Title } = Typography;

const AboutUs = () => {
    useEffect(() => {
        logEvent(trackingEvents.pageView, {
            page: "about-us"
        });
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%"
            }}>
            <div className="centered-container">
                <Title>Our Story</Title>
                <Paragraph className="about-us-paragraph">
                    <Text>
                        In 2015, a hearing-impaired student was enrolled in an
                        Algorithm course at Princeton University. It was a
                        struggle (and expensive) to get lectures transcribed to
                        help the student. So, we embarked on a journey to find a
                        better and more effective solution for that student and
                        many others who can benefit from curated lecture videos.
                        With support from Princeton University 250th innovation
                        fund and other grants, the faculty and students
                        researched and developed a technique for novel
                        integration of video, speech, text, auto-graded
                        assessments, and community, all combined to give an
                        immersive, interactive, and exceptional teaching and
                        learning experience in our courses. Over the years, all
                        Princeton CS students have come to rely on the
                        flexibility and organizational structure provided by
                        CUbits. Now we share this amazing technology with the
                        educational community for free. If you are a great at
                        creating instructional videos, use the platform to
                        distribute and monetize your IP in a fair and equitable
                        manner.
                        <br />
                    </Text>
                </Paragraph>

                <Button type="primary">
                    <Link to="/collections/">Explore Courses</Link>
                </Button>
                <br />
                <br />
                <Title level={3}>Become a creator</Title>
                <Paragraph>
                    CUbits only work with the best of the best. Design, publish
                    and monetize your video content in a whole new way. By
                    creating curated content for the CUbits platform, your
                    content will be enhanced by an interactive AI powered tool
                    giving students, a robust learning experience and a new
                    method to explore and subscribe to your intellectual
                    property. CUbits has innovated a new subscription model that
                    allows learners to purchase what they need, when they need
                    it. Say goodbye to old publishing and monetizing methods.
                    This is how the new generation of students will subscribe
                    and consume content. Maximize your potential to reach more
                    students worldwide with an option that is highly affordable.
                    Reach more students who will value your work and be a part
                    of the evolution in learning while monetizing your content.
                    There is no cost to you to create a course with CUbits.
                </Paragraph>
                <Button type="primary">
                    <a href={`${SIGNUP_URL}`}>Apply to be a Creator</a>
                </Button>
                <br />
                <br />
                <Title level={3}>Authors</Title>
                <Paragraph>
                    We work with publishers and authors who create amazing
                    instructional videos. We have exclusive partnerships with
                    Princeton University Press and other publishers to help
                    create supplemental video content to textbooks. You do not
                    need to be a textbook author to publish with CUbits. If you
                    already have recorded videos or are interested in recording
                    course videos, distributing, and monetizing them through
                    CUbits, please contact us to learn about IP protection,
                    royalty rates, and how to get started
                </Paragraph>

                <ContactUsForm />
            </div>
        </div>
    );
};

export default AboutUs;
