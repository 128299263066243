import { Button, Checkbox, Collapse, Divider, Form, Input, InputNumber, Modal, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddVideoToVideoBank from '../../Components/AddVideoToVideoBank';
import VideoRow from '../../Components/VideoRow';
import { connect } from 'react-redux';
import BorrowedContentPriceBreakdown from '../../Components/BorrowedContentPriceBreakdown';
import Tour from '../../Components/Tour';


const { Text, Title } = Typography; 

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      xl: { span: 8}
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      xl: { span: 8}
    },
};


const CollectionCreatorModuleEditor = ({ allVideos, authorsDict, currentUser, modalVisible, module, modules, policyModel, setModalVisible, updateState, videoBank, index=null, mode="new" }) => {

    const [form] = Form.useForm();
    const [customPrice, setCustomPrice] = useState(0);
    const [useDefaultPrice, setUseDefaultPrice] = useState(true);
    const [tempModuleState, updateTempModuleState] = useState(module);
    const [tempVideoBankState, updateVideoBankState] = useState(videoBank);
    const [tempAllVideosState, updateTempAllVideosState] = useState(allVideos);
    const [policyModelVisible, setPolicyModelVisible] = useState(false);
    const [borrowedPriceBreakdownVisible, setBorrowedPriceBreakdownVisible] = useState(false);

    useEffect(() => {
        updateVideoBankState(videoBank);
    }, [videoBank])


    
    const tutorialSteps = [
        {
            target: '#video-bank',
            content: <div>
                        <p style={{textAlign: 'left'}}>This is the video bank.</p>
                        <p style={{textAlign: 'left'}}>The video bank is a temporary placeholder for videos, of which you can use to move videos to or from modules, or between modules.</p>
                    </div>,
            placement: "right-start",
            disableBeacon: true, 
        }, 
        {
            target: '#videos-in-module',
            content: <div>
                        <p style={{textAlign: 'left'}}>These are the videos in your module.</p>
                        <p style={{textAlign: 'left'}}>Drag videos in this area from the video bank and reorder as necessary.</p>
                    </div>,
            placement: "left-start",
            disableBeacon: true, 
        }, 
        {
            target: '#add-update-module-btn',
            content: <div>
                        <p style={{textAlign: 'left'}}>When you are finished, click here to save your changes to the current module.</p>
                    </div>,
            placement: "right-start",
            disableBeacon: true, 
        }, 
        {
            target: '#import-video-btn',
            content: <div>
                        <p style={{textAlign: 'left'}}>Let's start by adding some videos.</p>
                        <p style={{textAlign: 'left'}}>Click "Import".</p>
                    </div>,
            placement: "right-start",
            disableBeacon: true, 
        }, 
    ];

    const renderTutorial = () => {
        const completedTutorial = localStorage.getItem('completedCreateCollectionTutorials');
        if(completedTutorial == null) {
            return <Tour steps={tutorialSteps} tutorialName='completedCreateCollectionTutorial' />
        } else {
            return
        }
    }


    const handleOnEditModuleFormFinish = (values) => {
        let authorData;
        if(values.primaryAuthorUsername in authorsDict){
            authorData = authorsDict[values.primaryAuthorUsername]
        } else {
            if(currentUser != null){
                authorData = {
                    id: currentUser.id,
                    username: currentUser.username,
                    firstName: currentUser.firstName,
                    lastName: currentUser.lastName,
                }
            } else {
                authorData = {
                    username: "Sample Username",
                    firstName: "FirstName",
                    lastName: "LastName",
                }
            }
        }
        let coAuthors = values.coAuthorUsernames.map(coAuthorUsername => {
            if(coAuthorUsername && coAuthorUsername in authorsDict) {
                return authorsDict[values.primaryAuthorUsername]
            } else {
                if(currentUser != null){
                    return {
                        id: currentUser.id,
                        username: currentUser.username,
                        firstName: currentUser.firstName,
                        lastName: currentUser.lastName,
                    }
                } else {
                    return {
                        username: "Sample Username",
                        firstName: "FirstName",
                        lastName: "LastName",
    
                    }
                }
            }
        })

        if(mode === "edit") {
            let updatedModules = Array.from(modules);
            let updatedData = {
                ...updatedModules[index],
                ...values,
                primaryAuthorUsername: authorData.username,
                primaryAuthor: authorData,
                coAuthors: coAuthors,
                videos: [...tempModuleState.videos],
                price: calculateTotalPrice()
            }
            updatedModules[index] = updatedData

            updateState("modules", [...updatedModules])
            updateState("videoBank", tempVideoBankState)
            updateState("allVideos", tempAllVideosState)
            form.resetFields();
            setModalVisible(false);
            
        } else {
            let newModule = {
                ...tempModuleState,
                ...values,
                key: (new Date()).toLocaleString(),
                primaryAuthorUsername: authorData.username,
                primaryAuthor: authorData,
                coAuthors: coAuthors,
                price: calculateTotalPrice()
            }
            updateState("modules", [...modules, newModule])
            updateState("videoBank", tempVideoBankState)
            updateState("allVideos", tempAllVideosState)
            updateTempModuleState(module);
            updateTempAllVideosState(tempAllVideosState)
            form.resetFields();
            setModalVisible(false);
            
        }
        
    }

    const handleOnEditModuleFormClose = () => {
        setModalVisible(false);
        updateVideoBankState(videoBank);
        updateTempModuleState(module);
        updateTempAllVideosState(allVideos)
        form.resetFields();
    }

    const handleOnDragEnd = (result) => {
  
        if(!result.destination) return;
      
        if(result.source.droppableId === "videos" && result.destination.droppableId === "videoBank") {
            const updatedVideos = Array.from(tempModuleState["videos"])
            const [movedVideo] = updatedVideos.splice(result.source.index, 1);
            const updatedVideoBank = Array.from(tempVideoBankState);
            updatedVideoBank.splice(result.destination.index, 0, movedVideo);

            updateTempModuleState(
                {
                    ...tempModuleState,
                    "videos": updatedVideos
                }
            )
            updateVideoBankState(updatedVideoBank)
        }
        if(result.source.droppableId === "videoBank" && result.destination.droppableId === "videos") {
            const updatedVideoBank = Array.from(tempVideoBankState);
            const [movedVideo] = updatedVideoBank.splice(result.source.index, 1);
            const updatedVideos = Array.from(tempModuleState["videos"])
            updatedVideos.splice(result.destination.index, 0, movedVideo);

            updateTempModuleState(
                {
                    ...tempModuleState,
                    "videos": updatedVideos
                }   
            )
            updateVideoBankState(updatedVideoBank)
        }
        if(result.source.droppableId === "videos" && result.destination.droppableId === "videos") {
            const updatedVideos = Array.from(tempModuleState["videos"]);
            const [reorderedVideo] = updatedVideos.splice(result.source.index, 1);
            updatedVideos.splice(result.destination.index, 0, reorderedVideo);
            updateTempModuleState(
                {
                    ...tempModuleState,
                    "videos": updatedVideos
                }   
            )
        }

        if(result.source.droppableId === "videoBank" && result.destination.droppableId === "videoBank") {
            const updatedVideoBank = Array.from(tempVideoBankState);
            const [reorderedVideo] = updatedVideoBank.splice(result.source.index, 1);
            updatedVideoBank.splice(result.destination.index, 0, reorderedVideo);
            updateVideoBankState(updatedVideoBank)
        }
        
    }

    const addVideosToVideoBank = (videos) => {
        
        let newVideos = []
        videos.forEach(video => {
            let found = tempAllVideosState.findIndex(existingVideo => existingVideo.embedId === video.embedId);
            if(found === -1) newVideos.push(video);
        })
        updateVideoBankState([
            ...newVideos,
            ...tempVideoBankState,
        ])
        updateTempAllVideosState([
            ...newVideos,
            ...tempAllVideosState,
        ])
    }


    const calculateBorrowedPrice = () => {
        let total = 0
        let licensedModules = {}
        tempModuleState.videos.forEach(video => {
            
            if(video.parentModule != module.id){
                if(!(video.parentModule in licensedModules)){
                    licensedModules[video.parentModule] = video.parentModulePrice
                    total += video.parentModulePrice
                }
            }
        })
        
        return total;
    }

    const calculateTotalPrice = () => {
        let total = 0
        if(useDefaultPrice){
            if(currentUser && policyModel != null){
                total += policyModel.platformDefaultPrice
            }
            
        }else {
            if(customPrice){
                total += customPrice
            }
        }
        let borrowedPrice = calculateBorrowedPrice()
        total += borrowedPrice;
        
        return total;
    }

    const handleOnCheck = () => {
        setUseDefaultPrice(!useDefaultPrice)
        form.resetFields(['customPrice'])
    }

    const updateVideoTitleInModule = (newTitle, module, video, videoIndex) => {
        let newVideoData = {
            ...video,
            title: newTitle
        }

        let newVideosArray = Array.from(module.videos);
        newVideosArray[videoIndex] = newVideoData;

        let newModuleData = {
            ...module,
            videos: newVideosArray
        }

        updateTempModuleState(newModuleData);
    }

    const updateVideoTitleInVideoBank = (newTitle, video, videoBank, videoIndex) => {
        let newVideoData = {
            ...video,
            title: newTitle
        }
        let newVideosArray = Array.from(module.videos);
        newVideosArray[videoIndex] = newVideoData;

        updateVideoBankState(newVideosArray)
    }


    const removeFromVideoBank = (videoIndex) => {
        let newVideoBankState = Array.from(tempVideoBankState)
        newVideoBankState.splice(videoIndex, 1);
        updateVideoBankState(newVideoBankState);
    }

    const PriceCustomization = () => {
        if(currentUser != null){
            return <Space align='baseline'>
                        <Checkbox checked={useDefaultPrice} onChange={handleOnCheck}>Use Default Price</Checkbox>
                        {
                            useDefaultPrice ? <InputNumber value={policyModel.platformDefaultPrice} disabled style={{marginBottom: "16px"}}/> : <Form.Item
                                name="customPrice"
                            >
                                <InputNumber min={0} defaultValue={0} onChange={(value)=>{setCustomPrice(value)}}/>
                            </Form.Item>
                        }
                        
                    </Space>
        } else { 
            return <Space align='baseline'>
                        <Checkbox checked={useDefaultPrice}>Use Default Price</Checkbox>
                        {
                            useDefaultPrice ? <InputNumber value={0} disabled style={{marginBottom: "16px"}}/> : <Form.Item
                                name="customPrice"
                            >
                                <InputNumber min={0} defaultValue={0} onChange={(value)=>{setCustomPrice(value)}}/>
                            </Form.Item>
                        }
                        
                    </Space>
        }
        
    }

    return <Modal
                visible={modalVisible}
                maskClosable={false}
                footer={null}
                onCancel={handleOnEditModuleFormClose}
                width={"90vw"}
            >
                <div>
                    {renderTutorial()}
                <DragDropContext onDragEnd={handleOnDragEnd}>     
                    <div className="new-module-container">
                        
                            <div id="video-bank" className="video-bank-container">
                                <div className="video-bank-header">                                    
                                    <Space direction='vertical'>
                                        <Title level={4} style={{margin: 0}}>Video Bank</Title>
                                        <div>
                                            <Text type="secondary">Drag videos from below into the module</Text>
                                            <br/>
                                            <Text>Video Bank Count: <Text type="success">{tempVideoBankState.length} video(s)</Text></Text>
                                        </div>
                                    </Space>
                                    <br/><br/>
                                    <AddVideoToVideoBank 
                                        addVideosToVideoBank={addVideosToVideoBank}
                                    />
                                </div>

                                
                                
                                <div className="video-bank">  
                                    <Droppable droppableId="videoBank">
                                    {
                                        (provided, snapshot) => (
                                                    <ol 
                                                        className="video-bank-list" 
                                                        {...provided.droppableProps} 
                                                        ref={provided.innerRef}
                                                        style={{
                                                            background: snapshot.isDraggingOver ? "lightblue" : "#1d1d1d",
                                                            transition: "all 0.2s ease"
                                                        }}
                                                    >
                                                        {
                                                            tempVideoBankState.map((video, videoIndex) => {
                                                                return (
                                                                    <Draggable key={`${video.embedId}`} draggableId={`${video.embedId}`} index={videoIndex}>
                                                                        {
                                                                            (provided) => (
                                                                                <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                                    <VideoRow 
                                                                                        index={videoIndex}
                                                                                        video={video}
                                                                                        removeable={true}
                                                                                        removeFromVideoBank={removeFromVideoBank} 
                                                                                        updateTempState={(newTitle)=>updateVideoTitleInVideoBank(newTitle, video, tempVideoBankState, videoIndex)}
                                                                                    />
                                                                                </li>
                                                                            )
                                                                        }   
                                                                    </Draggable>
                                                                )
                                                            })
                                                        }
                                                        {provided.placeholder}
                                                    </ol>
                                        )
                                    }
                                    </Droppable>
                                </div>      
                            </div>
                        
                            <div className="collection-creator-new-module">
                                <Title level={4}>{mode === "edit" ? "Editing module" : "Creating New Module"} </Title>
                                <Form
                                    {...formItemLayout}
                                    form={form}
                                    name="basic"
                                    layout="vertical"
                                    initialValues={module}
                                    onFinish={handleOnEditModuleFormFinish}
                                    //onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        label="Module Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please give your module a name!' }]}
                                    >
                                        <Input  />
                                    </Form.Item>
                                    
                                    
                                        <div id="videos-in-module">
                                            <Title level={5}>Videos</Title>
                                            <Text type="secondary">Drag and drop videos to and from Video Bank</Text>
                                            
                                            <div  className="videos-in-module-list-container">
                                                                
                                                    <Droppable droppableId="videos" direction='horizontal'>
                                                    {
                                                        (provided, snapshot) => (
                                                                    <div
                                                                        className="collection-creator-videos-in-module-list" 
                                                                        {...provided.droppableProps} 
                                                                        ref={provided.innerRef}
                                                                        style={{
                                                                            background: snapshot.isDraggingOver ? "lightblue" : "#1d1d1d",
                                                                            transition: "all 0.2s ease"
                                                                        }}
                                                                    >
                                                                        {
                                                                            tempModuleState.videos.map((video, videoIndex) => {
                                                                                return (
                                                                                    <Draggable key={`${video.embedId}`} draggableId={`${video.embedId}`} index={videoIndex}>
                                                                                        {
                                                                                            (provided) => (
                                                                                                <div
                                                                                                    {...provided.draggableProps} 
                                                                                                    {...provided.dragHandleProps} 
                                                                                                    ref={provided.innerRef} 
                                                                                                    className="draggable-video"
                                                                                                >
                                                                                                    <VideoRow 
                                                                                                        video={video} 
                                                                                                        updateTempState={(newTitle)=>updateVideoTitleInModule(newTitle, tempModuleState, video, videoIndex)}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }   
                                                                                    </Draggable>
                                                                                )
                                                                            })
                                                                        }
                                                                        {provided.placeholder}
                                                                    </div>
                                                        )
                                                    }
                                                    </Droppable>
                            
                                        
                                            </div>
                                        </div>
                                    <br/>
                                  
                                    <Form.Item 
                                        label="Author"
                                        name={['primaryAuthorUsername']}
                                        fieldKey={['primaryAuthorUsername']}
                                        tooltip="If you are not the primary author, search for the user's account by username or name. Only this user will get paid for any purchases. If you leave this blank, the primary author will be set to you."
                                        hidden
                                    >
                                        
                                    </Form.Item>
                                    <Form.Item 
                                        label="Co-Authors"
                                        name={['coAuthorUsernames']}
                                        fieldKey={['coAuthorUsernames']}
                                        tooltip="Used mainly for credit purposes only."
                                        hidden
                                    >
                                        
                                    </Form.Item>
                                    
                                    <br/>
                                    <br/>
                                    <div className="price-breakdown">
                                        <Title level={5}>Price Breakdown</Title>
                                        <div className="price-breakdown-row">
                                            <div>
                                                <Text>Price of your content</Text>
                                                <br/>
                                                
                                               
                                            </div>
                                            <PriceCustomization />
                                            
                                        </div>
                                        
                                        <div className="price-breakdown-row">
                                            <div>
                                                <Text>Price of borrowed content</Text>
                                                <br/>
                                                <Text type="secondary" className="price-breakdown-hint" onClick={()=>setBorrowedPriceBreakdownVisible(true)}>How is this calculated?</Text>
                                                <BorrowedContentPriceBreakdown
                                                    module={tempModuleState} 
                                                    setVisible={setBorrowedPriceBreakdownVisible} 
                                                    visible={borrowedPriceBreakdownVisible} 
                                                />
                                            </div>
                                            
                                            <Text>{calculateBorrowedPrice()} Cupoints</Text>
                                        </div>
                                        <Divider style={{ margin: "10px 0px"}} />
                                        <div className="price-breakdown-row">
                                            <Text>Total Price</Text>
                                            
                                            <Text>{calculateTotalPrice()} cupoints</Text>
                                        </div>
                                        
                                    </div>
                                    <br/>
                                    <div>
                                        <Space>
                                            <Button onClick={handleOnEditModuleFormClose}>
                                                Cancel
                                            </Button>
                                            <Button id="add-update-module-btn" type="primary" htmlType='submit' disabled={tempModuleState.videos.length < 1}>
                                                {mode === "edit" ? "Update Module" : "Add Module"}
                                            </Button>
                                        </Space>
                                    </div>
                                    
                                </Form>
                            </div>
                        
                    </div>
                    </DragDropContext>
                </div>

            </Modal>
}

const mapStateToProps = state => {
    return {
        policyModel: state.contentCreationReducer.policyModel,
    }
}

export default connect(mapStateToProps, {})(CollectionCreatorModuleEditor);