import { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, DatePicker, Space, Typography}  from 'antd';
import { Bullet } from '@ant-design/charts';
import moment from 'moment';
import { fetchModuleWatchPercentageData } from '../../actions/dashboard';
import ClassAverageBulletChart from './ClassAverageBulletChart';
import { CaretLeftOutlined, CaretRightOutlined, RightCircleOutlined } from '@ant-design/icons';


const dateFormat = 'MM/DD/YYYY';
const { Text, Title } = Typography;




class ClassAverageWatchActivity extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: moment(),
            moduleIndex: 0
        }
    }
    componentDidMount(){
        if(this.props.selectedCoupon && this.props.collection){
            let moduleId = this.props.collection.modules[this.state.moduleIndex].id
            this.props.fetchModuleWatchPercentageData(this.props.selectedCoupon.code, moduleId, this.state.selectedDate.startOf('week').toISOString(), this.state.selectedDate.endOf('week').toISOString())
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if((prevProps.collection != this.props.collection && this.props.collection) || (this.props.collection && (prevProps.dashboardPoV != this.props.dashboardPoV || prevState.moduleIndex != this.state.moduleIndex))){
            let moduleId = this.props.collection.modules[this.state.moduleIndex].id
            this.props.fetchModuleWatchPercentageData(this.props.selectedCoupon.code, moduleId, this.state.selectedDate.startOf('week').toISOString(), this.state.selectedDate.endOf('week').toISOString())
        }
    }

    handleOnNextClick = () => {
        this.setState({
            moduleIndex: this.state.moduleIndex + 1
        })
    }

    handleOnPreviousClick = () => {
        this.setState({
            moduleIndex: this.state.moduleIndex - 1
        })
    }

    handleOnRangePickerChange = (value) => {
 
        this.setState({
          selectedDate: value
        })
        
    }

    customWeekStartEndFormat = value => {
        return `${moment(value).startOf('week').format(dateFormat)} ~ ${moment(value).endOf('week').format(dateFormat)}`;
    }

    render(){
        // what if collection is null
        return <div id="video-percent-completion">
                <Card className="dashboard-card">
                    <Text>Video % Completion</Text>
                    <br/>
                    <div style={{display:"flex", justifyContent: "space-between", marginBottom: "10px"}}>

                        <Space>
                            <Text type="secondary">Viewing Statistics for</Text>
                            <DatePicker value={this.state.selectedDate} format={this.customWeekStartEndFormat} picker="week" onChange={this.handleOnRangePickerChange} allowClear={false}/>
            
                        </Space>
                        <Space>
                            <Button 
                                icon={<CaretLeftOutlined />}
                                onClick={this.handleOnPreviousClick}
                                disabled={this.props.moduleWatchPercentageDataStatus != "LOADED" || this.state.moduleIndex == 0 || !this.props.collection.modules.length}
                            >
                                Previous
                            </Button>
                            <Button 
                                onClick={this.handleOnNextClick}
                                disabled={this.props.moduleWatchPercentageDataStatus != "LOADED" || this.state.moduleIndex == this.props.collection.modules.length - 1 || !this.props.collection.modules.length}
                            >
                                Next 
                                <CaretRightOutlined />
                            </Button>
                        </Space>
                    </div>
                    <ClassAverageBulletChart 
                        moduleWatchPercentageData={this.props.moduleWatchPercentageData}
                        moduleWatchPercentageDataStatus={this.props.moduleWatchPercentageDataStatus}
                        moduleIndex={this.state.moduleIndex}
                        selectedCoupon={this.props.selectedCoupon}
                        user={this.props.user}
                        dashboardPoV={this.props.dashboardPoV}
                    />
                </Card>
            </div>
    }
}

const mapStateToProps = state => {
    return {
        collection: state.dashboardReducer.collection,
        dashboardPoV: state.userReducer.dashboardPoV,
        selectedCoupon: state.dashboardReducer.selectedCoupon,
        scheduledAccesses: state.dashboardReducer.scheduledAccesses,
        moduleWatchPercentageData: state.dashboardReducer.moduleWatchPercentageData,
        moduleWatchPercentageDataStatus: state.dashboardReducer.moduleWatchPercentageDataStatus,
        user: state.userReducer.user
    }
}
export default connect(mapStateToProps, {fetchModuleWatchPercentageData})(ClassAverageWatchActivity);