import QuizQuestion from './QuizQuestion';
import { Button, Popover, Space, Timeline } from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
const QuizQuestionsContainer = ({pauseVideo, quizQuestions, seekToVideoTimestamp}) => {

    return <>
            <Timeline mode="left">
                {quizQuestions.map((question, index) => <QuizQuestion key={`question[${index}]`} pauseVideo={pauseVideo} question={question} seekToVideoTimestamp={seekToVideoTimestamp}/>)}
            <Timeline.Item color="grey" >End</Timeline.Item>
        </Timeline></>

}

export default QuizQuestionsContainer;

