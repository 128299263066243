import { Card, Col, Empty, List, Row, Skeleton, Statistic, Typography } from 'antd';
import DashboardQnAQuestion from '../../Dashboard/QnA/DashboardQnAQuestion';

const { Text } = Typography;
const QnAStatistics = ({ qnAStatistics, qnAStatisticsStatus}) => {
        return <>
                <Row>
                    <Col span={3}>
                        <Statistic
                            title="QnA Questions" 
                            value={qnAStatistics.askedQuestions.length} 
                            className="dashboard-video-grid-statistic"
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic
                            title="Total Received Instructor Responses" 
                            value={qnAStatistics.receivedInstructorResponses} 
                            className="dashboard-video-grid-statistic"
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            title="Total Received Responses"
                            value={qnAStatistics.receivedResponses}
                            className="dashboard-video-grid-statistic"
                        />
                    </Col>
                </Row>
                <br/>
                
                <Row>
                    <Col span={3}>
                        
                    </Col>
                    <Col span={21}>
                        <Text type="secondary">Questions You've Asked</Text>
                        {
                            qnAStatistics.askedQuestions.length ? <>
                                
                                <List
                                    itemLayout="horizontal"
                                    style={{marginTop: "5px"}}
                                    dataSource={qnAStatistics.askedQuestions}
                                    renderItem={item => (
                                        <DashboardQnAQuestion
                                            question={item} 
                                            selectedDays={null}
                                            renderTag={false}
                                            renderNewReplyStatistics={false}
                                        />
                                    )}
                                />
                            </>
                            :
                            <div className="empty-list-container">
                                <Empty
                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                    imageStyle={{
                                    height: 60,
                                    }}
                                    description={
                                    <span>
                                        You haven't asked any questions in this video yet
                                    </span>
                                    }
                                />
                            </div>
                        }
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col span={3}>
                        <Statistic
                            title="Responses Given" 
                            value={qnAStatistics.givenResponses} 
                            className="dashboard-video-grid-statistic"
                        />
                    </Col>
                    <Col span={21}>
                    <Text type="secondary">Questions You've Responded To</Text>
                        {
                            qnAStatistics.questionsRespondedTo.length ?
                                <>
                                    
                                    <List
                                        itemLayout="horizontal"
                                        style={{marginTop: "5px"}}
                                        dataSource={qnAStatistics.questionsRespondedTo}
                                        renderItem={item => (
                                            <DashboardQnAQuestion
                                                question={item} 
                                                selectedDays={null}
                                                renderTag={false}
                                                renderNewReplyStatistics={false}
                                            />
                                        )}
                                    />
                                </>
                            :
                            <div className="empty-list-container">
                                <Empty
                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                    imageStyle={{
                                    height: 60,
                                    }}
                                    description={
                                    <span>
                                        You haven't replied to any questions in this video yet
                                    </span>
                                    }
                                />
                            </div>
                        }
                    </Col>
                </Row>
            </>

}

export default QnAStatistics;