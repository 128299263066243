import { Col, Image, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import Algorithms from "../../algorithms.png";

const { Paragraph, Title, Text } = Typography;

const CollectionInfoCard = ({ collection }) => {
    const CollectionCoverImage = () => {
        return <Image src={Algorithms} preview={false} width={"100%"} />;
    };

    const CollectionTitle = () => {
        return (
            <Link to={`/collections/${collection.id}`}>
                <Title level={4} className="collection-info-card-title">
                    {collection.name}
                </Title>
            </Link>
        );
    };

    return (
        <div className="collection-info-card">
            <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <CollectionCoverImage />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="collection-info-card-text-container">
                        <CollectionTitle />

                        <Text type="secondary">
                            By{" "}
                            <Link
                                to={`/creators/${collection.primaryAuthor.id}`}>{`${collection.primaryAuthor.firstName} ${collection.primaryAuthor.lastName}`}</Link>
                        </Text>

                        <Text type="secondary">
                            {collection.coAuthors.map((coAuthor) => (
                                <Text key={coAuthor.id}>
                                    <Link
                                        to={`/creators/${coAuthor.id}`}>{`, ${coAuthor.firstName} ${coAuthor.lastName}`}</Link>
                                </Text>
                            ))}
                        </Text>
                        {collection.university ? (
                            <>
                                {collection.universityLink ? (
                                    <Text type="secondary">
                                        <span> | </span>
                                        <a
                                            href={collection.universityLink}
                                            target="_blank">
                                            {collection.university.name}
                                        </a>{" "}
                                    </Text>
                                ) : (
                                    <Text type="secondary">
                                        <span> | </span>
                                        {collection.university.name}
                                    </Text>
                                )}
                            </>
                        ) : null}
                        <Paragraph className="collection-info-card-description">
                            <Text type="secondary">
                                {collection.description}
                            </Text>
                        </Paragraph>
                        <Paragraph className="collection-info-card-long-description">
                            {collection.longDescription}
                        </Paragraph>
                        <Paragraph>
                            <Text>Total Value: 200 cupoints</Text>
                        </Paragraph>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CollectionInfoCard;
