import { Col, Image, Input, Row, Skeleton, Space, Typography } from 'antd';


const { Search } = Input;
const { Paragraph, Text, Title } = Typography;

const LoadingModuleList = ({ collection = true, collectionNetPrice, mode="purchase" }) => {

    return <div className="collection-info-card collection-info-card-loading">
        
        <Row>
            
            <Skeleton loading={true} active paragraph={false} title={{ width: 100 }}/>
            {
                collection ? <>
                            <br/>
                            <Space>
                                <Skeleton.Button loading={true} active size={'small'} shape={'default'} block />
                                <Skeleton.Button loading={true} active size={'small'} shape={'default'} block />
                                <Skeleton.Button loading={true} active size={'small'} shape={'default'} block />
                                <Skeleton.Button loading={true} active size={'small'} shape={'default'} block />
                                <Skeleton.Button loading={true} active size={'small'} shape={'default'} block />
                                <Skeleton.Button loading={true} active size={'small'} shape={'default'} block />
                            </Space>
                        </> : <></>
            }
        </Row>
        <br/>
        <div className="loading-module-list-module"></div>
        <br/>
        <Row gutter={[16, 16]}>
            {
                [0, 0, 0, 0].map((video, index) => {
                    return <Col 
                                xs={24} 
                                sm={12} 
                                md={8} 
                                lg={6} 
                                key={index}
                            >
                                <div className="loading-module-list-module-video">
                                    <div className="loading-module-list-module-video-image"></div>
                                    <div className="loading-module-list-module-video-title">
                                        <Skeleton loading={true} active paragraph={false}/>
                                    </div>
                                </div>
                            </Col>
                })
            }
        </Row>
    </div>
}

export default LoadingModuleList;