import { Button, Col, Image, Row, Tooltip, Typography } from "antd";

import { Link } from "react-router-dom";
import CollectionAvatarUpload from "../CollectionAvatarUpload";
import CollectionInfoCardActions from "./CollectionInfoCardActions";

import CertificateBadgePNG from "./../assets/images/award.png";
import CollectionThumbnailPreviewer from "./CollectionThumbnailPreviewer";
import {
    EditOutlined,
    FolderOutlined,
    LinkOutlined,
    PlaySquareOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import Cupoints from "./Cupoints";

const { Paragraph, Text, Title } = Typography;

const CollectionInfoCard = ({
    collection,
    collectionNetPrice,
    hasAccessToEntireCollection = false,
    moduleAccess,
    mode = "purchase",
    type = "default",
    certificationView = false,
    isAuthenticated = false,
    redirectToCertificatePage = false,
    creationStep = false,
    afterCourseNameUpdate = () => {},
    setEditAuthorMode = () => {},
    setEditDescriptionMode = () => {},
    setEditLongDescriptionMode = () => {}
}) => {
    const CollectionCoverImage = () => {
        if (mode === "purchase" && type === "list") {
            const getImageUrl = (parsedCollection) => {
                if (
                    redirectToCertificatePage &&
                    parsedCollection?.certification?.image
                ) {
                    return collection.certification.image;
                }

                return parsedCollection.coverImage.image;
            };
            return (
                <Link
                    to={
                        redirectToCertificatePage && collection.certification
                            ? `/collections/${collection.id}/certification/`
                            : `/collections/${collection.id}`
                    }>
                    <CollectionThumbnailPreviewer
                        videoType={
                            redirectToCertificatePage
                                ? collection?.certification?.promotionVideoType
                                : collection?.introVideoType
                        }
                        videoUrl={
                            redirectToCertificatePage
                                ? collection?.certification?.promotionVideoUrl
                                : collection?.introVideoUrl
                        }
                        thumbnail={
                            <Image
                                src={getImageUrl(collection)}
                                preview={false}
                                width={"100%"}
                            />
                        }
                    />
                </Link>
            );
        } else if (mode === "preview") {
            return (
                <div className="collection-info-card-preview-image">
                    <Title level={3}>
                        You can upload a cover image after creating your course
                    </Title>
                </div>
            );
        } else {
            return (
                <>
                    <CollectionThumbnailPreviewer
                        videoType={collection?.introVideoType}
                        videoUrl={collection?.introVideoUrl}
                        thumbnail={
                            <Image
                                src={collection.coverImage.image}
                                preview={false}
                                width={"100%"}
                            />
                        }
                    />
                </>
            );
        }
    };

    const CertificateBadge = () => {
        return (
            <>
                <div className="course-certificate-badge-container">
                    <Tooltip
                        placement="bottom"
                        title={"Get a validated certificate on this course"}>
                        <img
                            src={CertificateBadgePNG}
                            className="course-certificate-badge"
                        />
                    </Tooltip>
                </div>
            </>
        );
    };

    const CollectionTitle = () => {
        if (mode === "purchase" && type === "list") {
            return (
                <Link
                    to={
                        redirectToCertificatePage && collection.certification
                            ? `/collections/${collection.id}/certification/`
                            : `/collections/${collection.id}`
                    }>
                    <Title level={4} className="collection-info-card-title">
                        {collection.name}
                    </Title>
                </Link>
            );
        }

        return (
            <Title level={4} className="collection-info-card-title">
                {creationStep ? (
                    <>
                        <Paragraph
                            ellipsis={{
                                rows: 1,
                                tooltip: true
                            }}
                            editable={{
                                autoSize: {
                                    maxRows: 1
                                },
                                onChange: afterCourseNameUpdate
                            }}>
                            {collection.name}
                        </Paragraph>
                    </>
                ) : (
                    <>{collection.name}</>
                )}
            </Title>
        );
    };

    const secondsToHms = (seconds, includeSeconds = true) => {
        seconds = Number(seconds);
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor((seconds % 3600) % 60);
        const hDisplay = h > 0 ? h + "hr " : "";
        const mDisplay = m > 0 ? m + "min " : "";
        const sDisplay = includeSeconds && s > 0 ? s + "sec" : "";
        return hDisplay + mDisplay + sDisplay;
    };

    return (
        <div className="collection-info-card">
            <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    {mode === "instructor" ? (
                        <CollectionAvatarUpload
                            collectionId={collection.id}
                            existingCoverImage={collection.coverImage.image}
                        />
                    ) : (
                        <>
                            {collection.certification && <CertificateBadge />}
                            <CollectionCoverImage />
                        </>
                    )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="collection-info-card-text-container">
                        <CollectionTitle />
                        {mode === "preview" ? (
                            <>
                                <Text type="secondary">{`${collection.primaryAuthor.firstName} ${collection.primaryAuthor.lastName}`}</Text>
                            </>
                        ) : (
                            <>
                                <Text type="secondary">
                                    By{" "}
                                    <Link
                                        to={`/creators/${collection.primaryAuthor.id}`}>{`${collection.primaryAuthor.firstName} ${collection.primaryAuthor.lastName}`}</Link>
                                </Text>
                            </>
                        )}
                        {mode === "preview" ? (
                            <Text type="secondary">
                                {collection.coAuthors.map((coAuthor) => (
                                    <Text
                                        key={coAuthor.id}
                                        type="secondary">{`, ${coAuthor.firstName} ${coAuthor.lastName}`}</Text>
                                ))}
                            </Text>
                        ) : (
                            <Text type="secondary">
                                {collection.coAuthors.map((coAuthor) => (
                                    <Text key={coAuthor.id}>
                                        <Link
                                            to={`/creators/${coAuthor.id}`}>{`, ${coAuthor.firstName} ${coAuthor.lastName}`}</Link>
                                    </Text>
                                ))}
                            </Text>
                        )}

                        {mode === "preview" && creationStep && (
                            <Tooltip title="Edit">
                                <Text type="secondary">
                                    <Button
                                        type="ghost"
                                        icon={<EditOutlined />}
                                        onClick={setEditAuthorMode}
                                        style={{
                                            border: "none",
                                            boxShadow: "none"
                                        }}
                                    />
                                </Text>
                            </Tooltip>
                        )}
                        {collection.university ? (
                            <>
                                {collection.universityLink ? (
                                    <Text type="secondary">
                                        <span> | </span>
                                        <a
                                            href={collection.universityLink}
                                            target="_blank">
                                            {collection.university.name}
                                        </a>{" "}
                                    </Text>
                                ) : (
                                    <Text type="secondary">
                                        <span> | </span>
                                        {collection.university.name}
                                    </Text>
                                )}
                            </>
                        ) : null}
                        <Paragraph className="collection-info-card-description">
                            <Text type="secondary" style={{ display: "flex" }}>
                                {collection.description}

                                {creationStep && (
                                    <Tooltip title="Edit">
                                        <Button
                                            type="ghost"
                                            icon={<EditOutlined />}
                                            onClick={setEditDescriptionMode}
                                            style={{
                                                border: "none",
                                                boxShadow: "none"
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </Text>
                        </Paragraph>
                        <Paragraph className="collection-info-card-long-description">
                            {collection.longDescription}

                            {creationStep && (
                                <Tooltip title="Edit">
                                    <Button
                                        type="ghost"
                                        icon={<EditOutlined />}
                                        onClick={setEditLongDescriptionMode}
                                        style={{
                                            border: "none",
                                            boxShadow: "none"
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Paragraph>
                        <div className="collection-details-bullets">
                            {collection?.moduleCount > 0 && (
                                <span>
                                    <FolderOutlined />
                                    {collection.moduleCount} Modules
                                </span>
                            )}
                            {collection?.videoCount > 0 && (
                                <span>
                                    <VideoCameraOutlined />
                                    {collection.videoCount} Videos
                                </span>
                            )}
                            {collection?.totalDuration > 0 && (
                                <span>
                                    <PlaySquareOutlined />
                                    {secondsToHms(
                                        collection.totalDuration || 0,
                                        false
                                    )}
                                    total duration
                                </span>
                            )}

                            {collection?.supplementLink && (
                                <span>
                                    <Link
                                        to={{
                                            pathname: collection.supplementLink
                                        }}
                                        target="_blank">
                                        <LinkOutlined /> Supplement Link
                                    </Link>
                                </span>
                            )}
                        </div>

                        {!certificationView && (
                            <>
                                {/* If not certification view, show collection actions */}
                                <Paragraph>
                                    <Text>
                                        <Cupoints
                                            points={collection.valuePrice}>
                                            Total Value:{" "}
                                        </Cupoints>
                                    </Text>

                                    {collection.promotion && (
                                        <>
                                            <div className="promo-apply-label">
                                                {parseFloat(
                                                    collection.promotion
                                                        .promoDiscount
                                                ) * 100}
                                                {"% "}
                                                PROMOTION APPLIED
                                            </div>
                                        </>
                                    )}
                                </Paragraph>

                                <CollectionInfoCardActions
                                    collection={collection}
                                    collectionNetPrice={collectionNetPrice}
                                    hasAccessToEntireCollection={
                                        hasAccessToEntireCollection
                                    }
                                    mode={mode}
                                    type={type}
                                    redirectToCertificatePage={
                                        redirectToCertificatePage
                                    }
                                />
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CollectionInfoCard;
