import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Col, Collapse, Divider, List, PageHeader, Row, Space, Spin, Tag, Typography} from 'antd';
import {HomeOutlined, LoadingOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import { fetchUsedScheduledLicense } from '../actions/user';
import CouponDataPreferencesModal from './CouponDataPreferencesModal';

const { Title, Text } = Typography;

class UsedCouponPage extends Component {
    componentDidMount() {
        if(!this.props.fetchingUser && this.props.isAuthenticated){
            this.props.fetchUsedScheduledLicense()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
            this.props.fetchUsedScheduledLicense()
        }
    }

    breadcrumbConfig = () => {
        function itemRender(route, params, routes, paths) {
            const last = routes.indexOf(route) === routes.length - 1;
            return last ? (
              <span>{route.breadcrumbName}</span>
            ) : (
              <Link to={route.path}>{route.breadcrumbName}</Link>
            );
        }
        return {
            itemRender: itemRender,
            routes: [
                {
                    path: "/",
                    breadcrumbName: 'Home'
                }, {
                    path: "/collections/",
                    breadcrumbName: "Scheduled Licenses"
                }
            ]
        }
    }
    render(){
        if(this.props.fetchingUser || (!this.props.isAuthenticated && localStorage.getItem("access"))){
            return <Space align='center'>
                <Spin indicator={<LoadingOutlined spin style={{ fontSize: 50 }}/>}/> 
                <Title >Loading...</Title>
            </Space>
        }

        const schedules = <Collapse>
                        {
                            this.props.usedCoupons.map(license => {
                                return <Collapse.Panel 
                                            header={<Space><Text>{license.label}</Text> <Tag>{license.couponCode}</Tag></Space>} 
                                            //extra={<CouponDataPreferencesModal usedCoupon={license}/>}
                                        >
                                        <List
                                          
                                            dataSource={license.scheduledAccess}
                                            pagination={{
                                                pageSize: 5,
                                            }}
                                            renderItem={access => {
                                                let startDate = new Date(access.accessFrom);
                                                let endDate = new Date(access.accessUntil);
                                                let currentDate = new Date();
                                                let isActive = currentDate >= startDate && currentDate < endDate;
                                                let isExpired = currentDate >= startDate && currentDate >= endDate;

                                                var tag;
                                                if(this.props.user.moduleAccess[access.module.id] && this.props.user.moduleAccess[access.module.id].accessType === "permanent"){
                                                    tag = <Tag color='#108ee9'>Purchased</Tag>
                                                }
                                                else if(isActive && !this.props.user.moduleAccess[access.module.id]){
                                                    tag = <Tag color='green'>Active</Tag>
                                                }
                                                else if(isActive){
                                                    tag = <Tag color='#0e7356'>Available</Tag>
                                                }
                                                else if(isExpired){
                                                    tag = <Tag color='#f50'>Expired</Tag>
                                                }else {
                                                    tag = <Tag>Inactive</Tag>
                                                }

                                                let linkToModuleCollection = access.module.parentCollection ? <Link to={`/collections/${access.module.parentCollection}/${access.module.parentCollectionUnlistedKey ? `?key=${access.module.parentCollectionUnlistedKey}` : ""}`}>
                                                                                                                {access.module.name}
                                                                                                            </Link> : <Text disabled>{access.module.name} (Removed from course)</Text>


                                                return <List.Item actions={[tag]} className="coupon-schedule">
                                                    <Row style={{marginBottom: 10}}>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            {linkToModuleCollection}
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            Access From:
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Space wrap>
                                                                <Text keyboard className="date-tag">{startDate.toLocaleString()}</Text> → 
                                                                <Text keyboard className="date-tag">{endDate.toLocaleString()}</Text>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                  
                                                </List.Item>
                                            }}
                                        />
                                        </Collapse.Panel>
                  
                            })
                    }</Collapse>
        return  <div id="scheduled-licenses-page">
                        <Space className="ant-space-responsive">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/"><HomeOutlined /></Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>My Courses</Breadcrumb.Item>
                            </Breadcrumb>
                        </Space>
                   
                        <Space direction="vertical" size={2}>
                        
                            <br/>
                            <Title level={4}>Legend</Title>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Row>
                                        <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                                            <Tag color='#108ee9'>Purchased</Tag>
                                        </Col>
                                        <Col xs={24} sm={20} md={20} lg={20} xl={20}>
                                            <Text>You own this module and can access it at any time</Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Row>
                                        <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                                            <Tag color='#0e7356'>Available</Tag>
                                        </Col>       
                                        <Col xs={24} sm={20} md={20} lg={20} xl={20}>
                                            <Text>Currently available to you until the end date as listed</Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Row>
                                        <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                                            <Tag color='green'>Active</Tag>
                                        </Col> 
                                        <Col xs={24} sm={20} md={20} lg={20} xl={20}>
                                            <Text>Not available to you, but currently available to everyone in the course*</Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Row>
                                        <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                                            <Tag color='#f50'>Expired</Tag>
                                        </Col>
                                        <Col xs={24} sm={20} md={20} lg={20} xl={20}>
                                            <Text>No longer available to you </Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Row>
                                        <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                                            <Tag>Inactive</Tag>
                                        </Col>    
                                        <Col xs={24} sm={20} md={20} lg={20} xl={20}>
                                            <Text>Not yet available to you until the start date as listed </Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br/>
                            
                        </Space>


                    
                    <Divider orientation="left" orientationMargin="0">
                        My Classes
                    </Divider>
                    {schedules}
                   
                </div>
    }
}

const mapStateToProps = state => {
    return {
        usedCoupons: state.userReducer.scheduledLicenses,
        fetchingUser: state.userReducer.fetchingUser,
        isAuthenticated: state.userReducer.isAuthenticated,
        user: state.userReducer.user
    }
}
export default connect(mapStateToProps, {fetchUsedScheduledLicense})(UsedCouponPage);