import { Button, Carousel, Col, Drawer, Row, Tag, Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useState } from 'react';
import QuizQuestionOption from '../../../Collections/Video/QuizQuestions/QuizQuestionOption';
import RichTextPreview from '../../../Components/RichTextPreview';
import QuizQuestionAttempt from './QuizQuestionAttempt';
import { convertFromRaw, EditorState } from 'draft-js';


const QuizQuestion = ({ currentUser, selectedCoupon, question, setQuizQuestion}) => {
    const [visibleQuestion, setVisibleQuestion] = useState(false);
    const [visibleAttemptHistory, setVisibleAttemptHistory] = useState(false);
    const [revealed, setRevealed] = useState(false);
    const [selected, setSelected] = useState(false);
    const [submitted, setSubmitted] = useState(false);


    const contentState = convertFromRaw(question['text']);
    const editorState = EditorState.createWithContent(contentState);

    const toggleRevealedAnswers = () => {
        setSubmitted(!submitted);
        setSelected(!selected);
        setRevealed(!revealed);
    }

    const toggleQuestionExpansion = () => {
        let element = document.getElementById(`dashboard-quiz-section-question-attempts-${question.id}`);
        setVisibleAttemptHistory(!visibleAttemptHistory)
        if(element.style.maxHeight) {
            element.style.maxHeight = null;
        } else {
            element.style.maxHeight = element.scrollHeight + "px";
        }
       
        //setTimeout(()=>element.lastChild.scrollIntoView({behavior: "smooth", block: "center"}), 300)
    }

    const renderQuestionType = () => {
        if(question.questionType == "select_one"){
            return "Select one"
        } else if(question.questionType == "choose_all_that_apply"){
            return "Choose all that apply"
        } else if(question.questionType == "open_ended"){
            return "Your answer"
        }
    }

    const calculateSimpleScore = () => {
        let correctAttemptIndex = question.attempts.findIndex(attempt => attempt.numCorrect === attempt.maxCorrectAnswer && attempt.numIncorrect === 0)
        if(correctAttemptIndex < 3){
            return 100 * Math.pow(0.5, correctAttemptIndex)
        }else {
            return 0
        }
    }

    return <div >
            <div className="quiz-section-quiz-header" onClick={toggleQuestionExpansion}>
                {question.title}
                <Button size="small" onClick={(event)=>{
                    event.stopPropagation();
                    setVisibleQuestion(true)
                }}>View Question</Button>
            </div>
            <div style={{padding: "0px 0px 0px 20px"}} id={`dashboard-quiz-section-question-attempts-${question.id}`} className={`dashboard-quiz-section-question-attempts-container`}>
                <div className="quiz-section-video-column-headers">
                <Row gutter={16}>
                    <Col span={2}>
                        {`Attempt #`}
                    </Col>
                    <Col span={4}>
                        Submitted At
                    </Col>
                    <Col span={4}>
                        # of correctly selected options
                    </Col>
                    <Col span={4}>
                        # of incorrectly selected options
                    </Col>
                    <Col span={4}>
                        # of true correct options
                    </Col>
                    <Col span={4}>
                        Actions
                    </Col>
                </Row>
                </div>
                { question.attempts.length ? <>
                    {
                        question.attempts.map((attempt, index) => (
                            <QuizQuestionAttempt attempt={attempt} index={index} question={question} />
                        )) 
                       
                    } 
                     <div className="quiz-section-video-column-headers">
                        <Row gutter={16}>
                            <Col span={14}>
                            
                            </Col>
                            <Col span={4}>
                                {`Simple Score (Max 100): `}
                            </Col>
                            <Col span={4}>
                                {calculateSimpleScore()}
                            </Col>
                        </Row>
                     </div>
                    </> : <div className="quiz-section-video-row">This user has not attempted this quiz question yet.</div> 
                }
            </div>
            <Drawer
                width={640}
                placement="right"
                closable={false}
                onClose={()=>setVisibleQuestion(false)}
                visible={visibleQuestion}
                title={question.title}
                footer={
                    <div
                        style={{
                        textAlign: 'right',
                        }}
                    >
                        <Button  style={{ marginRight: 8 }} onClick={()=>setVisibleQuestion(false)}>
                            Close Preview
                        </Button>
                        {
                            selectedCoupon.createdById === currentUser.id ?
                            <Button onClick={toggleRevealedAnswers}>
                                <EyeOutlined /> {revealed ? "Hide Answers" : "Reveal Answers"}
                            </Button>
                            : null
                        }
                        
                    </div>
                }
            >
                <div className="quizquestion-drawer-content-width">
                    <RichTextPreview editorState={editorState}/>
                    <br/>
                    <Carousel style={{background: '#364d79'}} autoplay className="quizquestion-carousel-width">
                        {question.images.map((imageFile, index) => <div key={index}><img alt="example" style={{ width: '100%' }} src={imageFile.image} /></div>)}
                    </Carousel>
                    <br/>
                    <Tag color="geekblue">{renderQuestionType()}</Tag>
                    {
                        question.options.map((option, index) => <QuizQuestionOption key={`option[${index}]`}
                                                                    handleOnClick={() => {}}
                                                                    selected={selected} 
                                                                    submitted={submitted}
                                                                    option={option}
                                                            />)
                    }
                    </div>
            </Drawer>
        </div>
}

export default QuizQuestion;