import CollectionInfoCard from "../../Components/CollectionInfoCard";
import CollectionModulesList from "../../Components/CollectionModulesList";
import { Alert, Button, Space } from 'antd';
import { connect } from 'react-redux';
import { createCollection } from "../../actions/contentCreation";
import Tour from "../../Components/Tour";

const ReviewCollection = ({authorsDict, collection, createCollection, currentUser, goToNextStep, goToPreviousStep, modules }) => {

    let previewCollectionData = {
        ...collection,
        modules: modules
    }

    const handleOnPublishClick = () => {
        goToNextStep()
    }

    const tutorialSteps = [
        {
            target: '#review-collection',
            content: <div>
                        <p style={{textAlign: 'left'}}>Review your changes on this page. This is how the collection will appear to your users when you publish and share your collection.</p>
                    </div>,
            placement: "left-start",
            disableBeacon: true, 
        },
        {
            target: '#publish-changes-btn',
            content: <div>
                        <p style={{textAlign: 'left'}}>When you are ready to publish your collection, click here.</p>
                    </div>,
            placement: "left-start",
            disableBeacon: true, 
        },
       
       
        
    ];
    
    const renderTutorial = () => {
        const completedTutorial = localStorage.getItem('completedCreateCollectionTutorials');
        if(completedTutorial == null) {
            return <Tour steps={tutorialSteps} tutorialName='completedCreateCollectionTutorial' />
        } else {
            return
        }
    }


    return <div id="review-collection">
        {renderTutorial()}
        <Alert
            message="Note"
            description="This is the preview of the public-facing page of your collection."
            type="info"
            showIcon
            action={<Space>
                <Button onClick={goToPreviousStep}>
                    Go Back
                </Button>
                <Button type="primary" onClick={handleOnPublishClick} id="publish-changes-btn">
                    Publish Changes
                </Button>
            </Space>}
        />
        <CollectionInfoCard 
            collection={previewCollectionData}
            collectionNetPrice={collection.collectionNetPrice}
            key={collection.id}
            mode="preview"
        />
        <CollectionModulesList
            collection={previewCollectionData} 
            mode="preview"
            urlParams={{}}
        />
    </div>
}

export default connect(null, { createCollection })(ReviewCollection);

