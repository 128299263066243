import { PlayCircleOutlined } from "@ant-design/icons";
import {
    Alert,
    Breadcrumb,
    Button,
    Card,
    Col,
    Image,
    Row,
    Space,
    Steps,
    Typography
} from "antd";
import { Component } from "react";
import { connect } from "react-redux";
import { fetchUserPolicyModel } from "../actions/contentCreation";
import { Prompt } from "react-router";
import CreatorStepsTutorial from "./CreateCollectionTutorial/CreatorStepsTutorial";

const { Paragraph, Text, Title } = Typography;
const { Step } = Steps;

class InstructorWalkthrough extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            collection: {
                name: "Sample Course Name",
                description: "Sample Description",
                longDescription: "Sample Long Description",
                primaryAuthorUsername: "",
                coAuthorUsernames: [],
                valuePrice: 0,
                collectionNetPrice: 0
            },
            modules: [],
            authorsDict: {},
            videoBank: [],
            allVideos: []
        };
    }

    componentDidMount() {
        if (this.props.currentUser !== null) {
            this.props.fetchUserPolicyModel(this.props.currentUser.id);
        }
    }

    updateState = (key, pair) => {
        this.setState({
            [key]: pair
        });
    };
    goToPreviousStep = () => {
        this.setState({
            currentStep: this.state.currentStep - 1
        });
    };

    goToNextStep = () => {
        this.setState({
            currentStep: this.state.currentStep + 1
        });
    };

    render() {
        return (
            <div className="centered-container">
                <div className="collection-creator-container">
                    <Prompt
                        when={
                            this.state.currentStep != 3 &&
                            this.state.currentStep != 0
                        }
                        message="Any changes you have made will not be saved, are you sure you want to leave?"
                    />

                    <Alert
                        message="This is a tutorial"
                        description="Any actions you make will not be saved and is just for demonstration purposes only."
                        type="warning"
                        showIcon
                        action={
                            <Button
                                size="small"
                                type="primary"
                                onClick={() => this.props.history.goBack()}>
                                Exit tutorial
                            </Button>
                        }
                    />
                    <br />
                    <Space className="breadcrumbs">
                        <Breadcrumb>
                            <Breadcrumb.Item>Creator Home</Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Create a Course
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Space>

                    <Card>
                        <Title level={4}>Creating a Course</Title>
                        <Steps size="small" current={this.state.currentStep}>
                            <Step
                                title={
                                    this.state.currentStep === 0
                                        ? "Course Details"
                                        : "Finished"
                                }
                            />
                            <Step
                                title={
                                    this.state.currentStep <= 1
                                        ? "Add Modules"
                                        : "Finished"
                                }
                            />
                            <Step
                                title={
                                    this.state.currentStep <= 2
                                        ? "Review Changes"
                                        : "Finished"
                                }
                            />
                            <Step
                                title={
                                    this.state.currentStep <= 3
                                        ? "Done"
                                        : "Finished"
                                }
                            />
                        </Steps>

                        <Card
                            bordered={false}
                            className="collection-creator-steps-container">
                            <CreatorStepsTutorial
                                allVideos={this.state.allVideos}
                                videoBank={this.state.videoBank}
                                authorsDict={this.state.authorsDict}
                                collection={this.state.collection}
                                modules={this.state.modules}
                                currentStep={this.state.currentStep}
                                currentUser={this.props.currentUser}
                                isAuthenticated={this.props.isAuthenticated}
                                goToNextStep={this.goToNextStep}
                                goToPreviousStep={this.goToPreviousStep}
                                updateState={this.updateState}
                            />
                        </Card>
                    </Card>
                </div>
                <div className="collection-creator-container-mobile">
                    <Alert
                        message="Unavailable on current screen size"
                        description="The Course Creator is not available on your current screen size. Please try using another device."
                        type="info"
                        showIcon
                        action={
                            <Button size="small" type="primary">
                                Go back
                            </Button>
                        }
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.user,
        isAuthenticated: state.userReducer.isAuthenticated
    };
};

export default connect(mapStateToProps, { fetchUserPolicyModel })(
    InstructorWalkthrough
);
