import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Breadcrumb, Button, Card, Space, Steps, Typography } from 'antd';
import ModuleCreatorSteps from './ModuleCreatorSteps';
import { connect } from 'react-redux';
import { fetchUserPolicyModel } from '../actions/contentCreation';

const { Step } = Steps;
const { Paragraph, Text, Title } = Typography;

class ModuleCreator extends Component {
    constructor(props){
        super(props);
        this.state = {
            currentStep: 0,
            module: {
                name: "",
                primaryAuthorUsername: "",
                coAuthorUsernames: [],
                videos: []
            },
            authorsDict: {},
            videoBank: [],
            allVideos: []
        }
    }

    componentDidMount() {
        this.props.fetchUserPolicyModel(this.props.currentUser.id)
    }

    updateState = (key, pair) => {
        this.setState({
            [key]: pair
        })
    }
    goToPreviousStep = () => {
        this.setState({
            currentStep: this.state.currentStep - 1
        })
    }

    goToNextStep = () => {
        this.setState({
            currentStep: this.state.currentStep + 1
        })
    }
    
    render(){
        if(!this.props.policyModel) return <></>

        return  <div className="centered-container">
                <div className="module-creator-container">
                    <Space className="breadcrumbs">
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to="/creator/home">Creator Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>Create a Module</Breadcrumb.Item>
                        </Breadcrumb>
                    </Space>
                    <Card>
                        <Title level={4}>Creating a Module</Title>
                        <Steps size="small" current={this.state.currentStep}>
                            <Step title={this.state.currentStep === 0 ? "Module Details" : "Finished"} />
                            <Step title={this.state.currentStep <= 1 ? "Advanced Configurations" : "Finished"} />
                            <Step title={this.state.currentStep <= 2 ? "Review Changes" : "Finished"} />
                            <Step title={this.state.currentStep <= 3 ? "Done" : "Finished"} />
                        </Steps>

                        <Card ghost bordered={false}>
                            <ModuleCreatorSteps 
                                allVideos={this.state.allVideos}
                                currentStep={this.state.currentStep}
                                goToNextStep={this.goToNextStep}
                                goToPreviousStep={this.goToPreviousStep}
                                module={this.state.module}
                                videoBank={this.state.videoBank}
                                updateState={this.updateState}
                                authorsDict={this.state.authorsDict}
                                currentUser={this.props.currentUser}
                            />
                        </Card>
                    </Card>
                </div>
                <div className="module-creator-container-mobile">
                    <Alert
                        message="Unavailable on current screen size"
                        description="The Module Creator is not available on your current screen size. Please try using another device."
                        type="info"
                        showIcon
                        action={
                            <Button size="small" type="primary" >
                                <Link to='/creator/home/'>
                                    Go back
                                </Link>
                            </Button>
                            }
                    />
                </div>
            </div>
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.user,
        policyModel: state.contentCreationReducer.policyModel,
    }
}

export default connect(mapStateToProps, { fetchUserPolicyModel })(ModuleCreator);