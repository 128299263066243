import { Avatar, Button, Checkbox, Form, List, message, Space, Statistic, Tag, Tooltip, Typography } from 'antd';
import { EyeInvisibleOutlined, SendOutlined, UserOutlined } from '@ant-design/icons';
import DashboardQnAAnswersList from './DashboardQnAAnswersList';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import RichTextEditor from '../../Components/RichTextEditor';
import RichTextPreview from '../../Components/RichTextPreview';
import { postQnAReplyFromRecent } from '../../actions/dashboard';
import { ContentState, EditorState, convertFromRaw } from "draft-js";
import { compositeDecorator } from '../../Components/RichTextDecoratorConfig';

const { Text } = Typography;
const DashboardQnAQuestion = ({ currentUser, enrolledLicenses, history, postQnAReplyFromRecent, renderTag, renderNewReplyStatistics, selectedDays, selectedLicense, question}) => {
    const [visibleReplies, setVisibleReplies] = useState(false);
    const [visibleReplyForm, setVisibleReplyForm] = useState(false);
    const [reset, setReset] = useState(false);
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [submitReplyLoading, setSubmitReplyLoading] = useState(false);
    const [form] = Form.useForm();
    let textToRender;
    if(!visibleReplies && !question.removed) {
        textToRender = {
            ...question.text,
            blocks: [question.text.blocks[0]]
        }
    }else {
        textToRender = question.text;
    }
    const contentState = question.removed ? ContentState.createFromText(question.text) : convertFromRaw(textToRender);
    const editorState = EditorState.createWithContent(contentState, compositeDecorator);


    const renderDisplayName = () => {
        if(!question.anonymous && question.instructorHighlight){
            if(question.user.firstName && question.user.lastName){
                return <Tag color="#868CBE">{`${question.user.firstName} ${question.user.lastName}`}</Tag>
            } else {
                return <Tag color="#868CBE">{question.user.username}</Tag>
            }
        } else if(question.anonymous || question.removed){
            if(question.user && question.user.id == currentUser.id){
                return "Anonymous User (You)"
            }else if(selectedLicense.createdById == currentUser.id && question.user){
                return <div className="dashboard-qna-anonymous-spoiler-tag">
                            {question.user.firstName && question.user.lastName ? `${question.user.firstName} ${question.user.lastName}` : question.user.username}
                            <div className="dashboard-qna-anonymous-hover-to-reveal"><EyeInvisibleOutlined /></div>
                        </div>
            } else {
                return "Anonymous User"
            }
        }else if(question.user.firstName && question.user.lastName){
            return question.user.id === currentUser.id ? `${question.user.firstName} ${question.user.lastName} (You)` : `${question.user.firstName} ${question.user.lastName}`
        } else {
            return question.user.id === currentUser.id ? `${question.user.username} (You)` : question.user.username
        }
        
    }

    const toggleRepliesExpansion = () => {
        let element = document.getElementById(`dashboard-qna-question-replies-${question.id}`);
        setVisibleReplies(!visibleReplies)
        if(element.style.maxHeight) {
            element.style.maxHeight = null;
        } else {
            element.style.maxHeight = element.scrollHeight + "px";
        }
       
        //setTimeout(()=>element.lastChild.scrollIntoView({behavior: "smooth", block: "center"}), 300)
    }

    const checkIfReplyMadeOneDayAgo = (response) => {
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - parseInt(selectedDays));
        let responseDate = new Date(response.createdAt);
        if(responseDate >= yesterday) {
            return true;
        }else {
            return false;
        }
    }

    const checkIfQuestionMadeOneDayAgo = (askedQuestion) => {
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - parseInt(selectedDays));
        let questionDate = new Date(askedQuestion.createdAt);
        if(questionDate >= yesterday) {
            return true;
        }else {
            return false;
        }
    }
    
    const handleOnFinish = (values) => {
        const handleAfterSuccess = () => {
            setReset(true);
            setSubmitReplyLoading(false);
            setVisibleReplyForm(false);
            form.setFieldsValue({'text': undefined})
            message.success("Your reply was posted.")
        }

        let qnaLevel;
        if(question.collection && question.module && question.video) {
            qnaLevel = "video"
        }else if(question.collection && question.module) {
            qnaLevel = "module"
        }else {
            qnaLevel = "collection"
        }
        postQnAReplyFromRecent(question.collection, question.module, question.video, question.id, selectedLicense.id, qnaLevel, values, handleAfterSuccess)
    }

    const handleOnRichTextEditorChange = (value) => {
        form.setFieldsValue({
            text: value
        })
    }
    const handleCheckboxChange = (event) => {
        form.setFieldsValue({
            anonymous: event.target.checked
        })
        setIsAnonymous(event.target.checked)
    }

    const openReplyForm = () => {
        setVisibleReplyForm(true)
        let element = document.getElementById(`dashboard-qna-question-replies-${question.id}`);
        element.style.maxHeight = element.scrollHeight + 100000 + "px";
    }
    const renderAnonymousCheckbox = () => {
        let currentLicense = enrolledLicenses.find(license => license.id === selectedLicense.id);
        if(currentLicense && currentLicense.createdById == currentUser.id){
            return <div></div>
        }else {
            return <Checkbox onChange={handleCheckboxChange}>Post anonymously to other students</Checkbox>
        }
    }

    const renderDate = () => {
        let date = new Date(question.createdAt);
        return `${date.toDateString()} at ${date.toLocaleTimeString()}`;
    }
    
    const renderQnALevelTag = () => {
        if(renderTag){
            if(question.video){
                return <Tooltip title={question.videoName}><Tag color="#7e76db" onClick={()=> history.push(`/dashboard/qna/collections/${question.collection}/modules/${question.module}/videos/${question.video}`)}>Video</Tag></Tooltip>
            }else if(question.module) {
                return <Tooltip title={question.moduleName}><Tag color="#7694db" onClick={()=> history.push(`/dashboard/qna/collections/${question.collection}/modules/${question.module}`)}>Module</Tag></Tooltip>
            }else {
                return <Tooltip title={question.collectionName}><Tag color="#76c0db" onClick={()=> history.push(`/dashboard/qna/collections/${question.collection}`)}>Collection</Tag></Tooltip>
            }
        }else {
            return
        }
       
    }

    const renderForm = () => {
        if(visibleReplyForm) {
            return <>
            
                    <div className={`qna-reply-editor`} style={{margin: "10px 0px"}}>
                        <Form form={form} onFinish={handleOnFinish}>
                            <Form.Item name='text' hidden>
                                
                            </Form.Item>
                            <Form.Item name='anonymous' hidden>
                                        
                            </Form.Item>
                        </Form>
                        <RichTextEditor 
                            customOnChange={handleOnRichTextEditorChange} 
                            placeholder="Post a reply (Use @ to reference timestamp/range in a video. Ex: @1:00 to @2:00)" 
                            readOnly={false} 
                            reset={reset} 
                            setReset={setReset} 
                            allowTimestamps={true}
                        />
                
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
                        <Space>{renderAnonymousCheckbox()}</Space>
                        <div style={{ display: "flex", justifyContent: "flex-end"}}>

                            <Button onClick={()=>setVisibleReplyForm(false)} style={{marginRight: "10px"}}>
                                Cancel
                            </Button>
                            <Button 
                                type="primary" 
                                onClick={() => {
                                    let textValue = form.getFieldValue('text')
                                    if(textValue && textValue.blocks.some(block => block.text.length)){
                                        setSubmitReplyLoading(true);
                                        form.submit()
                                    } else {
                                        message.warning('Please enter a reply first.')
                                    }
                                }} 
                                loading={submitReplyLoading}
                            >
                                <SendOutlined /> Post Reply
                            </Button>
                        </div>
                    </div>
                </>
        } else {
            return <Button type="dashed" style={{ margin: "10px 0px", padding: "5px", width: "100%"}} onClick={openReplyForm}>
                Reply to this question
            </Button>
        }
    }
    return <div style={{marginBottom: "5px"}}>
                <List.Item className={`dashboard-qna-question${visibleReplies ? ' dashboard-qna-question-visible-replies' : ""}${checkIfQuestionMadeOneDayAgo(question) ? " dashboard-qna-question-highlight-recent" : " dashboard-qna-question-normal-highlight"}`} onClick={()=>{
                    toggleRepliesExpansion();
                }}>
                    <List.Item.Meta
                        avatar={<Avatar icon={<UserOutlined />} />}
                        title={<RichTextPreview editorState={editorState} lightmode={true}/>}
                        description={<div>
                                        
                                        <Space>
                                            <Text type="secondary" style={{color: "grey"}}><Space>Posted by {renderDisplayName()}| {renderDate()}</Space></Text>
                                            {renderQnALevelTag()}
                                        </Space>
                                       
                                    </div>}
                    />
                    <Space>
                        {renderNewReplyStatistics ? (<>
                            <Statistic title="New Replies" value={question.responses.filter(response => checkIfReplyMadeOneDayAgo(response)).length} className="dashboard-qna-question-replies-statistic" />
                            <Statistic title="Instructor Replies" value={question.responses.filter(response => response.instructorHighlight).length} className="dashboard-qna-question-replies-statistic" />
                            </>
                        ) : null}
                        
                        <Statistic title="Total Replies" value={question.responses.length} className="dashboard-qna-question-replies-statistic" />
                    </Space>
                </List.Item>
                <div id={`dashboard-qna-question-replies-${question.id}`} className={`dashboard-qna-question-expanded-content`}>
                    <DashboardQnAAnswersList 
                        responses={question.responses} 
                        currentUser={currentUser} 
                        enrolledLicenses={enrolledLicenses} 
                        selectedLicense={selectedLicense}
                        checkIfReplyMadeOneDayAgo={checkIfReplyMadeOneDayAgo}
                    />
                    {renderForm()}
                </div>

            </div>
}

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.user,
        enrolledLicenses: state.userReducer.enrolledLicenses,
        selectedLicense: state.dashboardReducer.selectedCoupon,
    }
}
export default connect(mapStateToProps, {postQnAReplyFromRecent})(withRouter(DashboardQnAQuestion));

