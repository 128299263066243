import { useEffect, useRef, useState } from "react";
import YouTube from "react-youtube";
import Player from "@vimeo/player";

const YT_PLAYER_OPTIONS = {
    width: "100%",
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        rel: 0
    }
};

const MAX_PLAYING_TIME = 300; // seconds

const asyncIntervals = [];

const runAsyncInterval = async (cb, interval, intervalIndex) => {
    await cb();
    if (asyncIntervals[intervalIndex]) {
        setTimeout(
            () => runAsyncInterval(cb, interval, intervalIndex),
            interval
        );
    }
};

const setAsyncInterval = (cb, interval) => {
    if (cb && typeof cb === "function") {
        const intervalIndex = asyncIntervals.length;
        asyncIntervals.push(true);
        runAsyncInterval(cb, interval, intervalIndex);
        return intervalIndex;
    } else {
        throw new Error("Callback must be a function");
    }
};

const clearAsyncInterval = (intervalIndex) => {
    if (asyncIntervals[intervalIndex]) {
        asyncIntervals[intervalIndex] = false;
    }
};

const CollectionThumbnailPreviewer = ({
    thumbnail,
    videoType,
    videoUrl,
    play = false
}) => {
    const containerRef = useRef(null);
    const [hovered, setHovered] = useState(false);
    const [video, setVideo] = useState(null);

    const YoutubePreviewer = ({ afterPreviewEnd }) => {
        const [youtubePlayer, setYoutubePlayer] = useState(null);

        const destroyPlayer = () => {
            asyncIntervals.forEach((e, i) => {
                clearAsyncInterval(i);
            });
            youtubePlayer?.destroy();
            setYoutubePlayer(null);
        };

        const endVideo = () => {
            youtubePlayer?.pauseVideo();
            destroyPlayer();
            afterPreviewEnd();
        };

        const onReady = (e) => {
            // setAsyncInterval(async () => {
            //     const currentTime = await e.target?.getCurrentTime();
            //     if (currentTime > MAX_PLAYING_TIME) {
            //         endVideo();
            //     }
            // }, 1000);
            setYoutubePlayer(e.target);
        };

        useEffect(() => {
            if (youtubePlayer) {
                return () => {
                    destroyPlayer();
                };
            }
        }, [youtubePlayer]);

        return (
            <YouTube
                videoId={video.embedId}
                opts={YT_PLAYER_OPTIONS}
                onReady={onReady}
                onEnd={endVideo}
            />
        );
    };

    const VimeoPreviewer = ({ afterPreviewEnd }) => {
        const vimeoContainerRef = useRef(null);
        const [vimeoPlayer, setVimeoPlayer] = useState(null);

        const destroyPlayer = () => {
            asyncIntervals.forEach((e, i) => {
                clearAsyncInterval(i);
            });
            vimeoPlayer?.off("play");
            setVimeoPlayer(null);
        };

        const endVideo = () => {
            vimeoPlayer.pause();
            destroyPlayer();
            afterPreviewEnd();
        };

        const onVideoEnded = () => {
            endVideo();
        };

        const handleVimeoOnPlay = () => {};

        useEffect(() => {
            if (vimeoContainerRef?.current && !vimeoPlayer) {
                const player = new Player(vimeoContainerRef?.current, {
                    id: video.embedId,
                    autoplay: true,
                    width: "100%",
                    height: "100%"
                });
                setVimeoPlayer(player);
            }
        }, [vimeoContainerRef]);

        useEffect(() => {
            if (vimeoPlayer) {
                vimeoPlayer.on("play", handleVimeoOnPlay);
                vimeoPlayer.on("ended", onVideoEnded);
                vimeoPlayer.on("error", () => {
                    destroyPlayer();
                });
                return () => {
                    destroyPlayer();
                };
            }
        }, [vimeoPlayer]);

        return (
            <>
                <div className="vimeo-video-wrapper">
                    <div ref={vimeoContainerRef}></div>
                </div>
            </>
        );
    };

    const processVideo = (video) => {
        if (video === null) {
            setVideo(null);
        } else {
            setVideo(video);
        }
    };

    const getEmbedIdFromUrl = (url) => {
        let video_id_regExp =
                /^.*((youtu.be\/|vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
            match = url.match(video_id_regExp);

        if (match && match[7]) {
            return match[7];
        }
        return "";
    };

    useEffect(() => {
        if (hovered && videoType && videoUrl) {
            const embedId = getEmbedIdFromUrl(videoUrl);
            processVideo(
                embedId
                    ? {
                          videoType: videoType,
                          embedId: embedId
                      }
                    : null
            );
        } else {
            processVideo(null);
        }
    }, [hovered, videoType, videoUrl]);

    return (
        <>
            <div
                ref={containerRef}
                className="course-previewer"
                onMouseEnter={() => setHovered(true)}
                onFocus={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onBlur={() => setHovered(false)}>
                <div
                    style={{ opacity: video && hovered ? 0 : 1 }}
                    className={video ? "course-previewer-thumbnail" : null}>
                    {thumbnail}
                </div>
                <div
                    className="course-previewer-video-previewer"
                    style={{ opacity: video && hovered ? 1 : 0 }}>
                    {video?.videoType === "youtube" && (
                        <YoutubePreviewer
                            afterPreviewEnd={() => setHovered(false)}
                        />
                    )}
                    {video?.videoType === "vimeo" && (
                        <VimeoPreviewer
                            afterPreviewEnd={() => setHovered(false)}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default CollectionThumbnailPreviewer;
