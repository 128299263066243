import { Col, Image, Input, Row, Skeleton, Space, Typography } from 'antd';

const LoadingVideoPage = ({ collection, collectionNetPrice, mode="purchase" }) => {

    return  <div className="">
                <div className='loading-video-page-header'>
                    <Skeleton loading={true} active paragraph={false} title={{width: 500}} button/>
                    <Skeleton loading={true} active paragraph={false} title={{width: 200}} button/>
                </div>
                <div className='loading-video-page'>
                    <div className="loading-video-page-menu">
                        
                    </div>
                       
                    <div className="loading-video"></div>        
                            
                    <div className="loading-video-page-transcript-menu">
                        
                    </div>
                </div>
            </div>
}

export default LoadingVideoPage;