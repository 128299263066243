import { Card, List, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import VideoCard from "../Components/VideoCard";

const { Text, Title } = Typography;

const UserCreatedModulesModal = ({
    modules,
    createdModules,
    visible,
    setVisible,
    afterSelect
}) => {
    const getSelectableModules = (createdModuleList) => {
        return createdModuleList.filter(
            (cm) => !modules.some((m) => m.id === cm.id)
        );
    };

    const [selectableModules, setSelectableModules] = useState([]);
    const [selectedModulesToImport, setSelectedModulesToImport] = useState(
        new Set()
    );

    // On confirm selected modules
    const handleOnSelectedModulesFinish = () => {
        let modulesToAdd = [];
        let filtered = [];
        selectableModules.forEach((moduleData, index) => {
            if (selectedModulesToImport.has(moduleData.id)) {
                modulesToAdd.push({
                    key: Date.now() + index,
                    isDefaultPrice: moduleData.customPrice === null,
                    primaryAuthorUsername: moduleData.primaryAuthor.username,
                    coAuthorUsernames: moduleData.coAuthors.map(
                        (coAuthor) => coAuthor.username
                    ),
                    ...moduleData
                });
            } else {
                filtered.push(moduleData);
            }
        });
        setSelectedModulesToImport(new Set());
        afterSelect({
            selectedModules: modulesToAdd,
            filteredCreatedModules: filtered
        });
        setVisible(false);
    };

    // On select module
    const onSelectModule = (moduleId) => {
        if (selectedModulesToImport.has(moduleId)) {
            selectedModulesToImport.delete(moduleId);
            setSelectedModulesToImport(new Set([...selectedModulesToImport]));
        } else {
            selectedModulesToImport.add(moduleId);
            setSelectedModulesToImport(new Set([...selectedModulesToImport]));
        }
    };

    useEffect(() => {
        if (createdModules.length > 0) {
            const selModules = getSelectableModules(createdModules);
            setSelectableModules(selModules);
        }
    }, [modules, createdModules]);

    return (
        <Modal
            visible={visible}
            width={"800px"}
            onOk={handleOnSelectedModulesFinish}
            okText={"Add Selected Modules"}
            onCancel={() => {
                setVisible(false);
                setSelectedModulesToImport(new Set());
            }}>
            <div className="add-module-modal">
                <Title level={3}>Select the modules you want to import</Title>
                <Text type="secondary">
                    Selected {selectedModulesToImport.size} of{" "}
                    {selectableModules.length}
                </Text>
                <List
                    style={{ marginTop: 10 }}
                    pagination={{
                        pageSize: 3
                    }}
                    dataSource={selectableModules}
                    renderItem={(module, index) => (
                        <List.Item
                            key={module.id}
                            className="created-modules-list-item">
                            <div className="created-modules-list-item">
                                <Card
                                    onClick={() => onSelectModule(module.id)}
                                    className={`modules-to-import-list selectable-module-option ${
                                        selectedModulesToImport.has(module.id)
                                            ? "selected-module-to-import"
                                            : ""
                                    }`}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}>
                                        <div>
                                            <Title
                                                level={5}
                                                style={{ margin: 0 }}>
                                                {module.name}
                                            </Title>

                                            <>
                                                <Text type="secondary">{`${module.primaryAuthor.firstName} ${module.primaryAuthor.lastName}`}</Text>
                                                <Text type="secondary">
                                                    {module.coAuthors.map(
                                                        (coAuthor) => (
                                                            <Text
                                                                key={
                                                                    coAuthor.id
                                                                }>{`, ${coAuthor.firstName} ${coAuthor.lastName}`}</Text>
                                                        )
                                                    )}
                                                </Text>
                                            </>
                                        </div>
                                        <div></div>
                                    </div>
                                </Card>
                                <br />
                                <List
                                    grid={{
                                        gutter: [16, 16],
                                        xs: 1,
                                        sm: 2,
                                        md: 4,
                                        lg: 3,
                                        xl: 4,
                                        xxl: 4
                                    }}
                                    pagination={{
                                        pageSize: 4,
                                        size: "small"
                                    }}
                                    dataSource={module.videos}
                                    renderItem={(video) => (
                                        <List.Item key={`video-${video.id}`}>
                                            <VideoCard
                                                index={index}
                                                collectionId={null}
                                                module={module}
                                                moduleId={module.id}
                                                mode={"preview"}
                                                moduleName={module.name}
                                                moduleAccess={{}}
                                                moduleIndex={index}
                                                video={video}
                                                price={module.price}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </List.Item>
                    )}
                />
            </div>
        </Modal>
    );
};

export default UserCreatedModulesModal;
