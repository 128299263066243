import { connect } from 'react-redux';
import { Card, Col, List, Row, Skeleton, Typography } from 'antd';

const { Text } = Typography;
const QuizSectionOverview = ({watchHistoryOfCollection, watchHistoryOfCollectionStatus}) => {
    if (watchHistoryOfCollectionStatus == "LOADED"){
        return <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    pageSize: 2,
                }}
                dataSource={watchHistoryOfCollection.modules}
                renderItem={module => (
                <List.Item
                    key={module.id}
                    style={{padding: 0}}
                >
                    <div style={{padding: "0px 0px 10px 0px"}}>
                        <div className="quiz-section-module-header">{module.name}</div>
                        <div className="quiz-section-video-container">
                            <div className="quiz-section-video-column-headers">
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Text className="quiz-section-video-column-headers-text">Video Title</Text>
                                    </Col>
                                    <Col span={4}>
                                        <Text className="quiz-section-video-column-headers-text">Completed Questions</Text>
                                    </Col>
                                    <Col span={4}>
                                        <Text className="quiz-section-video-column-headers-text">Number of Questions</Text>
                                    </Col>
                                    <Col span={4}>
                                        <Text className="quiz-section-video-column-headers-text">Actions</Text>
                                    </Col>
                                </Row>
                            </div>
                            {module.videos.map(video => (
                                <div className="quiz-section-video-row">
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Text>{video.title}</Text>
                                        </Col>
                                        <Col span={4}>
                                            <Text>{video.quizQuestionsCorrectlyAnswered}</Text>
                                        </Col>
                                        <Col span={4}>
                                            <Text>{video.totalNumberOfQuizQuestions}</Text>
                                        </Col>
                                        <Col span={4}>
                                            <Text>View More</Text>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                    </div>
                </List.Item>
                )}
            />
    }else {
        return <Card className="dashboard-card">
                    <Skeleton active/>
                </Card>
    }
    
}

const mapStateToProps = state => {
    return {
        watchHistoryOfCollection: state.dashboardReducer.watchHistoryOfCollection,
        watchHistoryOfCollectionStatus: state.dashboardReducer.watchHistoryOfCollectionStatus
    }
}

export default connect(mapStateToProps, {})(QuizSectionOverview);