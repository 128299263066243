import { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, Card, List, Menu, Skeleton, Space, Tag, Typography} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchQnAQuestionsByUser } from '../../../actions/dashboard';
import DashboardQnAQuestion from '../../QnA/DashboardQnAQuestion';

const { SubMenu } = Menu;
const { Text } = Typography;

class QnAByLevel extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedLevel: null, // collection, module, or video
            selectedCollectionId: null,
            selectedModuleId: null,
            selectedVideoId: null,
            selectedLevelName: ""
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.selectedCollectionId != this.state.selectedCollectionId || prevState.selectedModuleId != this.state.selectedModuleId || prevState.selectedVideoId != this.state.selectedVideoId){
            this.props.fetchQnAQuestionsByUser(this.props.selectedCoupon.code, this.props.currentUser.id, this.state.selectedLevel, this.state.selectedCollectionId, this.state.selectedModuleId, this.state.selectedVideoId)
        }
    }

    renderQnALevelTag = () => {
        switch(this.state.selectedLevel){
            case "collection":
                return <Space><Tag color="#272f41">Course</Tag><Text>{this.state.selectedLevelName}</Text></Space>
            case "module":
                return <Space><Tag color="#272f41">Module</Tag><Text>{this.state.selectedLevelName}</Text></Space>
            case "video":
                return <Space><Tag color="#272f41">Video</Tag><Text>{this.state.selectedLevelName}</Text></Space>
            default:
                return <></>
        }
    }
    renderCollectionMenu = () => {
        if(this.props.collection){
            return <Menu>
                <Menu.ItemGroup title="Course">
                    <Menu.Item onClick={()=>this.setState({
                        selectedLevel: "collection",
                        selectedCollectionId: this.props.collection.id,
                        selectedModuleId: null,
                        selectedVideoId: null,
                        selectedLevelName: this.props.collection.name
                    })}>
                        {this.props.collection.name}
                    </Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup title="Modules">
                    {this.props.collection.modules.map(module => {
                        return <SubMenu 
                                    key={`module-${module.id}`} 
                                    title={module.name} 
                                    onTitleClick={()=>this.setState({
                                        selectedLevel: "module",
                                        selectedCollectionId: this.props.collection.id,
                                        selectedModuleId: module.id,
                                        selectedVideoId: null,
                                        selectedLevelName: module.name
                                    })}>
                                    <Menu.ItemGroup title="Videos">
                                        {
                                            module.videos.map(video=> {
                                                return <Menu.Item 
                                                            key={`video-${video.id}`} 
                                                            onClick={()=>this.setState({
                                                                selectedLevel: "video",
                                                                selectedCollectionId: this.props.collection.id,
                                                                selectedModuleId: module.id,
                                                                selectedVideoId: video.id,
                                                                selectedLevelName: video.title
                                                            })}
                                                        >
                                                            {video.title}
                                                        </Menu.Item>
                                            })
                                        }
                                    </Menu.ItemGroup>
                                </SubMenu>
                                
                    })}
                </Menu.ItemGroup>
            </Menu>
        }else {
            return  <Menu>
               <    Menu.Item key="collection">
                        Loading <LoadingOutlined />
                    </Menu.Item>
                </Menu>
        }
    }
    render(){
        if(this.props.qnaDataStatus === "LOADING"){
            return <>
                        <Breadcrumb>
                            <Breadcrumb.Item overlay={this.renderCollectionMenu()}>
                                Select a Discussion
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <br/>
                        {this.renderQnALevelTag()}
                        <Card className="dashboard-card">
                            <Skeleton active />
                        </Card>
                    </>
        } else if(this.props.qnaDataStatus === "LOADED"){
            return <>
                        <Breadcrumb>
                            <Breadcrumb.Item overlay={this.renderCollectionMenu()}>
                                Select a Discussion
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <br/>
                        {this.renderQnALevelTag()}
                        <List
                            itemLayout="horizontal"
                            style={{marginTop: "5px"}}
                            dataSource={this.props.qnaData}
                            renderItem={item => (
                                <DashboardQnAQuestion
                                    question={item} 
                                    selectedDays={null}
                                    renderTag={false}
                                    renderNewReplyStatistics={false}
                                />
                            )}
                        />
                    </>
        }else {
            return <>
                        <Breadcrumb>
                            <Breadcrumb.Item overlay={this.renderCollectionMenu()}>
                                Select a Discussion
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <br/>
                        {this.renderQnALevelTag()}
                </>
        }
        
    }
}

const mapStateToProps = state => {
    return {
        collection: state.dashboardReducer.collection,
        currentUser: state.userReducer.user,
        selectedCoupon: state.dashboardReducer.selectedCoupon,
        qnaData: state.dashboardReducer.userQnAQuestionsByLevel,
        qnaDataStatus: state.dashboardReducer.userQnAQuestionsByLevelStatus
    }
}
export default connect(mapStateToProps, {fetchQnAQuestionsByUser})(QnAByLevel);