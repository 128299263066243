import { Button, Result, Space, Spin, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';


const { Text, Title } = Typography;

const CollectionUpdateResultStep = ({ updateCollectionStatus, goToPreviousStep, latestCreatedCollectionId }) => {

    const history = useHistory();

    if(updateCollectionStatus === "NOT ATTEMPTED") {
        return <div style={{textAlign: "center"}}>
                <Title level={3}>Waiting to publish the changes to your collection...</Title>
        </div>
    } else if(updateCollectionStatus === "LOADING") {
        return <div style={{textAlign: "center"}}>
                    <Space>
                        <Spin size="large" />
                        <Title level={3}>Publishing the changes to your collection...</Title>
                    </Space>
                </div>
    } else if(updateCollectionStatus === "SUCCESS"){
        return <div style={{textAlign: "center"}}>
            
            <Result
                status="success"
                title="Your collection is now updated!"
                subTitle="Click the button below to view your updated collection."
                extra={[
                    <Button
                        type="primary"
                        onClick={()=>history.push(`/creator/created-collections/${latestCreatedCollectionId}`)}
                    >
                        View your collection
                    </Button>,
                ]}
            />
        </div>
    } else if(updateCollectionStatus === "FAILED"){
        return <div style={{textAlign: "center"}}>
                    <Result
                        status="error"
                        title="Submission Failed"
                        subTitle="Something went wrong with creating your collection."
                        extra={[
                            <Button onClick={()=>{ goToPreviousStep()}}>Go Back</Button>,
                        ]}
                    />
                </div>
    }
    return 
}
const mapStateToProps = state => {
    return {
        updateCollectionStatus: state.contentCreationReducer.updateCollectionStatus,
        latestCreatedCollectionId: state.contentCreationReducer.latestCreatedCollectionId
    }
}
export default connect(mapStateToProps, { })(CollectionUpdateResultStep);