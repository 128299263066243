import { Component } from "react";
import { connect } from "react-redux"
import CollectionEditor from "./CollectionEditor";
import { fetchCollectionDetail } from '../actions/contentCreation';

class CollectionEditorWrapper extends Component {
    
    /* 
        This is a wrapper to preformat existing data before feeding it into the editor.
        The plan is to abstract the CollectionEditor and CollectionCreator to be combined into one pipeline,
        but for the short term we had to skip this.
    */

    componentDidMount(){
        this.props.fetchCollectionDetail(this.props.match.params.collectionId, this.props.currentUser.id)
    }
    
    collectionWithAuthorUsernames = (collection) => {
        let primaryAuthorUsername = collection.primaryAuthor.username;
        let coAuthorUsernames = collection.coAuthors.map(coAuthor => coAuthor.username)
        return {
            ...collection,
            primaryAuthorUsername: primaryAuthorUsername,
            coAuthorUsernames: coAuthorUsernames
        }
    }

    modulesWithAuthorUsernames = (modules) => {

        let preformattedModules = modules.map(module => {
            return {
                ...module,
                primaryAuthorUsername: module.primaryAuthor.username,
                coAuthorUsernames: module.coAuthors.map(coAuthor => coAuthor.username),
                videos: module.videos.map((video, videoIndex) => {
                    return {
                        ...video,
                        title: module.videoTitles[videoIndex].videoTitle
                    }
                })
            }
        })
       
        return preformattedModules
    }

    render() {
        if(this.props.collection === null) return <></>

        return  <CollectionEditor 
                    collection={this.collectionWithAuthorUsernames(this.props.collection)} 
                    modules={this.modulesWithAuthorUsernames(this.props.collection.modules)}
                />
    }
}


const mapStateToProps = state => {
    return {
        collection: state.contentCreationReducer.collectionDetail,
        currentUser: state.userReducer.user
    }
}

export default connect(mapStateToProps, { fetchCollectionDetail })(CollectionEditorWrapper);