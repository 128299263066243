import { message } from 'antd';
import { API_URL } from '../const.js';
import {getNewAccessToken} from './user.js';


export const setDemoRequestStatus = (status) => {
    return {
        type: "SET_DEMO_REQUEST_STATUS",
        status
    }
}

export const submitDemoRequest = (formData) => {
    return dispatch => {
        dispatch(setDemoRequestStatus("LOADING"))
        return fetch(API_URL+`/api/v1/demos/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...formData,
            })
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status)
            }
        })
        .then(data => {
            dispatch(setDemoRequestStatus("SUCCESS"))
        })
        .catch(error => {
            if(parseInt(error.message) === 401){
                return dispatch(getNewAccessToken(()=>submitDemoRequest(formData)))
            }else {
                dispatch(setDemoRequestStatus("FAILED"))
                return message.error("Something went wrong.")
            }
        })
    }
}

export const setFetchCategoriesStatus = (status) => {
    return {
        type: "SET_FETCH_CATEGORIES_STATUS",
        status
    }
}
export const setIssueCategories = (categories) => {
    return {
        type: "SET_ISSUE_CATEGORIES",
        categories
    }
}

export const fetchIssueCategories = () => {
    return dispatch => {
        dispatch(setFetchCategoriesStatus("LOADING"))
        return fetch(API_URL+`/api/v1/issues/categories/`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status)
            }
        })
        .then(data => {
            dispatch(setIssueCategories(data))
            dispatch(setFetchCategoriesStatus("LOADED"))
        })
        .catch(error => {
            if(parseInt(error.message) === 401){
                return dispatch(getNewAccessToken(()=>fetchIssueCategories()))
            }else {
                dispatch(setFetchCategoriesStatus("FAILED"))
                return message.error("Something went wrong.")
            }
        })
    }
}

export const submitIssue = (formData, handleAfterFailure, handleAfterSuccess) => {
    return dispatch => {
        return fetch(API_URL+`/api/v1/issues/`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status)
            }
        })
        .then(data => {
            // TODO: Add to "My Reported Issues"
            handleAfterSuccess()
        })
        .catch(error => {
            if(parseInt(error.message) === 401){
                return dispatch(getNewAccessToken(()=>submitIssue(formData, handleAfterSuccess)))
            }else {
                handleAfterFailure()
            }
        })
    }
}