import { Button, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';


const { Text, Title } = Typography;

const ModuleCreationResultStep = ({ createModuleStatus, goToPreviousStep, latestCreatedModuleId }) => {

    const history = useHistory();

    if(createModuleStatus === "NOT ATTEMPTED") {
        return  <div>
                    Waiting
                </div>
    } else if(createModuleStatus === "LOADING") {
        return  <div>
                    Loading
                </div>
    } else if(createModuleStatus === "SUCCESS"){
        return <div style={{textAlign: "center"}}>
            
                    <Title level={2}>Your module is now available!</Title>
                    <Button
                        type="primary"
                        onClick={()=>history.push(`/creator/created-modules/${latestCreatedModuleId}`)}
                    >
                        View your module
                    </Button>
                </div>
    } else if(createModuleStatus === "FAILED"){
        return  <div>
                    Failed
                    <Button onClick={()=>{ goToPreviousStep()}}>Go Back</Button>
                </div>
    }
    return 
}
const mapStateToProps = state => {
    return {
        createModuleStatus: state.contentCreationReducer.createModuleStatus,
        latestCreatedModuleId: state.contentCreationReducer.latestCreatedModuleId
    }
}
export default connect(mapStateToProps, { })(ModuleCreationResultStep);