import { Modal, Tag, Typography } from "antd";

const { Text, Title } = Typography;

const BorrowedContentPriceBreakdown = ({ module, setVisible, visible }) => {
    const licensedModules = [];
    const licensedModulesDict = {};

    module.videos.forEach((video) => {
        if (!video.parentModule) return;

        if (
            (module.id && video.parentModule != module.id) ||
            (module.id == null && video.parentModule)
        ) {
            if (video.parentModule in licensedModulesDict) {
                licensedModulesDict[video.parentModule].push(video);
            } else {
                licensedModules.push({
                    id: video.parentModule,
                    name: video.parentModuleName,
                    price: video.parentModulePrice
                });
                licensedModulesDict[video.parentModule] = [video];
            }
        }
    });

    return (
        <Modal
            onCancel={() => setVisible(false)}
            visible={visible}
            footer={null}>
            <div className="modal-body">
                <Title level={4}>Borrowed Content Price Breakdown</Title>
                <Text type="secondary">
                    The list below shows which videos in your module are
                    borrowed, which module they are borrowed from, and also the
                    associated price of that module.
                </Text>
                <br />
                {licensedModules.map((licensedModule, index) => {
                    return (
                        <div key={index}>
                            <div className="borrowed-content-price-breakdown-modules">
                                <div>
                                    <Tag>Module</Tag>
                                    <Text>{licensedModule.name}</Text>
                                </div>{" "}
                                <Text>+{licensedModule.price} cupoints</Text>
                            </div>
                            {licensedModulesDict[licensedModule.id].map(
                                (video) => (
                                    <div className="borrowed-content-price-breakdown-video">
                                        <Tag>Video</Tag>
                                        <Text>{video.title}</Text>
                                    </div>
                                )
                            )}
                        </div>
                    );
                })}
            </div>
        </Modal>
    );
};

export default BorrowedContentPriceBreakdown;
