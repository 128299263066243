import { RightCircleOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Result, Space, Spin, Typography } from "antd";
import { useHistory } from 'react-router-dom';

const { Paragraph, Text, Title } = Typography;

const SuccessfulAccountTypeRegistrationStep = ({ accountTypeRegistrationStatus, currentUser, clearAccountTypeRegistrationStatus }) => {
    
    const history = useHistory()

    const handleOnClick = () => {
        // clear visible modal
        // then
        if(currentUser.isContentCreator) {
            history.push('/creator/home')
        } else {
            history.push('/collections/')
        }
        clearAccountTypeRegistrationStatus("NOT LOADED")
    }

    if(accountTypeRegistrationStatus === "NOT LOADED") {
        return  <div style={{textAlign: "center"}}>
                        <Title level={3}>Waiting to complete registration...</Title>
                </div>
    } else if(accountTypeRegistrationStatus === "LOADING") {
        return  <div style={{textAlign: "center"}}>
                    <Space>
                        <Spin size="large" />
                        <Title level={3}>Completing registration...</Title>
                    </Space>
                </div>
    } else if(accountTypeRegistrationStatus === "SUCCESS") {
        return <>
                <Result
                    icon={<SmileOutlined />}
                    title="Great, registration now complete!"
                    extra={<Button type="primary" icon={<RightCircleOutlined />} onClick={handleOnClick}>Let's begin!</Button>}
                />
            </>
    } else if(accountTypeRegistrationStatus === "FAILED"){
        return  <div style={{textAlign: "center"}}>
                    <Result
                        status="error"
                        title="Submission Failed"
                        subTitle="Something went wrong with your registration. Please try again."
                        extra={[
                            <Button onClick={()=>{ window.location.reload()}}>Go Back</Button>,
                        ]}
                    />
                </div>
    }
    
                
}

export default SuccessfulAccountTypeRegistrationStep;