import { useState } from "react";
import { Card, Form, Input, Space, Switch, Typography } from "antd";
import { convertToRaw, EditorState } from "draft-js";
import OptionTextEditorModal from "./OptionTextEditorModal";
import QuizQuestionImageUpload from "./QuizQuestionImageUpload";

const QuestionCreatorFormOption = ({
    addImageToAll,
    addImageToForm,
    addImageFile,
    field,
    formRef,
    index,
    removeOption,
    isOpenEndedQuestion,
    onOptionChanged
}) => {
    const { Paragraph, Text } = Typography;
    const formOptionValue = formRef.getFieldValue("options");

    const [optionText, setOptionText] = useState(
        formOptionValue?.[field.name]?.optionText
            ? formOptionValue?.[field.name]?.optionText
            : convertToRaw(EditorState.createEmpty().getCurrentContent())
    );
    const [optionExplanation, setOptionExplanation] = useState(
        formOptionValue?.[field.name]?.explanationText
            ? formOptionValue?.[field.name]?.explanationText
            : convertToRaw(EditorState.createEmpty().getCurrentContent())
    );

    const [imageFileList, setImageFileList] = useState(
        formOptionValue?.[field.name]?.imageFile
            ? [formOptionValue[field.name].imageFile]
            : []
    );

    const handleChangeExplanation = (value) => {
        let options = formRef.getFieldValue("options");
        options[field.name] = {
            ...options[field.name],
            explanationText: value
        };
        formRef.setFieldsValue({
            options: options
        });
        setOptionExplanation(value);
    };

    const handleChangeOptionText = (value) => {
        let options = formRef.getFieldValue("options");
        options[field.name] = {
            ...options[field.name],
            optionText: value
        };
        formRef.setFieldsValue({
            options: options
        });
        setOptionText(value);
    };

    const setFileList = (fileList, index) => {
        setImageFileList(fileList);
        if (addImageFile) {
            addImageFile(index, fileList);
        }
    };

    const handleAfterOptionTextChanged = (value) => {
        if (onOptionChanged) {
            let options = formRef.getFieldValue("options");
            options[field.name] = {
                ...options[field.name],
                optionText: value
            };
            onOptionChanged(options);
        }
    };

    const handleAfterOptionExplanationChanged = (value) => {
        if (onOptionChanged) {
            let options = formRef.getFieldValue("options");
            options[field.name] = {
                ...options[field.name],
                explanationText: value
            };
            onOptionChanged(options);
        }
    };

    const handleOnSwitchChange = (checked) => {
        let options = formRef.getFieldValue("options");
        let questionType = formRef.getFieldValue("questionType");

        if (questionType == "select_one") {
            options.forEach((option, index) => {
                if (option.correctAnswerFlag && index != field.name) {
                    option.correctAnswerFlag = false;
                }
            });
        }

        options[field.name] = {
            ...options[field.name],
            correctAnswerFlag: checked
        };
        formRef.setFieldsValue({
            options: options
        });
        if (onOptionChanged) {
            onOptionChanged(options);
        }
    };

    return (
        <Form.Item
            label={
                index === 0 ? (isOpenEndedQuestion ? "Answer" : "Options") : ""
            }
            required={!isOpenEndedQuestion}
            key={field.key}>
            <Card>
                <OptionTextEditorModal
                    onOptionTextChange={handleChangeOptionText}
                    onOptionExplanationChange={handleChangeExplanation}
                    textValue={optionText}
                    explanationValue={optionExplanation}
                    removeOption={removeOption}
                    isOpenEndedQuestion={isOpenEndedQuestion}
                    afterOptionTextChanged={handleAfterOptionTextChanged}
                    afterOptionExplanationChanged={
                        handleAfterOptionExplanationChanged
                    }
                />

                {isOpenEndedQuestion ? (
                    <>
                        <Text>Image</Text>
                        <QuizQuestionImageUpload
                            addImageToForm={(imageId) =>
                                addImageToForm(index, imageId)
                            }
                            addImageToAll={addImageToAll}
                            maxCount={1}
                            customOnRemove={(file) => {}}
                            imageFileList={imageFileList}
                            setFileList={(files) => {
                                setFileList(files, index);
                            }}
                        />
                    </>
                ) : (
                    <>
                        <Text>Supporting Image (Limited to 1 per Option)</Text>
                        <QuizQuestionImageUpload
                            addImageToForm={(imageId) =>
                                addImageToForm(index, imageId)
                            }
                            addImageToAll={addImageToAll}
                            maxCount={1}
                            customOnRemove={(file) => {}}
                            imageFileList={imageFileList}
                            setFileList={(files) => {
                                setFileList(files, index);
                            }}
                        />
                        <br />
                        <Form.Item
                            name={[field.name, "correctAnswerFlag"]}
                            initialValue={false}
                            style={{ margin: 0 }}>
                            <Space align="center">
                                <Switch
                                    onChange={handleOnSwitchChange}
                                    checked={formRef.getFieldValue([
                                        "options",
                                        field.name,
                                        "correctAnswerFlag"
                                    ])}
                                    size="small"
                                />
                                <Text
                                    type={
                                        formRef.getFieldValue([
                                            "options",
                                            field.name,
                                            "correctAnswerFlag"
                                        ])
                                            ? "success"
                                            : "warning"
                                    }>
                                    {formRef.getFieldValue([
                                        "options",
                                        field.name,
                                        "correctAnswerFlag"
                                    ])
                                        ? "This option is correct."
                                        : "This option is incorrect."}
                                </Text>
                            </Space>
                        </Form.Item>
                    </>
                )}
            </Card>
            <Form.Item
                name={[field.name, "optionText"]}
                fieldKey={[field.fieldKey, "optionText"]}
                initialValue={"Enter the option text"}
                hidden>
                <Input value={optionText} />
            </Form.Item>
            <Form.Item
                name={[field.name, "explanationText"]}
                fieldKey={[field.fieldKey, "explanationText"]}
                initialValue={
                    "Enter an explanation for why this question is correct or incorrect."
                }
                hidden>
                <Input />
            </Form.Item>
        </Form.Item>
    );
};

export default QuestionCreatorFormOption;
