import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Image, Modal, Space, Tooltip } from 'antd';
import { FormOutlined, LockOutlined, PlayCircleOutlined, QuestionOutlined } from '@ant-design/icons';
import '../Components.less';
import { purchaseModule } from '../actions/user';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import LockedVideoCardModalContent from './LockedVideoCardModalContent';
import PlaceholderImage from '../placeholder-video-cover.png';

const { Meta } = Card;

const VideoCard = ({index, collection, currentUser, collectionId, mode, module, moduleAccess, moduleId, moduleIndex, moduleName, locked, playVideoInModal, purchaseModule, price, unlistedKey, video}) => {

    const history = useHistory();
    var [vimeoThumbnailUrl, setVimeoThumbnailUrl] = useState(null);
    var [lockedVideoModalVisible, setLockedVideoModalVisible] = useState(false);

    const fetchVimeoThumbnail = async(video) => {
        
        try {
            var response = await fetch(`https://vimeo.com/api/v2/video/${video.embedId}.json`)
            let json = await response.json()
            return setVimeoThumbnailUrl(json[0]["thumbnail_large"]);
        } catch(e) {
            return setVimeoThumbnailUrl("");
        }
        
    }
    const getVideoThumbnail = (video) => {
        if(video.videoType === "youtube"){
            return <Image src={`https://img.youtube.com/vi/${video.embedId}/mqdefault.jpg`} fallback={PlaceholderImage} preview={false} style={{aspectRatio: 16/9}}/>
        }else if(video.videoType === "vimeo"){
            fetchVimeoThumbnail(video)
            return <Image
                        src={vimeoThumbnailUrl}
                        fallback={PlaceholderImage}
                        preview={false}
                        style={{aspectRatio: 16/9}}
                    />
        }
    }
   
    if(mode === "purchase"){

        if(locked) {
            return  <div>
                        <Modal
                            visible={lockedVideoModalVisible}
                            onOk={()=>setLockedVideoModalVisible(false)}
                            onCancel={()=>setLockedVideoModalVisible(false)}
                            footer={null}
                        >
                            <div className="modal-body">
                                <LockedVideoCardModalContent 
                                    collection={collection}
                                    moduleAccess={moduleAccess}
                                    currentUser={currentUser} 
                                    module={module}
                                    moduleName={moduleName}   
                                    price={price}
                                />
                            </div>
                        </Modal>
                        <Card
                            hoverable
                            className="video-card"
                            onClick={()=>setLockedVideoModalVisible(true)}
                            cover={getVideoThumbnail(video)}
                            style={{animationDelay: `${60 * index}ms`}}
                        >
                            <Tooltip title="No Access"><LockOutlined style={{fontSize: 20, position: "absolute", top: 10, right: 10, color: "yellow"}} /></Tooltip>
                            <Meta title={module.videoTitles[index].videoTitle} />
                            
                        </Card>
                    </div>
        }
        return  <Link to={`/collections/${collectionId}/modules/${moduleIndex + 1}/videos/${index + 1}${ unlistedKey && unlistedKey.length > 0 ? `?key=${unlistedKey}` : ""}`}>
                <Card
                    hoverable
                    className="video-card"
                    cover={getVideoThumbnail(video)}
                    style={{animationDelay: `${60 * index}ms`}}
                >
                    <div className="play-icon-overlay">
                        <PlayCircleOutlined />
                    </div>
                    <Meta title={module.videoTitles[index].videoTitle} />
                </Card>
            </Link>
    } else if(mode === "instructor"){
        return  <Card
                    hoverable
                    className="video-card"
                    cover={getVideoThumbnail(video)}
                    style={{animationDelay: `${60 * index}ms`}}
                    onClick={()=>playVideoInModal(video)}
                >
                    <div className="play-icon-overlay">
                        <PlayCircleOutlined />
                    </div>
                    <Meta title={module.videoTitles[index].videoTitle} />
                    <Space className="video-card-options">
                        {
                            video.owner == currentUser.id || currentUser.isAdmin || collection?.createdBy === currentUser.id ? <Button type="text" onClick={()=>history.push(`/modules/${module.id}/videos/${video.id}/questions/create/`)} icon={<FormOutlined />}>Manage Quiz</Button> : <></>
                        }

                    </Space>
                </Card>
    } else if(mode === "preview") {
        return  <Card
                    hoverable
                    className="video-card"
                    cover={getVideoThumbnail(video)}
                    style={{animationDelay: `${60 * index}ms`}}
                >
                    <Meta title={video.title} />
                </Card>
    } else {
        return <Card
                    hoverable
                    className="video-card"
                    cover={getVideoThumbnail(video)}
                    style={{animationDelay: `${60 * index}ms`}}
                >
                    <div className="play-icon-overlay">
                        <PlayCircleOutlined />
                    </div>
                    <Meta title={module.videoTitles[index].videoTitle} />
                </Card>
    }

    
}

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.user
    }
}

export default connect(mapStateToProps, { purchaseModule })(VideoCard);