import { Button, Space, Tooltip, Typography } from "antd";
import { useState } from "react";
import ModuleRenderer from "./ModuleRenderer";

const { Paragraph, Text } = Typography;

const CollectionModulesList = ({
    collection,
    isAuthenticated,
    mode = "purchase",
    moduleAccess,
    unlistedKey,
    urlParams,
    certificationView = false
}) => {
    const [selectedModule, setSelectedModule] = useState(
        urlParams.moduleId && parseInt(urlParams.moduleId) >= 1
            ? parseInt(urlParams.moduleId) - 1
            : null
    );

    return (
        <div>
            <Paragraph
                className={
                    certificationView ? "certificate-view-module-title" : ""
                }>
                <Text>{collection.modules.length} Modules</Text>
            </Paragraph>
            <Space wrap={true}>
                <Button
                    className="module-list-button"
                    type={
                        selectedModule == null ||
                        selectedModule >= collection.modules.length
                            ? "primary"
                            : "default"
                    }
                    onClick={() => setSelectedModule(null)}>
                    All
                </Button>

                {collection.modules.map((module, index) => (
                    <div key={index}>
                        <Tooltip title={module.name}>
                            <Button
                                className="module-list-button"
                                type={
                                    selectedModule == index
                                        ? "primary"
                                        : "default"
                                }
                                onClick={() => setSelectedModule(index)}>
                                {index + 1}
                            </Button>
                        </Tooltip>
                    </div>
                ))}
            </Space>
            <br />
            <br />
            <ModuleRenderer
                collection={collection}
                collectionId={collection.id}
                isAuthenticated={isAuthenticated}
                mode={mode}
                modules={collection.modules}
                selectedModule={selectedModule}
                moduleAccess={moduleAccess}
                unlistedKey={unlistedKey}
                certificationView={certificationView}
            />
        </div>
    );
};

export default CollectionModulesList;
