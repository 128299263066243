import { CheckCircleOutlined, CopyOutlined, DisconnectOutlined, DownloadOutlined, DownOutlined, ExportOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Divider, Drawer, Dropdown, Menu, Skeleton, Space, Tag, Typography } from "antd"
import { Component, useState } from "react"
import { connect } from "react-redux"
import { fetchDemoRequest, fetchDemoRequests, markDemoRequestAsComplete } from '../actions/admin';
import fileDownload from 'js-file-download';

const demos = [
    {
        'courseName': "Bitcoin and Cryptocurrency Technologies",
        'courseDescription': "Bitcoin and Cryptocurrency Technologies provides a comprehensive introduction to the revolutionary yet often misunderstood new technologies of digital currency.",
        'firstName': "Arvind",
        'lastName': "Narayanan",
        'email': "someemail@princeton.edu"
    },
    {
        'courseName': "Analysis of Algorithms",
        'courseDescription': "People who analyze algorithms have double happiness. First of all they experience the sheer beauty of elegant mathematical patterns that surround elegant computational procedures. Then they receive a practical payoff when their theories make it possible to get other jobs done more quickly and more economically. D. E. Knuth",
        'firstName': "Robert",
        'lastName': "Sedgewick",
        'email': "someemail@princeton.edu"
    }
]

const { Paragraph } = Typography;

const downloadAllFiles = (demoRequestFiles) => {
    demoRequestFiles.forEach((fileData, index) => {
        downloadFile(fileData.file, `video-${index + 1}.mp4` )
    })
}

const downloadFile = (url, filename) => {
    fetch(url, 
        {
            method: "GET",
        }
    )
    .then (response => { 
        if(response.ok){
            return response.blob()
        }else {
            throw new Error(response.status);
        }
    })
    .then( data => {
        fileDownload(data, filename)
    })
    .catch(error => console.log(error))
}

const DemoRequestFile = ({fileData, index}) => {

    return <a href={fileData.file} className="demo-request-video-row-link" target="_blank" rel="noopener noreferrer">
        <div className="demo-request-video-row">
            <p>#{index + 1}</p>
            <button 
                onClick={(event)=>{
                    event.preventDefault();
                    downloadFile(fileData.file, `video-${index + 1}.mp4`)
                }}
                className="demo-request-video-download-btn"
            >
                <DownloadOutlined />
            </button>
        </div>
    </a>
}
const DemoRequestFilesList = ({demoRequestFiles}) => {
    if(demoRequestFiles){

        if(demoRequestFiles.length == 0) {
            return <>No files were provided.</>
        }
        return demoRequestFiles.map((fileData, index) => {
            return <DemoRequestFile fileData={fileData} index={index}/>
          })
    } else {
        return <></>
    }

}

const DemoRequestLinksList = ({ demoRequestLinks}) => {
    if(demoRequestLinks) {

        if(demoRequestLinks.length == 0) {
            return <>No links were provided.</>
        }

        return demoRequestLinks.map((linkData, index) => {
            if(linkData.link.startsWith('https://')){
                return <div><a className="demo-request-link-btn" href={linkData.link} target="_blank" rel="noopener noreferrer"><LinkOutlined/> {linkData.link}</a></div>
            }else if(linkData.link.startsWith('www.')){
                return <div><a className="demo-request-link-btn" href={"https://" + linkData.link} target="_blank" rel="noopener noreferrer"><LinkOutlined/> https://{linkData.link}</a></div>
            }else {
                return <div className="demo-request-link-btn-invalid"><DisconnectOutlined/> {linkData.link}</div>
            }

            
        })
    } else {
        return <></>
    }
}

const DemoRequestList = ({demoRequest, demoRequests, demoRequestUpdateStatus, fetchDemoRequest, markDemoRequestAsComplete, sortBy}) => {
    if(sortBy === "Newest to Oldest") {
        return demoRequests.sort((a, b) => b.id - a.id).map(demo => {
            return <DemoRequest demo={demo} demoRequest={demoRequest} demoRequestUpdateStatus={demoRequestUpdateStatus} markDemoRequestAsComplete={markDemoRequestAsComplete} fetchDemoRequest={fetchDemoRequest} />
        })
    } else {
        return demoRequests.sort((a, b) => a.id - b.id).map(demo => {
            return <DemoRequest demo={demo} demoRequest={demoRequest} demoRequestUpdateStatus={demoRequestUpdateStatus} markDemoRequestAsComplete={markDemoRequestAsComplete} fetchDemoRequest={fetchDemoRequest} />
        })
    }
    
}

const DateRenderer = ({createdAt}) => {
    let dateObject = new Date(createdAt)
    return dateObject.toLocaleString()
}

const DemoRequest = ({ demo, demoRequest, demoRequestStatus, demoRequestUpdateStatus, fetchDemoRequest, markDemoRequestAsComplete }) => {
    const [visibleDrawer, setVisibleDrawer] = useState(false);

    const handleOnCardClick = () => {
        setVisibleDrawer(true);
        fetchDemoRequest(demo.id)
    }

    const DemoRequestStatus = () => {
        if(demo.status === "completed") {
            return <Button disabled><CheckCircleOutlined /> Completed</Button>
        } else {
            return <Button loading={demoRequestUpdateStatus === "LOADING"} onClick={()=> markDemoRequestAsComplete(demoRequest.id)}>Mark as Completed</Button>
        }
    }

    const DemoStatusTag = () => {
        if(demo.status === "completed") {
            return <Tag color="#2db7f5">Completed</Tag>
        } else {
            return <Tag >Submitted</Tag>
        }
    }

    return <>
        <div className="demo-request-card" onClick={handleOnCardClick}>
            <div className="demo-request-status-tag">
                <DemoStatusTag />
            </div>
            <h6 className="demo-request-card-name" >{demo.courseName}</h6>
            <p className="demo-request-card-description">
                <Paragraph 
                    ellipsis={{
                        rows: 4
                      }}
                >
                    {demo.courseDescription}
                </Paragraph>
                
            </p>
            <div className="demo-request-card-footer">
                <div>
                    <p>{demo.firstName} {demo.lastName}</p>
                    <p><span>{demo.email}</span></p>
                    
                </div>
                

                <div>
                    Requested at: <DateRenderer createdAt={demo.createdAt} />
                </div>
            </div>
        </div>
        <Drawer className="demo-request-drawer-component" placement="right" onClose={()=>setVisibleDrawer(false)} visible={visibleDrawer}>
            <div className="demo-request-drawer">
                <div className="demo-request-main-header-container">
                    <h6 className="demo-request-main-header" >Demo Request #{demo.id}</h6>
                    <DemoRequestStatus />
                </div>
                <div className="demo-request-metadata">
                    <div className="demo-request-row">
                        <div className="demo-request-metadata-item">
                            <p className="demo-request-metadata-header">Sender</p>
                            <p className="demo-request-metadata-value">{demo.firstName} {demo.lastName}</p>
                        </div>
                        <div className="demo-request-metadata-item">
                            <p className="demo-request-metadata-header">Contact</p>
                            <p className="demo-request-metadata-value">
                                <Paragraph 
                                    copyable key="share" 
                                    className="demo-request-copy-email"
                                >
                                   {demo.email}
                                </Paragraph>
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="demo-request-metadata-item">
                            <p className="demo-request-metadata-header">Request on</p>
                            <p className="demo-request-metadata-value"><DateRenderer createdAt={demo.createdAt}/></p>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className="demo-request-course-details">
                    <div className="demo-request-course-detail-container">
                        <p className="demo-request-course-detail-header">Course Name</p>
                        <p className="demo-request-course-detail-collection-name">{demo.courseName}</p>
                    </div>
                    <div className="demo-request-course-detail-container">
                        <p className="demo-request-course-detail-header">Course Description</p>
                        <p className="demo-request-course-detail-value">{demo.courseDescription && demo.courseDescription.length ? demo.courseDescription : "No description provided."}</p>
                    </div>
                </div>
                <Divider />
                {
                    demoRequestStatus == "LOADING" ? <Skeleton 
                                                        loading={true} 
                                                        active 
                                                        paragraph={{ rows: 4 }} 
                                                        button
                                                    /> : <div  className="demo-request-links-container">
                                                            <p className="demo-request-video-links-header">Links (Dropbox, Google Drive, etc.)</p>
                                                            <DemoRequestLinksList demoRequestLinks={demoRequest.links} />
                                                        </div>

                }
                <Divider />

                {
                    demoRequestStatus == "LOADING" ? <Skeleton 
                                                        loading={true} 
                                                        active 
                                                        paragraph={{ rows: 4 }} 
                                                        button
                                                    /> : <div>
                                                            <div className="demo-request-video-list-header">
                                                                <p>Video Files</p>
                                                                <button 
                                                                    disabled={demoRequest.files && demoRequest.files.length == 0} 
                                                                    className={demoRequest.files && demoRequest.files.length == 0 ? "demo-request-download-all-files-disabled" : "demo-request-download-all-files"} 
                                                                    onClick={()=>downloadAllFiles(demoRequest.files)} 
                                                                    type="primary"
                                                                >
                                                                    <DownloadOutlined /> Download All
                                                                </button>
                                                            </div>
                                                            <DemoRequestFilesList demoRequestFiles={demoRequest.files}/>
                                                        </div>
                }

                
            </div>
        </Drawer>
    </>
}




class AdminDemoRequests extends Component {

    constructor(props){
        super(props);
        this.state = {
            sortBy: "Newest to Oldest"
        }
    }

    componentDidMount() {
        this.props.fetchDemoRequests()
    }

    menu = (
        <Menu
          items={[
            {
              key: '1',
              label: (
                <div onClick={()=>this.setState({ sortBy: "Newest to Oldest" })}>
                    Newest to Oldest
                </div>
              )
            },
            {
                key: '2',
                label: (
                    <div onClick={()=>this.setState({ sortBy: "Oldest to Newest" })}>
                        Oldest to Newest
                    </div>
                  ) 
            },
          ]}
        />
      );
    render() {
        return <div id="demo-requests-list-container">
                    <div id="demo-requests-list-header">
                        <h3>Demo Requests</h3>
                        <div id="demo-requests-sort-dropdown">
                            <p>Sort by</p>
                           
                            <Dropdown overlay={this.menu} >
                                <Button>
                                    <Space>
                                        {this.state.sortBy}
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                   
                    <DemoRequestList 
                        demoRequests={this.props.demoRequests}
                        demoRequest={this.props.demoRequest}
                        demoRequestStatus={this.props.demoRequestStatus}
                        fetchDemoRequest={this.props.fetchDemoRequest}
                        sortBy={this.state.sortBy}
                        markDemoRequestAsComplete={this.props.markDemoRequestAsComplete}
                        demoRequestUpdateStatus={this.props.demoRequestUpdateStatus}
                    />
                </div>
    }
    
}

const mapStateToProps = state => {
    return {
        demoRequests: state.adminReducer.demoRequests,
        demoRequest: state.adminReducer.demoRequest,
        demoRequestStatus: state.adminReducer.demoRequestStatus,
        demoRequestUpdateStatus: state.adminReducer.demoRequestUpdateStatus
    }
}

export default connect(mapStateToProps, { fetchDemoRequest, fetchDemoRequests, markDemoRequestAsComplete })(AdminDemoRequests);