import { Component, useEffect, useState } from 'react';
import { Breadcrumb, Button, Divider, Modal, Popconfirm, Space, Table, Tag, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, HomeOutlined, SendOutlined, SmallDashOutlined, UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchCertificationRequestList, approveOrRejectCertificate } from '../actions/admin';


const { Text } = Typography;

const RequestTable = ({ source, fetchData, dataLoadingStatus, onApprove, approveProgress, rejectProgress }) => {

    const [data, setData] = useState(source);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 25,
        showSizeChanger: false
    });
    const confirmOrReject = (instance, approved) => { // 1: approved, 0: rejected
        onApprove(instance.id, approved);
    }

    const columns = [
        {
            title: 'Name',
            render: instance => <Link to={`/admin/certificate-requests/${instance.id}`}>
                <>
                    {instance.user.firstName} {instance.user.lastName} ({instance.user.username})
                </>
            </Link>,
        },
        {
            title: 'Email',
            render: instance => <Text>{instance.user.email}</Text>
        },
        {
            title: 'Certification Name',
            dataIndex: 'collection',
            render: collection => <Text>{collection.name}</Text>
        },
        {
            title: 'Status',
            render: instance => {
                return (
                    <>
                        {instance.status === 'not_requested' && <Tag color={'gray'} key={instance.status}> {instance.status.toUpperCase()} </Tag>}
                        {instance.status === 'requested' && <Tag color={'blue'} key={instance.status}> {instance.status.toUpperCase()} </Tag>}
                        {instance.status === 'issued' && <Tag color={'green'} key={instance.status}> {instance.status.toUpperCase()} </Tag>}
                        {instance.status === 'rejected' && <Tag color={'red'} key={instance.status}> {instance.status.toUpperCase()} </Tag>}
                    </>
                )
            },
        },
        {
            title: 'Action',
            fixed: 'right',
            width: 200,
            render: instance => {
                let currentDate = new Date()
                let endDate = new Date(instance.accessUntil)
                let requestExpired = currentDate > endDate;
                if (requestExpired) {
                    return <Text type="danger"><CloseCircleOutlined /> Expired</Text>
                }
                if (!requestExpired && instance.status === "requested") {
                    return (
                        <>
                            <Popconfirm
                                title="Are you sure you want to approve this certification?"
                                onConfirm={() => confirmOrReject(instance, 1)}
                                onCancel={(e) => { e.stopPropagation() }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button onClick={(e) => e.stopPropagation()} loading={approveProgress} disabled={approveProgress || rejectProgress}>
                                    <Text color='green'>
                                        <CheckCircleOutlined /> Approve
                                    </Text>
                                </Button>
                            </Popconfirm>

                            <Popconfirm
                                title="Are you sure you want to reject this certification?"
                                onConfirm={() => confirmOrReject(instance, 0)}
                                onCancel={(e) => { e.stopPropagation() }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button onClick={(e) => e.stopPropagation()} loading={rejectProgress} disabled={approveProgress || rejectProgress}>
                                    <Text color='red'>
                                        <CloseCircleOutlined color='red' /> Reject
                                    </Text>
                                </Button>
                            </Popconfirm>
                        </>
                    )
                }
            },
        }
    ]

    const updateTableState = (pageNum, pageSize, total) => {
        setLoading(false)
        setPagination({
            current: pageNum,
            pageSize: pageSize,
            total: total,
            showSizeChanger: false,
        })
    }

    const handleTableChange = (pagination) => {
        fetchData(pagination.current, updateTableState);
    };

    useEffect(() => {
        fetchData(1, updateTableState);
    }, []);

    useEffect(() => {
        setData(source)
    }, [source]);

    return (
        <>
            <Table columns={columns} dataSource={[...data]} pagination={pagination} onChange={handleTableChange} loading={dataLoadingStatus === "LOADING"} rowKey={r => r.id} />
        </>
    )
}




class AdminCertificateRequests extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="centered-container">
            <Space className="breadcrumbs">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/"><HomeOutlined /></Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item><Link to="/admin">Admin Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Certificate Requests</Breadcrumb.Item>
                </Breadcrumb>
            </Space>
            <br />
            <br />

            <RequestTable
                dataLoadingStatus={this.props.certificateRequestListStatus}
                source={this.props.certificateRequestList}
                fetchData={this.props.fetchCertificationRequestList}
                onApprove={this.props.approveOrRejectCertificate}
                approveProgress={this.props.approveProgress}
                rejectProgress={this.props.rejectProgress}
            ></RequestTable>
        </div>
    }

}

const mapStateToProps = state => {
    return {
        certificateRequestList: state.adminReducer.certificateRequestList,
        certificateRequestListStatus: state.adminReducer.certificateRequestListStatus,
        approveProgress: state.adminReducer.approveProgress,
        rejectProgress: state.adminReducer.rejectProgress,
    }
}

export default connect(mapStateToProps, { fetchCertificationRequestList, approveOrRejectCertificate })(AdminCertificateRequests);