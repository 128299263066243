import { Button, Col, Divider, Drawer, Row, Statistic, Typography } from 'antd';
import { useState } from 'react';
import ModulePopularity from './ModulePopularity';
import RevenueByModule from './RevenueByModule';


const CreatorCollectionPerformanceDetail = ({collection}) => {
    const [visibleDrawer, setVisibleDrawer] = useState(false);

    const closeDrawer = () => {
        setVisibleDrawer(false)
    }

    const openDrawer = () => {
        setVisibleDrawer(true)
    }

    return <>
            <Button type="primary" onClick={openDrawer}>
                View Details
            </Button>
            <Drawer 
                title={collection.name} 
                placement="right" 
                onClose={closeDrawer} 
                visible={visibleDrawer}
                className="creator-collection-performance-detail"
            >
                <Row>
                    <Col span={6}>
                        <Statistic 
                            title="Total Revenue Generated"
                            value={collection.totalRevenue.toFixed(2)}
                            prefix={"$ "}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic 
                            title="Unique Subscribers"
                            value={collection.totalSubscribers}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic 
                            title="Unique Limited Subscribers"
                            value={collection.totalLimitedSubscribers}
                        />
                    </Col>
                </Row>
                <Divider/>
                <ModulePopularity
                    collection={collection}
                />
                <Divider/>
                <RevenueByModule 
                    collection={collection}
                />

                

                
            </Drawer>
        </>
}

export default CreatorCollectionPerformanceDetail;