import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Layout, Menu } from "antd";
import { PlayCircleOutlined, MenuOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;

class VideoPageMenu extends Component {
    render() {
        return (
            <>
                <Button
                    onClick={() => this.props.onToggle()}
                    style={{
                        position: "fixed",
                        left: 0,
                        zIndex: 99,
                        border: "none",
                        borderRadius: 0,
                        marginTop: "-40px",
                        fontSize: "16px"
                    }}>
                    <MenuOutlined />
                </Button>
                <Layout.Sider
                    id="video-page-menu"
                    width={"15vw"}
                    collapsedWidth={0}
                    collapsible
                    collapsed={this.props.isMenuCollapsed}
                    trigger={null}>
                    <Menu
                        mode="inline"
                        onClick={this.handleClick}
                        defaultOpenKeys={[`sub${this.props.currentModule.id}`]}
                        defaultSelectedKeys={[
                            `${this.props.currentModule.id}-${this.props.currentVideo.id}`
                        ]}>
                        {this.props.collection.modules.map(
                            (module, moduleIndex) => {
                                return (
                                    <SubMenu
                                        key={`sub${module.id}`}
                                        title={module.name}
                                        disabled={
                                            !this.props.user.moduleAccess[
                                                module.id
                                            ]
                                        }>
                                        {module.videos.map(
                                            (video, videoIndex) => {
                                                return (
                                                    <Menu.Item
                                                        key={`${module.id}-${video.id}`}
                                                        title={video.title}
                                                        icon={
                                                            <PlayCircleOutlined />
                                                        }>
                                                        <Link
                                                            to={
                                                                this.props
                                                                    .unlistedKey !=
                                                                null
                                                                    ? `/collections/${
                                                                          this
                                                                              .props
                                                                              .collection
                                                                              .id
                                                                      }/modules/${
                                                                          moduleIndex +
                                                                          1
                                                                      }/videos/${
                                                                          videoIndex +
                                                                          1
                                                                      }/?key=${
                                                                          this
                                                                              .props
                                                                              .unlistedKey
                                                                      }`
                                                                    : `/collections/${
                                                                          this
                                                                              .props
                                                                              .collection
                                                                              .id
                                                                      }/modules/${
                                                                          moduleIndex +
                                                                          1
                                                                      }/videos/${
                                                                          videoIndex +
                                                                          1
                                                                      }/`
                                                            }>
                                                            {video.title}
                                                        </Link>
                                                    </Menu.Item>
                                                );
                                            }
                                        )}
                                    </SubMenu>
                                );
                            }
                        )}
                    </Menu>
                </Layout.Sider>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        collection: state.collectionReducer.singleCollection,
        currentModule: state.collectionReducer.singleModule,
        currentVideo: state.collectionReducer.singleVideo
    };
};
export default connect(mapStateToProps, {})(VideoPageMenu);
