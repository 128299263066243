import { Card, Result, Typography } from "antd";


const { Paragraph, Title } = Typography;

const FollowUp = () => {
    return <div>
        <Card>
        <Result
            status="404"
            title="How can I get started?"
        />

        <div style={{display: "flex",  margin: "0 auto", justifyContent: "center", flexDirection: "column", width: 550}}>
            <Paragraph>
                <ol style={{fontSize: 18}}>
                    <li>Click Sign Up button in the top right</li>
                    <li>Create an account</li>
                    <li>Confirm your email</li>
                    <li>Return to www.cubits.ai</li>
                    <li>Complete registration!</li>
                </ol>
            </Paragraph>

            <div style={{ padding: 0}}>
                <Title>If you have any questions, feel free to reach us at support@cubits.ai</Title>
            </div>
        </div>
        </Card>
       

    </div>
}

export default FollowUp;