import RichTextEditor from "../../../Components/RichTextEditor";
import { postQnAReply } from "../../../actions/collection";
import { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Divider, Form, message, Space } from 'antd';
import { SendOutlined } from '@ant-design/icons';


const QnAReplyForm = ({cancelReply, collectionId, currentUser, enrolledLicenses, handleSubmitSuccess, moduleId, postQnAReply, qnaLevel, questionId, replyingTo, selectedLicense, videoId}) => {
    const [form] = Form.useForm();
    const [reset, setReset] = useState(false);
    const [submitReplyLoading, setSubmitReplyLoading] = useState(false);
    const [isAnonymous, setIsAnonymous] = useState(false);

    const handleOnFinish = (values) => {
        const handleAfterSuccess = () => {
            setReset(true);
            handleSubmitSuccess();
            setSubmitReplyLoading(false);
            form.setFieldsValue({'text': undefined})
            message.success("Your reply was posted.")
        }
        postQnAReply(collectionId, moduleId, videoId, questionId, selectedLicense, qnaLevel, values, handleAfterSuccess)
    }

    const handleOnRichTextEditorChange = (value) => {
        form.setFieldsValue({
            text: value
        })
    }

    const handleCheckboxChange = (event) => {
        form.setFieldsValue({
            anonymous: event.target.checked
        })
        setIsAnonymous(event.target.checked)
    }

    const renderAnonymousCheckbox = () => {
        let currentLicense = enrolledLicenses.find(license => license.id === selectedLicense);
        if(currentLicense.createdById == currentUser.id){
            return <div></div>
        }else {
            return <Checkbox onChange={handleCheckboxChange}>Post anonymously to other students</Checkbox>
        }
    }

    return <div id={`qna-question-${questionId}-reply-form`}  className={`qna-reply-form-container`}>
            <Space direction="horizontal"><span className="qna-reply-username">Replying to... </span><span style={{color: "#33bcb7", fontSize: "12px"}}>{replyingTo}</span></Space>
            <div className={`qna-reply-editor`}>
                <Form form={form} onFinish={handleOnFinish}>
                    <Form.Item name='text' hidden>
                        
                    </Form.Item>
                    <Form.Item name='anonymous' hidden>
                                
                    </Form.Item>
                </Form>
                <RichTextEditor 
                    customOnChange={handleOnRichTextEditorChange} 
                    placeholder="Post a reply (Use @ to reference timestamp/range in a video. Ex: @1:00 to @2:00)" 
                    readOnly={false} 
                    reset={reset} 
                    setReset={setReset} 
                    allowTimestamps={true}
                />
            </div>
            <br/> 
            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <Space>{renderAnonymousCheckbox()}</Space>
                <div style={{ display: "flex", justifyContent: "flex-end"}}>

                    <Button onClick={cancelReply} style={{marginRight: "10px"}}>
                        Cancel
                    </Button>
                    <Button 
                        type="primary" 
                        onClick={() => {
                            let textValue = form.getFieldValue('text')
                            if(textValue && textValue.blocks.some(block => block.text.length)){
                                setSubmitReplyLoading(true);
                                form.submit()
                            } else {
                                message.warning('Please enter a reply first.')
                            }
                        }} 
                        loading={submitReplyLoading}
                    >
                        <SendOutlined /> Post Reply
                    </Button>
                </div>
            </div>
            <br/>
        </div>
}

export default connect(null, { postQnAReply })(QnAReplyForm);