import { HomeOutlined } from "@ant-design/icons";
import { Alert, Breadcrumb, Input, Typography } from "antd"
import React from "react";
import { Link } from "react-router-dom";
import CollectionInfoCard from "./CollectionInfoCard";
import collection from '../MockData.json';
import CollectionModulesList from "./CollectionModulesList";

const { Title, Text } = Typography;
const { Search } = Input;

class MockCollection extends React.Component {
    render(){
    
        return (
            <div id="premium-collection-mid-section">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/"><HomeOutlined /></Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{collection.originalCollection ? <Link to="/collections/">Premium Courses</Link> : "Courses"}</Breadcrumb.Item>
                    <Breadcrumb.Item>{collection.name}</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Alert
                        message="For Demo Purposes Only"
                        description="Please note that some features are hidden until you create an account. Any actions you make are not saved.    "
                        type="info"
                        showIcon
                    />
                <div id="collection-detail">
                
                    <CollectionInfoCard collection={collection}/>
                    
                    <CollectionModulesList 
                        collection={collection} 
                        urlParams={this.props.match.params} 
                    />
                </div>
            </div>
        )          
    }
}

export default MockCollection