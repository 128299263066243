import { Button, Carousel, Drawer, Tag } from 'antd';
import { useState } from 'react';
import RichTextPreview from '../../Components/RichTextPreview';
import { convertFromRaw, EditorState } from 'draft-js';
import QuizQuestionOption from '../../Collections/Video/QuizQuestions/QuizQuestionOption';

const QuizQuestionAttempt = ({question, attempt, attemptNum}) => {
    const [visibleAttempt, setVisibleAttempt] = useState(false);
    const [revealed, setRevealed] = useState();
    const [submitted, setSubmitted] = useState(true);


    const renderQuestionType = () => {
        if(question.questionType == "select_one"){
            return "Select one"
        } else if(question.questionType == "choose_all_that_apply"){
            return "Choose all that apply"
        }else if(question.questionType == "open_ended"){
            return "Your answer"
        }
    }

    const contentState = convertFromRaw(question['text']);
    const editorState = EditorState.createWithContent(contentState);

    return <>
            <Button
                onClick={()=>setVisibleAttempt(true)}
            >
                {`Attempt # ${attemptNum}`}
            </Button>
            <Drawer
                width={640}
                placement="right"
                closable={false}
                onClose={()=>setVisibleAttempt(false)}
                visible={visibleAttempt}
                title={question.title}
                footer={
                    <div
                        style={{
                        textAlign: 'right',
                        }}
                    >
                        <Button  style={{ marginRight: 8 }} onClick={()=>setVisibleAttempt(false)}>
                            Close Preview
                        </Button>
                    </div>
                }
            >
                    <div className="quizquestion-drawer-content-width">
                        <RichTextPreview editorState={editorState}/>
                        <br/>
                        <Carousel style={{background: '#364d79'}} autoplay className="quizquestion-carousel-width">
                            {question.images.map((imageFile, index) => <div key={index}><img alt="example" style={{ width: '100%' }} src={imageFile.image} /></div>)}
                        </Carousel>
                        <br/>
                        <Tag color="geekblue">{renderQuestionType()}</Tag>
                        {
                            question.options.map((option, index) => <QuizQuestionOption key={`option[${index}]`}
                                                                        handleOnClick={() => {}}
                                                                        selected={attempt.selectedAnswers.includes(option.id)} 
                                                                        submitted={submitted}
                                                                        option={option}
                                                                        latestAttempt={attempt}
                                                                    />)
                        }
                        </div>
                </Drawer>
        </>
}

export default QuizQuestionAttempt;