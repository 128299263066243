import { Anchor, Card, Col, Row, Statistic, Typography } from 'antd'; 
import { Component } from 'react';
import { connect } from 'react-redux';

import CreatorProfile from './CreatorProfile';
import { fetchCreatorCollectionPerformances, fetchCreatorModulePerformances, fetchCreatorEarnings, fetchCreatorProfile, fetchCreatorPublicCollections, fetchCreatorTransactionsSummary } from '../actions/creator';
import CreatorEarnings from './CreatorEarnings';
import CreatorCollectionPerformances from './CollectionPerformances/CreatorCollectionPerformances';
import CreatorModulePerformances from './ModulePerformances/CreatorModulePerformances';
import CreatorTransactionsSummary from './CreatorTransactionsSummary';
import CreatorPublicCollections from './CreatorPublicCollections';

const { Text, Title } = Typography;
const { Link } = Anchor;

class CreatorAboutPage extends Component {
    componentDidMount(){
        this.props.fetchCreatorProfile(this.props.match.params.userId)
        this.props.fetchCreatorPublicCollections(this.props.match.params.userId);
        if(this.props.isAuthenticated && (this.props.user.id == this.props.match.params.userId || this.props.user.isAdmin)){
            this.props.fetchCreatorEarnings(this.props.match.params.userId)
            this.props.fetchCreatorCollectionPerformances(this.props.match.params.userId)
            this.props.fetchCreatorModulePerformances(this.props.match.params.userId)
        }
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps.isAuthenticated != this.props.isAuthenticated && this.props.isAuthenticated && (this.props.user.id == this.props.match.params.userId || this.props.user.isAdmin)){
            this.props.fetchCreatorEarnings(this.props.match.params.userId)
            this.props.fetchCreatorCollectionPerformances(this.props.match.params.userId)
            this.props.fetchCreatorModulePerformances(this.props.match.params.userId)
        }
    }
    render(){
        if(this.props.isAuthenticated && (this.props.user.id == this.props.match.params.userId || this.props.user.isAdmin)){
            return <div className="centered-container">
                    <div className="creator-page-content">
                        <br/>
                        <CreatorProfile 
                            creatorProfile={this.props.creatorProfile} 
                            creatorProfileStatus={this.props.creatorProfileStatus}
                            creatorId={this.props.match.params.userId}
                        />
                        <br/>
                        <CreatorPublicCollections />
                        <br/>
                        <CreatorEarnings
                            creatorEarnings={this.props.creatorEarnings}
                            creatorEarningsStatus={this.props.creatorEarningsStatus}
                        />
                        <br/>
                        <CreatorCollectionPerformances 
                            creatorCollectionPerformances={this.props.creatorCollectionPerformances}
                            creatorCollectionPerformancesStatus={this.props.creatorCollectionPerformancesStatus}
                        />
                        <br/>
                        <CreatorModulePerformances 
                            creatorModulePerformances={this.props.creatorModulePerformances}
                            creatorModulePerformancesStatus={this.props.creatorModulePerformancesStatus}
                        />
                        <br/>
                        <CreatorTransactionsSummary
                            creatorTransactionsSummary={this.props.creatorTransactionsSummary}
                            creatorTransactionsSummaryStatus={this.props.creatorTransactionsSummaryStatus}
                            fetchCreatorTransactionsSummary={this.props.fetchCreatorTransactionsSummary}
                            userId={this.props.match.params.userId}
                        />
                    </div>
                    <div className="creator-page-anchor-container">
                        <br/>
                        <Card style={{width: "200px", height: "100%"}}>
                            <Anchor  offsetTop={70}>
                                <Link href="#author-profile" title="Author Profile" />
                                <Link href="#creator-public-collections" title="Public Courses" />
                                <Link href="#earning-details" title="Earning Details" />
                                <Link href="#collection-performance" title="Course Performance">
                                    
                                </Link>
                                <Link href="#module-performance" title="Module Performance">
                    
                                </Link>
                                <Link href="#transactions-summary" title="Transactions Summary">
                    
                                </Link>
                            
                            </Anchor>
                        </Card>
                    </div>
                </div>
        }else {
            return <div className="centered-container" >
                    <div className="creator-page-content">
                        <br/>
                        <CreatorProfile 
                            creatorProfile={this.props.creatorProfile} 
                            creatorProfileStatus={this.props.creatorProfileStatus}
                            creatorId={this.props.match.params.userId}
                        />
                        <br/>
                        <CreatorPublicCollections 
                            creatorProfile={this.props.creatorProfile} 
                            creatorProfileStatus={this.props.creatorProfileStatus}
                        />
                    </div>
                    <div className="creator-page-anchor-container">
                        <br/>
                        <Card style={{width: "200px", height: "100%"}}>
                            <Anchor  offsetTop={70}>
                                <Link href="#author-profile" title="Author Profile" />     
                                <Link href="#creator-public-collections" title="Public Courses" />                       
                            </Anchor>
                        </Card>
                    </div>
                </div>
        }
        
    }
}

const mapStateToProps = state => {
    return {
        creatorCollectionPerformances: state.creatorReducer.creatorCollectionPerformances,
        creatorCollectionPerformancesStatus: state.creatorReducer.creatorCollectionPerformancesStatus,
        creatorEarnings: state.creatorReducer.creatorEarnings,
        creatorEarningsStatus: state.creatorReducer.creatorEarningsStatus,
        creatorModulePerformances: state.creatorReducer.creatorModulePerformances,
        creatorModulePerformancesStatus: state.creatorReducer.creatorModulePerformancesStatus,
        creatorProfile: state.creatorReducer.creatorProfile,
        creatorProfileStatus: state.creatorReducer.creatorProfileStatus,
        creatorTransactionsSummary: state.creatorReducer.creatorTransactionsSummary,
        creatorTransactionsSummaryStatus: state.creatorReducer.creatorTransactionsSummaryStatus,
        isAuthenticated: state.userReducer.isAuthenticated,
        user: state.userReducer.user,
    }
}
export default connect(mapStateToProps, { fetchCreatorCollectionPerformances, fetchCreatorModulePerformances, fetchCreatorEarnings, fetchCreatorProfile,fetchCreatorPublicCollections, fetchCreatorTransactionsSummary })(CreatorAboutPage);