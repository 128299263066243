import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Card, Image } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import '../../Components.less';
import { useHistory } from 'react-router-dom';
import PlaceholderImage from '../../placeholder-video-cover.png';

const { Meta } = Card;

const VideoCard = ({index, collection, currentUser, collectionId, mode, module, moduleAccess, moduleId, moduleIndex, moduleName, locked, playVideoInModal, purchaseModule, price, unlistedKey, video}) => {

    const history = useHistory();
    var [vimeoThumbnailUrl, setVimeoThumbnailUrl] = useState(null);

    const fetchVimeoThumbnail = async(video) => {
        
        try {
            var response = await fetch(`https://vimeo.com/api/v2/video/${video.embedId}.json`)
            let json = await response.json()
            return setVimeoThumbnailUrl(json[0]["thumbnail_large"]);
        } catch(e) {
            return setVimeoThumbnailUrl("");
        }
        
    }
    const getVideoThumbnail = (video) => {
        if(video.videoType === "youtube"){
            return <Image src={`https://img.youtube.com/vi/${video.embedId}/mqdefault.jpg`} fallback={PlaceholderImage} preview={false} style={{aspectRatio: 16/9}}/>
        }else if(video.videoType === "vimeo"){
            fetchVimeoThumbnail(video)
            return <Image
                        src={vimeoThumbnailUrl}
                        fallback={PlaceholderImage}
                        preview={false}
                        style={{aspectRatio: 16/9}}
                    />
        }
    }
   
    
    return  <Link to={`/mock/video/`}>
            <Card
                hoverable
                className="video-card"
                cover={getVideoThumbnail(video)}
                style={{animationDelay: `${60 * index}ms`}}
            >
                <div className="play-icon-overlay">
                    <PlayCircleOutlined />
                </div>
                <Meta title={video.title} />
            </Card>
        </Link>

    
}


export default VideoCard;