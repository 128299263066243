import ModuleDisplay from "./ModuleDisplay";
import { Divider } from 'antd';
const ModuleRenderer = ({collection, collectionId, isAuthenticated, moduleAccess, modules, selectedModule, unlistedKey, mode="purchase", certificationView=false}) => {

    if(selectedModule == null || selectedModule >= modules.length){
        return modules.map((module, index) => {
            if(index == 0) {
                return <div key={index}>
                            <ModuleDisplay 
                                collection={collection}
                                collectionId={collectionId} 
                                isAuthenticated={isAuthenticated}
                                mode={mode}
                                index={index}
                                module={module} 
                                moduleAccess={moduleAccess} 
                                moduleIndex={index}
                                unlistedKey={unlistedKey}
                                certificationView={certificationView}
                            />
                        </div>
            } else {
                return <div key={index}><Divider />
                            <ModuleDisplay 
                                collection={collection}
                                collectionId={collectionId} 
                                isAuthenticated={isAuthenticated}
                                index={index}
                                mode={mode}
                                module={module}
                                moduleAccess={moduleAccess} 
                                moduleIndex={index}
                                unlistedKey={unlistedKey}
                                certificationView={certificationView}
                            />
                        </div>
            }
        })  
    } else {
        return  <ModuleDisplay 
                    collection={collection}
                    collectionId={collectionId} 
                    isAuthenticated={isAuthenticated}
                    index={0}
                    mode={mode} 
                    module={modules[selectedModule]} 
                    moduleAccess={moduleAccess} 
                    moduleIndex={selectedModule}
                    unlistedKey={unlistedKey}
                    certificationView={certificationView}
                />
    }
    
}

export default ModuleRenderer;