import {
    Form,
    Input,
    Button,
    Select,
    Space,
    TimePicker,
    Typography
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { convertToRaw, EditorState } from "draft-js";
import { useState } from "react";
import RichTextEditor from "../../Components/RichTextEditor";
import QuestionCreatorFormOption from "../QuestionCreatorFormOption";
import QuizQuestionImageUpload from "../QuizQuestionImageUpload";
import moment from "moment";
import { useEffect } from "react";

const { Paragraph, Text } = Typography;
const { RangePicker } = TimePicker;
const { Option } = Select;

const BulkCreateForm = ({ question, onQuestionUpdate }) => {
    const [form] = Form.useForm();
    const [reset, setReset] = useState(false);
    const [questionTitle, setQuestionTitle] = useState(question.title);
    const [maxDuration, setMaxDuration] = useState(
        moment("00:00:00", "HH:mm:ss").seconds(question.videoObj.duration)
    );
    const [questionTextImageIds, setQuestionTextImageIds] = useState([]);
    const [imageFileList, setImageFileList] = useState(question.fileList || []);
    const [allImages, setAllImages] = useState([]);

    // On form value changed
    const onFormValueChanged = (changedValues, allValues) => {
        onQuestionUpdate(allValues);
    };

    // On title changed
    const handleOnQuestionTitleChange = (title) => {
        form.setFieldsValue({ title: title });
        setQuestionTitle(title);
        onQuestionUpdate({ title });
    };

    // On Question text changed
    const handleOnQuestionTextChange = (value) => {
        form.setFieldsValue({ text: value });
        onQuestionUpdate({ text: value });
    };

    const onOptionChanged = (changedOptionsList) => {
        onQuestionUpdate({ options: changedOptionsList });
    };

    const addImageIdToQuestionImages = (id) => {
        const imgIds = [...questionTextImageIds, id];
        setQuestionTextImageIds(imgIds);
        onQuestionUpdate({ imageIds: imgIds });
    };

    const setFileList = (fileList) => {
        setImageFileList(fileList);
        onQuestionUpdate({ fileList: fileList });
    };

    const removeItemFromQuestionImages = (id) => {
        let newState = [...questionTextImageIds];
        let foundIndex = newState.indexOf(id);
        if (foundIndex > -1) {
            newState.splice(foundIndex, 1);
        }
        setQuestionTextImageIds(newState);
        onQuestionUpdate({ imageIds: newState });

        let newAllImagesState = allImages.filter(
            (imageData) => imageData.id != id
        );
        setAllImages(newAllImagesState);
    };

    const addImageToAll = (imageData) => {
        setAllImages([...allImages, imageData]);
    };

    const getDisabledHours = () => {
        let hours = [];
        for (let i = maxDuration.hours() + 1; i <= 23; i++) {
            hours.push(i);
        }
        return hours;
    };
    const getDisabledMinutes = (selectedHour) => {
        let minutes = [];
        if (selectedHour === maxDuration.hours()) {
            for (let i = maxDuration.minutes() + 1; i <= 60; i++) {
                minutes.push(i);
            }
        }
        return minutes;
    };
    const getDisabledSeconds = (selectedHour, selectedMinute) => {
        let seconds = [];
        if (
            selectedHour === maxDuration.hours() &&
            selectedMinute === maxDuration.minutes()
        ) {
            for (let i = maxDuration.seconds() + 1; i <= 60; i++) {
                seconds.push(i);
            }
        }
        return seconds;
    };

    const addImageIdToOptionImages = (optionIndex, id) => {
        let options = form.getFieldValue("options");
        options[optionIndex]["imageId"] = id;
        form.setFieldsValue({ options });
        onQuestionUpdate({ options });
    };

    const addImageFile = (index, fileList) => {
        console.log(index, fileList);
        let options = form.getFieldValue("options");
        options[index]["imageFile"] =
            fileList.length && fileList.length > 0 ? fileList[0] : null;
        form.setFieldsValue({ options });
        onQuestionUpdate({ options });
    };

    const onQuestionTypeChanged = async (value) => {
        let options = form.getFieldValue("options");
        if (value == "select_one" && options.length > 0) {
            let updatedOptions = options.map((option) => {
                return {
                    ...option,
                    correctAnswerFlag: false
                };
            });
            form.setFieldsValue({
                questionType: value,
                options: updatedOptions
            });
            onQuestionUpdate({ options: updatedOptions });
        }

        if (value == "open_ended" && options.length === 0) {
            let updatedOptions = [
                {
                    correctAnswerFlag: true,
                    optionText: convertToRaw(
                        EditorState.createEmpty().getCurrentContent()
                    ),
                    imageId: null,
                    explanationText: convertToRaw(
                        EditorState.createEmpty().getCurrentContent()
                    )
                }
            ];
            form.setFieldsValue({
                questionType: value,
                options: updatedOptions
            });
            onQuestionUpdate({ options: updatedOptions });
        }
        await form.validateFields();
    };

    useEffect(async () => {
        try {
            await form.validateFields();
        } catch (e) {
            // invalid form
        }
    }, []);

    return (
        <>
            <Form
                name="questions"
                form={form}
                layout="vertical"
                initialValues={question}
                onValuesChange={onFormValueChanged}>
                <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                        {
                            required: true,
                            message: "Title is required."
                        }
                    ]}>
                    <Paragraph
                        editable={{
                            onChange: handleOnQuestionTitleChange,
                            maxLength: 100
                        }}
                        style={{ margin: 0 }}>
                        {questionTitle}
                    </Paragraph>
                </Form.Item>
                <RichTextEditor
                    editorRawData={question.text}
                    customOnChange={(value) => {}}
                    afterContentChanged={handleOnQuestionTextChange}
                    placeholder="Enter the question text"
                    readOnly={false}
                    reset={reset}
                    setReset={setReset}
                />
                <br />
                <Text>Supporting Images</Text>
                <QuizQuestionImageUpload
                    addImageToForm={addImageIdToQuestionImages}
                    addImageToAll={addImageToAll}
                    customOnRemove={removeItemFromQuestionImages}
                    reset={reset}
                    imageFileList={imageFileList}
                    setFileList={setFileList}
                />
                <br />
                <Form.Item
                    name="questionType"
                    label="Question Type"
                    rules={[
                        {
                            required: true,
                            message: "Must select a question type."
                        },
                        {
                            message: "Invalid Selection",
                            validator: (_, value) => {
                                if (
                                    [
                                        "select_one",
                                        "choose_all_that_apply",
                                        "open_ended"
                                    ].indexOf(value) !== -1
                                ) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject("Invalid Selection");
                                }
                            }
                        }
                    ]}>
                    <Select
                        placeholder="Type of question"
                        onChange={onQuestionTypeChanged}>
                        <Option value="select_one" label="Select One">
                            Select one
                        </Option>
                        <Option
                            value="choose_all_that_apply"
                            label="Choose all that apply">
                            Choose all that apply
                        </Option>
                        <Option value="open_ended" label="Open-ended Question">
                            Open-ended question
                        </Option>
                    </Select>
                </Form.Item>
                <Space align="center">
                    <Form.Item
                        name="timeRange"
                        label="Associated timestamps"
                        tooltip="Questions will be triggered when video reaches the 'End time' value you set"
                        rules={[
                            {
                                required: true,
                                message: "Must select a time range."
                            },
                            {
                                validator: async (_, values) => {
                                    if (
                                        values[1].isBefore(values[0]) ||
                                        values[1].isSame(values[0])
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                "End time must take place after start time."
                                            )
                                        );
                                    }
                                }
                            }
                        ]}>
                        <RangePicker
                            showNow={false}
                            disabledHours={getDisabledHours}
                            disabledMinutes={getDisabledMinutes}
                            disabledSeconds={getDisabledSeconds}
                        />
                    </Form.Item>
                </Space>
                <br />
                <Form.List
                    name="options"
                    rules={[
                        {
                            validator: async (_, options) => {
                                let data = form.getFieldsValue(true);
                                let questionType = data["questionType"];
                                if (questionType == "open_ended") {
                                    if (options.length < 1) {
                                        return Promise.reject(
                                            new Error(
                                                "Please enter anwer hint."
                                            )
                                        );
                                    }
                                } else {
                                    if (!options || options.length < 2) {
                                        return Promise.reject(
                                            new Error(
                                                "At least two options required."
                                            )
                                        );
                                    } else if (
                                        !options.some(
                                            (option) =>
                                                option.correctAnswerFlag == true
                                        )
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                "Must have at least one correct answer."
                                            )
                                        );
                                    } else if (
                                        questionType == "select_one" &&
                                        options.every(
                                            (o) => o.correctAnswerFlag === true
                                        )
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                "Only one answer should be correct."
                                            )
                                        );
                                    }
                                }
                            }
                        }
                    ]}>
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <QuestionCreatorFormOption
                                    formRef={form}
                                    field={field}
                                    key={index}
                                    index={index}
                                    removeOption={() => remove(index)}
                                    addImageToForm={addImageIdToOptionImages}
                                    addImageToAll={addImageToAll}
                                    addImageFile={addImageFile}
                                    isOpenEndedQuestion={
                                        form.getFieldsValue(true)[
                                            "questionType"
                                        ] == "open_ended"
                                    }
                                    onOptionChanged={onOptionChanged}
                                />
                            ))}

                            {form.getFieldsValue(true)["questionType"] !=
                                "open_ended" && (
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add({
                                                correctAnswerFlag: false,
                                                optionText: convertToRaw(
                                                    EditorState.createEmpty().getCurrentContent()
                                                ),
                                                imageId: null,
                                                explanationText: convertToRaw(
                                                    EditorState.createEmpty().getCurrentContent()
                                                )
                                            });
                                        }}
                                        block
                                        icon={<PlusOutlined />}>
                                        Add an option
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form.List>
                <Form.Item name="title" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="text" hidden>
                    <Input />
                </Form.Item>
            </Form>
        </>
    );
};

export default BulkCreateForm;
