import { Comment, Divider, Popconfirm, Tag } from 'antd';
import { DeleteOutlined, EyeInvisibleOutlined, LoadingOutlined } from '@ant-design/icons';
import { deleteQnAReply } from '../../../actions/collection';
import RichTextPreview from '../../../Components/RichTextPreview';
import { EditorState, convertFromRaw } from "draft-js";
import { compositeDecorator } from '../../../Components/RichTextDecoratorConfig';
import { useState } from 'react';
import { connect } from 'react-redux';

const QnAAnswer = ({currentUser, deleteQnAReply, enrolledLicenses, selectedLicense, qnaLevel, questionId, response}) => {
    const [deleteLoading, setDeleteLoading] = useState(false);
    const contentState = convertFromRaw(response['text']);
    const editorState = EditorState.createWithContent(contentState, compositeDecorator);
    
    const renderDisplayName = () => {
        let currentLicense = enrolledLicenses.find(license => license.id === selectedLicense);

        if(!response.anonymous && response.instructorHighlight){
            if(response.user.firstName && response.user.lastName){
                return <Tag color="#868CBE">{`${response.user.firstName} ${response.user.lastName}`}</Tag>
            } else {
                return <Tag color="#868CBE">{response.user.username}</Tag>
            }
        }else if(response.anonymous){
            if(response.user && response.user.id == currentUser.id){
                return "Anonymous User (You)"
            }
            else if(currentUser.id === currentLicense.createdById){
                return <div className="qna-anonymous-spoiler-tag">
                            {response.user.firstName && response.user.lastName ? `${response.user.firstName} ${response.user.lastName}` : response.user.username}
                            <div className="qna-anonymous-hover-to-reveal"><EyeInvisibleOutlined /></div>
                        </div>
            }else {
                return "Anonymous User"
            }
        }else if(response.user.firstName && response.user.lastName){
            return `${response.user.firstName} ${response.user.lastName}`
        } else {
            return response.user.username
        }
        
    }

    const renderDate = () => {
        let date = new Date(response.createdAt);
        return `${date.toDateString()} at ${date.toLocaleTimeString()}`;
    }


    const renderActions = () => {
        let actionsToRender = [];
        let currentLicense = enrolledLicenses.find(license => license.id === selectedLicense);
        
        if((response.user && currentUser.id === response.user.id) || currentUser.id === currentLicense.createdById){
            actionsToRender.push(
                <Popconfirm
                    title="Are you sure you want to delete this comment?"
                    onConfirm={()=>{
                        deleteQnAReply(questionId, response.id, qnaLevel, setDeleteLoading)
                        setDeleteLoading(true)
                    }}
                    okButtonProps={{loading: deleteLoading}}
                    okText="Yes"
                    cancelText="No"
                >
                    <span 
                        key="comment-nested-reply-delete" 
                        className="comment-nested-reply-delete"
                        
                    >
                        
                    Delete <DeleteOutlined />
                    </span>
                </Popconfirm>
            )
        }
        
        return actionsToRender
    }
    return <>
            <Divider style={{ margin: "5px 0 5px 0"}}/>
            <Comment
                author={renderDisplayName()}
                content={
                    <RichTextPreview editorState={editorState}/>
                }
                datetime={renderDate()}
                className={response.newComment ? "highlight-new-comment" : ""}
                actions={
                    renderActions()
                }
            >
              
            </Comment>
        </>
}

export default connect(null, {deleteQnAReply})(QnAAnswer);