import { Button, Modal, Space, Typography } from "antd";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { convertFromRaw, EditorState } from "draft-js";
import RichTextEditor from "../Components/RichTextEditor";
import RichTextPreview from "../Components/RichTextPreview";

const { Text } = Typography;

const OptionTextEditorModal = ({
    onOptionTextChange,
    onOptionExplanationChange,
    afterOptionTextChanged,
    afterOptionExplanationChanged,
    textValue,
    explanationValue,
    removeOption,
    isOpenEndedQuestion
}) => {
    const [isTextModalVisible, setIsTextModalVisible] = useState(false);
    const [isExplanationModalVisible, setIsExplanationModalVisible] =
        useState(false);
    const contentStateText = convertFromRaw(textValue);
    const editorStateText = EditorState.createWithContent(contentStateText);
    const contentStateExplanation = convertFromRaw(explanationValue);
    const editorStateExplanation = EditorState.createWithContent(
        contentStateExplanation
    );

    const afterOptionTextChange = (value) => {
        if (afterOptionTextChanged) {
            afterOptionTextChanged(value);
        }
    };

    const afterOptionExplanationTextChange = (value) => {
        if (afterOptionExplanationChanged) {
            afterOptionExplanationChanged(value);
        }
    };

    return (
        <>
            {!isOpenEndedQuestion && (
                <div
                    onClick={() => removeOption()}
                    style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        color: "orange",
                        cursor: "pointer"
                    }}>
                    <CloseOutlined /> Remove Option
                </div>
            )}
            <Modal
                title={
                    isOpenEndedQuestion
                        ? "Editing Correct Answer Text"
                        : "Editing Option Text"
                }
                visible={isTextModalVisible}
                onCancel={() => setIsTextModalVisible(false)}
                maskClosable={false}
                footer={
                    <div
                        style={{
                            textAlign: "right"
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: 8 }}
                            onClick={() => setIsTextModalVisible(false)}>
                            Close
                        </Button>
                    </div>
                }>
                <div style={{ padding: 25 }}>
                    <RichTextEditor
                        customOnChange={onOptionTextChange}
                        placeholder={
                            isOpenEndedQuestion
                                ? "Enter Correct Answer Text"
                                : "Enter the option text"
                        }
                        readOnly={false}
                        editorRawData={textValue}
                        afterContentChanged={afterOptionTextChange}
                    />
                </div>
            </Modal>
            <Modal
                title={
                    isOpenEndedQuestion
                        ? "Edit Question Hint"
                        : "Editing Option Explanation"
                }
                visible={isExplanationModalVisible}
                onCancel={() => setIsExplanationModalVisible(false)}
                maskClosable={false}
                footer={
                    <div
                        style={{
                            textAlign: "right"
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: 8 }}
                            onClick={() => setIsExplanationModalVisible(false)}>
                            Close
                        </Button>
                    </div>
                }>
                <RichTextEditor
                    customOnChange={onOptionExplanationChange}
                    placeholder={"Enter the explanation text"}
                    readOnly={false}
                    editorRawData={explanationValue}
                    afterContentChanged={afterOptionExplanationTextChange}
                />
            </Modal>
            {isOpenEndedQuestion ? (
                <>
                    <Text strong>
                        Correct Answer{" "}
                        <span className="text-color-green">
                            <EditOutlined
                                onClick={() => setIsTextModalVisible(true)}
                            />
                        </span>
                    </Text>
                    <RichTextPreview
                        editorState={editorStateText}
                        placeholder="No Correct Answer"
                    />
                </>
            ) : (
                <>
                    <Text strong>
                        Option Text{" "}
                        <span className="text-color-green">
                            <EditOutlined
                                onClick={() => setIsTextModalVisible(true)}
                            />
                        </span>
                    </Text>
                    <RichTextPreview
                        editorState={editorStateText}
                        placeholder="No option text"
                    />
                </>
            )}
            <Text strong>
                {isOpenEndedQuestion ? "Question Hint" : "Option Explanation"}{" "}
                <span className="text-color-green">
                    <EditOutlined
                        onClick={() => setIsExplanationModalVisible(true)}
                    />
                </span>
            </Text>
            <RichTextPreview
                editorState={editorStateExplanation}
                placeholder={isOpenEndedQuestion ? "No Hint" : "No explanation"}
            />
        </>
    );
};

export default OptionTextEditorModal;
