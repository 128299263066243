import { Button, Carousel, Drawer, Space, Tag, Typography} from 'antd';
import { useState } from 'react';
import QuizQuestionOption from '../../Collections/Video/QuizQuestions/QuizQuestionOption';
import RichTextPreview from '../../Components/RichTextPreview';
import { convertFromRaw, EditorState } from 'draft-js';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

const { Text } = Typography;

const QuizQuestion = ({question, quizQuestionIndex, setQuizQuestionIndex, totalNumOfQuizQuestions}) => {

    const [visibleQuestion, setVisibleQuestion] = useState(false);
    const [visibleAttemptHistory, setVisibleAttemptHistory] = useState(false);
    const [revealed, setRevealed] = useState(false);
    const [selected, setSelected] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const contentState = convertFromRaw(question['text']);
    const editorState = EditorState.createWithContent(contentState);

    const toggleRevealedAnswers = () => {
        setSubmitted(!submitted);
        setSelected(!selected);
        setRevealed(!revealed);
    }

    const toggleQuestionExpansion = () => {
        let element = document.getElementById(`dashboard-quiz-section-question-attempts-${question.id}`);
        setVisibleAttemptHistory(!visibleAttemptHistory)
        if(element.style.maxHeight) {
            element.style.maxHeight = null;
        } else {
            element.style.maxHeight = element.scrollHeight + "px";
        }
       
        //setTimeout(()=>element.lastChild.scrollIntoView({behavior: "smooth", block: "center"}), 300)
    }

    const renderQuestionType = () => {
        if(question.questionType == "select_one"){
            return "Select one"
        } else if(question.questionType == "choose_all_that_apply"){
            return "Choose all that apply"
        }else if(question.questionType == "open_ended"){
            return "Your answer"
        }
    }

    return  <>
            <Button 
                onClick={()=>setVisibleQuestion(true)}
                style={{width: "100%"}}
            >
                View Question
            </Button>
            <br/>
            <br/>
         
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div>
                    <Button
                        onClick={()=>{
                            if(quizQuestionIndex > 0){
                                setQuizQuestionIndex(quizQuestionIndex - 1)
                            }
                        }}
                        disabled={quizQuestionIndex <= 0}
                    >
                        <CaretLeftOutlined/>
                    </Button>
                    <Button
                        onClick={()=>{
                            if(quizQuestionIndex < totalNumOfQuizQuestions - 1){
                                setQuizQuestionIndex(quizQuestionIndex + 1)
                            }
                        }}
                        disabled={quizQuestionIndex >= totalNumOfQuizQuestions - 1}
                    >
                        <CaretRightOutlined />
                    </Button>
                </div>
                <Text>Question {quizQuestionIndex + 1} of {totalNumOfQuizQuestions}</Text>
            </div>
       
            
            <Drawer
                width={640}
                placement="right"
                closable={false}
                onClose={()=>setVisibleQuestion(false)}
                visible={visibleQuestion}
                title={question.title}
                footer={
                    <div
                        style={{
                        textAlign: 'right',
                        }}
                    >
                        <Button  style={{ marginRight: 8 }} onClick={()=>setVisibleQuestion(false)}>
                            Close Preview
                        </Button>
                        
                    </div>
                }
            >
                <div className="quizquestion-drawer-content-width">
                    <RichTextPreview editorState={editorState}/>
                    <br/>
                    <Carousel style={{background: '#364d79'}} autoplay className="quizquestion-carousel-width">
                        {question.images.map((imageFile, index) => <div key={index}><img alt="example" style={{ width: '100%' }} src={imageFile.image} /></div>)}
                    </Carousel>
                    <br/>
                    <Tag color="geekblue">{renderQuestionType()}</Tag>
                    {
                        question.options.map((option, index) => <QuizQuestionOption key={`option[${index}]`}
                                                                    handleOnClick={() => {}}
                                                                    selected={selected} 
                                                                    submitted={submitted}
                                                                    option={option}
                                                            />)
                    }
                    </div>
            </Drawer>
        </>
}
export default QuizQuestion;