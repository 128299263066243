import { Button, Popconfirm, Space } from "antd";
import { API_URL } from "../const";

const LoginSignupConfirmButton = ({
    id,
    className = "",
    title,
    buttonType = "default",
    buttonText,
    icon = undefined,
    buttonBlock = false
}) => {
    const handleOnSignupConfirm = () => {
        window.location.href =
            API_URL + `/accounts/signup/?next=${window.location.href}`;
        return;
    };

    const handleOnLoginConfirm = () => {
        window.location.href =
            API_URL + `/accounts/login/?next=${window.location.href}`;
        return;
    };

    return (
        <Space className="ant-space-responsive">
            <Popconfirm
                title={title}
                onConfirm={handleOnSignupConfirm}
                onCancel={handleOnLoginConfirm}
                okText="Signup"
                cancelText="Login">
                <Button
                    block={buttonBlock}
                    type={buttonType}
                    icon={icon || undefined}
                    className={className}
                    id={id}>
                    {buttonText}
                </Button>
            </Popconfirm>
        </Space>
    );
};

export default LoginSignupConfirmButton;
