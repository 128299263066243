import { API_URL } from '../const.js';
import {getNewAccessToken} from './user.js';

export const setVideoTimestamp = (timestamp) => {
    return {
        type: "SET_VIDEO_TIMESTAMP",
        timestamp
    }
}

export const setNextTranscriptUpdate = (timeInterval) => {
    return {
        type: "SET_NEXT_TRANSCRIPT_UPDATE",
        timeInterval
    }
}

export const toggleMutedQuizQuestions = () => {
    return {
        type: "TOGGLE_MUTED_QUIZ_QUESTIONS"
    }
}

export const setSkipToVideoTimestamp = (timestamp) => {
    return {
        type: "SET_SKIP_TO_VIDEO_TIMESTAMP",
        timestamp
    }
}



export const fetchModuleAccessForVideo = (collectionId, moduleId, videoId) => {
    return dispatch => {
        let url = API_URL+`/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/access/`
        dispatch(setModuleAccessForVideoStatus("LOADING"))
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {
            dispatch(setModuleAccessForVideo(data));
            dispatch(setModuleAccessForVideoStatus("LOADED"));
        })
        .catch(error => {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=>fetchModuleAccessForVideo(collectionId, moduleId, videoId)))
            }else {
                dispatch(setModuleAccessForVideoStatus("FAILED"))
            }
        })
    }
}

export const setModuleAccessForVideoStatus = (status) => {
    return {
        type: "SET_MODULE_ACCESS_FOR_VIDEO_STATUS",
        status
    }
}

export const setModuleAccessForVideo = (data) => {
    return {
        type: "SET_MODULE_ACCESS_FOR_VIDEO",
        data
    }
}

export const clearModuleAccessForVideo = () => {
    return {
        type: "CLEAR_MODULE_ACCESS_FOR_VIDEO"
    }
}