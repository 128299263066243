import { Avatar, List, Space, Tag, Typography } from 'antd';
import { EyeInvisibleOutlined, UserOutlined } from '@ant-design/icons';

import { ContentState, EditorState, convertFromRaw } from "draft-js";
import { compositeDecorator } from '../../Components/RichTextDecoratorConfig';
import RichTextPreview from '../../Components/RichTextPreview';

const { Text } = Typography;
const DashboardQnAAnswer = ({answer, checkIfReplyMadeOneDayAgo, currentUser, enrolledLicenses, selectedLicense }) => { 

    const contentState = answer.removed ? ContentState.createFromText(answer.text) : convertFromRaw(answer.text);
    const editorState = EditorState.createWithContent(contentState, compositeDecorator);

    const renderDisplayName = () => {
        let currentLicense = enrolledLicenses.find(license => license.id === selectedLicense.id);

        if(!answer.anonymous && answer.instructorHighlight){
            if(answer.user.firstName && answer.user.lastName){
                return <Tag color="#868CBE">{`${answer.user.firstName} ${answer.user.lastName}`}</Tag>
            } else {
                return <Tag color="#868CBE">{answer.user.username}</Tag>
            }
        }else if(answer.anonymous){
            if(answer.user && answer.user.id == currentUser.id){
                return "Anonymous User (You)"
            }
            else if(currentUser.id === currentLicense.createdById){
                return <div className="dashboard-qna-answer-anonymous-spoiler-tag">
                            {answer.user.firstName && answer.user.lastName ? `${answer.user.firstName} ${answer.user.lastName}` : answer.user.username}
                            <div className="dashboard-qna-answer-anonymous-hover-to-reveal"><EyeInvisibleOutlined /></div>
                        </div>
            }else {
                return "Anonymous User"
            }
        }else if(answer.user.firstName && answer.user.lastName){
            return `${answer.user.firstName} ${answer.user.lastName}`
        } else {
            return answer.user.username
        }
        
    }

    const renderDate = () => {
        let date = new Date(answer.createdAt);
        return `${date.toDateString()} at ${date.toLocaleTimeString()}`;
    }

    return <List.Item className={`dashboard-qna-answer${checkIfReplyMadeOneDayAgo(answer) ? " dashboard-qna-reply-highlight-recent" : " dashboard-qna-reply-normal-highlight"}`}>
                <List.Item.Meta
                    avatar={<Avatar icon={<UserOutlined />} />}
                    title={<RichTextPreview editorState={editorState} lightmode={true}/>}
                    description={
                        <Space >
                            <Text type="secondary" style={{color: "lightgrey"}}><Space>Posted by {renderDisplayName()}| {renderDate()}</Space></Text>
                  
                        </Space>}
                />
            </List.Item>
}

export default DashboardQnAAnswer;