/******************************************************************
VideoHome.js
Written by Adam Gamba, Summer 2021

Home page for videos. Includes search, filters, and VideoGrid
******************************************************************/

import React, { Component, useState, useEffect } from "react";
import { Input, Select, Card, Row, Col, Empty, Skeleton } from "antd";

import "../Home.css";
import { connect } from "react-redux";
import InstructorVideoGrid from "./InstructorVideoGrid.js";
import StudentVideoGrid from "./StudentVideoGrid";

const { Search } = Input;
const { Option } = Select;

class VideoHome extends Component {
  constructor(props){
    super(props);
    this.state = {
      // Search term of search bar and function to set its value (can search
      // automatically using url)
      searchTerm: props.match.params.hasOwnProperty("searchTerm") ? props.match.params.searchTerm : "",
      sortType: sortModuleAscending,
      sortTypeString: "sortModuleAscending" // passed to VideoGrid to determine whether to display modules
    }
  }

  renderVideoGrid = () => {
    if(this.props.dashboardPoV == "instructor"){
      return  <InstructorVideoGrid
                  videos={this.props.videoList
                    .filter((video) => {
                      if (this.state.searchTerm === "") return true;
                      else if (
                        !isNaN(this.state.searchTerm) &&
                        (video.videoId === parseInt(this.state.searchTerm) ||
                          video.moduleId === parseInt(this.state.searchTerm))
                      ) {
                        return true;
                      } else if (
                        video.videoTitle
                          .toLowerCase()
                          .includes(this.state.searchTerm.toLowerCase()) ||
                        video.moduleName
                          .toLowerCase()
                          .includes(this.state.searchTerm.toLowerCase())
                      ) {
                        return true;
                      }
                    })
                    // Sort type chosen from (currently 4) types in helpers below
                    .sort(this.state.sortType)}
                  sortType={this.state.sortTypeString}
                />
    } else {
      return <StudentVideoGrid 
                watchHistory={this.props.watchHistory}
                watchHistoryStatus={this.props.watchHistoryStatus}
                videos={this.props.videoList
                  .filter((video) => {
                    if (this.state.searchTerm === "") return true;
                    else if (
                      !isNaN(this.state.searchTerm) &&
                      (video.videoId === parseInt(this.state.searchTerm) ||
                        video.moduleId === parseInt(this.state.searchTerm))
                    ) {
                      return true;
                    } else if (
                      video.videoTitle
                        .toLowerCase()
                        .includes(this.state.searchTerm.toLowerCase()) ||
                      video.moduleName
                        .toLowerCase()
                        .includes(this.state.searchTerm.toLowerCase())
                    ) {
                      return true;
                    }
                  })
                  // Sort type chosen from (currently 4) types in helpers below
                  .sort(this.state.sortType)}
                sortType={this.state.sortTypeString}
              />
    }
  }
  render(){
    if(this.props.videoListStatus !== "LOADED"){
      return <Card><Skeleton active/></Card>
    }
    return (
      <div className="site-layout-content">
        <Row style={{ paddingBottom: "1%", margin: "5px" }}>
          {/* antd search bar to search videos */}
          <Col span={24}>
            <Search
              type="text"
              placeholder="Search Videos"
              onChange={(e) => this.setState({ searchTerm: e.target.value})}
              size="large"
              allowClear
            />
          </Col>
        </Row>

        {/* Sorted grid of videos to display, filtered and sorted */}
        {this.renderVideoGrid()}
        
      </div>
    );
  }
};

// Sorting helper methods
// ? undefined check saves from error, not entirely sure why
const sortAZ = (a, b) => {
  if (a === undefined || b === undefined) return sortAZ;
  if (a.videoTitle > b.videoTitle) return 1;
  else if (a.videoTitle < b.videoTitle) return -1;
  else return 0;
};
const sortZA = (a, b) => {
  if (a === undefined || b === undefined) return sortZA;
  return sortAZ(b, a);
};
// sortModuleAscending and sortModuleDescending are mostly the same here
// and are differentiated in the VideoGrid component
const sortModuleAscending = (a, b) => {
  if (a === undefined || b === undefined) return sortModuleAscending;
  if (a.videoId > b.videoId) return 1;
  else if (a.videoId < b.videoId) return -1;
  else return sortAZ(a, b);
};
const sortModuleDescending = (a, b) => {
  if (a === undefined || b === undefined) return sortModuleDescending;
  if (a.videoId > b.videoId) return 1;
  else if (a.videoId < b.videoId) return -1;
  else return sortAZ(a, b);
};

const mapStateToProps = (state) => {
  return {
    collection: state.dashboardReducer.collection,
    dashboardPoV: state.userReducer.dashboardPoV,
    selectedCoupon: state.dashboardReducer.selectedCoupon,
    currentUser: state.userReducer.user,
    scheduledAccesses: state.dashboardReducer.scheduledAccesses,
    watchHistory: state.dashboardReducer.watchHistoryOfCollection,
    watchHistoryStatus: state.dashboardReducer.watchHistoryOfCollectionStatus,
    videoList: state.dashboardReducer.videoList,
    videoListStatus: state.dashboardReducer.videoListStatus,
  };
};

export default connect(mapStateToProps)(VideoHome);
