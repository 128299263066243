import { Button, Checkbox, Col, Collapse, DatePicker, Form, Input, InputNumber, Modal, Row, Space, Table, Typography } from 'antd';
import { useState } from 'react';
import { connect } from 'react-redux';
import { updateScheduledLicense } from '../actions/contentCreation';
import moment from 'moment';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const EditTermCouponModal = ({ collectionId, coupon, modules, updateScheduledLicense}) => {
    const [editTermCouponForm] = Form.useForm();
    const [invalidDatesCount, setInvalidDatesCount] = useState(0);
    const [initalValuesLoaded, setInitialValuesLoaded] = useState(false);
    const [useTermDateRange, setUseTermDateRange] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const rangeConfig = {
        rules: [
            {
            type: 'array',
            required: true,
            message: 'Please select time!',
            },
        ],
    };

    const preFormatInitialValues = () => {
        let invalidCount = 0;

        let existingScheduledAccesses = coupon['scheduledAccesses'];
        let startDate = new Date(coupon['validFrom'])
        startDate = new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000);
        let endDate = new Date(coupon['expiresOn'])
        endDate = new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000);
        let dateRange = [moment(startDate), moment(endDate)] 
        
        let newScheduledAccesses = modules.map(module => {
            let foundModule = existingScheduledAccesses.find(access => access.module == module.id);
            if(foundModule) {
                
                
                startDate = new Date(foundModule['accessFrom']);
                endDate = new Date(foundModule['accessUntil']);

                let moduleDateRange = [moment(startDate), moment(endDate)]
                if(useTermDateRange && (moduleDateRange[0].isAfter(dateRange[0], 'day') || moduleDateRange[1].isAfter(dateRange[1], 'day'))) {
                    setUseTermDateRange(false);
                }
                return {
                    'collection': collectionId,
                    'module': module.id,
                    'moduleName': module.name,
                    'dateRange': moduleDateRange
                }

            }else {
                invalidCount = invalidCount + 1
                return {
                    'collection': collectionId,
                    'module': module.id,
                    'moduleName': module.name
                }
            }
        })

        setInvalidDatesCount(invalidCount);
        return {
            'code': coupon['code'],
            'label': coupon['label'],
            'percentDiscount': coupon['percentDiscount'],
            'couponType': coupon['couponType'],
            'dateRange': dateRange,
            'scheduledAccesses': newScheduledAccesses,
            'allowableDataAccessChoices': coupon['allowableDataAccessChoices']
        }
    }

    if(!initalValuesLoaded && editTermCouponForm) {
        let initialValues = preFormatInitialValues();
        editTermCouponForm.setFieldsValue(initialValues);
        setInitialValuesLoaded(true);
    }

    const columns = [
        {
          title: 'Module Name',
          key: 'moduleName',
          render: instance => <>
                            <Text>{instance.moduleName}</Text> 
                            <Form.Item
                                name={[instance.name, 'moduleName']}
                                fieldKey={[instance.fieldKey, 'moduleName']}
                                hidden
                            >
                                <Input />
                            </Form.Item>
                        </>
        },
        {
            title: 'Dates Available',
            key: 'datesAvailable',
            render: instance => <Form.Item 
                                key={instance.fieldKey}
                                style={{margin: "auto"}}
                                name={[instance.name, 'dateRange']}
                                fieldKey={[instance.fieldKey, 'dateRange']}
                                //rules={[{ required: true, message: 'Missing price' }]}
                                {...rangeConfig}
                            >
                                <RangePicker 
                                    onChange={() => {
                                        if(useTermDateRange) {
                                            setUseTermDateRange(false);
                                        }   
                                        checkForInvalidDates()
                                    }}
                                    allowClear={false}    
                                />
                            </Form.Item>,
        },
        {
            title: 'Selected Duration',
            key: 'selectedDuration',
            render: instance => <Text type="secondary">{calculateDuration(editTermCouponForm.getFieldValue(['scheduledAccesses', instance.name, 'dateRange']))} days</Text>,
        },
    ]

    const calculateDuration = (ranges) => {
        if(ranges){
            return ranges[1].diff(ranges[0], 'days')
        }else {
            return 0;
        }   
    }

    const formatTableData = (fieldsArray) => {
        const data = fieldsArray.map((field, index) =>{
           return {
               "key": index,
               ...field,
               ...editTermCouponForm.getFieldValue(['scheduledAccesses', field.name])
           }
        })
        return data
    }


    const onCheckboxChange = (e) => {
        if(!useTermDateRange) {
            setUseTermDateRange(true);
            setAllDateRangesToTermDateRange();
            setInvalidDatesCount(0);
        }
    }

    const setAllDateRangesToTermDateRange = () => {
        let termDateRange = editTermCouponForm.getFieldValue(['dateRange']);
        let modules = editTermCouponForm.getFieldValue(['scheduledAccesses']);
        editTermCouponForm.setFieldsValue({ 
            'scheduledAccesses': modules.map(module=>{
                return {
                    ...module,
                    'dateRange': termDateRange
                }
            }),
        })
    }

    const handleOnFinish = (values) => {
        let couponValidDates = values['dateRange'];
        delete values.dateRange;
        values['validFrom'] = couponValidDates[0].startOf('day').format('YYYY-MM-DD');
        values['expiresOn'] = couponValidDates[1].endOf('day').format('YYYY-MM-DD');

        let formattedScheduledAccesses = values['scheduledAccesses'].map(access => {
            let dateRange = access['dateRange'];
            return {
                'collection': access['collection'],
                'module': access['module'],
                'accessFrom': dateRange[0].startOf('day').format(),
                'accessUntil': dateRange[1].endOf('day').format(),
            }
        })
        values['scheduledAccesses'] = formattedScheduledAccesses;
        values['discountedCollections'] = [];
        values['discountedModules'] = [];
        if(values['allowableDataAccessChoices'] == undefined) {
            values['allowableDataAccessChoices'] = [];
        }

        const afterEditSuccess = () => {
            /*editScheduledLicenseForm.setFieldsValue({
                'code': "",
                'label': "",
                'allowableDataAccessChoices': ['full', 'anonymous', 'no_access']
            });*/
            closeModal()
        }

        setSubmitLoading(true);
        const finishLoading = () => setSubmitLoading(false);
        updateScheduledLicense(coupon.code, values, afterEditSuccess, finishLoading)
    } 


    const closeModal = () => {
        setVisible(false);
    }

    const checkForInvalidDates = () => {
        let scheduledAccesses = editTermCouponForm.getFieldValue(['scheduledAccesses'])
        let count = scheduledAccesses.filter(access => !("dateRange" in access)).length;
        setInvalidDatesCount(count);
    }

    const renderModuleAvailabilityMessage = () => {
        if(invalidDatesCount > 0) {
            return <Text type="danger">{invalidDatesCount > 1 ? `${invalidDatesCount} modules are missing assigned dates.` : `${invalidDatesCount} module is missing assigned dates.`}</Text>
        } else {
            return <Text underline>{useTermDateRange ? "Full Term (default)" : "Custom (See below)"}</Text>
        }
    }
    return ( 
        <>
            <Button
                onClick={()=>setVisible(true)}
            >
                Edit
            </Button>
            <Modal title="Editing Term Coupon"
                visible={visible} 
                onCancel={()=>{
                    let initialValues = preFormatInitialValues();
                    editTermCouponForm.setFieldsValue(initialValues);
                    setVisible(false)
                }}
                width={900}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button  
                            style={{ marginRight: 8 }} 
                            onClick={()=>{
                                let initialValues = preFormatInitialValues();
                                editTermCouponForm.setFieldsValue(initialValues);
                                setVisible(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            type="primary" 
                            onClick={() => editTermCouponForm.submit()} 
                            loading={submitLoading}
                        >

                            Update
                        </Button>
                    </div>
                }
            >
                <Form 
                    onFinish={handleOnFinish}
                    id="create-scheduled-license-form"
                    form={editTermCouponForm}
                    layout="vertical"
                >
                    <Space align="center">
                        <Form.Item
                            label="Coupon Code"
                            name="code"
                            style={{marginBottom: 0, width: 280}}
                            rules={[
                                { 
                                    required: true, 
                                    message: 'Please give your scheduled license a coupon code!'
                                },
                            ]}
                        >
                            <Input 
                                placeholder="Enter a coupon code" maxLength={20} disabled
                            />
                        </Form.Item>
                      
                    </Space>
                    <br/><br/>

                    <Form.Item
                        label="Label"
                        name="label"
                        rules={[{ required: true, message: 'Please give your scheduled license a label!' }]}
                        tooltip="This label is meant for you to easily identify who/what this coupon is for. For example, 'COS126 SPRING 2021'. (Max 20 characters)"
                    >
                        <Input placeholder="Enter a coupon label" maxLength={20} />
                    </Form.Item>
                    <Form.Item
                        name="percentDiscount"
                        hidden
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        name="couponType"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="dateRange" label="Term Date Range" {...rangeConfig}>
                        <RangePicker  
                            onChange={()=>{
                                if(useTermDateRange){
                                    setAllDateRangesToTermDateRange()
                                }
                            }}
                            allowClear={false}    
                        />
                    </Form.Item>

                    <Form.Item 
                        hidden
                        name="allowableDataAccessChoices" 
                        label="Data Access Choices" 
                        rules={[{ required: true, message: 'Please pick one!' }]} 
                        tooltip={"We believe students should have the choice to opt in to sharing their data before sharing analytics to you. Based on which boxes you check, you determine which of these choices they can choose from."}>
                        <Checkbox.Group>
                        <Row>
                            <Col>
                                <Checkbox value="full" style={{ lineHeight: '32px' }}>
                                    Full
                                </Checkbox>
                            </Col>
                            <Col >
                                <Checkbox value="anonymous" style={{ lineHeight: '32px' }}>
                                    Anonymous
                                </Checkbox>
                            </Col>
                            <Col >
                                <Checkbox value="no_access" style={{ lineHeight: '32px' }}>
                                    No Access
                                </Checkbox>
                            </Col>
                        </Row>
                        </Checkbox.Group>
                    </Form.Item>
                    
                    <Space style={{marginBottom: "5px"}}>
                        <Text strong>Module Availability:</Text>
                        {renderModuleAvailabilityMessage()}
                    </Space>
                    <Collapse >
                        <Panel forceRender header="OPTIONAL: Customize when/how long each module will be available (defaults to value in Term Date Range)">
                            <Checkbox 
                                style={{marginBottom: "5px"}}
                                checked={useTermDateRange} 
                                onChange={onCheckboxChange}
                            >
                                Use “Term Date Range” for all modules
                            </Checkbox>
                        

                        
                            <Form.List 
                                name="scheduledAccesses"
                            >
                                {(fields, { add, remove }, { errors }) => (
                                <>
                                    <Table columns={columns} dataSource={formatTableData(fields)} size="small" />
                                    <Form.ErrorList errors={errors} />
                                </>
                                )}
                            </Form.List>
                        </Panel>
                    </Collapse>
                </Form>

            </Modal>
        </>
    )
}

export default connect(null, { updateScheduledLicense })(EditTermCouponModal);