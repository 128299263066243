import { Component } from 'react';
import { Avatar, Image, Upload, message, Spin, Typography } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { API_URL } from '../const';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}


const { Title } = Typography;

class CreatorProfilePicture extends Component {

    constructor(props){
      super(props)
      this.state = {
        imageUrl: props.existingCoverImage,
        //imageUrl: "https://cuvids.io/static/img/4/icon.png",
        loading: false,
      };
    }
    
    componentDidUpdate(prevProps) {
      if(prevProps.existingCoverImage != this.props.existingCoverImage){
        this.setState({imageUrl: this.props.existingCoverImage})
      }
    }
    
    handleChange = info => {
    if (info.file.status === 'uploading') {
        this.setState({ loading: true });
        return;
    }
    if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, imageUrl => {
          if(this.props.receivedUserCreatedCollections){
            this.props.updateImage(imageUrl);
          }
          return this.setState({
            imageUrl,
            loading: false,
          })
        });
    }
    };
    
    render() {
        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload Image</div>
            </div>
        );

        if(this.props.isOwner){
  
            return (<ImgCrop shape="round">
                        <Upload
                            name="profile_picture"
                            listType="picture-card"
                            showUploadList={false}
                            action={`${API_URL}/api/v1/creators/${this.props.userId}/profile-picture/`}
                            headers={{
                            'Authorization': 'Bearer ' + localStorage.getItem('access'),
                            }}
                            method="PATCH"
                            beforeUpload={beforeUpload}
                            onChange={this.handleChange}
                            className="avatar-uploader"
                        >
                            {this.state.imageUrl ? <Avatar 
                                                      src={this.state.imageUrl} 
                                                      alt="avatar" 
                                                      preview={false} 
                                                      style={{width: "168px", height: "168px"}}
                                                      size="large"
                                                    /> : uploadButton}
                           
                       
                        </Upload>
                </ImgCrop>
            )
        } else {
            return <Avatar 
                      src={this.state.imageUrl} 
                      alt="avatar" 
                      preview={false} 
                      style={{width: "168px", height: "168px"}}
                      size="large"
                      className="creator-profile-picture"
                    />
        }
    }
}

export default CreatorProfilePicture;