import CollectionInfoCard from "../Components/CollectionInfoCard";
import CollectionModulesList from "../Components/CollectionModulesList";
import { Alert, Button, Space } from "antd";
import { connect } from "react-redux";
import { createCollection } from "../actions/contentCreation";
import { useState } from "react";
import EditAuthorsModal from "./EditAuthorsModal";
import DescriptionEditModal from "./DescriptionEditModal";
import { updateCollection } from "../actions/contentCreation";

const ReviewCollectionChangesStep = ({
    isEditMode,
    authorsDict,
    collection,
    createCollection,
    updateCollection,
    currentUser,
    goToNextStep,
    goToPreviousStep,
    modules,
    setAuthorsDict,
    updateState
}) => {
    const [editAuthorMode, setEditAuthorMode] = useState(false);
    const [editLongDescriptionMode, setEditLongDescriptionMode] =
        useState(false);
    const [editDescriptionMode, setEditDescriptionMode] = useState(false);

    let previewCollectionData = {
        ...collection,
        modules: modules
    };

    const handleOnPublishClick = () => {
        if (isEditMode) {
            updateCollection(collection.id, previewCollectionData);
        } else {
            createCollection(previewCollectionData);
        }
        goToNextStep();
    };

    // After author updated
    const onFinishAuthorForm = (formData) => {
        const { authorData, coAuthors } = formData;

        const tempCollectionStateObj = {
            ...collection,
            primaryAuthorUsername: authorData.username,
            primaryAuthor: authorData,
            coAuthors: coAuthors,
            coAuthorUsernames: coAuthors.map((c) => c.username)
        };
        updateState("collection", tempCollectionStateObj);
    };

    // After Description updated
    const onFinishDescriptionForm = (formData) => {
        updateState("collection", {
            ...collection,
            ...formData
        });
    };

    // After course name updated
    const afterCourseNameUpdate = (newName) => {
        updateState("collection", {
            ...collection,
            name: newName
        });
    };

    return (
        <div>
            <Alert
                message="Note"
                description="This is the preview of the public-facing page of your collection."
                type="info"
                showIcon
                action={
                    <Space>
                        <Button onClick={goToPreviousStep}>Go Back</Button>
                        <Button type="primary" onClick={handleOnPublishClick}>
                            Publish Changes
                        </Button>
                    </Space>
                }
            />
            <CollectionInfoCard
                collection={previewCollectionData}
                collectionNetPrice={collection.collectionNetPrice}
                key={collection.id}
                creationStep={true}
                setEditAuthorMode={setEditAuthorMode}
                setEditDescriptionMode={setEditDescriptionMode}
                setEditLongDescriptionMode={setEditLongDescriptionMode}
                afterCourseNameUpdate={afterCourseNameUpdate}
                mode="preview"
            />
            <CollectionModulesList
                collection={previewCollectionData}
                mode="preview"
                urlParams={{}}
            />

            {/* Author Update Modal */}
            <EditAuthorsModal
                title={"Course Authors"}
                visible={editAuthorMode}
                setVisibility={setEditAuthorMode}
                currentUser={currentUser}
                initialValues={{
                    primaryAuthorUsername: collection.primaryAuthorUsername,
                    coAuthorUsernames: collection.coAuthorUsernames
                }}
                authorsDict={authorsDict}
                setAuthorsDict={setAuthorsDict}
                onFinish={onFinishAuthorForm}
            />

            {/* Description Update Modal */}
            <DescriptionEditModal
                title={"Course Description"}
                visible={editDescriptionMode}
                field="description"
                setVisibility={setEditDescriptionMode}
                initialValues={{
                    description: collection.description
                }}
                onFinish={onFinishDescriptionForm}
            />

            {/* Long Description Update Modal */}
            <DescriptionEditModal
                title={"Course Long Description"}
                visible={editLongDescriptionMode}
                field="longDescription"
                setVisibility={setEditLongDescriptionMode}
                initialValues={{
                    longDescription: collection.longDescription
                }}
                onFinish={onFinishDescriptionForm}
            />
        </div>
    );
};

export default connect(null, { createCollection, updateCollection })(
    ReviewCollectionChangesStep
);
