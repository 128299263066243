import React, { useContext, useEffect, useRef } from "react";
import Chat from "./Chat.js";
import { GptContext } from "./ChatbotContainer.js";
import ExpandOnHistory from "./ExpandOnHistory.js";
import MessageLoading from "./MessageLoading.js";

const ChatHistory = ({
    visibleExpandsOnHistory,
    onExpandOnHistoryItemClick,
    onExpandOnHistoryToggle
}) => {
    const chatContainerRef = useRef(null);
    const { messages, video, collection, loading } = useContext(GptContext);

    const scrollToBottom = () => {
        setTimeout(() => {
            chatContainerRef.current.scroll({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth"
            });
        }, 100);
    };

    useEffect(() => {
        if (messages.length || loading) {
            scrollToBottom();
        }
    }, [messages.length, loading]);

    return (
        <div
            id="message-list"
            className={`${visibleExpandsOnHistory ? "history-open" : ""}`}
            ref={chatContainerRef}>
            {messages.map((message, index) => (
                <Chat
                    key={index}
                    message={message}
                    scrollToBottom={scrollToBottom}
                />
            ))}

            <MessageLoading />
            {/* Expand on history */}
            <ExpandOnHistory
                video={video?.id}
                collection={collection}
                onItemClick={onExpandOnHistoryItemClick}
                onExpandOnHistoryToggle={(expanded) => {
                    scrollToBottom();
                    onExpandOnHistoryToggle(expanded);
                }}
                visibleExpandsOnHistory={visibleExpandsOnHistory}
            />
        </div>
    );
};

export default ChatHistory;
