import {
    ClockCircleOutlined,
    SmileOutlined,
    UnlockOutlined
} from "@ant-design/icons";
import { message, notification, Typography } from "antd";
import { API_URL } from "../const.js";
import { addModuleAccess, fetchSingleCollectionAccess } from "./collection.js";
import { setCollectionCertificateData } from "./certification.js";

const { Text } = Typography;

export const setDashboardPoV = (pov) => {
    return {
        type: "SET_DASHBOARD_POV",
        pov
    };
};

export const setUser = (user) => {
    return {
        type: "SET_USER",
        user
    };
};

export const setRedirectAfterLogout = (status) => {
    return {
        type: "SET_REDIRECT_AFTER_LOGOUT",
        status
    };
};

export const clearActiveCoupon = () => {
    return {
        type: "CLEAR_ACTIVE_COUPON"
    };
};

export const logoutUser = () => {
    return {
        type: "LOGOUT_USER"
    };
};

export const setRequireLogin = (status) => {
    return {
        type: "SET_REQUIRE_LOGIN",
        status
    };
};

export const setFetchingUserStatus = (status) => {
    return {
        type: "SET_FETCHING_USER_STATUS",
        status
    };
};

export const setActiveCoupon = (coupon) => {
    return {
        type: "SET_ACTIVE_COUPON",
        coupon
    };
};

export const setDepartments = (departments) => {
    return {
        type: "SET_DEPARTMENTS",
        departments
    };
};

export const setUniversities = (universities) => {
    return {
        type: "SET_UNIVERSITIES",
        universities
    };
};

export const setUserPurchasedCollections = (collections) => {
    return {
        type: "SET_USER_PURCHASED_COLLECTIONS",
        collections
    };
};

export const setFetchPurchasedCollectionStatus = (status) => {
    return {
        type: "SET_FETCH_PURCHASED_COLLECTION_STATUS",
        status
    };
};

export const setEnrolledLicenses = (licenses) => {
    return {
        type: "SET_ENROLLED_LICENSES",
        licenses
    };
};

export const setFetchEnrolledLicensesStatus = (status) => {
    return {
        type: "SET_FETCH_ENROLLED_LICENSES_STATUS",
        status
    };
};
export const fetchUserData = () => {
    return (dispatch) => {
        dispatch(setFetchingUserStatus("LOADING"));
        return fetch(API_URL + `/api/v1/users/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setUser(data));
                dispatch(setFetchingUserStatus("SUCCESS"));
                notification.open({
                    message: `Welcome Back, ${data["username"]}!`,
                    icon: <SmileOutlined style={{ color: "#108ee9" }} />
                });
                if (data["isContentCreator"]) {
                    dispatch(fetchEnrolledLicenses(data["id"], true));
                    dispatch(setDashboardPoV("instructor"));
                } else {
                    dispatch(fetchEnrolledLicenses(data["id"]));
                    dispatch(setDashboardPoV("student"));
                }
            })
            .catch((error) => {
                if (error === "TypeError: Failed to fetch") {
                    message.error(
                        "Unable to reach the server right now. Please try again later."
                    );
                    dispatch(setFetchingUserStatus("FAILED"));
                }
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(fetchUserData));
                } else {
                    dispatch(setFetchingUserStatus("FAILED"));
                }
            });
    };
};

export const getNewAccessToken = (authenticatedFunc) => {
    localStorage.removeItem("access");
    return (dispatch) => {
        return fetch(API_URL + `/api/v1/token/refresh/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ refresh: localStorage.getItem("refresh") })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                if (data != null) {
                    localStorage.setItem("access", data["access"]);
                    dispatch(authenticatedFunc());
                }
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    localStorage.removeItem("refresh");
                    message.info(
                        "Your session has timed out. Please log in again."
                    );
                    dispatch(setRequireLogin(true));
                }
            });
    };
};

// Wallet actions:
export const appendNewTransaction = (transactionData) => {
    return {
        type: "APPEND_NEW_TRANSACTION",
        transactionData
    };
};

export const updateWalletBalance = (cupoints) => {
    return {
        type: "UPDATE_WALLET_BALANCE",
        cupoints
    };
};

export const updateRefundableToFalse = (transactionId) => {
    return {
        type: "UPDATE_REFUNDABLE_TO_FALSE",
        transactionId
    };
};

export const topUpWallet = (
    walletId,
    cupoints,
    paymentIntentId,
    handleAfterTopUpSuccess
) => {
    return (dispatch) => {
        return fetch(API_URL + `/api/v1/wallets/${walletId}/topup/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                cupoints: cupoints,
                paymentIntentId: paymentIntentId
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(appendNewTransaction(data["transaction"]));
                dispatch(updateWalletBalance(cupoints));
                handleAfterTopUpSuccess();
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            topUpWallet(walletId, cupoints, paymentIntentId)
                        )
                    );
                }
            });
    };
};

export const issueRefund = (walletId, transactionId, finishRefund) => {
    return (dispatch) => {
        return fetch(
            API_URL +
                `/api/v1/wallets/${walletId}/transactions/${transactionId}/refund/`,
            {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => {
                if (response.ok || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                if (data === null) {
                    message.error(
                        "Something went wrong. Please try again later or contact us for more help."
                    );
                } else if (data.detail) {
                    message.error(data.detail);
                } else {
                    dispatch(updateRefundableToFalse(transactionId));
                    dispatch(appendNewTransaction(data["transaction"]));
                    dispatch(
                        updateWalletBalance(
                            data["transaction"].transactionTotalCupoints
                        )
                    );
                    dispatch(revokeModuleAccess(data["refundedModules"]));
                    finishRefund();
                }
                finishRefund();
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            issueRefund(walletId, transactionId)
                        )
                    );
                }
            });
    };
};

export const revokeModuleAccess = (moduleIds) => {
    return {
        type: "REVOKE_MODULE_ACCESS",
        moduleIds
    };
};

export const addNewEnrolledLicense = (licenseData) => {
    return {
        type: "ADD_NEW_ENROLLED_LICENSE",
        licenseData
    };
};

export const purchaseModule = (moduleId, setLoadingStatus) => {
    return (dispatch, getState) => {
        const activeCoupon = getState().userReducer.activeCoupon;
        const couponCode =
            activeCoupon && activeCoupon.discountedModules.includes(moduleId)
                ? activeCoupon.code
                : "";
        setLoadingStatus(true);
        return fetch(API_URL + `/api/v1/modules/${moduleId}/purchase`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ couponCode: couponCode })
        })
            .then((response) => {
                if (response.ok || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(appendNewTransaction(data["transaction"]));
                dispatch(
                    updateWalletBalance(
                        -1 * data["transaction"].transactionTotalCupoints
                    )
                );
                dispatch(addModuleAccess(data["moduleAccess"]));
                //dispatch(clearActiveCoupon())
                setLoadingStatus(false);
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    console.log(error);

                    dispatch(
                        getNewAccessToken(() =>
                            purchaseModule(moduleId, setLoadingStatus)
                        )
                    );
                }
                setLoadingStatus(false);
            });
    };
};

export const purchaseCollection = (
    collection,
    setLoadingStatus,
    certificate = false
) => {
    const collectionId = collection.id;
    return (dispatch, getState) => {
        const activeCoupon = getState().userReducer.activeCoupon;
        const couponCode =
            activeCoupon &&
            activeCoupon.discountedCollections.includes(collectionId)
                ? activeCoupon.code
                : "";
        setLoadingStatus(true);
        let postData = { couponCode: couponCode };
        if (certificate) {
            postData = { ...postData, certificate: certificate };
        }
        if (collection.promotion) {
            postData = {
                ...postData,
                promotionCode: collection.promotion.code
            };
        }
        return fetch(
            API_URL + `/api/v1/collections/${collectionId}/purchase/`,
            {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postData)
            }
        )
            .then((response) => {
                if (response.ok || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                if (data["transaction"]) {
                    dispatch(appendNewTransaction(data["transaction"]));
                    dispatch(
                        updateWalletBalance(
                            -1 * data["transaction"].transactionTotalCupoints
                        )
                    );
                    dispatch(addModuleAccess(data["moduleAccess"]));
                }

                if (certificate && data["certificate"]) {
                    dispatch(
                        appendNewTransaction(
                            data["certificate"]["certificateTransaction"]
                        )
                    );
                    dispatch(
                        updateWalletBalance(
                            -1 *
                                data["certificate"]["certificateTransaction"]
                                    .transactionTotalCupoints
                        )
                    );
                    dispatch(
                        setCollectionCertificateData(
                            data["certificate"]["certificationDetails"]
                        )
                    );
                }

                dispatch(fetchSingleCollectionAccess(collectionId));

                //dispatch(clearActiveCoupon())
                setLoadingStatus(false);
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            purchaseCollection(
                                collection,
                                setLoadingStatus,
                                certificate
                            )
                        )
                    );
                } else {
                    console.log(error);
                    setLoadingStatus(false);
                }
            });
    };
};

export const setCouponCodeError = (couponCodeError) => {
    return {
        type: "SET_COUPON_CODE_ERROR",
        couponCodeError
    };
};
// TODO - ADD MODULE ACCESS HAS BEEN CHANGED
export const verifyCoupon = (
    couponCode,
    successfulSignUp,
    clearCouponSearch
) => {
    return (dispatch) => {
        return fetch(API_URL + `/api/v1/coupons/${couponCode}/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                console.log(data);
                if (data.detail) {
                    dispatch(setCouponCodeError(data.detail));
                    clearCouponSearch();
                } else if (data != null && data["moduleAccess"]) {
                    dispatch(addModuleAccess(data["moduleAccess"]));
                    successfulSignUp(data);
                    clearCouponSearch();
                } else if (data != null) {
                    dispatch(setActiveCoupon(data));
                    successfulSignUp(data);
                    clearCouponSearch();
                    notification.success({
                        message: `Coupon Code "${data["code"]}" applied!`,
                        description: (
                            <Text>
                                All unlocked discounts are now marked with the
                                color{" "}
                                <span style={{ color: "#fa541c" }}>orange</span>
                                !
                            </Text>
                        ),
                        icon: <UnlockOutlined style={{ color: "#108ee9" }} />
                    });
                } else {
                    message.error("Something went wrong.");
                }
            })
            .catch((error) => {
                if (parseInt(error.message) === 404) {
                    message.error(
                        "The coupon code you have entered does not exist."
                    );
                    clearCouponSearch();
                } else if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            verifyCoupon(
                                couponCode,
                                successfulSignUp,
                                clearCouponSearch
                            )
                        )
                    );
                }
            });
    };
};

export const postVideoWatchData = (
    collectionId,
    moduleId,
    videoId,
    videoTimestamp,
    pollRate,
    playbackRate
) => {
    return (dispatch) => {
        return fetch(API_URL + `/api/v1/videowatchdata/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                collection: collectionId,
                module: moduleId,
                video: videoId,
                pollRate: pollRate,
                playbackRate: playbackRate,
                videoTimestamp: Math.round(videoTimestamp)
            })
        })
            .then((response) => {
                if (response.ok) {
                    return;
                } else {
                    throw new Error(response.status);
                }
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            postVideoWatchData(
                                videoId,
                                videoTimestamp,
                                pollRate,
                                playbackRate
                            )
                        )
                    );
                }
            });
    };
};

export const setDataAccessPreference = (
    moduleAccessId,
    preference,
    handlePreferenceSaved
) => {
    return (dispatch) => {
        return fetch(
            API_URL +
                `/api/v1/module-access/${moduleAccessId}/data-sharing-preferences/`,
            {
                method: "PATCH",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ dataAccessLevel: preference })
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(addModuleAccess(data));
                message.success("Data access preferences saved.");
                handlePreferenceSaved();
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            setDataAccessPreference(moduleAccessId, preference)
                        )
                    );
                }
            });
    };
};

export const setScheduledLicenses = (scheduledLicenses) => {
    return {
        type: "SET_SCHEDULED_LICENSES",
        scheduledLicenses
    };
};

export const fetchUsedScheduledLicense = () => {
    return (dispatch) => {
        return fetch(API_URL + `/api/v1/used-scheduled-license/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setScheduledLicenses(data));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() => fetchUsedScheduledLicense())
                    );
                }
            });
    };
};

export const updateCouponDataAccessPreference = (
    licenseId,
    dataAccessValue
) => {
    return {
        type: "UPDATE_COUPON_DATA_ACCESS_PREFERENCE",
        licenseId,
        dataAccessValue
    };
};

export const setCouponDataAccessPreference = (
    licenseId,
    dataAccessValue,
    handlePreferenceSaved
) => {
    return (dispatch) => {
        return fetch(
            API_URL +
                `/api/v1/used-scheduled-license/${licenseId}/data-sharing-preferences/`,
            {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ dataAccessLevel: dataAccessValue })
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(updateCouponDataAccessPreference(licenseId, data));
                message.success("Data access preferences saved.");
                handlePreferenceSaved();
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            setCouponDataAccessPreference(
                                licenseId,
                                dataAccessValue,
                                handlePreferenceSaved
                            )
                        )
                    );
                }
            });
    };
};

export const fetchJWTTokens = () => {
    return (dispatch) => {
        return fetch(API_URL + `/accounts/jwt-tokens/`, {
            method: "GET",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                localStorage.setItem("refresh", data["refresh"]);
                localStorage.setItem("access", data["access"]);
                dispatch(setRequireLogin(false));
                dispatch(fetchUserData());
            })
            .catch((error) => {
                console.log(error);
                dispatch(setFetchingUserStatus("FAILED"));
            });
    };
};

export const fetchAllDepartments = () => {
    return (dispatch) => {
        return fetch(API_URL + `/api/v1/departments/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setDepartments(data));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => fetchAllDepartments()));
                }
            });
    };
};

export const fetchAllUniversities = () => {
    return (dispatch) => {
        return fetch(API_URL + `/api/v1/universities/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setUniversities(data));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => fetchAllUniversities()));
                }
            });
    };
};

export const setAccountTypeRegistrationStatus = (status) => {
    return {
        type: "SET_ACCOUNT_TYPE_REGISTRATION_STATUS",
        status
    };
};

export const applyAsLearner = (userId) => {
    return (dispatch) => {
        dispatch(setAccountTypeRegistrationStatus("LOADING"));
        return fetch(API_URL + `/api/v1/users/${userId}/learner/apply/`, {
            method: "PATCH",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setAccountTypeRegistrationStatus("SUCCESS"));
                dispatch(setUser(data));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => applyAsLearner(userId)));
                }
                dispatch(setAccountTypeRegistrationStatus("FAILED"));
            });
    };
};

export const applyForCreator = (userId, formData) => {
    return (dispatch) => {
        dispatch(setAccountTypeRegistrationStatus("LOADING"));
        return fetch(API_URL + `/api/v1/users/${userId}/creator/apply/`, {
            method: "PATCH",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setUser(data));
                dispatch(setAccountTypeRegistrationStatus("SUCCESS"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            applyForCreator(userId, formData)
                        )
                    );
                }
                dispatch(setAccountTypeRegistrationStatus("FAILED"));
            });
    };
};

export const fetchUserPurchasedCollections = (userId) => {
    return (dispatch) => {
        dispatch(setFetchPurchasedCollectionStatus("LOADING"));
        return fetch(
            API_URL + `/api/v1/users/${userId}/purchased-collections/`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setFetchPurchasedCollectionStatus("LOADED"));
                dispatch(setUserPurchasedCollections(data));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchUserPurchasedCollections(userId)
                        )
                    );
                } else {
                    dispatch(setFetchPurchasedCollectionStatus("FAILED"));
                }
            });
    };
};

export const fetchEnrolledLicenses = (
    userId,
    getUserCreatedQuestions = false
) => {
    return (dispatch) => {
        let url;
        if (getUserCreatedQuestions) {
            url = API_URL + `/api/v1/users/${userId}/scheduled-licenses/`;
        } else {
            url = API_URL + `/api/v1/users/${userId}/enrolled-licenses/`;
        }
        dispatch(setFetchEnrolledLicensesStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setEnrolledLicenses(data));
                dispatch(setFetchEnrolledLicensesStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() => fetchEnrolledLicenses(userId))
                    );
                } else {
                    dispatch(setFetchEnrolledLicensesStatus("FAILED"));
                }
            });
    };
};
