import { Button, Card, Col, Skeleton, Row, Space, Statistic, Typography } from 'antd';
import { Bar } from '@ant-design/charts';
import QuizQuestionAttempt from '../../Dashboard/Videos/QuizQuestionAttempt';
import QuizQuestion from '../../Dashboard/Videos/QuizQuestion';
import { EyeInvisibleOutlined } from '@ant-design/icons';

const { Text } = Typography;


const QuizQuestionStatistics = ({quizQuestionIndex, quizQuestionStatistics, quizQuestionStatisticsStatus, setQuizQuestionIndex, totalNumOfQuizQuestions}) => {
    const replaceIdWithLabel = (data) => {
        return data.map((value, index) => {
            return {
                option: String.fromCharCode(index + 65),
                timesSelected: value.timesSelected
            }
        })
    }
    if(totalNumOfQuizQuestions > 0){
        var config = {
            data: replaceIdWithLabel(quizQuestionStatistics.choiceDistribution),
            xField: 'timesSelected',
            yField: 'option',
            legend: { position: 'top-left' },
            barBackground: { style: { fill: 'rgba(0,0,0,0.1)' } },
            interactions: [
              {
                type: 'active-region',
                enable: false,
              },
            ],

          };
        return <>
                <Col span={5}>
                    <Card className="quiz-question-statistics-card">
                        <div>
                            <Text>{quizQuestionStatistics.quizQuestion.title}</Text>
                            <br/>
                        </div>
                        <div>
                            <QuizQuestion 
                                question={quizQuestionStatistics.quizQuestion}
                                quizQuestionIndex={quizQuestionIndex}
                                setQuizQuestionIndex={setQuizQuestionIndex}
                                totalNumOfQuizQuestions={totalNumOfQuizQuestions}
                            />
                        </div>
                    </Card>
                </Col>
                <Col span={1}>
                </Col>
                <Col span={15} >
                    <Row>
                        <Col span={8}>
                            <Statistic 
                                title="Median # of Attempts" value={quizQuestionStatistics.classMedianAttempts} 
                                className="dashboard-video-grid-statistic"
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic 
                                title="# of Correct Answerers" value={quizQuestionStatistics.classNumOfCorrectAnswerers} 
                                className="dashboard-video-grid-statistic"
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title="Median Watch Completion for Correct Attempts" 
                                value={quizQuestionStatistics.classMedianWatchCompletionOfCorrectAnswerers * 100}
                                className="dashboard-video-grid-statistic"
                                suffix="%"
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={8}>
                            <Statistic 
                                title="Total Unique Watchers" value={quizQuestionStatistics.uniqueViewers} 
                                className="dashboard-video-grid-statistic"
                            />
                        </Col>
                        <Col span={8}>
                        
                        </Col>
                        <Col span={8}>

                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={7}>
                            <Text type="secondary">Popular Choices</Text>
                            <br/>
                            {
                                quizQuestionStatistics.hasAnsweredCorrectly ? <>
                                                                                <Bar {...config} height={200}/> 
                                                                            </>: <>
                                                                            <EyeInvisibleOutlined /> Hidden until you've answered the question correctly.
                                                                            
                                                                            </>
                            }
                        </Col>
                        <Col span={1}>
                        </Col>
                        <Col span={16}>
                            <Text type="secondary">Your Attempts</Text>
                            <br/>
                            <Space>
                                {quizQuestionStatistics.attempts.map((attempt, index) => <QuizQuestionAttempt 
                                                                                            attempt={attempt} 
                                                                                            attemptNum={index + 1}
                                                                                            question={quizQuestionStatistics.quizQuestion} 
                                                                                        />)}
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col span={15}>
                   
                </Col>
            </>
    } else if(totalNumOfQuizQuestions == 0) {
        return <>
                <Col span={5}>
                    <Card className="quiz-question-statistics-card">
                        There are no questions for this video
                    </Card>
                </Col>
                <Col span={1}>
                </Col>
                <Col span={15} >
                    <Row>
                        <Col span={8}>
                            <Statistic 
                                title="Median # of Attempts" value={"N/A"} 
                   
                                className="dashboard-video-grid-statistic"
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic 
                                title="# of Correct Answerers" value={"N/A"} 
                                className="dashboard-video-grid-statistic"
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title="Median Watch Completion for Correct Attempts" 
                                value={"N/A"} 
                                className="dashboard-video-grid-statistic"
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={8}>
                            <Statistic 
                                title="Total Unique Watchers" 
                                value={"N/A"} 
                                className="dashboard-video-grid-statistic"
                            />
                        </Col>
                        <Col span={8}>
                        
                        </Col>
                        <Col span={8}>

                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={7}>
                
                        </Col>
                        <Col span={1}>
                        </Col>
                        <Col span={16}>
                            
                        </Col>
                    </Row>
                </Col>
                <Col span={15}>
                
                </Col>
            </>
    }
    else {
        return <>
            <Col span={5}>
                    <Card className="quiz-question-statistics-card">
                        <Skeleton active />
                    </Card>
                </Col>
                <Col span={1}>
                </Col>
                <Col span={15} >
                    <Row>
                        <Col span={8}>
                            <Statistic 
                                title="Median # of Attempts" value={0} 
                                loading={true}
                                className="dashboard-video-grid-statistic"
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic 
                                title="# of Correct Answerers" value={0} 
                                loading={true}
                                className="dashboard-video-grid-statistic"
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title="Median Watch Completion for Correct Attempts" 
                                value={0} 
                                loading={true}
                                className="dashboard-video-grid-statistic"
                                suffix="%"
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={8}>
                            <Statistic 
                                title="Total Unique Watchers" 
                                value={0} 
                                loading={true}
                                className="dashboard-video-grid-statistic"
                            />
                        </Col>
                        <Col span={8}>
                        
                        </Col>
                        <Col span={8}>

                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={7}>
                          <Skeleton active />
                        </Col>
                        <Col span={1}>
                        </Col>
                        <Col span={16}>
                            <Skeleton active />
                        </Col>
                    </Row>
                </Col>
                <Col span={15}>
                   
                </Col>
        </>
    }

}

export default QuizQuestionStatistics;