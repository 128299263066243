import { Upload, notification } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const BulkUploadSection = ({ setPreviewData }) => {
    const props = {
        name: "file",
        accept: ".json",
        multiple: false,
        beforeUpload: (file) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    setPreviewData(JSON.parse(e.target.result));
                } catch (error) {
                    setPreviewData(null);
                    notification.error({
                        message: `Invalid file uploaded!`
                    });
                }
            };
            reader.readAsText(file);
            return false;
        }
    };

    return (
        <div className="upload-section">
            <p className="upload-label">Upload JSON File</p>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    Click or drag JSON file to this area to generate bulk
                    questions
                </p>
                <p className="ant-upload-hint">
                    Support for a single file upload.
                </p>
            </Dragger>
        </div>
    );
};

export default BulkUploadSection;
