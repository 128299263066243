import { UserOutlined } from "@ant-design/icons";
import { point } from "@antv/g2plot";
import { Avatar, Space } from "antd";
import { Link } from "react-router-dom";

const roster = [
    {
        "id": 1,
        "name": "John Smith"
    },
    {
        "id": 2,
        "name": "Jane Doe"
    }, 
    {
        "id": 3,
        "name": "Johnny Appleseed"
    }
]
const MockDashboardRoster = () => {
    return <div>
        {
            roster.map(student => {
                return <Link to={`/mock/dashboard/roster/sample?name=${student.name}`}>
                    <div style={{backgroundColor: "white", padding: 15, marginBottom: 5, cursor: "pointer"}}>
                        <Space>
                            <Avatar size="large" icon={<UserOutlined />} /> {student.name}
                        </Space>
                    </div>
                </Link>
            })
        }
    </div>
}

export default MockDashboardRoster;