import { Button, Card, Col, Row, Table, Typography } from 'antd';

const { Text, Title } = Typography;

const CreatorModulePerformances = ({ creatorModulePerformances, creatorModulePerformancesStatus}) => {
    const columns = [
        { title: 'Modules', dataIndex: 'name', key: 'name' },
        { title: 'Subscriptions', dataIndex: 'subscriptions', key: 'subscriptions' },
        { title: 'Limited Subscriptions', dataIndex: 'limitedSubscriptions', key: 'limitedSubscriptions' },
        { title: 'Generated Revenue', dataIndex: 'revenue', key: 'revenue', render: (value) => `$${value.toFixed(2)}` },
    ];
    
    const data = creatorModulePerformances.map((performance, index) => {
        return {
            ...performance,
            key: index
        }
    })

    return <Card className="creator-author-page-card" id="module-performance">
                <Text>Module Performance</Text>
                <br/><br/>
                <Table
                    columns={columns}
                    dataSource={data}
                />
    </Card>
}

export default CreatorModulePerformances;