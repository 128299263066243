import { CloseCircleOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, InputNumber, Select, Space, Typography } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { applyForCreator, fetchAllDepartments, fetchAllUniversities } from '../actions/user';


const { Option } = Select;
const { Link, Paragraph, Text, Title } = Typography;


const InstructorInformationStep = ({ applyForCreator, currentUser, departments, fetchAllDepartments, fetchAllUniversities, fetchingUser, isAuthenticated, universities, goToNextStep, goToPreviousStep }) => {

    const [form] = Form.useForm();
    const [fetchedDepartments, setFetchedDepartments] = useState(false);
    const [fetchedUniversities, setFetchedUniversities] = useState(false);


    if(!fetchedDepartments){
        fetchAllDepartments()
        setFetchedDepartments(true)
    } 
    if(!fetchedUniversities){
        fetchAllUniversities()
        setFetchedUniversities(true)
    }

    const handleOnFormFinish = (values) => {
        goToNextStep(1)
        applyForCreator(currentUser.id, values)
    }

    return <>
                    <Title level={4}>To become a creator, we need some additional information from you</Title>
                    <Text type="secondary">Meant to sign up as a student? </Text><Link onClick={()=>goToPreviousStep(1)}>Go back</Link>
                    <div style={{margin: "20px 0px"}}>
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={handleOnFormFinish}
                        //onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Space>
                            <Form.Item
                                
                                name={'firstName'}
                                label="First Name"
                                rules={[{ required: true, message: 'Missing first name' }]}
                            >
                                <Input placeholder="First Name" maxLength={150}/>
                            </Form.Item>
                            <Form.Item
                                
                                name={'lastName'}
                                label="Last Name"
                                rules={[{ required: true, message: 'Missing last name' }]}
                            >
                                <Input placeholder="Last Name" maxLength={150}/>
                            </Form.Item>
                        </Space>
                        <Form.Item  name="universities" label="Universities" rules={[{ required: true }]}>
                            <Select placeholder="Please select a university" mode="tags">
                                {universities.map(university => <Option value={university.name} key={university.name}>{university.name}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item  name="departments" label="Departments" rules={[{ required: true }]}>
                            <Select placeholder="Please select a department" mode="tags">
                                {departments.map(department => <Option value={department.name} key={department.name}>{department.name}</Option>)}
                            </Select>
                        </Form.Item> 
                        <Text>Courses you teach</Text>
                        <Form.List 
                            name="courses" 
                            rules = {[{
                                validator: async (_, courses) => {
                                    if (!courses || courses.length < 1) {
                                        return Promise.reject(new Error('At least one course required'));
                                    }
                                }
                            }]}
                        >
                            {(fields, { add, remove }, {errors}) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Card style={{margin: "10px 0px"}}>
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 , marginLeft: 15}} align="center">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                label="Course Name"
                                                fieldKey={[fieldKey, 'name']}
                                                rules={[{ required: true, message: 'Missing course name' }]}
                                            >
                                                <Input placeholder="Course Name" maxLength={100}/>
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'numOfStudents']}
                                                label="Class Size"
                                                fieldKey={[fieldKey, 'numOfStudents']}
                                                rules={[{ required: true, message: 'Missing course size' }]}
                                            >
                                                <InputNumber min={1} />
                                            </Form.Item>
                                            <CloseCircleOutlined style={{ position: "absolute", top: -5, right: -5}} onClick={() => remove(name)} />
                                        </Space>
                                    </Card>
                                ))}
                                <Form.ErrorList errors={errors} />
                                <Form.Item >
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add course
                                    </Button>
                                </Form.Item>
                            </>
                            )}
                        </Form.List>
                        
                      
                        <br/><br/>
                        <Button type="primary" htmlType="submit" style={{ width: "100%"}}>
                            Finish
                        </Button>
                
                    </Form>
              
                    </div>
            </>
}


const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.user,
        fetchingUser: state.userReducer.fetchingUser,
        isAuthenticated: state.userReducer.isAuthenticated,
        departments: state.userReducer.departments,
        universities: state.userReducer.universities
    }
}


export default connect(mapStateToProps, {applyForCreator, fetchAllDepartments, fetchAllUniversities})(InstructorInformationStep);