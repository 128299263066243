import { Card, Col, Modal, Row} from 'antd';
import YouTubePlayer from './YouTubePlayer';
import VimeoPlayer from './VimeoPlayer';
import ModalVideoPlayerVideoList from './ModalVideoPlayerVideoList';
import { useState } from 'react';

const ModalVideoPlayer = ({ module, playVideoInModal, toggleVideoPlayerModalVisibility, currentVideo, visible}) => {
    const [videoPlayerRef, setVideoPlayerRef] = useState(null);

    const renderProperVideoPlayer = () => {
        if(!visible){
            if(currentVideo.videoType == "youtube"){
                videoPlayerRef?.pauseVideo();
            }else if(currentVideo.videoType == "vimeo"){
                videoPlayerRef?.pause();
            }
        }
        switch(currentVideo.videoType){
            case "youtube":
                return <YouTubePlayer video={currentVideo} playerRef={videoPlayerRef} setVideoPlayerRef={setVideoPlayerRef} />
            case "vimeo":
                return <VimeoPlayer video={currentVideo} playerRef={videoPlayerRef} setVideoPlayerRef={setVideoPlayerRef} />
            default:
                return
        }
    }

    const renderFooter = () => {
        if(module){
            return  <div>
                <h3>Other videos in this module:</h3>
                <Card className="modules-component-video-list-container">
                    <Row key={`module-${module.id}-row`} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} wrap={false} className="module-video-list">
                        {module.videos.map((video, index) => {
                            return <Col key={`module-${module.id}-video-${index}`}>
                                <ModalVideoPlayerVideoList index={index} currentVideo={currentVideo} videoData={video} playVideoInModal={playVideoInModal} module={module} />
                            </Col>
                        })}
                    </Row>
                </Card>
            </div>
        }else {
            return <div></div>
        }
    }
    return  <Modal 
                title={currentVideo.title}
                visible={visible} 
                footer={renderFooter()}
                onCancel={toggleVideoPlayerModalVisibility}
                width="80vw"
                className="module-pool-video-player"
                >
                    {renderProperVideoPlayer()}
            </Modal>
}

export default ModalVideoPlayer;