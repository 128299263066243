import { Col, Row, Typography } from 'antd';
import { useState } from 'react';
const { Text } = Typography;

const QnAOverviewModule = ({ module }) => {
    const [visibleModuleVideos, setVisibleModuleVideos] = useState(false);

    const toggleModuleExpansion = () => {
        let element = document.getElementById(`qna-section-modules-container-${module.id}`);
        setVisibleModuleVideos(!visibleModuleVideos)
        if(element.style.maxHeight) {
            element.style.maxHeight = null;
        } else {
            element.style.maxHeight = element.scrollHeight + "px";
        }
       
        //setTimeout(()=>element.lastChild.scrollIntoView({behavior: "smooth", block: "center"}), 300)
    }

    return <div style={{padding: "0px 0px 10px 0px"}}>
                <div className="quiz-section-module-header" onClick={toggleModuleExpansion}>
                    <Row gutter={16}>
                        <Col span={16}>
                            {module.name}
                        </Col>
                        <Col span={4}>
                            {module.totalNumberOfQnaQuestions}
                        </Col>
                    </Row>
                    
                </div>
                <div className="qna-section-video-container" id={`qna-section-modules-container-${module.id}`}>
                    <div className="quiz-section-video-column-headers">
                        <Row gutter={16}>
                            <Col span={20}>
                                <Text className="quiz-section-video-column-headers-text">Video Title</Text>
                            </Col>
                            <Col span={4}>
                                <Text className="quiz-section-video-column-headers-text"># of Questions Asked</Text>
                            </Col>
                        </Row>
                    </div>
                    {module.videos.map(video => (
                        <div className="quiz-section-video-row">
                            <Row gutter={16}>
                                <Col span={20}>
                                    <Text>{video.title}</Text>
                                </Col>
                                <Col span={4}>
                                    <Text>{video.totalNumberOfQnaQuestions}</Text>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
                
            </div>
}

export default QnAOverviewModule;