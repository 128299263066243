import { CheckCircleOutlined, TeamOutlined } from "@ant-design/icons";
import {
    Button,
    Form,
    Input,
    Modal,
    Popconfirm,
    Space,
    Tooltip,
    Typography
} from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { setCouponCodeError, verifyCoupon } from "../actions/user";
import { API_URL } from "../const";

const { Search } = Input;
const { Paragraph, Text, Title } = Typography;

const CouponSearchModal = ({
    collection,
    couponCodeError,
    hasAccessToEntireCollection,
    isAuthenticated,
    setCouponCodeError,
    verifyCoupon,
    disabled = false
}) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [signedUpForClass, setSignedUpForClass] = useState(false);
    const [classCodeData, setClassCodeData] = useState(null);

    const handleOnFinish = (values) => {
        if (!isAuthenticated) {
            localStorage.setItem("couponCode", values["couponCode"]);
            window.location.href =
                API_URL + `/accounts/login/?next=${window.location.href}`;
            return null;
        } else {
            setLoading(true);
            setCouponCodeError(null);

            const successfulSignUp = (couponData) => {
                setClassCodeData(couponData);
                setSignedUpForClass(true);
            };
            verifyCoupon(values["couponCode"], successfulSignUp, () => {
                setLoading(false);
            });
        }
    };

    const handleOnSearch = () => {
        form.submit();
    };

    const handleOnCancel = () => {
        setClassCodeData(null);
        setSignedUpForClass(false);
        setVisible(false);
        form.resetFields();
    };

    const handleOnChange = () => {
        setCouponCodeError(null);
    };

    const handleOnSignupConfirm = () => {
        alert(window.location.href);
        window.location.href =
            API_URL + `/accounts/login/?next=${window.location.href}`;
        return;
    };

    const handleOnLoginConfirm = () => {
        window.location.href =
            API_URL + `/accounts/login/?next=${window.location.href}`;
        return;
    };

    const ClassCodeButton = () => {
        if (!isAuthenticated) {
            return (
                <Popconfirm
                    title="You must be logged in to use a class code."
                    onConfirm={handleOnSignupConfirm}
                    onCancel={handleOnLoginConfirm}
                    okText="Signup"
                    cancelText="Login">
                    <Button id="class-code-btn">
                        Unlock all with class code
                    </Button>
                </Popconfirm>
            );
        } else if (hasAccessToEntireCollection) {
            return (
                <Button
                    onClick={() => {
                        setVisible(true);
                    }}
                    disabled={disabled}
                    id="class-code-btn">
                    Have a class code?
                </Button>
            );
        } else {
            return (
                <Tooltip
                    placement="top"
                    title="Request code from your instructor or contact support@cuvids.io">
                    <Button
                        onClick={() => {
                            setVisible(true);
                        }}
                        disabled={disabled}
                        id="class-code-btn">
                        Unlock all with class code
                    </Button>
                </Tooltip>
            );
        }
    };

    const ModalContent = () => {
        if (signedUpForClass) {
            const renderDates = (moduleId) => {
                let foundIndex =
                    classCodeData.coupon.scheduledAccesses.findIndex(
                        (access) => {
                            return access.module == moduleId;
                        }
                    );
                if (foundIndex != -1) {
                    let startDate = new Date(
                        classCodeData.coupon.scheduledAccesses[
                            foundIndex
                        ].accessFrom
                    );
                    startDate = new Date(
                        startDate.getTime() +
                            startDate.getTimezoneOffset() * 60000
                    );
                    startDate.setHours(0, 0, 0, 0);

                    let endDate = new Date(
                        classCodeData.coupon.scheduledAccesses[
                            foundIndex
                        ].accessUntil
                    );
                    endDate = new Date(
                        endDate.getTime() + endDate.getTimezoneOffset() * 60000
                    );
                    endDate.setHours(23, 59, 59, 999);
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                            <Text>
                                <Text
                                    code>{`${startDate.toLocaleString()}`}</Text>{" "}
                                to
                            </Text>

                            <Text>
                                <Text
                                    code>{`${endDate.toLocaleString()}`}</Text>
                            </Text>
                        </div>
                    );
                } else {
                    return "---";
                }
            };
            return (
                <div>
                    <Title>
                        <CheckCircleOutlined className="success-animation" />{" "}
                        Enrolled!
                    </Title>
                    <Paragraph>
                        You have now enrolled in a new class using the coupon
                        code <Text code>{classCodeData.coupon.code}</Text>
                    </Paragraph>
                    <Paragraph>
                        <Text>
                            You will now temporarily have free access to the
                            following modules according to the dates outlined
                            below.
                        </Text>
                        <br />
                        <Text>
                            You can view these dates at any time by clicking "My
                            Classes" in the menu in the top right of your
                            screen.
                        </Text>
                    </Paragraph>

                    <Paragraph>
                        <Text>
                            Additionally, you will now be able to participate in
                            Q&A's with other members of your class and view your
                            progression using our dashboard.
                        </Text>
                    </Paragraph>

                    <Paragraph>
                        <Title level={5}>
                            Class Label: {classCodeData.coupon.label}
                        </Title>
                        <div className="coupon-module-dates-available coupon-module-dates-available-header">
                            <Text>Module</Text>
                            <Text>Dates Available</Text>
                        </div>
                        {collection.modules.map((module) => {
                            return (
                                <div
                                    className="coupon-module-dates-available"
                                    key={module.id}>
                                    <Text>{module.name}</Text>
                                    <Text>{renderDates(module.id)}</Text>
                                </div>
                            );
                        })}
                    </Paragraph>
                </div>
            );
        } else {
            return (
                <Paragraph>
                    <Space direction="vertical">
                        <Text>
                            Unlock <Text italic>temporarily</Text> with a class
                            code:
                        </Text>
                        <Form form={form} onFinish={handleOnFinish}>
                            <Form.Item
                                name="couponCode"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input a coupon code!"
                                    }
                                ]}
                                style={{ margin: 0 }}>
                                <Search
                                    placeholder="Class Code"
                                    allowClear
                                    enterButton="Submit"
                                    onSearch={handleOnSearch}
                                    loading={loading}
                                    onChange={handleOnChange}
                                />
                            </Form.Item>
                            <Text type="danger">{couponCodeError}</Text>
                        </Form>
                    </Space>
                </Paragraph>
            );
        }
    };
    return (
        <>
            <ClassCodeButton />
            <Modal
                visible={visible}
                onCancel={handleOnCancel}
                footer={null}
                width={700}>
                <div className="modal-body">
                    <ModalContent />
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.userReducer.isAuthenticated,
        couponCodeError: state.userReducer.couponCodeError
    };
};

export default connect(mapStateToProps, { setCouponCodeError, verifyCoupon })(
    CouponSearchModal
);
