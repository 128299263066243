import { Tooltip } from "antd";

const Cupoints = ({
    children,
    points,
    tooltipEnabled = true,
    secondaryWithinBrackets = true
}) => {
    const convertToUsd = (p) => {
        const num = p / 100;
        return Number.isInteger(num) ? num : num.toFixed(2);
    };

    return (
        <Tooltip
            placement="top"
            className="cupoints-tooltip"
            title={
                !tooltipEnabled
                    ? ""
                    : points === 0
                    ? `Free`
                    : `US $${convertToUsd(points)} = ${points} cupoint${
                          points > 1 ? "s" : ""
                      }`
            }>
            <span>
                {children}
                {points === 0 ? (
                    <span className="cupoints-container">
                        <>Free</>
                    </span>
                ) : (
                    <span className="cupoints-container">
                        <span className="dollar-amount">
                            US ${convertToUsd(points)}{" "}
                        </span>
                        <span className="cupoints-amount">
                            {secondaryWithinBrackets
                                ? `(${points} cupoint${points > 1 ? "s" : ""})`
                                : `${points} cupoint${points > 1 ? "s" : ""}`}
                        </span>
                    </span>
                )}
            </span>
        </Tooltip>
    );
};

export default Cupoints;
