import { List } from 'antd';
import DashboardQnAAnswer from './DashboardQnAAnswer';

const DashboardQnAAnswersList = ({responses, checkIfReplyMadeOneDayAgo, currentUser, enrolledLicenses, selectedLicense}) => {
    if(responses.length){

        return <List
                    itemLayout="horizontal"
                    dataSource={responses}
                    renderItem={item => (
                        <DashboardQnAAnswer 
                            answer={item} 
                            checkIfReplyMadeOneDayAgo={checkIfReplyMadeOneDayAgo}
                            currentUser={currentUser} 
                            enrolledLicenses={enrolledLicenses} 
                            selectedLicense={selectedLicense} 
                        />
                    )}
                />
                    
    } else {
        return <></>
    }
}

export default DashboardQnAAnswersList;