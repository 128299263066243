import { Button, Checkbox, Form, message,  Space } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { connect } from 'react-redux';
import RichTextEditor from '../../Components/RichTextEditor';
import { postQnAQuestion } from '../../actions/collection';

const DashboardCreateQnAQuestion = ({ collectionId, currentUser, enrolledLicenses, moduleId, postQnAQuestion, selectedLicense, videoId}) => {
    const [form] = Form.useForm();
    const [reset, setReset] = useState(false);
    const [submitQuestionLoading, setSubmitQuestionLoading] = useState(false);
    const [visibleQuestionForm, setVisibleQuestionForm] = useState(false);
    const [isAnonymous, setIsAnonymous] = useState(false);

    const handleOnRichTextEditorChange = (value) => {
        form.setFieldsValue({
            text: value
        })
    }

    const handleOnFinish = (values) => {
        const handleAfterSuccess = () => {
            setSubmitQuestionLoading(false);
            setReset(true);
            form.setFieldsValue({'text': undefined})
            message.success("Your question was posted.")
        }

        let selectedQnALevel;
        if(collectionId && moduleId && videoId){
            selectedQnALevel = "video"
        }else if(collectionId && moduleId) {
            selectedQnALevel = "module"
        }else {
            selectedQnALevel = "collection"
        }

        postQnAQuestion(collectionId, moduleId, videoId, selectedLicense.id, selectedQnALevel, values, handleAfterSuccess)
    }

    const handleCheckboxChange = (event) => {
        form.setFieldsValue({
            anonymous: event.target.checked
        })
        setIsAnonymous(event.target.checked)
    }

    const renderAnonymousCheckbox = () => {
        let currentLicense = enrolledLicenses.find(license => license.id === selectedLicense.id);
        if(currentLicense.createdById == currentUser.id){
            return <div></div>
        }else {
            return <Checkbox onChange={handleCheckboxChange}>Post anonymously to other students</Checkbox>
        }
    }

    if(visibleQuestionForm) {
        return <>
                <div style={{backgroundColor: "white", marginTop: "10px"}}>
                    <Form form={form} onFinish={handleOnFinish}>
                        <Form.Item name='text' hidden>
                            
                        </Form.Item>
                        <Form.Item name='anonymous' hidden>
                            
                        </Form.Item>
                    </Form>
                    <RichTextEditor 
                        customOnChange={handleOnRichTextEditorChange} 
                        placeholder="Ask a question (Use @ to reference timestamp/range in a video. Ex: @1:00 to @2:00)" 
                        readOnly={false} 
                        reset={reset} 
                        setReset={setReset} 
                        allowTimestamps={true}
                    />
                </div>
                
                <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 0px 0px 10px"}}>
                    <Space>{renderAnonymousCheckbox()}</Space>
                    <Space>
                        <Button onClick={()=>setVisibleQuestionForm(false)} style={{marginRight: "10px"}}>
                            Cancel
                        </Button>
                        <Button 
                            type="primary" 
                            onClick={() => {
                                let textValue = form.getFieldValue('text')
                                if(textValue && textValue.blocks.some(block => block.text.length)){
                                    setSubmitQuestionLoading(true);
                                    form.submit()
                                } else {
                                    message.warning('Please enter a question first.')
                                }
                            }} 
                            loading={submitQuestionLoading}
                        >
                            <MessageOutlined /> Ask Question
                        </Button>
                    </Space>
                </div>
            </>           
    } else {
        return <div style={{margin: "10px 0px"}}><Button type="dashed" style={{width: "100%"}} onClick={()=>setVisibleQuestionForm(true)}>Ask a question</Button></div>
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.user,
        enrolledLicenses: state.userReducer.enrolledLicenses,
        selectedLicense: state.dashboardReducer.selectedCoupon
    }
}

export default connect(mapStateToProps, { postQnAQuestion })(DashboardCreateQnAQuestion);