import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Layout, Menu } from "antd";
import { MenuOutlined, PlayCircleOutlined } from "@ant-design/icons";
import collection from "./MockData.json";

const { SubMenu } = Menu;

class VideoPageMenu extends Component {
    render() {
        return (
            <>
                <Button
                    onClick={() => this.props.onToggle()}
                    style={{
                        position: "fixed",
                        left: 0,
                        zIndex: 99,
                        border: "none",
                        borderRadius: 0,
                        marginTop: "-40px",
                        fontSize: "16px"
                    }}>
                    <MenuOutlined />
                </Button>
                <Layout.Sider
                    id="video-page-menu"
                    width={"15vw"}
                    collapsedWidth={0}
                    collapsible
                    collapsed={this.props.isMenuCollapsed}
                    trigger={null}>
                    {/* openKeys={this.props.isMenuCollapsed ? [] : ["0-0"]} */}
                    <Menu
                        mode="inline"
                        onClick={this.handleClick}
                        defaultSelectedKeys={["0-0"]}
                        defaultOpenKeys={["sub0"]}>
                        {collection.modules.map((module, moduleIndex) => {
                            return (
                                <SubMenu
                                    key={`sub${moduleIndex}`}
                                    title={module.name}>
                                    {module.videos.map((video, videoIndex) => {
                                        return (
                                            <Menu.Item
                                                key={`${moduleIndex}-${videoIndex}`}
                                                title={video.title}
                                                icon={<PlayCircleOutlined />}
                                                onClick={() =>
                                                    this.props.setVideo(video)
                                                }>
                                                {video.title}
                                            </Menu.Item>
                                        );
                                    })}
                                </SubMenu>
                            );
                        })}
                    </Menu>
                </Layout.Sider>
            </>
        );
    }
}

export default VideoPageMenu;
