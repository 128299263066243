import { Button, Collapse, Form, Input, Space } from "antd";
import { useHistory } from "react-router-dom";
import CollectionDetailsStep from "../../CollectionCreator/CollectionDetailsStep";
import Tour from "../../Components/Tour";

const CollectionDetails = ({
    authorsDict,
    collection,
    currentUser,
    goToNextStep,
    updateState
}) => {
    const history = useHistory();
    const tutorialSteps = [
        {
            target: "#collection-details-form",
            content: (
                <div>
                    <p style={{ textAlign: "left" }}>
                        Provide a name, description, and long description for
                        your course.
                    </p>

                    <br />
                    <p style={{ textAlign: "left" }}>
                        Click "Next: Add Modules" to proceed to the next step.
                    </p>
                </div>
            ),
            placement: "left-start",
            disableBeacon: true
        }
    ];

    const renderTutorial = () => {
        return (
            <Tour
                steps={tutorialSteps}
                tutorialName="createCollectionTutorial"
            />
        );
    };

    const handleOnFinish = (values) => {
        let authorData;
        if (values.primaryAuthorUsername in authorsDict) {
            authorData = authorsDict[values.primaryAuthorUsername];
        } else {
            if (!currentUser) {
                authorData = {
                    username: "Sample Username",
                    firstName: "FirstName",
                    lastName: "LastName"
                };
            } else {
                authorData = {
                    id: currentUser.id,
                    username: currentUser.username,
                    firstName: currentUser.firstName,
                    lastName: currentUser.lastName
                };
            }
        }

        let coAuthors = values.coAuthorUsernames.map((coAuthorUsername) => {
            if (coAuthorUsername in authorsDict) {
                return authorsDict[coAuthorUsername];
            } else {
                if (!currentUser) {
                    authorData = {
                        username: "Sample Username",
                        firstName: "FirstName",
                        lastName: "LastName"
                    };
                } else {
                    authorData = {
                        id: currentUser.id,
                        username: currentUser.username,
                        firstName: currentUser.firstName,
                        lastName: currentUser.lastName
                    };
                }
            }
        });

        updateState("collection", {
            ...values,
            primaryAuthorUsername: authorData.username,
            primaryAuthor: authorData,
            coAuthors: coAuthors
        });
        goToNextStep();
    };

    return (
        <div>
            {renderTutorial()}
            <div id="collection-details-form">
                <Form
                    name="basic"
                    initialValues={collection}
                    layout="vertical"
                    onFinish={handleOnFinish}
                    //onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    <Form.Item
                        label="Course Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please give your course a name!"
                            },
                            {
                                max: 50,
                                message:
                                    "Course name can contains maximum 50 characters!"
                            }
                        ]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Please give your course a description!"
                            }
                        ]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Long Description"
                        name="longDescription"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Please give your course a longer description!"
                            }
                        ]}>
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                        hidden
                        label="Author"
                        name={["primaryAuthorUsername"]}
                        fieldKey={["primaryAuthorUsername"]}
                        tooltip="If you are not the primary author, search for the user's account by username or name. Only this user will get paid for any purchases. If you leave this blank, the primary author will be set to you."></Form.Item>
                    <Form.Item
                        hidden
                        label="Co-Authors"
                        name={["coAuthorUsernames"]}
                        fieldKey={["coAuthorUsernames"]}
                        tooltip="Used mainly for credit purposes only."></Form.Item>
                    <br />
                    <Space>
                        <Button onClick={() => history.push("/creator/home/")}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Next: Add Modules
                        </Button>
                    </Space>
                </Form>
            </div>
        </div>
    );
};

export default CollectionDetails;
