import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import { Alert, Breadcrumb, Button, Card, Space, Steps, Typography } from 'antd';
import CollectionEditorSteps from './CollectionEditorSteps';
import { fetchCollectionDetail, fetchUserCreatedModules, fetchUserPolicyModel, setUserCreatedModules } from '../actions/contentCreation';

const { Step } = Steps;
const { Paragraph, Text, Title } = Typography;

class CollectionEditor extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentStep: 0,
            collection: props.collection,
            modules: props.modules,
            authorsDict: {},
            videoBank: [],
            allVideos: props.modules.flatMap(module=>module.videos)
        }
    }

    componentDidMount() {
        this.props.fetchUserPolicyModel(this.props.currentUser.id)
        this.props.fetchUserCreatedModules(this.props.currentUser.id)
    }

    updateState = (key, pair) => {
        this.setState({
            [key]: pair
        })
    }
    goToPreviousStep = () => {
        this.setState({
            currentStep: this.state.currentStep - 1
        })
    }

    goToNextStep = () => {
        this.setState({
            currentStep: this.state.currentStep + 1
        })
    }

    render() {
        return  <div className="centered-container">
                    <div className="collection-editor-container">
                        <Prompt
                            when={this.state.currentStep != 3}
                            message='Any changes you have made will not be saved, are you sure you want to leave?'
                        />
                        <Space className="breadcrumbs">
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/creator/home">Creator Home</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>Editing Course</Breadcrumb.Item>
                            </Breadcrumb>
                        </Space>
                        <Card>
                            <Title level={4}>
                                Editing Course
                            </Title>
                            <Steps size="small" current={this.state.currentStep}>
                                <Step title={this.state.currentStep === 0 ? "Collection Details" : "Finished"} />
                                <Step title={this.state.currentStep <= 1 ? "Add Modules" : "Finished"} />
                                <Step title={this.state.currentStep <= 2 ? "Review Changes" : "Finished"} />
                                <Step title={this.state.currentStep <= 3 ? "Done" : "Finished"} />
                            </Steps>

                            <Card ghost bordered={false}>
                                <CollectionEditorSteps 
                                    allVideos={this.state.allVideos}
                                    collection={this.state.collection}
                                    currentStep={this.state.currentStep}
                                    goToNextStep={this.goToNextStep}
                                    goToPreviousStep={this.goToPreviousStep}
                                    modules={this.state.modules}
                                    videoBank={this.state.videoBank}
                                    updateState={this.updateState}
                                    authorsDict={this.state.authorsDict}
                                    currentUser={this.props.currentUser}
                                    createdModules={this.props.createdModules}
                                    setUserCreatedModules={this.props.setUserCreatedModules}
                                />
                            </Card>
                        </Card>
                    </div>
                    <div className="collection-editor-container-mobile">
                        <Alert
                            message="Unavailable on current screen size"
                            description="The Collection Creator is not available on your current screen size. Please try using another device."
                            type="info"
                            showIcon
                            action={
                                <Button size="small" type="primary" >
                                    <Link to='/creator/home/'>
                                        Go back
                                    </Link>
                                </Button>
                              }
                        />
                    </div>
                </div>
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.user,
        createdModules: state.contentCreationReducer.userCreatedModules,
    }
}

export default connect(mapStateToProps, { fetchCollectionDetail, fetchUserCreatedModules, fetchUserPolicyModel, setUserCreatedModules })(CollectionEditor);