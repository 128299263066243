/******************************************************************
HomePageRosterActivity.js
Written by Adam Gamba, Summer 2021

Displays summary information about the class roster including a
histogram of student watch times, bar chart of top students, and
StudentGrid of students with zero watch time (past 7 days)
******************************************************************/

import React, { Component, useState, useEffect } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Card, Collapse, DatePicker, Tooltip, Empty, Skeleton, Space, Typography, message } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { withRouter } from "react-router-dom";
import StudentGrid from "./StudentGrid";
import RosterHistogram from "./RosterHistogram";
import { fetchHomePageRosterActivity } from '../../actions/dashboard';

const { Panel } = Collapse;

const dateFormat = 'MM/DD/YYYY';
const { Text, } = Typography;

class HomePageRosterActivity extends Component {
  constructor(props){
    super(props);
   
    this.state = {
      zeroRoster: [],
      topChartData: [],
      topChartLabels: [],
      topChartSutdents: [],
      histogramData: [],
      selectedDate: moment(),
    }
  }

  // Formatting/styling options of graph
  options = {
    responsive: true,
    indexAxis: "y",
    scales: {
      xAxes: [
        {
          ticks: { min: 0 },
          scaleLabel: {
            display: true,
            labelString: "Hours Watched",
            fontSize: 16,
            fontStyle: "bold",
          },
          gridLines: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            autoSkip: false,
          },
          scaleLabel: {
            display: false,
            labelString: "Student ID",
            fontStyle: "bold",
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    onClick: (_, element) => {
      if (element[0] === undefined) return;
      let student = this.state.topChartStudents[element[0]._index];
      window.location = `./students/${student.studentId}`;
    },

    tooltips: {
      callbacks: {
        label: (tooltipItem) => {
          return `${tooltipItem.xLabel.toFixed(1)} hours`;
        },
      },
    },
  };

  componentDidMount(){
    if(this.props.videoListStatus === "LOADED" && this.props.selectedCoupon){
      let coupon = this.props.selectedCoupon.code;

      this.props.fetchHomePageRosterActivity(this.state.selectedDate.startOf('week').toISOString(), this.state.selectedDate.endOf('week').toISOString(), coupon, this.formatData, this.setZeroRoster)
    }
  }

  // if class coupon is already set, fetch roster data for the given coupon
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.videoListStatus !== this.props.videoListStatus && this.props.videoListStatus === "LOADED" && this.props.selectedCoupon || (prevState.selectedDate != this.state.selectedDate)){

      // ! Uncomment to showcase an arbitrary week of semester
      // today = moment(
      //   new Date().setDate(new Date().getDate() - 150)
      // ).toISOString();
      // oneWeekAgo = moment(
      //   new Date().setDate(new Date().getDate() - 157)
      // ).toISOString();
      // !

      let coupon = this.props.selectedCoupon.code;
      this.props.fetchHomePageRosterActivity(this.state.selectedDate.startOf('week').toISOString(), this.state.selectedDate.endOf('week').toISOString(), coupon, this.formatData, this.setZeroRoster)
    }
  };
  setZeroRoster = (rosterData) => {
    this.setState({
      zeroRoster: rosterData
    })
  }
  
  customWeekStartEndFormat = value => {
    return `${moment(value).startOf('week').format(dateFormat)} ~ ${moment(value).endOf('week').format(dateFormat)}`;
  }
  
  // format fetch request data for input to roster graph
  formatData = (data) => {
    // Assuming data is already sorted, if not, uncomment this block
    // data = data.sort((a, b) => {
    //   if (a.hours >= b.hours) return a;
    //   else return b;
    // });

    // Set histogram data to just include hours
    this.setState({
      histogramData: data.map((x) => x.hours)
    });

    // Take top 10 sorted students (by hours watched)
    data = data.slice(0, 10);

    // set labels as each student's username
    let labels = [];
    let hours = [];
    let students = [];
    data.forEach((student) => {
      labels.push(student.username);
      hours.push(student.hours);
      students.push(student);
    });

    // Update the data and labels in state
    this.setState({
      topChartLabels: labels,
      topChartData: hours,
      topChartStudents: students
    })

  };

  handleOnRangePickerChange = (value) => {
 
    this.setState({
      selectedDate: value
    })
    
  }

  render(){
    if (this.props.homePageRosterActivityDataStatus !== "LOADED" && this.props.homePageRosterActivityDataStatus !== "FAILED") {
      return (
        <Card id="roster-activity-this-past-week"
          title={<>
            <Text >
              Roster Data
            </Text>
            <br/>
              <Space>
                <Text type="secondary">Viewing Statistics for</Text>
                <DatePicker value={this.state.selectedDate} format={this.customWeekStartEndFormat} picker="week" onChange={this.handleOnRangePickerChange} allowClear={false}/>

              </Space>
            </>
          }
        >
          <Skeleton active/>
        </Card>
      );
    }else if(this.props.homePageRosterActivityDataStatus === "FAILED"){
      return (
        <Card id="roster-activity-this-past-week"
          title={<>
            <Text >
              Roster Data
            </Text>
            <br/>
              <Space>
                <Text type="secondary">Viewing Statistics for</Text>
                <DatePicker value={this.state.selectedDate} format={this.customWeekStartEndFormat} picker="week" onChange={this.handleOnRangePickerChange} allowClear={false}/>

              </Space>
            </>
          }
        >
          <div className="site-layout-content flex-center">
            <Empty description="Failed to load data" />
          </div>
        </Card>
        
      );
    }
    // Empty if greatest value in sorted students is 0
    else if (this.state.topChartData[0] === 0) {
      return (
        <Card id="roster-activity-this-past-week"
          title={<>
            <Text >
              Roster Data
            </Text>
            <br/>
              <Space>
                <Text type="secondary">Viewing Statistics for</Text>
                <DatePicker value={this.state.selectedDate} format={this.customWeekStartEndFormat} picker="week" onChange={this.handleOnRangePickerChange} allowClear={false}/>

              </Space>
            </>
          }
        >
        <div className="site-layout-content flex-center">
          <Empty description="No Roster Data Available (Past 7 Days)" />
        </div>
        </Card>
      );
    } else {
      let barChartData = {
        labels: this.state.topChartLabels,
        datasets: [
          {
            backgroundColor: "#5bd1d78F",
            barPercentage: 1,
            borderWidth: 4,
            data: this.state.topChartData,
          },
        ],
      };
      return (
        // Else, return data summaries
        <Card
          id="roster-activity-this-past-week"
          extra={
            <Tooltip
              placement="left"
              title="Histogram of student watch patterns of the past 7 days."
            >
              <QuestionCircleOutlined style={{ fontSize: "150%" }} />
            </Tooltip>
          }
          title={<>
            <Text >
              Roster Data
            </Text>
            <br/>
              <Space>
                <Text type="secondary">Viewing Statistics for</Text>

                 <DatePicker value={this.state.selectedDate} format={this.customWeekStartEndFormat} picker="week" onChange={this.handleOnRangePickerChange} allowClear={false}/>
                 
              </Space>
            </>
          }
          bordered={false}
        >
          <br/>
          {/* Render histogram of student watch tendencies of the past 7 days */}
          <RosterHistogram data={this.state.histogramData} />
          {/* <Card
            type="inner"
            title={
              <h2
                className="flex-center"
                style={{
                  fontSize: "16pt",
                  fontWeight: "bold",
                  margin: "5px",
                }}
              >
                Star Students
              </h2>
            }
          > */}
          {/* Render collapsible bar chart of top 10 most active students */}
          <Collapse style={{ margin: "10px" }}>
            <Panel
              header={`Click to reveal top 10 most active students of the past 7 days`}
              extra={
                <Tooltip
                  placement="left"
                  title="Data from the top 10 most active students of the past 7 days. Click on a bar to visit a student's individual data page."
                >
                  <QuestionCircleOutlined style={{ fontSize: "150%" }} />
                </Tooltip>
              }
            >
              <HorizontalBar
                data={barChartData}
                options={this.options}
                height={50}
              />
            </Panel>
          </Collapse>
          {/* </Card> */}
          {/* Render collapsible grid of inactive students of the past 7 days */}
          {this.state.zeroRoster.length > 0 ? (
            // <Card
            //   type="inner"
            //   title={
            //     <h2
            //       className="flex-center"
            //       style={{
            //         fontSize: "16pt",
            //         fontWeight: "bold",
            //         margin: "5px",
            //       }}
            //     >
            //       Zero Watch Time
            //     </h2>
            //   }
            // >
            <Collapse style={{ margin: "10px" }}>
              <Panel
                header={`Click to reveal ${this.state.zeroRoster.length} students with zero watch time in the past 7 days`}
                extra={
                  <Tooltip
                    placement="left"
                    title="Data from all inactive students of the past 7 days. Click on a card to visit a student's individual data page."
                  >
                    <QuestionCircleOutlined
                      style={{ fontSize: "150%" }}
                    />
                  </Tooltip>
                }
              >
                <StudentGrid user={this.props.user} roster={this.state.zeroRoster} />
              </Panel>
            </Collapse>
          ) : // </Card>
          null}
        </Card>
      );
    }
  }
};

const mapStateToProps = (state) => {
  return {
    homePageRosterActivityDataStatus: state.dashboardReducer.homePageRosterActivityDataStatus,
    scheduledAccesses: state.dashboardReducer.scheduledAccesses,
    selectedCoupon: state.dashboardReducer.selectedCoupon,
    videoList: state.dashboardReducer.videoList,
    videoListStatus: state.dashboardReducer.videoListStatus,
    user: state.userReducer.user
  };
};

export default connect(mapStateToProps, {fetchHomePageRosterActivity})(
  withRouter(HomePageRosterActivity)
);
