import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Card, Form, Input, message, PageHeader, Select, Space, Spin, Typography } from 'antd';

import { fetchIssueCategories, submitIssue} from './actions/issue';

const { Title } = Typography;
class ReportIssuePage extends Component {
    formRef = React.createRef();

    constructor(props){
        super(props)
        this.state = {
            submitLoading: false,
        }
    }
    componentDidMount() {
        if(this.props.isAuthenticated && this.props.categoriesStatus === "NOT LOADED"){
            this.props.fetchIssueCategories()
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isAuthenticated != this.props.isAuthenticated && this.props.isAuthenticated && this.props.categoriesStatus === "NOT LOADED"){
            this.props.fetchIssueCategories()
        }
    }

    onFinish = (values) => {
        this.setState({ submitLoading: true})
        const onSubmitSuccess = () => {
            message.success("Report received.")
            this.setState({ submitLoading: false})
            this.formRef.current.resetFields();
        }
        const onSubmitFailure = () => {
            message.error("Something went wrong.")
            this.setState({ submitLoading: false})
        }
        this.props.submitIssue(values, onSubmitFailure, onSubmitSuccess)
    };

    render(){
        if(!this.props.isAuthenticated && (!this.props.fetchingUser) && localStorage.getItem("access") === null){
            return <Space size="middle" className="loading-indicator-container">
                Please login to report an issue
            </Space>
        }
        if(this.props.categoriesStatus === "NOT LOADED" || this.props.categoriesStatus === "LOADING"){
            return <Space size="middle" className="loading-indicator-container">
                        <Spin size="large" />
                    </Space>
        }
        if(this.props.categoriesStatus === "FAILED"){
            return "Issue Reports page failed to load."
        }
        
        return <div id="report-issue-page">
            <PageHeader
                title="Issue Reports"
                ghost={false}
                onBack={() => window.history.back()}
                extra={[
                    
                ]}
            >
                    
            </PageHeader>
            <br/>
            <div>

                <Card id="report-issue-form">
                    <Title level={3}>Report an Issue</Title>
                    <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} validateMessages={{required: "This field is required."}}>
                        <Form.Item 
                            name="category" 
                            label="Category" 
                            rules={[
                                {
                                required: true,
                                },
                            ]}
                        >
                            <Select>
                                {this.props.categories.map(category => <Select.Option value={category.name}>{category.label}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item 
                            name="subject" 
                            label="Subject" 
                            rules={[
                                {
                                required: true,
                                },
                            ]}
                        >
                            <Input maxLength={200} placeholder="Brief overview of issue" />
                        </Form.Item>
                        <Form.Item 
                            name="text" 
                            label="Tell us more about your issue including a link to a course or video (if applicable), etc" 
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            
                        >
                            <Input.TextArea autoSize={{minRows: 4}} placeholder="Include details about the course/module/video name (if applicable) so that we can better understand this issue"/>
                        </Form.Item>
                        <Form.Item >
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                style={{float: "right"}} 
                                loading={this.state.submitLoading}
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
                <br/>
                {/*<Card>
                    Known Issues:
                </Card>*/}
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        categories: state.issueReducer.categories,
        categoriesStatus: state.issueReducer.fetchCategoriesStatus,
        fetchingUser: state.userReducer.fetchingUser,
        isAuthenticated: state.userReducer.isAuthenticated
    }
}
export default connect(mapStateToProps, { fetchIssueCategories, submitIssue } )(ReportIssuePage);