import { Card, Empty, Table, Typography } from "antd";
import { Pie } from '@ant-design/charts';

const { Text } = Typography;


const ModulePopularity = ({ collection }) => {

    var config = {
        appendPadding: 10,
        data: collection.modules,
        angleField: 'subscriptions',
        colorField: 'name',
        radius: 0.8,
        label: {
          type: 'outer',
          content: '{name} {percentage}',
        },
        interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
        tooltip: {
 
            formatter: (module) => {
                return { name: "Paid Subscriptions", value: module.subscriptions };
            },
        },
    };

    const columns = [
        { title: 'Module Name', dataIndex: 'name', key: 'name'},
        { title: 'Module Price (cupoints)', dataIndex: 'price', key: 'price'},
        { title: '# of Licensed Modules', dataIndex: 'licensedModulesCount', key: 'licensedModulesCount'}
    ];

    return <>
        <Text>Paid Subscriptions by Module</Text>
        <br/><br/>
        {
            collection.modules.every(module => module.subscriptions === 0) ?    <Card>
                                                                                    <Empty description="No subscriptions yet."/>
                                                                                </Card> 
                                                                            :   <Pie {...config} />

        }

        <Table 
            size="small"
            columns={columns}
            dataSource={collection.modules}
        />
    </>
}

export default ModulePopularity;