import CollectionInfoCard from "../Components/CollectionInfoCard";
import CollectionModulesList from "../Components/CollectionModulesList";
import { Alert, Button, Space } from 'antd';
import { connect } from 'react-redux';
import { updateCollection } from "../actions/contentCreation";

const ReviewCollectionChangesStep = ({ authorsDict, collection, currentUser, goToNextStep, goToPreviousStep, modules, updateCollection }) => {

    let previewCollectionData = {
        ...collection,
        modules: modules
    }

    const handleOnPublishClick = () => {
        updateCollection(collection.id, previewCollectionData)
        goToNextStep()
    }
    

    return <div>
         <Alert
            message="Note"
            description="This is the preview of the public-facing page of your collection."
            type="info"
            showIcon
            action={<Space>
                <Button onClick={goToPreviousStep}>
                    Go Back
                </Button>
                <Button type="primary" onClick={handleOnPublishClick}>
                    Publish Changes
                </Button>
            </Space>}
        />
        <CollectionInfoCard 
            collection={previewCollectionData}
            collectionNetPrice={collection.collectionNetPrice}
            key={collection.id}
            mode="preview"
        />
        <CollectionModulesList
            collection={previewCollectionData} 
            mode="preview"
            urlParams={{}}
        />
        
    </div>
}


export default connect(null, { updateCollection })(ReviewCollectionChangesStep);