import { message } from "antd"
import { getNewAccessToken } from './user.js';
import { fetchSingleCollectionAccess, updateOriginalCollection } from './collection';
import { API_URL } from "../const.js";

export const setCollectionCertificateLoadingStatus = (status) => {
    return {
        type: "SET_CERTIFICATION_LOADING_STATUS",
        status
    }
}

export const setCollectionEvaluationProgressStatus = (status) => {
    return {
        type: "SET_CERTIFICATION_EVALUATION_PROGRESS_STATUS",
        status
    }
}

export const setCollectionCertificateData = (certification) => {
    return {
        type: "SET_CERTIFICATION_DATA",
        certification
    }
}

export const setCertificateProgressStatus = (progress) => {
    return {
        type: "SET_CERTIFICATION_PROGRESS_STATUS",
        progress
    }
}

export const setCertificateStatus = (status) => {
    return {
        type: "SET_CERTIFICATION_STATUS",
        status
    }
}

export const fetchCollectionCertificationDetails = (collectionId) => {
    return dispatch => {
        dispatch(setCollectionCertificateLoadingStatus("LOADING"))
        return fetch(API_URL + `/api/v1/collections/${collectionId}/certification-details/`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(response.status)
            }
        }).then(data => {
            dispatch(setCollectionCertificateLoadingStatus("SUCCESS"))
            dispatch(setCollectionCertificateData(data))
        }).catch(error => {
            if (parseInt(error.message) === 401) {
                dispatch(getNewAccessToken(() => fetchCollectionCertificationDetails(collectionId)))
            } else {
                dispatch(setCollectionCertificateLoadingStatus("FAILED"))
            }
        })
    }
}


export const evaluateCollectionCertification = (collectionId) => {
    return dispatch => {
        dispatch(setCollectionEvaluationProgressStatus(true))
        return fetch(API_URL + `/api/v1/collections/${collectionId}/evaluate/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(response.status)
            }
        }).then(data => {
            setTimeout(() => {
                dispatch(setCollectionCertificateData(data))
                dispatch(setCollectionEvaluationProgressStatus(false))
                dispatch(fetchSingleCollectionAccess(collectionId))
                return message.success("Evaluation success.")
            }, 2000);
        }).catch(error => {
            if (parseInt(error.message) === 401) {
                dispatch(getNewAccessToken(() => evaluateCollectionCertification(collectionId)))
            } else {
                dispatch(setCollectionEvaluationProgressStatus(false))
                return message.error("Something went wrong.")
            }
        })
    }
}

export const requestCollectionCertification = (collectionId) => {
    return dispatch => {
        dispatch(setCertificateProgressStatus(true))
        return fetch(API_URL + `/api/v1/collections/${collectionId}/request-certificate/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(response.status)
            }
        }).then(data => {
            setTimeout(() => {
                // dispatch(setCollectionCertificateData(data))
                // dispatch(setCertificateProgressStatus(false))
                dispatch(setCertificateStatus(data.status))
                return message.success("You have successfully requested certificate request. Now we are evaluating your performace.")
            }, 2000);
        }).catch(error => {
            if (parseInt(error.message) === 401) {
                dispatch(getNewAccessToken(() => requestCollectionCertification(collectionId)))
            } else {
                dispatch(setCertificateProgressStatus(false))
                return message.error("Something went wrong.")
            }
        })
    }
}