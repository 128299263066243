import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripePaymentForm from './StripePaymentForm';


const stripePromise = loadStripe("pk_live_51JDw98H1nFM3sj3kMZjvRxLECvlslHBbiQRPn4W2NpQaE6C38JhDPQxYHE3RDdyHUkJqjuMRD5RLoaHLCohQK6Oj00hXMckgJT");


const StripePaymentContainer = ({resetReadyToCheckout, cupointsValue, currencyValue, currency}) => {

    return  (
        <Elements stripe={stripePromise}>
           <StripePaymentForm 
                cupointsValue={cupointsValue} 
                currency={currency} 
                currencyValue={currencyValue}
                resetReadyToCheckout={resetReadyToCheckout}
            />
        </Elements>
    )       
}

export default StripePaymentContainer;