import { useState } from "react";
import { convertFromRaw, EditorState } from "draft-js";
import RichTextPreview from "../../../Components/RichTextPreview";
import RichTextEditor from "../../../Components/RichTextEditor";
import { useEffect } from "react";
import QuizQuestionImageUpload from "../../../QuestionsCreator/QuizQuestionImageUpload";
import { Typography } from "antd";

const QuizOpenEndedQuestion = ({
    option,
    submitted,
    showCorrectAnswer,
    latestAttempt,
    handleOnAnswerTextChange,
    handleOnImageChange
}) => {
    const { Text } = Typography;

    const [reset, setReset] = useState(false);
    const [answer, setAnswer] = useState();
    const [answerImage, setAnswerImage] = useState(null);


    const contentStateExplanation = convertFromRaw(option.optionText);
    const editorStateExplanation = EditorState.createWithContent(
        contentStateExplanation
    );

    const onAnswerTextChange = (value) => {
        setAnswer(value);
        handleOnAnswerTextChange(value);
    };

    const renderCorrectAnswer =
        option.optionText.blocks.length <= 1 &&
        option.optionText.blocks[0].text.length == 0 ? null : (
            <div>
                <RichTextPreview editorState={editorStateExplanation} />
            </div>
        );

    const renderImage = option.image ? (
        <div className="quiz-question-option-image-container">
            <img
                alt="example"
                style={{ width: "100%" }}
                src={option.image.image}
            />
        </div>
    ) : null;

    const addImageToForm = (imageId) => {
        handleOnImageChange(imageId);
    };

    useEffect(() => {
        if (
            latestAttempt &&
            latestAttempt != "preview" &&
            latestAttempt.answerText &&
            latestAttempt.answerText.blocks.length
        ) {
            setAnswer(latestAttempt.answerText);
            if(latestAttempt.answerImage){
                setAnswerImage(latestAttempt.answerImage)
            }
        }
    }, [latestAttempt]);

    if (!submitted) {
        return (
            <>
                <div style={{ marginTop: "10px" }}>
                    <RichTextEditor
                        customOnChange={onAnswerTextChange}
                        placeholder="Enter the answer"
                        readOnly={false}
                        reset={reset}
                        setReset={setReset}
                    />
                    <br />
                    <Text>Image (Optional)</Text>
                    <QuizQuestionImageUpload
                        addImageToForm={(imageId) =>
                            addImageToForm(imageId)
                        }
                        maxCount={1}
                        customOnRemove={(file) => {}}
                    />
                </div>
            </>
        );
    } else {
        return (
            <div>
                {
                    <div className="correct-quiz-option quiz-option">
                        {answer && (
                            <RichTextPreview
                                editorState={EditorState.createWithContent(
                                    convertFromRaw(answer)
                                )}
                            />
                        )}

                        {answerImage && (
                            <>
                                <br />
                                <div className="quiz-question-option-image-container">
                                    <img
                                        alt="example"
                                        style={{ width: "100%" }}
                                        src={answerImage.image}
                                    />
                                </div>
                            </>
                        )}


                    </div>
                }
                {showCorrectAnswer && (
                    <>
                        <div
                            style={{
                                padding: "10px",
                                fontStyle: "italic",
                                border: "1px solid #dbdbdb",
                                borderRadius: "5px"
                            }}>
                            <p style={{ fontWeight: "bold" }}>Correct Answer</p>
                            {renderCorrectAnswer}
                            {renderImage}
                        </div>
                    </>
                )}
            </div>
        );
    }
};

export default QuizOpenEndedQuestion;
