import { Component } from "react";
import { connect } from "react-redux";
import { Link, Prompt } from "react-router-dom";
import {
    Alert,
    Breadcrumb,
    Button,
    Card,
    Result,
    Space,
    Spin,
    Steps,
    Typography
} from "antd";
import CollectionCreatorSteps from "./CollectionCreatorSteps";
import {
    fetchCollectionDetail,
    fetchUserCreatedModules,
    fetchUserPolicyModel
} from "../actions/contentCreation";
import { logEvent, trackingEvents } from "../Tracking";

const { Step } = Steps;
const { Title } = Typography;

class CollectionCreator extends Component {
    constructor(props) {
        super(props);
        const editMode = Boolean(this.props.match.params.collectionId);
        if (editMode) {
            this.setEditModeContents();
        }
        this.state = {
            contentLoading: editMode,
            isEditMode: editMode,
            currentStep: 0,
            collection: {
                name: "Sample Course Name",
                description: "Sample Description",
                longDescription: "Sample Long Description",
                noOfModules: 1,
                primaryAuthorUsername: this.props.currentUser.username,
                primaryAuthor: {
                    id: this.props.currentUser.id,
                    username: this.props.currentUser.username,
                    firstName: this.props.currentUser.firstName,
                    lastName: this.props.currentUser.lastName
                },
                coAuthors: [],
                coAuthorUsernames: [],
                valuePrice: 0,
                collectionNetPrice: 0,
                url: "" // TODO: Temp
            },
            showUrlImport: true,
            modules: [],
            authorsDict: {},
            videoBank: [],
            allVideos: [],
            importInProgress: false,
            policyModel: props.policyModel
        };
    }

    componentDidMount() {
        this.props.fetchUserPolicyModel(this.props.currentUser.id);
        this.props.fetchUserCreatedModules(this.props.currentUser.id);

        const editMode = Boolean(this.props.match.params.collectionId);
        logEvent(
            trackingEvents.pageView,
            {
                page: editMode ? "course-edit" : "course-create"
            },
            this.props.currentUser
        );
    }

    collectionWithAuthorUsernames = (collection) => {
        let primaryAuthorUsername = collection.primaryAuthor.username;
        let coAuthorUsernames = collection.coAuthors.map(
            (coAuthor) => coAuthor.username
        );
        return {
            ...collection,
            primaryAuthorUsername: primaryAuthorUsername,
            coAuthorUsernames: coAuthorUsernames
        };
    };

    modulesWithAuthorUsernames = (modules) => {
        let preformattedModules = modules.map((module, index) => {
            return {
                ...module,
                key: Date.now() + index,
                primaryAuthorUsername: module.primaryAuthor.username,
                coAuthorUsernames: module.coAuthors.map(
                    (coAuthor) => coAuthor.username
                ),
                videos: module.videos.map((video, videoIndex) => {
                    return {
                        ...video,
                        title: module.videoTitles[videoIndex].videoTitle
                    };
                })
            };
        });

        return preformattedModules;
    };

    setEditModeContents() {
        this.props
            .fetchCollectionDetail(
                this.props.match.params.collectionId,
                this.props.currentUser.id
            )
            .then((s) => {
                this.setState({
                    showUrlImport: false,
                    collection: this.collectionWithAuthorUsernames(
                        this.props.collection
                    ),
                    modules: this.modulesWithAuthorUsernames(
                        this.props.collection.modules
                    ),
                    contentLoading: false
                });
            });
    }

    updateState = (key, pair) => {
        this.setState({
            [key]: pair
        });
    };
    goToPreviousStep = () => {
        this.setState({
            currentStep: this.state.currentStep - 1
        });
    };

    goToNextStep = () => {
        this.setState({
            currentStep: this.state.currentStep + 1
        });
    };

    getModuleDefaults = (defaults) => {
        if (defaults) return defaults;

        const authorData = {
            id: this.props.currentUser.id,
            username: this.props.currentUser.username,
            firstName: this.props.currentUser.firstName,
            lastName: this.props.currentUser.lastName
        };
        return {
            primaryAuthorUsername: authorData.username,
            primaryAuthor: authorData,
            coAuthorUsernames: [],
            coAuthors: [],
            price: this.props.policyModel?.platformDefaultPrice || 0,
            isDefaultPrice: true,
            customPrice: null,
            videos: []
        };
    };

    // Add new sample module
    addNewSampleModule = (nameList, defaults = null) => {
        const newModuleList = nameList.map((name, index) => {
            return {
                key: Date.now() + index,
                name: `Sample Module ${name}`,
                ...this.getModuleDefaults(defaults)
            };
        });
        this.setState({
            modules: [...this.state.modules, ...newModuleList]
        });

        return newModuleList;
    };

    // set authors dict
    setAuthorsDict = (newAuthorsDict) => {
        this.updateState("authorsDict", {
            ...this.state.authorsDict,
            ...newAuthorsDict
        });
    };

    render() {
        if (!this.props.currentUser.isContentCreator) {
            return (
                <Result
                    status="403"
                    title="403"
                    subTitle="Sorry, you are not authorized to access this page."
                    extra={
                        <Button
                            type="primary"
                            onClick={() => this.props.history.push("/")}>
                            Go Back to Home
                        </Button>
                    }
                />
            );
        }

        return (
            <>
                {!this.state.contentLoading ? (
                    <div className="centered-container">
                        <div className="collection-creator-container">
                            <Prompt
                                when={
                                    this.state.currentStep != 3 &&
                                    this.state.currentStep != 0
                                }
                                message="Any changes you have made will not be saved, are you sure you want to leave?"
                            />
                            <Space className="breadcrumbs">
                                <Breadcrumb>
                                    <Breadcrumb.Item>
                                        <Link to="/creator/home">
                                            Creator Home
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        {this.state.isEditMode
                                            ? "Editing Course"
                                            : "Create a Course"}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Space>

                            <Card>
                                <Title level={4}>
                                    {this.state.isEditMode
                                        ? "Editing Course"
                                        : "Creating a Course"}
                                </Title>
                                <Steps
                                    size="small"
                                    current={this.state.currentStep}>
                                    <Step
                                        title={
                                            this.state.currentStep === 0
                                                ? "Course Details"
                                                : "Finished"
                                        }
                                    />
                                    <Step
                                        title={
                                            this.state.currentStep <= 1
                                                ? "Add Modules"
                                                : "Finished"
                                        }
                                    />
                                    <Step
                                        title={
                                            this.state.currentStep <= 2
                                                ? "Review Changes"
                                                : "Finished"
                                        }
                                    />
                                    <Step
                                        title={
                                            this.state.currentStep <= 3
                                                ? "Done"
                                                : "Finished"
                                        }
                                    />
                                </Steps>

                                <Card
                                    bordered={false}
                                    className="collection-creator-steps-container">
                                    <CollectionCreatorSteps
                                        isEditMode={this.state.isEditMode}
                                        collection={this.state.collection}
                                        currentStep={this.state.currentStep}
                                        goToNextStep={this.goToNextStep}
                                        goToPreviousStep={this.goToPreviousStep}
                                        modules={this.state.modules}
                                        videoBank={this.state.videoBank}
                                        updateState={this.updateState}
                                        addNewSampleModule={
                                            this.addNewSampleModule
                                        }
                                        createdModules={
                                            this.props.createdModules
                                        }
                                        authorsDict={this.state.authorsDict}
                                        setAuthorsDict={this.setAuthorsDict}
                                        showUrlImport={this.state.showUrlImport}
                                        currentUser={this.props.currentUser}
                                        importInProgress={
                                            this.state.importInProgress
                                        }
                                        policyModel={this.props.policyModel}
                                    />
                                </Card>
                            </Card>
                        </div>
                        <div className="collection-creator-container-mobile">
                            <Alert
                                message="Unavailable on current screen size"
                                description="The Course Creator is not available on your current screen size. Please try using another device."
                                type="info"
                                showIcon
                                action={
                                    <Button size="small" type="primary">
                                        <Link to="/creator/home/">Go back</Link>
                                    </Button>
                                }
                            />
                        </div>
                    </div>
                ) : (
                    <div className="loading-authentication">
                        <Spin />
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        collection: state.contentCreationReducer.collectionDetail,
        createdModules: state.contentCreationReducer.userCreatedModules,
        currentUser: state.userReducer.user,
        policyModel: state.contentCreationReducer.policyModel
    };
};

export default connect(mapStateToProps, {
    fetchUserCreatedModules,
    fetchUserPolicyModel,
    fetchCollectionDetail
})(CollectionCreator);
