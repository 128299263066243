import { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Breadcrumb, Button, Card, Col, Divider, List, Result, Row, Space } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import './Components.less';
import CollectionInfoCard from './Components/CollectionInfoCard';
import { fetchCollectionDetail, fetchCollectionDetailScheduledLicenses } from './actions/contentCreation';
import CollectionModulesList from './Components/CollectionModulesList';
import LoadingCollectionInfoCard from './Components/LoadingCollectionInfoCard';
import LoadingModuleList from './Components/LoadingModuleList';


class CreatedCollectionDetail extends Component {

    componentDidMount(){
    
        if(!this.props.collection || this.props.collection.id != this.props.match.params.collectionId){
            this.props.fetchCollectionDetail(this.props.match.params.collectionId, this.props.currentUser.id)
            this.props.fetchCollectionDetailScheduledLicenses(this.props.match.params.collectionId, this.props.currentUser.id)
        }

    }

    render() {
        if(this.props.collectionNotFound) {
            return <>
                        <Result
                            status="403"
                            title="403"
                            subTitle="Sorry, you are not authorized to access this page."
                            extra={<Button type="primary" onClick={()=>this.props.history.push('/creator/home')}>Go Back to Creator Home</Button>}
                        />
                    </>
        }
        if(!this.props.collection || this.props.collection.id != this.props.match.params.collectionId){
            return <div className="centered-container">
                        <br/>
                        <LoadingCollectionInfoCard />
                        <LoadingModuleList />
                    </div>
        }

        return <div className="centered-container">
                    <Space>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/"><HomeOutlined /></Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/creator/home">Creator Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>My Created Courses</Breadcrumb.Item>
                            
                        </Breadcrumb>
                    </Space>
                    <CollectionInfoCard 
                        collection={this.props.collection}
                        collectionNetPrice={this.props.collection.valuePrice}
                        key={this.props.collection.id}
                        mode="instructor"
                    />

                    <CollectionModulesList 
                        collection={this.props.collection} 
                        mode="instructor"
                        urlParams={this.props.match.params}
                    />
                </div>
    }
}

const mapStateToProps = state => {
    return {
        collection: state.contentCreationReducer.collectionDetail,
        collectionNotFound: state.contentCreationReducer.collectionNotFound,
        currentUser: state.userReducer.user
    }
}

export default connect(mapStateToProps, { fetchCollectionDetail, fetchCollectionDetailScheduledLicenses })(CreatedCollectionDetail);