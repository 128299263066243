import { API_URL } from '../const.js';
import { getNewAccessToken } from './user.js';

export const setTranscriptsVideoList = (videos) => {
    return {
        type: "SET_TRANSCRIPTS_VIDEO_LIST",
        videos
    }
}

export const setTranscriptsVideoListStatus = (status) => {
    return {
        type: "SET_TRANSCRIPTS_VIDEO_LIST_STATUS",
        status
    }
}


export const setPlatformStatistics = (data) => {
    return {
        type: "SET_PLATFORM_STATISTICS",
        data
    }
}

export const setPlatformStatisticsStatus = (status) => {
    return {
        type: "SET_PLATFORM_STATISTICS_STATUS",
        status
    }
}

export const fetchPlatformStatistics = () => {
    return dispatch => {
        dispatch(setPlatformStatisticsStatus("LOADING"))
        return fetch(API_URL + `/api/v1/admin/statistics/`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                dispatch(setPlatformStatisticsStatus("LOADED"))
                dispatch(setPlatformStatistics(data))
            })
            .catch(error => {
                dispatch(setPlatformStatisticsStatus("FAILED"))
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => fetchPlatformStatistics()))
                }
            })
    }
}

export const setClassCodesList = (codes) => {
    return {
        type: "SET_CLASS_CODES_LIST",
        codes
    }
}

export const setClassCodesListStatus = (status) => {
    return {
        type: "SET_CLASS_CODES_LIST_STATUS",
        status
    }
}

export const setCertificateRequestsListStatus = (status) => {
    return {
        type: "SET_CERTIFICATE_REQUEST_LIST_STATUS",
        status
    }
}

export const setCertificationRequestList = (data) => {
    return {
        type: "SET_CERTIFICATE_REQUEST_LIST",
        data
    }
}

export const setCertificateRequestsApproveProgress = (status) => {
    return {
        type: "SET_CERTIFICATE_REQUEST_APPROVE_PROGRESS",
        status
    }
}

export const setCertificateRequestsRejectProgress = (status) => {
    return {
        type: "SET_CERTIFICATE_REQUEST_REJECT_PROGRESS",
        status
    }
}

export const setCertificateRequestStatusList = (data) => {
    return {
        type: "SET_CERTIFICATE_REQUEST_STATUS",
        data
    }
}

export const setCerticateUserSummary = (summary) => {
    return {
        type: "SET_CERTIFICATE_USER_SUMMARY",
        summary
    }
}

export const setCerticateUserSummaryStatus = (status) => {
    return {
        type: "SET_CERTIFICATE_USER_SUMMARY_STATUS",
        status
    }
}

export const setCerticateUserWatchedVideoStatistics = (data) => {
    return {
        type: "SET_CERTIFICATE_USER_WATCHED_VIDEO_STATISTICS",
        data
    }
}

export const setCerticateStatisticsAllVideos = (data) => {
    return {
        type: "SET_CERTIFICATE_STATISTICS_ALL_VIDEOS",
        data
    }
}

export const setCerticateStatisticsMaxVideoLength = (data) => {
    return {
        type: "SET_CERTIFICATE_STATISTICS_MAX_VIDEO_LENGTH",
        data
    }
}

export const fetchClassCodesList = (pageNum = 1, updateTableState) => {
    return dispatch => {
        let headers = {}
        if (localStorage.getItem('access') != null) {
            headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        }
        dispatch(setClassCodesListStatus("LOADING"))
        return fetch(API_URL + `/api/v1/classcodes/?page=${pageNum}`, {
            method: "GET",
            headers: headers
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                dispatch(setClassCodesListStatus("LOADED"))
                updateTableState(pageNum, 25, data.count)
                return dispatch(setClassCodesList(data.results))
            })
            .catch(error => {
                dispatch(setClassCodesListStatus("FAILED"))
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => fetchClassCodesList(pageNum)))
                }
            })
    }
}

export const fetchCertificationRequestList = (pageNum = 1, updateTableState) => {
    return dispatch => {
        let headers = {}
        if (localStorage.getItem('access') != null) {
            headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        }
        dispatch(setCertificateRequestsListStatus("LOADING"))
        return fetch(API_URL + `/api/v1/certificate-requests/?page=${pageNum}`, {
            method: "GET",
            headers: headers
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                dispatch(setCertificateRequestsListStatus("LOADED"))
                updateTableState(pageNum, 25, data.count)
                return dispatch(setCertificationRequestList(data.results))
            })
            .catch(error => {
                dispatch(setCertificateRequestsListStatus("FAILED"))
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => fetchClassCodesList(pageNum)))
                }
            })
    }
}

export const fetchCertificationRequestStatistics = (requestId) => {
    return dispatch => {
        dispatch(setCerticateUserSummaryStatus("LOADING"))
        let headers = {}
        if (localStorage.getItem('access') != null) {
            headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        }
        // dispatch(setCertificateRequestsListStatus("LOADING"))
        return fetch(API_URL + `/api/v1/certificate-requests/${requestId}/view_statistics`, {
            method: "GET",
            headers: headers
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                dispatch(setCerticateUserSummaryStatus("LOADED"))
                const formattedData = formatWatchedVideoStatistics(data?.statistics?.studentActivity || {});
                dispatch(setCerticateUserWatchedVideoStatistics(formattedData.formattedVideos));
                dispatch(setCerticateStatisticsMaxVideoLength(formattedData.maximumVideoDuration));

                dispatch(setCerticateStatisticsAllVideos(data?.certificationRequest?.collection))
                return dispatch(setCerticateUserSummary(data?.statistics?.studentSummeries || {}))
            })
            .catch(error => {
                dispatch(setCerticateUserSummaryStatus("FAILED"))
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => fetchCertificationRequestStatistics(requestId)))
                }
            })
    }
}

const formatWatchedVideoStatistics = (data) => {
    let formattedVideos = {};
    // Returns maximum video duration from all data (used for
    // scaling purposes))
    let maximumVideoDuration = 0;
    if (Object.keys(data).length > 0) {
        maximumVideoDuration = Object.values(data)
            .map((x) => x.videoDuration)
            .reduce((a, b) => {
                return Math.max(a, b);
            });
    }


    for (let video in data) {
        // Example videoObj: {videoTitle: "Conditionals: The IF
        // Statement", moduleId: 8, collectionId: 5, data: Array(26)}
        let videoObj = data[video];
        videoObj.dataSum = videoObj.data.reduce((a, b) => a + b, 0);

        // Format videos array into object, with keys being module IDs
        // and values being an array of videos in that module
        let moduleId = videoObj.moduleId;
        if (formattedVideos.hasOwnProperty(moduleId)) {
            formattedVideos[moduleId].push(videoObj);
        } else {
            formattedVideos[moduleId] = [videoObj];
        }
    }

    return { formattedVideos: formattedVideos, maximumVideoDuration: maximumVideoDuration }
}

export const approveOrRejectCertificate = (id, approve) => {
    return dispatch => {
        let headers = {}
        if (localStorage.getItem('access') != null) {
            headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        }

        if (approve === 1) {
            dispatch(setCertificateRequestsApproveProgress(true))
        } else {
            dispatch(setCertificateRequestsRejectProgress(true))
        }

        return fetch(API_URL + `/api/v1/certificate-requests/${id}/approval/`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                approve
            })
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(response.status);
            }
        }).then(data => {
            if (approve === 1) {
                dispatch(setCertificateRequestsApproveProgress(false))
            } else {
                dispatch(setCertificateRequestsRejectProgress(false))
            }
            return dispatch(setCertificateRequestStatusList({ id, status: data.status }))
        }).catch(error => {
            if (approve === 1) {
                dispatch(setCertificateRequestsApproveProgress(false))
            } else {
                dispatch(setCertificateRequestsRejectProgress(false))
            }
            if (parseInt(error.message) === 401) {
                dispatch(getNewAccessToken(() => approveOrRejectCertificate(id, approve)))
            }
        })
    }
}

export const setClassCodeDetail = (detail) => {
    return {
        type: "SET_CLASS_CODE_DETAIL",
        detail
    }
}

export const setClassCodeDetailStatus = (status) => {
    return {
        type: "SET_CLASS_CODE_DETAIL_STATUS",
        status
    }
}

export const fetchClassCodeDetail = (code) => {
    return dispatch => {

        dispatch(setClassCodeDetailStatus("LOADING"))
        return fetch(API_URL + `/api/v1/classcodes/${code}/roster/`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                dispatch(setClassCodeDetailStatus("LOADED"))
                dispatch(setClassCodeDetail(data))
            })
            .catch(error => {
                dispatch(setClassCodeDetailStatus("FAILED"))
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => fetchClassCodeDetail(code)))
                }
            })
    }
}

export const fetchTranscriptsVideoList = (pageNum = 1, updateTableState) => {
    return dispatch => {
        let headers = {}
        if (localStorage.getItem('access') != null) {
            headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        }
        dispatch(setTranscriptsVideoListStatus("LOADING"))
        return fetch(API_URL + `/api/v1/videos/?page=${pageNum}`, {
            method: "GET",
            headers: headers
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                dispatch(setTranscriptsVideoListStatus("LOADED"))
                updateTableState(pageNum, 25, data.count)
                return dispatch(setTranscriptsVideoList(data.results))
            })
            .catch(error => {
                dispatch(setTranscriptsVideoListStatus("FAILED"))
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => fetchTranscriptsVideoList(pageNum)))
                }
            })
    }
}

export const setUploadTranscriptStatus = (status) => {
    return {
        type: "SET_UPLOAD_TRANSCRIPT_STATUS",
        status
    }
}

export const setUploadTranscriptResult = (data) => {
    return {
        type: "SET_UPLOAD_TRANSCRIPT_RESULT",
        data
    }
}

export const uploadTranscripts = (uploadData) => {
    return dispatch => {
        dispatch(setUploadTranscriptStatus("LOADING"))

        return fetch(API_URL + `/api/v1/transcripts/`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: uploadData
            })
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                dispatch(setUploadTranscriptResult(data))
                dispatch(setUploadTranscriptStatus("SUCCESS"))
            })
            .catch(error => {
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => uploadTranscripts(uploadData)))
                } else {
                    dispatch(setUploadTranscriptStatus("FAILED"))
                }
            })
    }
}

const setVideosToCurateStatus = status => {
    return {
        type: "SET_VIDEOS_TO_CURATE_STATUS",
        status
    }
}

const setVideosToCurate = videos => {
    return {
        type: "SET_VIDEOS_TO_CURATE",
        videos
    }
}
export const fetchVideosToCurate = () => {
    return dispatch => {
        let headers = {}
        if (localStorage.getItem('access') != null) {
            headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        }
        dispatch(setVideosToCurateStatus("LOADING"))
        return fetch(API_URL + `/api/v1/admin/videos-to-curate/`, {
            method: "GET",
            headers: headers
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                dispatch(setVideosToCurateStatus("LOADED"))
                return dispatch(setVideosToCurate(data.videosToCurate))
            })
            .catch(error => {
                dispatch(setVideosToCurateStatus("FAILED"))
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => fetchVideosToCurate()))
                }
            })
    }
}

const setDemoRequestsStatus = status => {
    return {
        type: "SET_DEMO_REQUESTS_STATUS",
        status
    }
}

const setDemoRequests = data => {
    return {
        type: "SET_DEMO_REQUESTS",
        data
    }
}

export const fetchDemoRequests = () => {
    return dispatch => {
        dispatch(setDemoRequestsStatus("LOADING"))
        return fetch(API_URL + `/api/v1/demos/`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                dispatch(setDemoRequestsStatus("LOADED"))
                dispatch(setDemoRequests(data))
            })
            .catch(error => {
                dispatch(setDemoRequestsStatus("FAILED"))
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => fetchDemoRequests()))
                }
            })
    }
}


const setDemoRequestStatus = status => {
    return {
        type: "SET_DEMO_REQUEST_STATUS",
        status
    }
}

const setDemoRequest = data => {
    return {
        type: "SET_DEMO_REQUEST",
        data
    }
}

export const fetchDemoRequest = (demoRequestId) => {
    return dispatch => {
        dispatch(setDemoRequestStatus("LOADING"))
        return fetch(API_URL + `/api/v1/demos/${demoRequestId}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                dispatch(setDemoRequestStatus("LOADED"))
                dispatch(setDemoRequest(data))
            })
            .catch(error => {
                dispatch(setDemoRequestStatus("FAILED"))
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => fetchDemoRequest(demoRequestId)))
                }
            })
    }
}



const setDemoRequestUpdateStatus = status => {
    return {
        type: "SET_DEMO_REQUEST_UPDATE_STATUS",
        status
    }
}

const updateDemoRequest = data => {
    return {
        type: "UPDATE_DEMO_REQUEST",
        data
    }
}

export const markDemoRequestAsComplete = (demoRequestId) => {
    return dispatch => {
        dispatch(setDemoRequestUpdateStatus("LOADING"))
        return fetch(API_URL + `/api/v1/demos/${demoRequestId}/mark-as-complete/`, {
            method: "PATCH",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                dispatch(setDemoRequestUpdateStatus("LOADED"))
                dispatch(updateDemoRequest(data))
            })
            .catch(error => {
                dispatch(setDemoRequestUpdateStatus("FAILED"))
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => markDemoRequestAsComplete(demoRequestId)))
                }
            })
    }
}