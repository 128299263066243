import {  Button, Card, Col, Row, Table, Typography } from 'antd';
import { Component, useState } from 'react';
import CreatorTransactionsList from './CreatorTransactionsList';


const { Text, Title } = Typography;

class CreatorTransactionsSummary extends Component {
    constructor(props){
        super(props)
        this.state = {
            pagination: {
                current: 1,
                pageSize: 5,
                total: 10
            },
            previousTableValues: []
        }
    }
    

    componentDidMount(){
        this.props.fetchCreatorTransactionsSummary(this.props.userId, this.state.pagination.current, this.updatePagination, this.updatePreviousValues)
    }

    handleTableChange = (pagination) => {
        this.props.fetchCreatorTransactionsSummary(this.props.userId, pagination.current, this.updatePagination, this.updatePreviousValues)
      };
    
    updatePagination = (pageIndex, pageSize, totalNumPages) => {
        this.setState({
            pagination: {
                current: pageIndex,
                pageSize: pageSize,
                total: totalNumPages,
            }
        })
    }

    updatePreviousValues = (summariesData) => {
        this.setState(
            {
                previousTableValues: summariesData.map((week, index) => {
                            return {
                                ...week,
                                key: index
                            }
                })
            } 
        )
    }

    columns = [
        { title: 'Week of', dataIndex: 'week', key: 'week' },
        { title: 'Modules Purchased', dataIndex: 'totalModulesPurchased', key: 'totalModulesPurchased' },
        { title: 'Total Users', dataIndex: 'totalSubscribers', key: 'totalSubscribers' },
        { title: 'Royalties Earned', dataIndex: 'totalRoyaltiesEarned', key: 'totalRoyaltiesEarned', render: (value) => `$${value.toFixed(2)}` },
        /*{
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (summary) => <CreatorTransactionsList userId={this.props.userId} summary={summary}/>,
        },*/
    ];
    

    render(){
        return <Card className="creator-author-page-card" id="transactions-summary">
                    <Text>Transactions Summary</Text>
                    <br/><br/>
                    <Table
                        columns={this.columns}
                        dataSource={this.state.previousTableValues}
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        loading={this.props.creatorTransactionsSummaryStatus === "LOADING"}
                    />
            </Card>
    }
}

export default CreatorTransactionsSummary;