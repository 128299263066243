import {Card, Image, message, Tooltip, Typography} from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckOutlined, FileAddOutlined, PlayCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const ModalVideoPlayerVideoList = ({addImportedVideos, currentVideo, index, module, playVideoInModal, videoData, }) => {
    var [vimeoThumbnailUrl, setVimeoThumbnailUrl] = useState(null)
    const history = useHistory();

    const fetchVimeoThumbnail = async(video) => {
        var response = await fetch(`https://vimeo.com/api/v2/video/${video.embedId}.json`)
        const json = await response.json()
        return setVimeoThumbnailUrl(json[0]["thumbnail_large"])
    }
    const getVideoThumbnail = (video) => {
        if(video.videoType === "youtube"){
            return <Image 
                    src={`https://img.youtube.com/vi/${video.embedId}/mqdefault.jpg`} 
                    preview={false}
                    width="320px" 
                    height="200px"
                />
        }else if(video.videoType === "vimeo"){
            fetchVimeoThumbnail(video)
            return <Image
                        src={vimeoThumbnailUrl}
                        preview={false}
                        width="320px" 
                        height="200px"
                    />
        }
    }

    return  <Card
                hoverable
                className={`video-card${currentVideo.id === videoData.id ? " now-playing-video-card" : ""}`}
                cover={getVideoThumbnail(videoData)}
                onClick={()=>playVideoInModal(videoData)}
            >
                {
                    currentVideo.id === videoData.id ? <div className="now-playing-icon-overlay">Now Playing</div> : <div className="play-icon-overlay"><PlayCircleOutlined /></div>
                }
                
                <Card.Meta title={module.videoTitles[index].videoTitle} />
            </Card>

}

export default ModalVideoPlayerVideoList;