import { Card, Col, Row, Space, Statistic, Typography } from 'antd';

const { Paragraph, Text } = Typography;

const CreatorEarnings = ({ creatorEarnings, creatorEarningsStatus }) => {
    return <Card className="creator-author-page-card" id="earning-details">
                <Text>Earning Details</Text>
                <br/>
                <Space>
                    <Paragraph>
                        <Text type="secondary">For each module purchase, <Text italic>up to</Text> the first <Text strong>{creatorEarningsStatus == "LOADED" ? creatorEarnings.policyModel.platformThresholdPrice : 0} cupoints</Text>, 
                            you will receive <Text strong>{creatorEarningsStatus == "LOADED" ? parseFloat(creatorEarnings.policyModel.regularPayoutRate) * 100 : 0}%</Text> of the revenue.
                        </Text>
                    
                        <Text type="secondary"> For any additional cupoints above <Text strong>{creatorEarningsStatus == "LOADED" ? creatorEarnings.policyModel.platformThresholdPrice : 0} cupoints </Text> 
                            (up to <Text strong>{creatorEarningsStatus == "LOADED" ? creatorEarnings.policyModel.platformMaximumBonusPrice - creatorEarnings.policyModel.platformThresholdPrice : 0} cupoints</Text>), you will receive a bonus rate of <Text strong>{creatorEarningsStatus == "LOADED" ? parseFloat(creatorEarnings.policyModel.bonusPayoutRate) * 100 : 0}%</Text>.
                        </Text>
                    </Paragraph>
                </Space>
          
                <br/><br/>
                <Row>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Statistic 
                            title="Regular Royalties %" 
                            value={creatorEarningsStatus == "LOADED" ? parseFloat(creatorEarnings.policyModel.regularPayoutRate) * 100 : 0} 
                            loading={creatorEarningsStatus != "LOADED"}
                            suffix={" %"}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Statistic 
                            title="Bonus Royalties %" 
                            value={creatorEarningsStatus == "LOADED" ? parseFloat(creatorEarnings.policyModel.bonusPayoutRate) * 100 : 0} 
                            loading={creatorEarningsStatus != "LOADED"}
                            suffix={" %"}
                        />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Statistic 
                            title="Total Earnings" 
                            value={creatorEarningsStatus == "LOADED" ? creatorEarnings.totalEarnings.toFixed(2) : 0} 
                            loading={creatorEarningsStatus != "LOADED"}
                            prefix={"$ "}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Statistic 
                            title="Pending Earnings" 
                            value={creatorEarningsStatus == "LOADED" ? creatorEarnings.pendingEarnings.toFixed(2) : 0} 
                            loading={creatorEarningsStatus != "LOADED"}
                            prefix={"$ "}
                        />
                    </Col>
                </Row>
            </Card>
}

export default CreatorEarnings;