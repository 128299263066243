import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Menu, message, PageHeader, Space, Typography } from "antd";
import ClassCouponDropdown from "./ClassCouponDropdown";
import { LoadingOutlined, MenuOutlined } from "@ant-design/icons";
import { setDashboardPoV } from "../actions/user";

const { SubMenu } = Menu;
const { Text } = Typography;

class DashboardPageHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: null
        };
    }
    componentDidMount() {
        switch (this.props.location.pathname) {
            case "/dashboard/":
                return this.setState({
                    currentTab: "home"
                });
            case "/dashboard":
                return this.setState({
                    currentTab: "home"
                });
            case "/dashboard/students/":
                return this.setState({
                    currentTab: "students"
                });
            case "/dashboard/students":
                return this.setState({
                    currentTab: "students"
                });
            case "/dashboard/videos/":
                return this.setState({
                    currentTab: "videos"
                });
            case "/dashboard/videos":
                return this.setState({
                    currentTab: "videos"
                });
            case "/dashboard/qna/":
                return this.setState({
                    currentTab: "qna"
                });
            case "/dashboard/qna":
                return this.setState({
                    currentTab: "qna"
                });
            case "/dashboard/reports/":
                return this.setState({
                    currentTab: "reports"
                });
            case "/dashboard/reports":
                return this.setState({
                    currentTab: "reports"
                });
            default:
                return null;
        }
    }

    renderQnACollectionMenu = () => {
        if (this.props.collection) {
            return (
                <>
                    <Menu.ItemGroup title="Course">
                        <Menu.Item
                            key="collection"
                            onClick={() => {
                                this.props.history.push(
                                    `/dashboard/qna/collections/${this.props.collection.id}`
                                );
                                this.setState({
                                    currentTab: "collection"
                                });
                            }}>
                            {this.props.collection.name}
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.ItemGroup title="Modules">
                        {this.props.collection.modules.map((module) => {
                            return (
                                <SubMenu
                                    key={`module-${module.id}`}
                                    title={module.name}
                                    onTitleClick={() => {
                                        this.props.history.push(
                                            `/dashboard/qna/collections/${this.props.collection.id}/modules/${module.id}`
                                        );
                                        this.setState({
                                            currentTab: `module-${module.id}`
                                        });
                                    }}>
                                    <Menu.ItemGroup title="Videos">
                                        {module.videos.map((video) => {
                                            return (
                                                <Menu.Item
                                                    key={`video-${video.id}`}
                                                    onClick={() => {
                                                        this.props.history.push(
                                                            `/dashboard/qna/collections/${this.props.collection.id}/modules/${module.id}/videos/${video.id}`
                                                        );
                                                        this.setState({
                                                            currentTab: `video-${video.id}`
                                                        });
                                                    }}>
                                                    {video.title}
                                                </Menu.Item>
                                            );
                                        })}
                                    </Menu.ItemGroup>
                                </SubMenu>
                            );
                        })}
                    </Menu.ItemGroup>
                </>
            );
        } else {
            return (
                <Menu.Item key="collection">
                    Loading <LoadingOutlined />
                </Menu.Item>
            );
        }
    };

    renderDashboardHomeActions = () => {
        if (
            this.props.isAuthenticated &&
            this.props.selectedCoupon &&
            (this.props.currentUser.id ==
                this.props.selectedCoupon.createdById ||
                this.props.currentUser.id ==
                    this.props.selectedCoupon.coCreator1 ||
                this.props.currentUser.id ==
                    this.props.selectedCoupon.coCreator2) &&
            this.props.dashboardPoV == "instructor"
        ) {
            return (
                <Button
                    type="dashed"
                    onClick={() => {
                        // set POV to student
                        this.props.setDashboardPoV("student");
                        message.warning(
                            <Space>
                                You are now viewing the dashboard from the{" "}
                                <Text strong>students'</Text> perspective
                                <Button
                                    danger
                                    onClick={() => {
                                        this.props.setDashboardPoV(
                                            "instructor"
                                        );
                                        message.destroy();
                                    }}>
                                    Exit Student View
                                </Button>
                            </Space>,
                            0
                        );
                    }}>
                    Preview As Student
                </Button>
            );
        } else {
            return;
        }
    };
    render() {
        if (this.props.currentUser == null) {
            return <></>;
        }

        const onChangeCoupon = () => {
            this.props.history.push("/dashboard/");
            this.setState({
                currentTab: "home"
            });
        };

        if (
            this.props.dashboardPoV == "instructor" &&
            this.props.selectedCoupon &&
            (this.props.currentUser.id ==
                this.props.selectedCoupon.createdById ||
                this.props.currentUser.id ===
                    this.props.selectedCoupon.coCreator1 ||
                this.props.currentUser.id ===
                    this.props.selectedCoupon.coCreator2)
        ) {
            return (
                <>
                    <PageHeader
                        title="Dashboard"
                        ghost={false}
                        onBack={() => window.history.back()}
                        extra={[
                            this.renderDashboardHomeActions(),
                            <Space>
                                Select a class
                                <ClassCouponDropdown
                                    userLicenses={this.props.userLicenses}
                                    onChangeCoupon={onChangeCoupon}
                                />
                            </Space>
                        ]}
                        className="dashboard-pageheader">
                        <Menu
                            selectedKeys={[this.state.currentTab]}
                            mode="horizontal"
                            overflowedIndicator={<MenuOutlined />}>
                            <Menu.Item
                                key="home"
                                onClick={() => {
                                    this.props.history.push("/dashboard/");
                                    this.setState({
                                        currentTab: "home"
                                    });
                                }}>
                                Home
                            </Menu.Item>
                            <Menu.Item
                                key="students"
                                onClick={() => {
                                    this.props.history.push(
                                        "/dashboard/students/"
                                    );
                                    this.setState({
                                        currentTab: "students"
                                    });
                                }}>
                                Student Analytics
                            </Menu.Item>
                            <Menu.Item
                                key="videos"
                                onClick={() => {
                                    this.props.history.push(
                                        "/dashboard/videos/"
                                    );
                                    this.setState({
                                        currentTab: "videos"
                                    });
                                }}>
                                Video Analytics
                            </Menu.Item>
                            <SubMenu key="sub2" title="Q&A Section">
                                <Menu.Item
                                    key="qna"
                                    onClick={() => {
                                        this.props.history.push(
                                            "/dashboard/qna/"
                                        );
                                        this.setState({
                                            currentTab: "qna"
                                        });
                                    }}>
                                    Recent Activity
                                </Menu.Item>
                                {this.renderQnACollectionMenu()}
                            </SubMenu>
                            <Menu.Item
                                key="reports"
                                onClick={() => {
                                    this.props.history.push(
                                        "/dashboard/reports/"
                                    );
                                    this.setState({
                                        currentTab: "reports"
                                    });
                                }}>
                                Generated Reports
                            </Menu.Item>
                        </Menu>
                    </PageHeader>
                    <br />
                </>
            );
        } else {
            return (
                <>
                    <PageHeader
                        title="Dashboard"
                        ghost={false}
                        onBack={() => window.history.back()}
                        extra={[
                            this.renderDashboardHomeActions(),
                            <Space>
                                Select a class
                                <ClassCouponDropdown
                                    userLicenses={this.props.userLicenses}
                                    onChangeCoupon={onChangeCoupon}
                                />
                            </Space>
                        ]}
                        className="dashboard-pageheader">
                        <Menu
                            selectedKeys={[this.state.currentTab]}
                            mode="horizontal"
                            overflowedIndicator={<MenuOutlined />}>
                            <Menu.Item
                                key="home"
                                onClick={() => {
                                    this.props.history.push("/dashboard/");
                                    this.setState({
                                        currentTab: "home"
                                    });
                                }}>
                                Home
                            </Menu.Item>

                            <Menu.Item
                                key="students"
                                onClick={() => {
                                    this.props.history.push(
                                        `/dashboard/students/${this.props.currentUser.id}/`
                                    );

                                    this.setState({
                                        currentTab: "students"
                                    });
                                }}>
                                Student Analytics
                            </Menu.Item>
                            <Menu.Item
                                key="videos"
                                onClick={() => {
                                    this.props.history.push(
                                        "/dashboard/videos/"
                                    );
                                    this.setState({
                                        currentTab: "videos"
                                    });
                                }}>
                                Video Analytics
                            </Menu.Item>

                            <SubMenu key="sub2" title="Q&A Section">
                                <Menu.Item
                                    key="qna"
                                    onClick={() => {
                                        this.props.history.push(
                                            "/dashboard/qna/"
                                        );
                                        this.setState({
                                            currentTab: "qna"
                                        });
                                    }}>
                                    Recent Activity
                                </Menu.Item>
                                {this.renderQnACollectionMenu()}
                            </SubMenu>
                        </Menu>
                    </PageHeader>
                    <br />
                </>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.userReducer.isAuthenticated,
        currentUser: state.userReducer.user,
        collection: state.dashboardReducer.collection,
        selectedCoupon: state.dashboardReducer.selectedCoupon,
        dashboardPoV: state.userReducer.dashboardPoV
    };
};

export default connect(mapStateToProps, { setDashboardPoV })(
    withRouter(DashboardPageHeader)
);
