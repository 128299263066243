const initialState = {
    accountTypeRegistrationStatus: "NOT LOADED",
    dashboardPoV: null,
    user: null,
    username: '',
    password: '',
    requireLogin: false,
    redirectAfterLogout: false,
    fetchingUserStatus: "NOT LOADED",
    isAuthenticated: false,
    activeCoupon: null,
    departments: [],
    scheduledLicenses: [],
    enrolledLicenses: [],
    couponCodeError: null,
    universities: [],
    fetchEnrolledLicensesStatus: "NOT LOADED",
    fetchPurchasedCollectionStatus: "NOT LOADED",
    purchasedCollections: null,
}

export default (state=initialState, action) => {
    switch(action.type){
        case "ADD_NEW_ENROLLED_LICENSE":
            return Object.assign({}, state, { enrolledLicenses: [...state.enrolledLicenses, action.licenseData]})
        case "APPEND_NEW_TRANSACTION":
            return {
                ...state,
                user: {
                    ...state.user,
                    primaryWallet: {
                        ...state.user.primaryWallet,
                        transactions: [action.transactionData, ...state.user.primaryWallet.transactions]
                    }
                }
            }
        case "CLEAR_ACTIVE_COUPON":
            return Object.assign({}, state, {activeCoupon: null})
        case "LOGOUT_USER":
            return {
                isAuthenticated: false,
                user: null,
                redirectAfterLogout: true
            }
        case "REVOKE_MODULE_ACCESS":
            action.moduleIds.forEach(moduleId => delete state.user.moduleAccess[moduleId])
            return state
        case "SET_DASHBOARD_POV":
            return Object.assign({}, state, {
                dashboardPoV: action.pov
            })
        case "SET_ACCOUNT_TYPE_REGISTRATION_STATUS":
            return Object.assign({}, state, {
                accountTypeRegistrationStatus: action.status
            })
        case "SET_ACTIVE_COUPON":
            return Object.assign({}, state, {activeCoupon: action.coupon})
        case "SET_COUPON_CODE_ERROR":
            return Object.assign({}, state, { couponCodeError: action.couponCodeError})
        case "SET_DEPARTMENTS":
            return Object.assign({}, state, { departments: action.departments})
        case "SET_USER":
            return Object.assign({}, state, {user: action.user, isAuthenticated: true})
        case "SET_REDIRECT_AFTER_LOGOUT":
            return Object.assign({}, state, {redirectAfterLogout: action.status})
        case "SET_SCHEDULED_LICENSES":
            return Object.assign({}, state, {scheduledLicenses: action.scheduledLicenses})
        case "SET_REQUIRE_LOGIN":
            return Object.assign({}, state, {requireLogin: action.status})
        case "SET_ENROLLED_LICENSES":
            return Object.assign({}, state, {enrolledLicenses: action.licenses})
        case "SET_FETCH_ENROLLED_LICENSES_STATUS":
            return Object.assign({}, state, {fetchEnrolledLicensesStatus: action.status})
        case "SET_FETCHING_USER_STATUS":
            return Object.assign({}, state, {
                fetchingUserStatus: action.status
            })
        case "SET_FETCH_PURCHASED_COLLECTION_STATUS":
            return Object.assign({}, state, { fetchPurchasedCollectionStatus: action.status})
        case "SET_UNIVERSITIES":
            return Object.assign({}, state, { universities: action.universities})
        case "SET_USER_PURCHASED_COLLECTIONS":
            return Object.assign({}, state, { purchasedCollections: action.collections})
        case "UPDATE_COUPON_DATA_ACCESS_PREFERENCE":
            var index = state.scheduledLicenses.findIndex((usedCoupon => usedCoupon.id === action.licenseId))
            state.scheduledLicenses[index].dataAccessLevel = action.dataAccessValue
            return state
        case "UPDATE_WALLET_BALANCE":
            return {
                ...state,
                user: {
                    ...state.user,
                    primaryWallet: {
                        ...state.user.primaryWallet,
                        cupointsBalance: action.cupoints + state.user.primaryWallet.cupointsBalance
                    }
                }
            }
        case "UPDATE_REFUNDABLE_TO_FALSE":
            var index = state.user.primaryWallet.transactions.findIndex((transaction => transaction.orderId === action.transactionId))
            state.user.primaryWallet.transactions[index].refundable = false
            return state
        default:
            return state
    }
}