import { DownloadOutlined, FileAddOutlined, PlusCircleOutlined, PlusOutlined, SwapRightOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, DatePicker, Tag, Table, Tooltip, Typography } from 'antd';
import { Component, useState } from 'react';
import { connect } from 'react-redux';
import { fetchDashboardReports, requestDashboardReport } from '../../actions/dashboard';

const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

class DashboardGeneratedReports extends Component {
    constructor(props){
        super(props)
        this.state = {
            isModalVisible: false,
            indeterminate: true,
            checkAll: false,
            checkedList: [""],
            dates: [],
        }
    }

    showModal = () => {
        this.setState({
            isModalVisible: true
        })
    }
    handleOk = () => {
        this.setState({
            isModalVisible: false
        })
    }

    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }

    onChange = list => {
        this.setState({
            checkedList: list,
            indeterminate: !!list.length && list.length < this.plainOptions.length,
            checkAll: list.length === this.plainOptions.length
        })
    };
    plainOptions = ['Apple']
    onCheckAllChange = (e) => {
        this.setState({
            checkedList: e.target.checked ? this.plainOptions : [],
            indeterminate: false,
            checkAll: e.target.checked
        })
    }


    columns = [
        {
          title: 'Date Requested',
          dataIndex: 'dateRequested',
          key: 'dateRequested',
          render: (requestDate) => {
            var date = new Date(requestDate)
            return <Text>{date.toDateString()}</Text>
            } 
        },
        {
            title: 'Date Range',
            key: 'dateRange',
            render: (instance) => {
                var startDate = new Date(instance.startDate)
                var endDate = new Date(instance.endDate)
                return <Text>{`${startDate.toDateString()}`} <SwapRightOutlined /> {`${endDate.toDateString()}`}</Text>
                } 
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                if(status == "completed"){
                   return <Tag color="#108ee9">{status.charAt(0).toUpperCase() + status.slice(1)}</Tag>
                }
                if(status == "requested") {
                    return <Tag color="#f7d53b">{status.charAt(0).toUpperCase() + status.slice(1)}</Tag>
                }
                if(status == "failed") {
                    return <Tag color="#cd201f">{status.charAt(0).toUpperCase() + status.slice(1)}</Tag>
                }
                if(status == "loading")  {
                    return <Tag color="#87d068">{status.charAt(0).toUpperCase() + status.slice(1)}</Tag>
                }
            }
        },
        {
          title: 'Report',
          dataIndex: 'document',
          key: 'document',
          render: (document) => {
              if(document != null){
                return <Button 
                            type="primary" 
                            shape="round" 
                            icon={<DownloadOutlined />}
                            href={document}
                        >
                            Download
                        </Button>
              } else {
                return ""
              }
          }
        },
    ]

    data = [
        {
            key: '1',
            date: '10/21/21',
            document: 'linkTo',
        },
    ];


    componentDidMount(){
        if(this.props.selectedCoupon){
            this.props.fetchDashboardReports(this.props.selectedCoupon.code)
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.selectedCoupon != this.props.selectedCoupon){
            this.props.fetchDashboardReports(this.props.selectedCoupon.code)
        }
    }

    disabledDate = current => {
        if (!this.state.dates || this.state.dates.length === 0) {
          return false;
        }
        const tooLate = this.state.dates[0] && current.diff(this.state.dates[0], 'days') > 14;
        const tooEarly = this.state.dates[1] && this.state.dates[1].diff(current, 'days') > 14;
        return tooEarly || tooLate;
    };

    onOpenChange = open => {
        if (open) {
            this.setState( { dates: [], hackValue: [] });
        } else {
            this.setState( { hackValue: undefined });
        }
    };

    handleOnGenerateReportClick = () => {
        return this.props.requestDashboardReport(this.props.selectedCoupon.code, this.state.dates[0].startOf('day').toISOString(), this.state.dates[1].endOf('day').toISOString())
    }
    
    render(){
        return <Card className="dashboard-card">
   
                <Title level={5}>Reports</Title>
                <Text italic>Choose a date range no longer than 2 weeks</Text><br/>
                <RangePicker
                    value={this.state.hackValue || this.state.value}
                    disabledDate={this.disabledDate}
                    onCalendarChange={val => this.setState( {dates: val} )}
                    onChange={val => this.setState( { value: val } )}
                    onOpenChange={this.onOpenChange}
                />
                
                <Button 
                    type="primary" 
                    icon={<FileAddOutlined />}
                    onClick={this.handleOnGenerateReportClick}
                    loading={this.props.dashboardReportRequestStatus == "LOADING"}
                    disabled={!this.state.dates.length}
                >
                    Generate Report
                </Button>

            {
                /*<Modal title="Choose the fields you want" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
                <div style={{padding: "25px"}}>

                    <Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckAllChange} checked={this.state.checkAll}>
                        Check all
                    </Checkbox>
                    <Divider />
                    <CheckboxGroup options={this.plainOptions} value={this.state.checkedList} onChange={this.onChange} />
                </div>
            </Modal>
            */}
            
            <br/><br/>
            <Text strong>Note: <Text code>Total Watch Time</Text> is the time watched within the date range. All other fields are statistics for the <Text italic>entire term</Text>, as indicated by the coupon dates.</Text>
            <Table columns={this.columns} dataSource={this.props.dashboardReports} />
        </Card>
    }
}

const mapStateToProps = state => {
    return {
        selectedCoupon: state.dashboardReducer.selectedCoupon,
        dashboardReports: state.dashboardReducer.dashboardReports,
        dashboardReportsStatus: state.dashboardReducer.dashboardReportsStatus,
        dashboardReportRequestStatus: state.dashboardReducer.dashboardReportRequestStatus
    }
}
export default connect(mapStateToProps, { fetchDashboardReports, requestDashboardReport })(DashboardGeneratedReports);