import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    Form,
    Input,
    Modal,
    Space,
    Tooltip,
    Typography
} from "antd";
import {
    CheckCircleOutlined,
    ThunderboltOutlined,
    UnlockOutlined
} from "@ant-design/icons";
import "../Components.less";
import TopUpForm from "../Wallet/TopUpForm";
import {
    purchaseModule,
    setCouponCodeError,
    verifyCoupon
} from "../actions/user";
import { connect } from "react-redux";
import { API_URL } from "../const";
import Cupoints from "./Cupoints";

const { Search } = Input;
const { Paragraph, Text, Title } = Typography;

const LockedVideoCardModalContent = ({
    collection,
    couponCodeError,
    currentUser,
    isAuthenticated,
    moduleAccess,
    module,
    moduleName,
    price,
    purchaseModule,
    setCouponCodeError,
    verifyCoupon
}) => {
    const [purchaseLoading, setPurchaseLoading] = useState(false);
    const [showTopUpModal, setShowTopUpModal] = useState(false);
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [classCodeLoading, setClassCodeLoading] = useState(false);
    const [signedUpForClass, setSignedUpForClass] = useState(false);
    const [classCodeData, setClassCodeData] = useState(null);

    const handleOnFinish = (values) => {
        if (!isAuthenticated) {
            localStorage.setItem("couponCode", values["couponCode"]);
            window.location.href =
                API_URL + `/accounts/login/?next=${window.location.href}`;
            return null;
        } else {
            setClassCodeLoading(true);
            setCouponCodeError(null);

            const successfulSignUp = (couponData) => {
                setClassCodeData(couponData);
                setSignedUpForClass(true);
            };
            verifyCoupon(values["couponCode"], successfulSignUp, () => {
                setClassCodeLoading(false);
            });
        }
    };

    const handleOnSearch = () => {
        form.submit();
    };

    const handleOnCancel = () => {
        setClassCodeData(null);
        setSignedUpForClass(false);
        setVisible(false);
        form.resetFields();
    };

    const handleOnChange = () => {
        setCouponCodeError(null);
    };

    const InsufficientBalanceModalContent = () => {
        if (
            moduleAccess[module.id] &&
            moduleAccess[module.id].accessType == "permanent"
        ) {
            return (
                <div className="modal-body">
                    <Space>
                        <Title level={3}>
                            <UnlockOutlined />
                        </Title>
                        <Title level={3}>All videos are now unlocked.</Title>
                    </Space>
                    <br />
                    <Text>Thank you for your purchase!</Text>
                </div>
            );
        } else if (currentUser.primaryWallet.cupointsBalance >= price) {
            return (
                <div style={{ padding: 25 }}>
                    <Space>
                        <Title level={3}>
                            <CheckCircleOutlined />
                        </Title>
                        <Title level={3}>Your balance is now sufficient</Title>
                    </Space>
                    <Space size={"large"}>
                        <Text type="secondary">Current Wallet Balance:</Text>
                        <Text type="secondary">
                            {currentUser.primaryWallet.cupointsBalance} cupoints
                        </Text>
                    </Space>
                    <br />
                    <br />
                    <Button
                        type="primary"
                        loading={purchaseLoading}
                        icon={<ThunderboltOutlined />}
                        onClick={handleOnPurchaseClick}>
                        <Cupoints points={price}>Buy module @</Cupoints>
                    </Button>
                </div>
            );
        } else {
            return (
                <div style={{ padding: 25 }}>
                    <Title level={3}>Insufficient Balance</Title>
                    <Space size={"large"}>
                        <Text>Looks like you are short</Text>
                        <Text>
                            {price - currentUser.primaryWallet.cupointsBalance}{" "}
                            cupoints
                        </Text>
                    </Space>

                    <br />
                    <Space size={"large"}>
                        <Text type="secondary">Current Wallet Balance:</Text>
                        <Text type="secondary">
                            {currentUser.primaryWallet.cupointsBalance} cupoints
                        </Text>
                    </Space>
                    <br />
                    <br />
                    <Space direction="vertical">
                        <Text>
                            Click the button below to refill your balance.
                        </Text>
                        <TopUpForm marginTop={0} />
                    </Space>
                </div>
            );
        }
    };

    const handleOnPurchaseClick = () => {
        // check for balance
        if (currentUser.primaryWallet.cupointsBalance >= price) {
            purchaseModule(module.id, setPurchaseLoading);
        } else {
            setShowTopUpModal(true);
        }
    };

    const Content = () => {
        if (!isAuthenticated) {
            return (
                <>
                    <Title level={4}>This video is locked</Title>
                    <Text type="warning">
                        You must be signed in to make a purchase
                    </Text>
                    <Paragraph>
                        <Space direction="vertical">
                            <Text>
                                Unlock <Text italic>temporarily</Text> with a
                                class code:
                            </Text>
                            <Form form={form} onFinish={handleOnFinish}>
                                <Form.Item
                                    name="couponCode"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please input a coupon code!"
                                        }
                                    ]}
                                    style={{ margin: 0 }}>
                                    <Search
                                        placeholder="Class Code"
                                        allowClear
                                        enterButton="Submit"
                                        onSearch={handleOnSearch}
                                        loading={classCodeLoading}
                                        onChange={handleOnChange}
                                        disabled
                                    />
                                </Form.Item>
                                <Text type="danger">{couponCodeError}</Text>
                            </Form>
                        </Space>
                    </Paragraph>

                    <Paragraph>
                        <Space direction="vertical">
                            <Text>
                                Or buy the module <Text code>{moduleName}</Text>{" "}
                                <Text italic>permanently</Text>
                            </Text>
                            <Button
                                type="primary"
                                onClick={handleOnPurchaseClick}
                                loading={purchaseLoading}
                                disabled>
                                <Cupoints points={price}>Buy @</Cupoints>
                            </Button>
                            <Modal
                                visible={showTopUpModal}
                                onCancel={() => setShowTopUpModal(false)}
                                maskClosable={false}
                                footer={null}>
                                <InsufficientBalanceModalContent />
                            </Modal>
                        </Space>
                    </Paragraph>
                </>
            );
        }
        if (signedUpForClass) {
            const renderDates = (moduleId) => {
                let foundIndex =
                    classCodeData.coupon.scheduledAccesses.findIndex(
                        (access) => access.module == moduleId
                    );
                if (foundIndex != -1) {
                    let startDate = new Date(
                        classCodeData.coupon.scheduledAccesses[
                            foundIndex
                        ].accessFrom
                    );
                    startDate = new Date(
                        startDate.getTime() +
                            startDate.getTimezoneOffset() * 60000
                    );
                    startDate.setHours(0, 0, 0, 0);

                    let endDate = new Date(
                        classCodeData.coupon.scheduledAccesses[
                            foundIndex
                        ].accessUntil
                    );
                    endDate = new Date(
                        endDate.getTime() + endDate.getTimezoneOffset() * 60000
                    );
                    endDate.setHours(23, 59, 59, 999);
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                            <Text>
                                <Text
                                    code>{`${startDate.toLocaleString()}`}</Text>{" "}
                                to
                            </Text>

                            <Text>
                                <Text
                                    code>{`${endDate.toLocaleString()}`}</Text>
                            </Text>
                        </div>
                    );
                } else {
                    return "---";
                }
            };
            return (
                <div>
                    <Title>
                        <CheckCircleOutlined className="success-animation" />{" "}
                        Enrolled!
                    </Title>
                    <Paragraph>
                        You have now enrolled in a new class using the coupon
                        code <Text code>{classCodeData.coupon.code}</Text>
                    </Paragraph>
                    <Paragraph>
                        <Text>
                            You will now temporarily have free access to the
                            following modules according to the dates outlined
                            below.
                        </Text>
                        <br />
                        <Text>
                            You can view these dates at any time by clicking "My
                            Classes" in the menu in the top right of your
                            screen.
                        </Text>
                    </Paragraph>

                    <Paragraph>
                        <Text>
                            Additionally, you will now be able to participate in
                            Q&A's with other members of your class and view your
                            progression using our dashboard.
                        </Text>
                    </Paragraph>

                    <Paragraph>
                        <Title level={5}>
                            Class Label: {classCodeData.coupon.label}
                        </Title>
                        <div className="coupon-module-dates-available coupon-module-dates-available-header">
                            <Text>Module</Text>
                            <Text>Dates Available</Text>
                        </div>
                        {collection.modules.map((module) => {
                            return (
                                <div
                                    className="coupon-module-dates-available"
                                    key={module.id}>
                                    <Text>{module.name}</Text>
                                    <Text>{renderDates(module.id)}</Text>
                                </div>
                            );
                        })}
                    </Paragraph>
                </div>
            );
        } else {
            return (
                <>
                    <Title level={4}>This video is locked</Title>
                    <Paragraph>
                        <Space direction="vertical">
                            <Text>
                                Unlock <Text italic>temporarily</Text> with a
                                class code:
                            </Text>
                            <Form form={form} onFinish={handleOnFinish}>
                                <Form.Item
                                    name="couponCode"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please input a coupon code!"
                                        }
                                    ]}
                                    style={{ margin: 0 }}>
                                    <Search
                                        placeholder="Class Code"
                                        allowClear
                                        enterButton="Submit"
                                        onSearch={handleOnSearch}
                                        loading={classCodeLoading}
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                                <Text type="danger">{couponCodeError}</Text>
                            </Form>
                        </Space>
                    </Paragraph>

                    <Paragraph>
                        <Space direction="vertical">
                            <Text>
                                Or buy the module <Text code>{moduleName}</Text>{" "}
                                <Text italic>permanently</Text>
                            </Text>
                            <Button
                                type="primary"
                                onClick={handleOnPurchaseClick}
                                loading={purchaseLoading}>
                                <Cupoints points={price}>Buy @</Cupoints>
                            </Button>
                            <Modal
                                visible={showTopUpModal}
                                onCancel={() => setShowTopUpModal(false)}
                                maskClosable={false}
                                footer={null}>
                                <InsufficientBalanceModalContent />
                            </Modal>
                        </Space>
                    </Paragraph>
                </>
            );
        }
    };
    return <Content />;
};

const mapStateToProps = (state) => {
    return {
        couponCodeError: state.userReducer.couponCodeError,
        currentUser: state.userReducer.user,
        isAuthenticated: state.userReducer.isAuthenticated
    };
};

export default connect(mapStateToProps, {
    setCouponCodeError,
    purchaseModule,
    verifyCoupon
})(LockedVideoCardModalContent);
