import { Button } from "antd";
let firstExampleRendered = false;

const BulkJsonDownloadButton = ({ collection, setPreviewData }) => {
    const getQuestionTemplate = (video) => {
        if (firstExampleRendered) return [];
        firstExampleRendered = true;

        return [
            {
                questionType: "select_one|choose_all_that_apply|open_ended",
                title: "Enter a question title",
                text: "Question Text",
                options: [
                    {
                        correctAnswerFlag: false,
                        optionText: "Option Text",
                        explanationText: "Option Explanation"
                    }
                ],
                startVideoTimestamp: 0,
                endVideoTimestamp: video.duration
            }
        ];
    };

    const extractJson = (withQuestions = true) => {
        return collection.modules.map((m) => {
            return {
                uid: m.id,
                module: m.name,
                videos: m.videos.map((v) => {
                    return {
                        uid: v.id,
                        video: v.title,
                        questions: withQuestions ? getQuestionTemplate(v) : []
                    };
                })
            };
        });
    };

    const downloadJson = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(extractJson(), null, "\t")
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "sample_json.json";
        link.click();

        firstExampleRendered = false;
    };

    const manage = () => {
        const json = extractJson(false);
        setPreviewData(json);
    };

    return (
        <>
            <Button onClick={downloadJson}>Download Sample JSON</Button>
            <Button className="ml-10" onClick={manage}>
                Manage Bulk Questions
            </Button>
        </>
    );
};

export default BulkJsonDownloadButton;
