/******************************************************************
VideoDisplay.js
Written by Adam Gamba, Summer 2021

Displays requested video information on individual video page, including
embedded video and heatmap of watch time per minute bucket
******************************************************************/

import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import VideoHeatMap from "./VideoHeatMap";
import SimpleVimeoPlayer from "../SimpleVimeoPlayer";
import SimpleYouTubePlayer from "../SimpleYouTubePlayer";
import "../Home.css";
import { Card, Empty, Skeleton, Typography } from "antd";
import { fetchVideoRententionData } from '../../actions/dashboard'; 


const { Title } = Typography;
// VideoInfoCard code commented out - for use later
class VideoDisplay extends Component {
  // Example selectedObject: {"collectionID":5,"collection":"Computer
  // Science: An Interdisciplinary
  // Approach","moduleID":8,"module":"Conditionals &
  // Loops","videoID":18,"video":"Conditionals: The IF Statement"}

  constructor(props){
    super(props);
    this.state = {
      totalViewers: 0, // Number of unique viewes of video
      videoType: "",
      embedId: "", // Youtube embed id
      selectedDates: props.selectedDates,
      // Heatmap data and labels
      chartLabels: [],
      chartData: [],
    }
  }

  componentDidMount() {
    if(this.props.selectedObject){
      let coupon = this.props.selectedCoupon.code;
      /*
      props.selectedDates[0] !== selectedDates[0] ||
        props.selectedDates[1] !== selectedDates[1]
       setSelectedDates([
          props.selectedDates[0],
          props.selectedDates[1],
        ]);*/
      this.props.fetchVideoRententionData(this.props.selectedObject.collectionId, this.props.selectedObject.moduleId, this.props.selectedObject.videoId, this.props.selectedDates[0], this.props.selectedDates[1], coupon, this.setVideoData)
    }
   
  }

  componentDidUpdate(prevProps) {
    if(this.props.selectedCoupon && ((prevProps.selectedObject == null && this.props.selectedObject != null) || ((prevProps.selectedObject != null && this.props.selectedObject != null) && 
      (prevProps.selectedObject.collectionId !== this.props.selectedObject.collectionId || prevProps.selectedObject.moduleId !== this.props.selectedObject.moduleId || prevProps.selectedObject.videoId !== this.props.selectedObject.videoId) ||
      prevProps.selectedDates != this.props.selectedDates))){
      let coupon = this.props.selectedCoupon.code;
      /*
      props.selectedDates[0] !== selectedDates[0] ||
        props.selectedDates[1] !== selectedDates[1]
       setSelectedDates([
          props.selectedDates[0],
          props.selectedDates[1],
        ]);*/
       
       
      this.props.fetchVideoRententionData(this.props.selectedObject.collectionId, this.props.selectedObject.moduleId, this.props.selectedObject.videoId, this.props.selectedDates[0], this.props.selectedDates[1], coupon, this.setVideoData)
    }
  }


  formattingFunction = (seconds) => {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  };

  setVideoData = (data) => {
    let videoBuckets = [];
    let counts = [];
    const NUM_STUDENTS = this.props.numStudents;

    data["buckets"].forEach((item) => {

      videoBuckets.push(this.formattingFunction(item["bucket"]));
      counts.push(item["unique_viewers"] / NUM_STUDENTS);
    });

    this.setState({
      embedId: this.props.videoList.filter(
        (x) => x.videoTitle === this.props.selectedObject.videoTitle
      )[0].embedId,
      videoType: this.props.videoList.filter(
        (x) => x.videoTitle === this.props.selectedObject.videoTitle
      )[0].videoType,
      totalViewers: data["total_viewers"],
      chartLabels: videoBuckets,
      // Convert to % and round to integer
      chartData: counts.map((x) => Math.round(x * 100)),
    })

    // Update contents of info card, after data is fetched
    // cardTabContents: [
    //   <p>[DESCRIPTION HERE]</p>,
    //   <p>
    //     Total Unique Viewers: {totalViewers} <br />
    //     Video Length: ____ <br />
    //     Pie chart of Video watchspeeds (1x, 2x, etc) <br />
    //     Date posted <br />
    //     % Student who watched the bulk of it <br />
    //     Likes, Dislikes, Comments, Questions (possibly in another tab)
    //     <br />I wanna include some smaller graphs within this section
    //     that are only loaded when the 'stats' tab is clicked, not to
    //     overrun the loading process like the previous screen that was
    //     all pie charts <br />
    //     Some other ideas: <br />
    //     -Stats on % correct on each video question (could be this tab
    //     or another) <br />
    //     -Average watch speed for each minute of video (could show the
    //     instructor what parts of the video are either boring or not
    //     paid as much attention due to higher video speed)
    //   </p>]
  };

  // Update state with chosen tab of current video
  // const onTabChange = (tab) => {
  //   this.setState({ currentCardTab: tab });
  // };

  // Card tab keys and names
  // Keys are indices and tabs are displayed names
  // const cardTabList = [
  //   {
  //     key: 0,
  //     tab: "Description",
  //   },
  //   {
  //     key: 1,
  //     tab: "Stats",
  //   },
  // Could inclue grade tab, timestamped comments/questions and more in the future
  // ];

  renderVideoPlayer = () => {
    if(this.state.videoType === "youtube"){
      return <SimpleYouTubePlayer  
               embedId={this.state.embedId}
            /> 
    } else if(this.state.videoType === "vimeo"){
      return <SimpleVimeoPlayer 
                embedId={this.state.embedId}
              />
    }else {
      return <p>Incorrect video type</p>
    }
  }
  render(){
    const videoHeight = 500;
    if(this.props.selectedObject == null || (!this.state.embedId.length && !this.state.videoType.length)){
      return <Card><Skeleton active/></Card>
    }
    if (this.state.chartData.length === 0) {
      return (
        <section>
          <Title level={5}>
            {` ${this.props.selectedObject.moduleName}:
            ${this.props.selectedObject.videoTitle}`}
          </Title>
          <h3 className="flex-center">
            Total Unique Viewers: {this.state.totalViewers}
          </h3>
          <hr />
          <div className="flex-center">
            {/* Embedded YouTube video display */}
          
            {this.renderVideoPlayer()}
          </div>
          <Empty
            description={`No Watch Data for Selected Period`}
            style={{ padding: "40px" }}
          />
          ;
        </section>
      );
    }
    return (
      <section>
        <Title level={5}>
            {` ${this.props.selectedObject.moduleName}:
            ${this.props.selectedObject.videoTitle}`}
          </Title>
        <h3 className="flex-center">
          Total Unique Viewers: {this.state.totalViewers}
        </h3>
        <hr />
        <div className="flex-center">
          {/* Embedded YouTube video display */}
          {this.renderVideoPlayer()}
          {/* Info card row */}
          {/* <Card
              title={`${this.props.selectedObject.module}:
            ${this.props.selectedObject.video}`}
              tabList={cardTabList}
              // Get 0, 1, or 2 (3-tab setup) from array
              activeTab={this.state.currentCardTab}
              onTabChange={(tab) => {
                this.onTabChange(tab);
              }}
              hoverable
              bodyStyle={{
                overflow: "auto",
                height: "100%",
                width: "80vw",
              }}
              style={{
                marginTop: "1%",
              }}
            >
              {this.state.cardTabContents[this.state.currentCardTab]}
            </Card> */}
        </div>
        <div>
          {/* Heat map of video watch times */}
          <VideoHeatMap
            labels={this.state.chartLabels}
            data={this.state.chartData}
            video={this.props.selectedObject}
            width="80%"
            height="200px"
            homePage={false}
          />
        </div>
      </section>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    numStudents: state.dashboardReducer.numStudents,
    selectedCoupon: state.dashboardReducer.selectedCoupon,
    videoList: state.dashboardReducer.videoList
    // roster: state.graphsReducer.roster,
  };
};

export default connect(mapStateToProps, {fetchVideoRententionData})(VideoDisplay);
