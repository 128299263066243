import React, { createContext, useState, useMemo } from "react";
import MessageBox from "./Message.js";
import "./style.css";
import { useEffect } from "react";

export const GptContext = createContext({
    messages: [],
    setMessages: () => {},

    loading: false,
    setLoading: () => {},

    pauseVideo: () => {}
});

const ChatbotContainer = ({
    video,
    collection,
    pauseVideo = () => {},
    isCollectionOwner = false
}) => {
    let welcome = {
        user: "WELCOME",
        content: "Hi! How can I help you today?",
        mcq: false,
        element: 0, //0: welcome, 1: Summary, 2: question, 3: expand on
        elementId: 0,
        video: video,
        isCollectionOwner: isCollectionOwner
    };

    const [messages, setMessages] = useState([welcome]);
    const [loading, setLoading] = useState(false);
    const [module, setModule] = useState(null);

    const memoryValue = useMemo(
        () => ({
            messages,
            setMessages,
            loading,
            setLoading,
            video,
            module,
            setModule,
            collection,
            pauseVideo,
            isCollectionOwner
        }),
        [messages, loading, video, collection, module, isCollectionOwner]
    );

    useEffect(() => {
        const m = collection.modules.find((mod) => {
            return mod.videos.find((v) => v.id === video.id) !== undefined;
        });
        setModule(m);
    }, [collection]);

    return (
        <GptContext.Provider value={memoryValue}>
            <div id="chatbot-wrapper">
                <MessageBox />
            </div>
        </GptContext.Provider>
    );
};

export default ChatbotContainer;
