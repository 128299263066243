import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Typography
} from "antd";
import { useState } from "react";
const { Title } = Typography;

const inputMetaData = {
    description: {
        label: "Description",
        requiredMessage: "Please give your course a description!"
    },
    longDescription: {
        label: "Long Description",
        requiredMessage: "Please give your course a longer description!"
    }
};

const DescriptionEditModal = ({
    title,
    field,
    visible,
    setVisibility,
    initialValues,
    onFinish
}) => {
    // Description Edit Form
    const [descriptionForm] = Form.useForm();
    const [descriptionFormInitialValues, setDescriptionFormInitialValues] =
        useState(initialValues);
    const [metaData, setMetaData] = useState(inputMetaData[field]);

    // On finish description form
    const onFinishDescriptionForm = (values) => {
        onFinish(values);
        setVisibility(false);
    };

    return (
        <Modal
            visible={visible}
            footer={null}
            onCancel={() => setVisibility(false)}
            maskClosable={false}>
            <div className="add-module-modal">
                <Title level={4}>{title}</Title>
                <br />
                <Row gutter={16}>
                    <Col span={24}>
                        <Form
                            form={descriptionForm}
                            name="basic"
                            layout="vertical"
                            initialValues={descriptionFormInitialValues}
                            onFinish={onFinishDescriptionForm}
                            autoComplete="off">
                            <Form.Item
                                label={metaData.label}
                                name={field}
                                rules={[
                                    {
                                        required: true,
                                        message: metaData.requiredMessage
                                    }
                                ]}>
                                <Input.TextArea />
                            </Form.Item>
                            <Divider style={{ margin: "10px 0px" }} />
                            <div className="price-breakdown-row">
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

export default DescriptionEditModal;
