import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchClassMedianTimeSpent } from '../../actions/dashboard';
import { Card, Skeleton, Typography } from 'antd';
import ClassMedianTimeGraph from './ClassMedianTimeGraph';


const { Text } = Typography;

class ClassMedianTimeSpent extends Component {
    componentDidMount(){
        if(this.props.selectedCoupon){
            this.props.fetchClassMedianTimeSpent(this.props.selectedCoupon.code, this.props.user.id)
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedCoupon != this.props.selectedCoupon){
            this.props.fetchClassMedianTimeSpent(this.props.selectedCoupon.code, this.props.user.id)
        }
    }

    formatData = (data) => {
        if(this.props.selectedCoupon.createdById == this.props.user.id && this.props.dashboardPoV == "instructor"){
            return data.flatMap(medianData => {
                return [{
                        'watchTime': medianData['classMedianTimeSpent']/60,
                        'week': `Week ${medianData['weekNumber']}`,
                        'name': 'Class Median'
                    },
                    {
                        'watchTime': parseFloat((medianData['classAverageTimeSpent']/60).toFixed(2)),
                        'week': `Week ${medianData['weekNumber']}`,
                        'name': 'Class Average'
                    }]
            })
        } else {
            return data.flatMap(medianData => {
                return [
                    {
                        'watchTime': parseFloat((medianData['classMedianTimeSpent']/60).toFixed(2)),
                        'week': `Week ${medianData['weekNumber']}`,
                        'name': 'Class Median'
                    },
                    {
                        'watchTime': parseFloat((medianData['userTimeSpent']/60).toFixed(2)),
                        'week': `Week ${medianData['weekNumber']}`,
                        'name': 'Your Activity'
                    }, 
                    {
                        'watchTime': parseFloat((medianData['classAverageTimeSpent']/60).toFixed(2)),
                        'week': `Week ${medianData['weekNumber']}`,
                        'name': 'Class Average'
                    }, 
                ]
            })
        }
    }
    render() {
        if(this.props.classMedianTimeSpentStatus == "LOADED"){
            
            return <Card className="dashboard-card" id="time-spent-per-week">
                    <Text>Time Spent per Week</Text>
                    <ClassMedianTimeGraph formattedData={this.formatData(this.props.classMedianTimeSpent)} />
                </Card>
        } else {
            return <Card className="dashboard-card" id="time-spent-per-week">
                <Skeleton active />
                <Skeleton active />
            </Card>
        }
    }
}

const mapStateToProps = state => {
    return {
        dashboardPoV: state.userReducer.dashboardPoV,
        selectedCoupon: state.dashboardReducer.selectedCoupon,
        classMedianTimeSpent: state.dashboardReducer.classMedianTimeSpent,
        classMedianTimeSpentStatus: state.dashboardReducer.classMedianTimeSpentStatus,
        user: state.userReducer.user,
    }
}
export default connect(mapStateToProps, { fetchClassMedianTimeSpent })(ClassMedianTimeSpent);