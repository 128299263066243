/******************************************************************
VideoCard.js
Written by Adam Gamba, Summer 2021

Single video informational card which links to its individual data
page
******************************************************************/

import { Component, useState } from "react";
import { clearVideoStatisticsDrawerData, fetchQnAStatistics, fetchQuizQuestionStatistics, fetchVideoStatistics } from '../../actions/dashboard';
import { connect } from 'react-redux';
import { withRouter, useHistory } from "react-router-dom";
import { Button, Card, Col, Divider, Drawer, Progress, Row, Space, Statistic, Tooltip, Typography } from "antd";
import { BarChartOutlined, FundViewOutlined, PlayCircleOutlined, SwapRightOutlined } from "@ant-design/icons";
import QuizQuestionStatistics from './QuizQuestionStatistics';
import "../Home.css";
import QnAStatistics from "./QnAStatistics";

const { Text } = Typography;

const determineProgressBarColor = (percentage) => {
  if(percentage == 100){
    return "#9FE2BE"
  }else if(percentage > 50 && percentage < 100) {
    return "#84AFEF"
  }else if(percentage > 25 && percentage <= 50) {
    return "#FFD177"
  }else {
    return "#E29F9F"
  }
}

// Info Card for individual video
class VideoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleStatisticsDrawer: false,
      quizQuestionIndex: 0
    }
  }


  componentDidUpdate(prevProps, prevState){
    if(prevState.visibleStatisticsDrawer != this.state.visibleStatisticsDrawer && this.state.visibleStatisticsDrawer){
      this.props.fetchVideoStatistics(this.props.module[0].collectionId, this.props.module[0].moduleId, this.props.video.videoId, this.props.selectedCoupon.code)
      this.props.fetchQnAStatistics(this.props.module[0].collectionId, this.props.module[0].moduleId, this.props.video.videoId, this.props.selectedCoupon.code)
      
      if(this.props.isDataLoaded && this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].quizQuestionIds.length) {
        let quizQuestionId = this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].quizQuestionIds[this.state.quizQuestionIndex];
        this.props.fetchQuizQuestionStatistics(this.props.module[0].collectionId, this.props.module[0].moduleId, this.props.video.videoId, quizQuestionId, this.props.selectedCoupon.code)
      }
    }else if(this.state.visibleStatisticsDrawer && this.props.isDataLoaded && prevState.quizQuestionIndex != this.state.quizQuestionIndex){
      let quizQuestionId = this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].quizQuestionIds[this.state.quizQuestionIndex];
      this.props.fetchQuizQuestionStatistics(this.props.module[0].collectionId, this.props.module[0].moduleId, this.props.video.videoId, quizQuestionId, this.props.selectedCoupon.code)
    }else if(prevState.visibleStatisticsDrawer != this.state.visibleStatisticsDrawer && !this.state.visibleStatisticsDrawer){
      this.props.clearVideoStatisticsDrawerData()
    }
  }
  
  render(){
      // Example video: { id: 10, title: "A Simple Machine" }
    return <Card style={{width: "25%", paddingBottom: "5px"}}>
            <div style={{display: "flex", marginBottom: "5px"}}>
              <div style={{textOverflow: "ellipsis", overflow:"hidden", whiteSpace: "nowrap", marginRight: "10px"}}>
                <Text style={{fontSize: "20px"}}>{`${this.props.video.videoTitle}`}</Text>
              </div>
              <Space>
                <Tooltip title="Play">
                  <Button 
                    type="primary" 
                    shape="circle" 
                    icon={<PlayCircleOutlined />} 
                    size="small"
                    onClick={()=>{
                      this.props.history.push(`/collections/${this.props.module[0].collectionId}/modules/${this.props.module[0].moduleId}/videos/${this.props.module[0].videoId}/${this.props.module[0].privacyScope == "unlisted" ? `?key=${this.props.module[0].unlistedKey}` : ""}`)
                    }}
                  />
                </Tooltip>
                <Tooltip title="View Statistics">
                  <Button 
                    icon={<BarChartOutlined />} 
                    shape="circle"
                    size="small"
                    onClick={()=>{
                      this.setState({
                        visibleStatisticsDrawer: true
                      });
                    }}
                  />
                </Tooltip>
              </Space>
            </div>
              <Text type="secondary">Watch Progress</Text>
              <br/>
              <Progress
                strokeWidth={12}
                strokeColor={determineProgressBarColor(this.props.isDataLoaded ? Math.round(this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].userWatchPercentage * 100) : 0)}
                percent={this.props.isDataLoaded ? Math.round(this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].userWatchPercentage * 100) : 0}
              />
              <Text type="secondary">Class Median Watch Progress</Text>
              <br/>
              <Progress
                strokeWidth={12}
                strokeColor={"#6A7079"}
                percent={this.props.isDataLoaded ? Math.round(this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].classMedianWatchPercentage * 100) : 0}
              />
              <Row style={{margin: "12px 0px 20px 0px"}}>
                <Col span={12}>
                  <Statistic 
                    title="Quiz Questions" 
                    value={this.props.isDataLoaded ? this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].quizQuestionsCorrectlyAnswered : 0} 
                    suffix={`/ ${this.props.isDataLoaded ? this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].totalNumberOfQuizQuestions : 0}`} 
                    className="dashboard-video-grid-statistic"
                  />
                </Col>
                <Col span={12}>
                  <Statistic 
                    title="Questions Asked"
                    value={this.props.isDataLoaded ? this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].totalNumberOfQnaQuestions : 0} 
                    className="dashboard-video-grid-statistic"
                  />
                </Col>
              </Row>
            
              <Drawer 
                title={this.props.video.videoTitle}
                placement="right" 
                visible={this.state.visibleStatisticsDrawer}
                onClose={()=>this.setState({
                  visibleStatisticsDrawer: false
                })}
                width={1400}
                maskClosable={false}
              >
                {this.props.video.videoTitle}
                <Row >
                  <Col span={8}>
                    <Text type="secondary">Watch Progress</Text>
                    <br/>
                    <Progress
                      strokeWidth={12}
                      strokeColor={determineProgressBarColor(this.props.isDataLoaded ? Math.round(this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].userWatchPercentage * 100) : 0)}
                      percent={this.props.isDataLoaded ? Math.round(this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].userWatchPercentage * 100) : 0}
                    />
                    <br/>
                    <br/>
                    <Text type="secondary">Class Median Watch Progress</Text>
                    <br/>
                    <Progress
                      strokeWidth={12}
                      strokeColor={"#6A7079"}
                      percent={this.props.isDataLoaded ? Math.round(this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].classMedianWatchPercentage * 100) : 0}
                    />
                  </Col>
                  <Col span={1}>

                  </Col>
                  <Col span={15}>
                    <Row>
                      <Col span={8}>
                        <Statistic 
                          loading={this.props.videoStatisticsStatus !== "LOADED"} 
                          precision={2}
                          title="Your Avg Playback Speed" value={this.props.videoStatisticsStatus == "LOADED" ? this.props.videoStatistics.userAvgPlaybackRate : -1} 
                          className="dashboard-video-grid-statistic"
                          suffix={"x"}
                        />
                      </Col>
                      <Col span={8}>
                        <Statistic 
                          title="Your Time Spent" 
                          value={this.props.videoStatisticsStatus == "LOADED" ? this.props.videoStatistics.userTimeSpent : -1} 
                          className="dashboard-video-grid-statistic"
                          loading={this.props.videoStatisticsStatus !== "LOADED"}
                          formatter={(value) => {
                            let remainder = value;
                            let hours = Math.floor(remainder / 3600);
                            remainder = remainder % 3600;
                            let minutes = Math.floor(remainder / 60);
                            let seconds = Math.floor(remainder % 60);
                            return `${hours}hr ${minutes}min ${seconds}s`
                          }}
                        />
                      </Col>
                      <Col span={8}>

                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col span={8}>
                        <Statistic 
                          precision={2}
                          loading={this.props.videoStatisticsStatus !== "LOADED"} 
                          title="Class Median Playback Speed" value={this.props.videoStatisticsStatus == "LOADED" ? this.props.videoStatistics.classAvgPlaybackRate : -1} 
                          className="dashboard-video-grid-statistic"
                          suffix={"x"}
                        />
                      </Col>
                      <Col span={8}>
                        <Statistic 
                          title="Class Median TimeSpent" 
                          value={this.props.videoStatisticsStatus == "LOADED" ? this.props.videoStatistics.classMedianWatchTime : -1} 
                          className="dashboard-video-grid-statistic"
                          loading={this.props.videoStatisticsStatus !== "LOADED"}
                          formatter={(value) => {
                            let remainder = value;
                            let hours = Math.floor(remainder / 3600);
                            remainder = remainder % 3600;
                            let minutes = Math.floor(remainder / 60);
                            let seconds = Math.floor(remainder % 60);
                            return `${hours}hr ${minutes}min ${seconds}s`
                          }}
                        />
                      </Col>
                      <Col span={8}>

                      </Col>
                    </Row>
                  </Col>
                  
                  <Col span={6}>
                    
                    
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={3}>
                    <Statistic
                    title="Quiz Questions" 
                    value={this.props.isDataLoaded ? this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].quizQuestionsCorrectlyAnswered : 0} 
                    suffix={`/ ${this.props.isDataLoaded ? this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].totalNumberOfQuizQuestions : 0}`} 
                    className="dashboard-video-grid-statistic"
                    />
                  </Col>
             
                  <QuizQuestionStatistics
                    quizQuestionStatistics={this.props.quizQuestionStatistics}
                    quizQuestionStatisticsStatus={this.props.quizQuestionStatisticsStatus}
                    quizQuestionIndex={this.state.quizQuestionIndex}
                    setQuizQuestionIndex={(value)=>this.setState({
                     quizQuestionIndex: value
                    })}
                    totalNumOfQuizQuestions={this.props.isDataLoaded ? this.props.loadedData[this.props.moduleId].videos[this.props.videoIndex].totalNumberOfQuizQuestions : 0}
                  />
                </Row>
                <Divider />

                <QnAStatistics 
                  qnAStatistics={this.props.qnAStatistics}
                  qnAStatisticsStatus={this.props.qnAStatisticsStatus}
                />
              </Drawer>
          </Card>
  };
}

const mapStateToProps = state => {
  return {
    qnAStatistics: state.dashboardReducer.qnAStatistics,
    qnAStatisticsStatus: state.dashboardReducer.qnAStatisticsStatus,
    quizQuestionStatistics: state.dashboardReducer.quizQuestionStatistics,
    quizQuestionStatisticsStatus: state.dashboardReducer.quizQuestionStatisticsStatus,
    videoStatistics: state.dashboardReducer.videoStatistics,
    videoStatisticsStatus: state.dashboardReducer.videoStatisticsStatus,
    selectedCoupon: state.dashboardReducer.selectedCoupon
  }
}

export default connect(mapStateToProps, { clearVideoStatisticsDrawerData, fetchQnAStatistics, fetchQuizQuestionStatistics, fetchVideoStatistics })(withRouter(VideoCard));
