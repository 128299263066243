import { Component } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

class Tour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: props.steps,
            tutorialName: props.tutorialName
        }
    }

    handleJoyrideCallback = data => {
        const { status, type } = data;
    
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
          // Need to set our running state to false, so we can restart if we click start again.
          localStorage.setItem(this.state.tutorialName, true)
        }
    };

    render() {
        return <Joyride 
                    steps={this.state.steps} 
                    showSkipButton={true}
                    continuous={true}
                    locale={{
                        last: "Close",
                        skip: "Skip tour"
                      }}
                    scrollOffset={200}
                    showProgress={true}
                    styles={{
                        options: {
                            primaryColor: '#33bcb7',
                            zIndex: 1000
                        }
                    }}
                    callback={this.handleJoyrideCallback}
                    disableOverlayClose={true}
                    disableCloseOnEsc={true}
                    />
    }
}

export default Tour;