import { Col, Image, Input, Row, Skeleton, Space, Typography } from "antd";

const { Search } = Input;
const { Paragraph, Text, Title } = Typography;

const LoadingCertificationInfoCard = ({
    collection,
    collectionNetPrice,
    mode = "purchase"
}) => {
    return (
        <div className="collection-info-card collection-info-card-loading">
            <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="collection-info-card-text-container">
                        <Skeleton
                            title
                            loading={true}
                            active
                            paragraph={{ rows: 4 }}
                            button
                        />
                        <Space>
                            <Skeleton.Button
                                loading={true}
                                active
                                size={"small"}
                                shape={"default"}
                                block
                            />
                            <Skeleton.Button
                                loading={true}
                                active
                                size={"small"}
                                shape={"default"}
                                block
                            />
                        </Space>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="collection-info-card-preview-image-loading"></div>
                </Col>
            </Row>
        </div>
    );
};

export default LoadingCertificationInfoCard;
