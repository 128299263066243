import { Duration } from "luxon";

// Calculate borrowed price
export const getCalculateBorrowedPrice = (module) => {
    let total = 0;
    let licensedModules = {};
    module.videos.forEach((video) => {
        if (video.parentModule != module.id) {
            if (!(video.parentModule in licensedModules)) {
                const newPrice = video.parentModulePrice || 0;
                licensedModules[video.parentModule] = newPrice;
                total += newPrice;
            }
        }
    });
    return total;
};

// Set module temp total price
export const getModuleTotalPrice = (module, policyModel) => {
    let total = 0;
    if (module.isDefaultPrice) {
        total += policyModel?.platformDefaultPrice || 0;
    } else {
        if (module.customPrice) {
            total += module.customPrice;
        }
    }
    let borrowedPrice = getCalculateBorrowedPrice(module);
    total += borrowedPrice;
    return total;
};

// Parse YouTube data after import
export const parseYouTubeData = (data) => {
    let newVideos = data["data"]["items"].map((item) => {
        let videoId = item["id"];
        let videoDuration = Duration.fromISO(
            item["contentDetails"]["duration"]
        ).shiftTo("seconds").seconds;
        return {
            title: item["snippet"]["title"],
            videoType: data["videoType"],
            embedId: videoId,
            importedFromURL: "true",
            duration: videoDuration
        };
    });
    const videosToImport = newVideos.concat(data["licensableContent"]);
    return videosToImport;
};

// Parse Vimeo data after import
export const parseVimeoData = (data) => {
    let newVideos;
    if (data["contentType"] === "playlist") {
        newVideos = data["data"]["data"].map((videoData) => {
            return {
                title: videoData["name"],
                videoType: data["videoType"],
                embedId: videoData["uri"].replace("/videos/", ""),
                duration: videoData["duration"]
            };
        });
    } else if (data["contentType"] === "video") {
        newVideos = data["data"].map((videoData) => {
            return {
                title: videoData["name"],
                videoType: data["videoType"],
                embedId: videoData["uri"].replace("/videos/", ""),
                duration: videoData["duration"]
            };
        });
    }
    const videosToImport = newVideos.concat(data["licensableContent"]);
    return videosToImport;
};
