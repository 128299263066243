import { Card, Col, Divider, Row, Statistic, Table } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, QuestionCircleOutlined } from "@ant-design/icons";

const StudentSummaries = ({studentSummaries, studentSummariesStatus}) => {
    return <>
            <Divider orientation="left">Watch Activity</Divider>
            <Row gutter={16}>
            <Col span={8}>
                <Statistic 
                title="Total Watch Time" 
                value={10000}
                formatter={(value) => {
                    let remainder = value;
                    let hours = Math.floor(remainder / 3600);
                    remainder = remainder % 3600;
                    let minutes = Math.floor(remainder / 60);
                    let seconds = Math.floor(remainder % 60);
                    return `${hours}hr ${minutes}min ${seconds}s`
                }} 
                />
            </Col>
            <Col span={8}>
                <Statistic 
                title="Watch Time (This Week)" 
                value={1000} 
                prefix={<ArrowDownOutlined />}
                valueStyle={{ color: studentSummariesStatus == "LOADED" && studentSummaries.thisWeekWatchTime >= studentSummaries.lastWeekWatchTime ? '#3f8600' : '#FF0000'}}
                formatter={(value) => {
                    let remainder = value;
                    let hours = Math.floor(remainder / 3600);
                    remainder = remainder % 3600;
                    let minutes = Math.floor(remainder / 60);
                    let seconds = Math.floor(remainder % 60);
                    return `${hours}hr ${minutes}min ${seconds}s`
                }}
                />
            </Col>
            <Col span={8}>
                <Statistic 
                title="Watch Time (Last Week)" 
                value={10000} 
                valueStyle={{ color: '#CCC' }}
                formatter={(value) => {
                    let remainder = value;
                    let hours = Math.floor(remainder / 3600);
                    remainder = remainder % 3600;
                    let minutes = Math.floor(remainder / 60);
                    let seconds = Math.floor(remainder % 60);
                    return `${hours}hr ${minutes}min ${seconds}s`
                }}
                />
            </Col>
            </Row>
            <Divider orientation="left">Quizzes</Divider>
            <Row gutter={16}>
            <Col span={8}>
                <Statistic 
                title="Completed Quiz Questions" 
                value={5} 
                suffix={'/ ' + 6}
                />
            </Col>
            <Col span={8}>
                <Statistic 
                title="Average # of Attempts" 
                value={(2.34).toFixed(2)} 
                />
            </Col>
            <Col span={8}>
                
            </Col>
            </Row>
            <Divider orientation="left">Q&A</Divider>
            <Row gutter={16}>
            <Col span={8}>
                <Statistic 
                title="Questions Asked" 
                value={1}
                />
            </Col>
            <Col span={8}>
                <Statistic 
                title="Replies Given" 
                value={1} 
                />
            </Col>
            <Col span={8}>
                <Statistic 
                title="Replies Received" 
                value={1}
                />
            </Col>
            </Row>

           
        </>
}

export default StudentSummaries;