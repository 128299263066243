import { Button, Col, Divider, Form, Modal, Row, Typography } from "antd";
import { useState } from "react";
import AuthorSelect from "../Components/AuthorSelect";
import { API_URL } from "../const";
const { Title } = Typography;

const EditAuthorsModal = ({
    title,
    visible,
    setVisibility,
    initialValues,
    authorsDict,
    currentUser,
    setAuthorsDict,
    onFinish
}) => {
    // Author Form
    const [authorForm] = Form.useForm();
    const [authorFormInitialValues] =
        useState(initialValues);

    // On finish author form
    const onFinishAuthorForm = (values) => {
        let authorData = {
            id: currentUser.id,
            username: currentUser.username,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName
        };

        // Set primary author name
        if (values.primaryAuthorUsername in authorsDict) {
            authorData = authorsDict[values.primaryAuthorUsername];
        }

        // set co-authors
        let coAuthors = values.coAuthorUsernames.map((coAuthorUsername) => {
            if (coAuthorUsername && coAuthorUsername in authorsDict) {
                return authorsDict[coAuthorUsername];
            } else {
                return {
                    id: currentUser.id,
                    username: currentUser.username,
                    firstName: currentUser.firstName,
                    lastName: currentUser.lastName
                };
            }
        });

        onFinish({
            authorData,
            coAuthors
        });
        setVisibility(false);
    };

    // Fetch creator List
    const fetchCreatorList = async (username) => {
        return fetch(
            API_URL +
                `/api/v1/creators/${username ? "?search=" + username : ""}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((body) => {
                let options = [];
                let authors = {};
                body.forEach((user) => {
                    authors[user.username] = user;
                    options.push({
                        label: `${user.firstName} ${user.lastName}`,
                        value: user.username
                    });
                });

                // Set new authors dict
                setAuthorsDict(authors);
                return options;
            });
    };

    return (
        <Modal
            visible={visible}
            footer={null}
            onCancel={() => setVisibility(false)}
            maskClosable={false}>
            <div className="add-module-modal">
                <Title level={4}>{title}</Title>
                <br />
                <Row gutter={16}>
                    <Col span={24}>
                        <Form
                            form={authorForm}
                            name="basic"
                            layout="vertical"
                            initialValues={authorFormInitialValues}
                            onFinish={onFinishAuthorForm}
                            autoComplete="off">
                            <Form.Item
                                label="Author"
                                name={["primaryAuthorUsername"]}
                                tooltip="If you are not the primary author, search for the user's account by username or name. Only this user will get paid for any purchases. If you leave this blank, the primary author will be set to you.">
                                <AuthorSelect
                                    placeholder={
                                        "Select or search for a user by username (Or leave blank to default to yourself)"
                                    }
                                    fetchOptions={fetchCreatorList}
                                    mode={null}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Co-Authors"
                                name={["coAuthorUsernames"]}
                                tooltip="Used mainly for credit purposes only.">
                                <AuthorSelect
                                    placeholder={
                                        "Select or search for a user by username"
                                    }
                                    fetchOptions={fetchCreatorList}
                                    mode={"multiple"}
                                />
                            </Form.Item>
                            <Divider style={{ margin: "10px 0px" }} />
                            <div className="price-breakdown-row">
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

export default EditAuthorsModal;
