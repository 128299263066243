import { Card, Drawer, Form, Input, Button, Modal, Select, Space, TimePicker, Tooltip, Typography, message, Upload} from 'antd';
import { ConsoleSqlOutlined, EyeOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { convertToRaw, EditorState } from 'draft-js';
import { useState } from 'react';
import RichTextEditor from '../Components/RichTextEditor';
import QuestionCreatorFormOption from './QuestionCreatorFormOption';
import QuizQuestionImageUpload from './QuizQuestionImageUpload';
import moment from 'moment';

const QuestionCreatorForm = ({createQuizQuestion, handleDrawerClose, getCurrentTimestamp, setPreviewData, videoDuration, videoId, visibleForm}) => {
    const [form] = Form.useForm();
    const [questionTitle, setQuestionTitle] = useState('Enter a question title');
    const [questionText, setQuestionText] = useState(convertToRaw(EditorState.createEmpty().getCurrentContent()));
    const [maxDuration, setMaxDuration] = useState(moment("00:00:00", 'HH:mm:ss').seconds(videoDuration))
    const [submitLoading, setSubmitLoading] = useState(false);
    const [questionTextImageIds, setQuestionTextImageIds] = useState([]);
    const [allImages, setAllImages] = useState([]);
    const [previewImage, setPreviewImage] = useState("");
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [reset, setReset] = useState(false);

    const { Option } = Select;
    const { Paragraph, Text } = Typography;
    const { RangePicker } = TimePicker;

    const onFinish = (values) => {
       
        values['startVideoTimestamp'] = values['timeRange'][0].diff(moment("00:00:00", "HH:mm:ss"), 'seconds')
        values['endVideoTimestamp'] =  values['timeRange'][1].diff(moment("00:00:00", "HH:mm:ss"), 'seconds')
        values['imageIds'] = questionTextImageIds;
        delete values['timeRange']
        const handleAfterSuccess = () => {
            setSubmitLoading(false);
            message.success("Quiz Question created successfully.")
            setQuestionTextImageIds([])
            setAllImages([])
            setReset(true);
            form.setFieldsValue({
                title: "Enter a question title",
                text: convertToRaw(EditorState.createEmpty().getCurrentContent()),
                questionType: "select_one",
                options: [],
                timeRange: [moment('00:00:00', 'HH:mm:ss'), maxDuration]
            })
        }
        createQuizQuestion(videoId, [values], handleAfterSuccess)
    };

    const addImageIdToQuestionImages = (id) => {
        setQuestionTextImageIds([...questionTextImageIds, id])
    } 
    
    const addImageToAll = (imageData) => {
        setAllImages([...allImages, imageData])
    }

    const addImageIdToOptionImages = (optionIndex, id) => {
        let options = form.getFieldValue('options');
        options[optionIndex]['imageId'] = id
        form.setFieldsValue({options})
    }

    const removeItemFromQuestionImages = (id) => {
        let newState = [...questionTextImageIds];
        let foundIndex = newState.indexOf(id);
        if(foundIndex > -1) {
            newState.splice(foundIndex, 1);
        }
        setQuestionTextImageIds(newState);

        let newAllImagesState = allImages.filter(imageData => imageData.id != id);
        setAllImages(newAllImagesState);
    }
    const handleOnSelectChange = (value) => {

        if(value == "select_one"){
            let options = form.getFieldValue('options');
            let updatedOptions = options.map(option => {
                return {
                    ...option,
                    correctAnswerFlag: false
                }
            })
            form.setFieldsValue({
                questionType: value,
                options: updatedOptions
            })
        }

        if(value == "open_ended"){
            let updatedOptions = [{
                correctAnswerFlag: true,
                optionText: convertToRaw(EditorState.createEmpty().getCurrentContent()),
                imageId: null,
                explanationText: convertToRaw(EditorState.createEmpty().getCurrentContent())
            }];
            form.setFieldsValue({
                questionType: value,
                options: updatedOptions
            })
        }
    }

    const handleOnPreviewClick = () => {
        let data = form.getFieldsValue(true);
        let options = data['options'];
        data['images'] = allImages.filter(image => questionTextImageIds.includes(image.id))
        options = options.map(option => {
            return {
                ...option,
                'image': allImages.find(image => option.imageId == image.id)
            }
        })
        data['options'] = options
        setPreviewData(data);
    }

    const handleOnQuestionTitleChange = (value) => {
        form.setFieldsValue({title: value});
        setQuestionTitle(value)
    }

    const handleOnQuestionTextChange = (value) => {
        form.setFieldsValue({text: value})
        setQuestionText(value)
    }

    const getDisabledHours = () => {
        let hours = [];
        for(let i = maxDuration.hours() + 1; i <= 23; i++){
            hours.push(i);
        }
        return hours;
    }
    const setStartTime = async () => {
        let timestamp = await getCurrentTimestamp();
        let endTime = form.getFieldValue('timeRange')[1]
        let newStartTime = moment("00:00:00", 'HH:mm:ss').seconds(timestamp);
        form.setFieldsValue({
            timeRange: [newStartTime, endTime]
        })
    }

    const setEndTime = async () => {
        let timestamp = await getCurrentTimestamp();
        let startTime = form.getFieldValue('timeRange')[0];
        let newEndTime = moment("00:00:00", "HH:mm:ss").seconds(timestamp);
        form.setFieldsValue({
            timeRange:  [startTime, newEndTime]
        })
    }
    const getDisabledMinutes = (selectedHour) => {
        let minutes= [];
        if (selectedHour === maxDuration.hours()){
            for(let i = maxDuration.minutes() + 1; i <= 60; i++){
                minutes.push(i);
            }
        }
        return minutes;
    }

    const getDisabledSeconds = (selectedHour, selectedMinute) => {
        let seconds = []
        if(selectedHour === maxDuration.hours() && selectedMinute === maxDuration.minutes()){
            for(let i = maxDuration.seconds() + 1; i <= 60; i++) {
                seconds.push(i)
            }
        }
        return seconds
    }

    return <Drawer
                title={<Paragraph editable={{ onChange: handleOnQuestionTitleChange, maxLength: 100 }} style={{margin: 0}}>{questionTitle}</Paragraph>}
                placement="right"
                closable={false}
                forceRender={true}
                onClose={handleDrawerClose}
                visible={visibleForm}
                width={"37.5vw"}
                mask={false}
                footer={
                    <div
                        style={{
                        textAlign: 'right',
                        }}
                    >
                        <Button  style={{ marginRight: 8 }} onClick={handleDrawerClose}>
                        Cancel
                        </Button>
                        <Button onClick={handleOnPreviewClick} style={{ marginRight: 8 }}>
                        <EyeOutlined /> Preview
                        </Button>
                        <Button 
                            type="primary" 
                            onClick={() => {
                                setSubmitLoading(true)
                                form.submit()
                            }} 
                            loading={submitLoading}>
                        Create
                        </Button>
                    </div>
                    }
            >
                <Form name='questions' 
                    form={form} 
                    onFinishFailed={()=>setSubmitLoading(false)}
                    onFinish={onFinish} 
                    layout="vertical"
                    initialValues={{
                        title: "Enter a question title",
                        text: convertToRaw(EditorState.createEmpty().getCurrentContent()),
                        questionType: "select_one",
                        options: [],
                        timeRange: [moment('00:00:00', 'HH:mm:ss'), maxDuration]
                    }}
                >
                    <RichTextEditor customOnChange={handleOnQuestionTextChange} placeholder="Enter the question text" readOnly={false} reset={reset} setReset={setReset}/>
                    <br/>
                    <Text>Supporting Images</Text>
                    <QuizQuestionImageUpload addImageToForm={addImageIdToQuestionImages} addImageToAll={addImageToAll} customOnRemove={removeItemFromQuestionImages} reset={reset}/>
                    <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={()=>setPreviewVisible(false)}
                    >
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                    <br/>
                    <br/>
                    <Form.Item name="questionType" label="Question Type" rules={[{ required: true, message: 'Must select a question type.' }]}>
                        <Select
                            placeholder="Type of question"
                            onChange={handleOnSelectChange}
                        >
                            <Option value="select_one" label="Select One">Select one</Option>
                            <Option value="choose_all_that_apply" label="Choose all that apply">Choose all that apply</Option>
                            <Option value="open_ended" label="Open-ended Question">Open-ended question</Option>
                        </Select>
                    </Form.Item>
                    <Space align="center">
                    <Form.Item 
                        name="timeRange" 
                        label="Associated timestamps" 
                        tooltip="Questions will be triggered when video reaches the 'End time' value you set" 
                        rules={[
                            { required: true, message: 'Must select a time range.' }, 
                            { validator: async (_, values) => {
                                if (values[1].isBefore(values[0]) || values[1].isSame(values[0])) {
                                return Promise.reject(new Error('End time must take place after start time.'));
                                }
                            },
                        }]}
                    >
                        <RangePicker showNow={false} disabledHours={getDisabledHours} disabledMinutes={getDisabledMinutes} disabledSeconds={getDisabledSeconds}/>
                    </Form.Item>
                    <Tooltip placement="topLeft" title="Set start time to current timestamp in video"><Button onClick={setStartTime}>Set Start Time</Button></Tooltip>
                    <Tooltip placement="topLeft" title="Set end time to current timestamp in video"><Button onClick={setEndTime}>Set End Time</Button></Tooltip>
                    </Space>
                    <Form.List name="options" rules={[
                        {
                            validator: async (_, options) => {
                                let data = form.getFieldsValue(true);
                                let questionType = data['questionType'];
                                if(questionType == "open_ended"){
                                    if(options.length < 1){
                                        return Promise.reject(new Error('Please enter anwer hint.'));
                                    }
                                }else{
                                    if (!options || options.length < 2) {
                                        return Promise.reject(new Error('At least two options required.'));
                                    }else if(!options.some(option => option.correctAnswerFlag == true)){
                                        return Promise.reject(new Error('Must have at least one correct answer.'))
                                    }
                                }
                            },
                        },
                    ]}>
                        {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                            
                                    <QuestionCreatorFormOption formRef={form} field={field} key={index} index={index} removeOption={()=>remove(index)} addImageToForm={addImageIdToOptionImages} addImageToAll={addImageToAll} isOpenEndedQuestion={form.getFieldsValue(true)['questionType'] == "open_ended"}/>
                        
                                ))}

                            {form.getFieldsValue(true)['questionType'] != "open_ended" && (
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => {
                                            add({
                                            correctAnswerFlag: false,
                                            optionText: convertToRaw(EditorState.createEmpty().getCurrentContent()),
                                            imageId: null,
                                            explanationText: convertToRaw(EditorState.createEmpty().getCurrentContent())
                                        })}} block icon={<PlusOutlined /> } >
                                            Add an option
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                )
                            }
                        </>
                        )}
                    </Form.List>
                    <Form.Item name="title" hidden >
                        <Input />
                    </Form.Item>
                    <Form.Item name="text" hidden >
                        <Input />
                    </Form.Item>
                </Form>
            </Drawer>
}

export default QuestionCreatorForm;