import { HomeOutlined } from "@ant-design/icons";
import { Alert, Breadcrumb, Button, Input, List, Space } from "antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchOriginalCollections } from "../../actions/collection";
import CollectionInfoCard from "../../Components/CollectionInfoCard";
import LoadingCollectionInfoCard from "../../Components/LoadingCollectionInfoCard";
import CategoryFilter from "./CategoryFilter";
import { logEvent, trackingEvents } from "../../Tracking";

const { Search } = Input;

class PremiumCollectionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collectionSearchValue: "",
            pageTrackId: null
        };
    }

    // Log page view event
    trackPageViewEvent(props) {
        logEvent(
            trackingEvents.pageView,
            {
                page: "courses"
            },
            props.currentUser
        );
    }

    componentDidMount() {
        // Track page load event
        this.trackPageViewEvent(this.props);
    }

    handleOnCollectionSearch = (value) => {
        this.setState({ collectionSearchValue: value.toLowerCase() });
    };
    handleOnCollectionSearchChange = (event) => {
        this.setState({
            collectionSearchValue: event.target.value.toLowerCase()
        });
    };

    determineDataSource = () => {
        return this.props.categoryFilteredCollections.filter(
            (collection) =>
                collection.name
                    .toLowerCase()
                    .search(this.state.collectionSearchValue) !== -1 ||
                collection.description
                    .toLowerCase()
                    .search(this.state.collectionSearchValue) !== -1 ||
                collection.description
                    .toLowerCase()
                    .search(this.state.collectionSearchValue) !== -1
        );
    };

    render() {
        if (this.props.status === "LOADED") {
            return (
                <>
                    <div id="premium-collection-mid-section">
                        <Space className="ant-space-responsive">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/">
                                        <HomeOutlined />
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Premium Courses
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <Search
                                placeholder="Search for a course"
                                onSearch={this.handleOnCollectionSearch}
                                onChange={this.handleOnCollectionSearchChange}
                                allowClear
                            />
                        </Space>
                        <Space className="ant-space-responsive">
                            <CategoryFilter
                                categories={this.props.collectionCategories}
                                collections={this.props.collections}
                            />
                        </Space>
                        <List
                            id="premium-collection-list"
                            itemLayout="vertical"
                            bordered={false}
                            split={true}
                            size="large"
                            dataSource={this.determineDataSource()}
                            renderItem={(collection) => (
                                <div className="premium-collection-list-item">
                                    <CollectionInfoCard
                                        collection={collection}
                                        collectionNetPrice={
                                            collection.valuePrice
                                        }
                                        key={collection.id}
                                        type="list"
                                    />
                                </div>
                            )}
                        />
                    </div>
                </>
            );
        } else if (this.props.status === "FAILED") {
            return (
                <div id="premium-collection-mid-section">
                    <Space className="ant-space-responsive">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/">
                                    <HomeOutlined />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Premium Courses</Breadcrumb.Item>
                        </Breadcrumb>
                    </Space>
                    <br />
                    <br />
                    <Alert
                        message="Failed to Load Premium Courses"
                        description={`Try refreshing this page or click TRY AGAIN on the right to attempt loading the courses again. If the issue persists, we might be experiencing issues with our third-party services.`}
                        type="error"
                        showIcon
                        action={
                            <Button
                                size="small"
                                type="text"
                                onClick={this.props.fetchOriginalCollections}>
                                TRY AGAIN
                            </Button>
                        }
                    />
                </div>
            );
        } else {
            return (
                <div id="premium-collection-mid-section">
                    <Space className="ant-space-responsive">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/">
                                    <HomeOutlined />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Premium Courses</Breadcrumb.Item>
                        </Breadcrumb>
                        <Search placeholder="Search" enterButton />
                    </Space>
                    <LoadingCollectionInfoCard />
                    <LoadingCollectionInfoCard />
                    <LoadingCollectionInfoCard />
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        collections: state.collectionReducer.originalCollections,
        categoryFilteredCollections:
            state.collectionReducer.categoryFilteredCollections,
        collectionCategories: state.collectionReducer.collectionCategories,
        fetchingUserStatus: state.userReducer.fetchingUserStatus,
        status: state.collectionReducer.fetchingCollectionsStatus
    };
};
export default connect(mapStateToProps, { fetchOriginalCollections })(
    PremiumCollectionList
);
