import { Card, Empty, Typography } from 'antd';
import { Column } from '@ant-design/charts';

const { Text } = Typography;

const RevenueByModule = ({collection}) => {

        
    var config = {
        data: collection.modules,
        xField: 'name',
        yField: 'revenue',
        seriesField: '',
        legend: true,
        tooltip: {
            formatter: (module) => {
                return { name: "Generated Revenue", value: `$ ${module.revenue.toFixed(2)}` };
            },
        },
        xAxis: {
          label: {
            autoRotate: true,
          },
        },
        yAxis: {
            label: {
                formatter: function formatter(v) {
                    
                  return ''.concat("$ ", parseFloat(v).toFixed(2));
                },
              },
        }
    };


    return <>
                <Text>Revenue by Module</Text>
                <br/><br/>
                {
                    collection.modules.every(module => module.revenue === 0) ?    <Card>
                                                                                      <Empty description="No revenue generated yet."/>
                                                                                  </Card> 
                                                                              :  <Column {...config} />

                }
            </>
}

export default RevenueByModule;