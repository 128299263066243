import { Button, Collapse, Form, Input, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import AuthorSelect from '../Components/AuthorSelect';
import { API_URL } from '../const';

const CollectionDetailsStep = ({ authorsDict, collection, currentUser, goToNextStep, updateState }) => {

    const history = useHistory();

    const handleOnFinish = (values) => {

        let authorData;
        if(values.primaryAuthorUsername in authorsDict){
            authorData = authorsDict[values.primaryAuthorUsername]
        } else {
            authorData = {
                id: currentUser.id,
                username: currentUser.username,
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
            }
        }

        let coAuthors = values.coAuthorUsernames.map(coAuthorUsername => {
            if(coAuthorUsername in authorsDict) {
                return authorsDict[coAuthorUsername]
            } else {
                return {
                    id: currentUser.id,
                    username: currentUser.username,
                    firstName: currentUser.firstName,
                    lastName: currentUser.lastName,
                }
            }
        })

        updateState("collection", {
            ...collection,
            ...values,
            primaryAuthorUsername: authorData.username,
            primaryAuthor: authorData,
            coAuthors: coAuthors
        })
        goToNextStep()
    }

    const fetchCreatorList = async (username) => {
        return fetch(API_URL+`/api/v1/creators/${username ? "?search=" + username : ""}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((body) => {

                let options = []
                let authors = {}
                body.forEach((user) => {
                    
                    authors[user.username] = user;

                    options.push({
                        label: `${user.firstName} ${user.lastName}`,
                        value: user.username
                    })
                })

                updateState("authorsDict", {
                    ...authorsDict,
                    ...authors
                })
                return options
                     
            });
    }

    return  <div>
                <Form
                    name="basic"
                    initialValues={collection}
                    layout="vertical"
                    onFinish={handleOnFinish}
                    //onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Course Name"
                        name="name"
                        rules={[{ required: true, message: 'Please give your course a name!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[{ required: true, message: 'Please give your course a description!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Long Description"
                        name="longDescription"
                        rules={[{ required: true, message: 'Please give your course a longer description!' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Collapse>
                        <Collapse.Panel header="Not the author?" key="1" forceRender>
                            <Form.Item 
                                label="Author"
                                name={['primaryAuthorUsername']}
                                fieldKey={['primaryAuthorUsername']}
                                tooltip="If you are not the primary author, search for the user's account by username or name. Only this user will get paid for any purchases. If you leave this blank, the primary author will be set to you."
                            >
                                <AuthorSelect 
                                    placeholder={"Select or search for a user by username (Or leave blank to default to yourself)"}
                                    fetchOptions={fetchCreatorList}
                                    mode={null}
                                />
                            </Form.Item>
                            <Form.Item 
                                label="Co-Authors"
                                name={['coAuthorUsernames']}
                                fieldKey={['coAuthorUsernames']}
                                tooltip="Used mainly for credit purposes only."

                            >
                                <AuthorSelect 
                                    placeholder={"Select or search for a user by username"}
                                    fetchOptions={fetchCreatorList}
                                    mode={"multiple"}
                                />
                            </Form.Item>
                        </Collapse.Panel>
                    </Collapse>
                    <br/>
                    <Space>
                        <Button onClick={()=>history.push('/creator/home/')}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            Next: Add Modules
                        </Button>
                    </Space>
                </Form>
            </div>
}

export default CollectionDetailsStep;