import { Component, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Divider,
    List,
    Modal,
    Row,
    Space,
    Typography,
    Alert,
    Carousel,
    Image,
    Input,
    Skeleton,
    Popover
} from "antd";

import { fetchOriginalCollections } from "./actions/collection";
import CertificateSample from "./assets/images/certificate-sample.png";
import Cupoints from "./Components/Cupoints";

import "./Components.less";
import { logEvent, trackingEvents } from "./Tracking";
import {
    FolderOutlined,
    PlaySquareOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import CollectionThumbnailPreviewer from "./Components/CollectionThumbnailPreviewer";
import Slider from "@ant-design/react-slick";
// import Slider from "react-slick";

const { Text } = Typography;

/**
 * Certificate Collection Card
 */
const CertificateCollectionCard = ({ collection }) => {
    const [popupVisible, setPopupVisible] = useState(false);
    const onPopupToggle = (event) => {
        setPopupVisible(event);
    };
    return (
        <div className="certification-slider-item">
            <Popover
                overlayClassName="content-section-popover"
                trigger={"hover"}
                arrowPointAtCenter={true}
                placement="right"
                onVisibleChange={onPopupToggle}
                overlayInnerStyle={
                    {
                        // border: "thin solid gray"
                    }
                }
                overlayStyle={{
                    width: "25vw"
                }}
                content={() => (
                    <CertificateHoverContent collection={collection} />
                )}>
                <div className="certificate-collection-card">
                    <div className="certificate-collection-card-image-bio">
                        <Link
                            to={
                                collection.certification
                                    ? `/collections/${collection.id}/certification/`
                                    : `/collections/${collection.id}`
                            }>
                            <CollectionThumbnailPreviewer
                                collection={collection}
                                videoType={
                                    collection?.certification
                                        ?.promotionVideoType
                                }
                                videoUrl={
                                    collection?.certification?.promotionVideoUrl
                                }
                                thumbnail={
                                    <Image
                                        src={
                                            collection?.certification?.image ||
                                            collection.coverImage.image
                                        }
                                        preview={false}
                                        width={"100%"}
                                        className="certificate-collection-card-image"
                                    />
                                }
                            />
                        </Link>
                    </div>
                    <div className="certificate-collection-card-text">
                        <h3 className="title">{collection.name}</h3>
                        <p>
                            <Text type="secondary">
                                By{" "}
                                <Link
                                    to={`/creators/${collection.primaryAuthor.id}`}>{`${collection.primaryAuthor.firstName} ${collection.primaryAuthor.lastName}`}</Link>
                            </Text>
                            <Text type="secondary">
                                {collection.coAuthors.map((coAuthor) => (
                                    <Text key={coAuthor.id}>
                                        <Link
                                            to={`/creators/${coAuthor.id}`}>{`, ${coAuthor.firstName} ${coAuthor.lastName}`}</Link>
                                    </Text>
                                ))}
                            </Text>
                        </p>
                        {collection?.certification && (
                            <>
                                <p className="price">
                                    <Cupoints
                                        points={
                                            collection?.certification?.price
                                        }></Cupoints>
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </Popover>
        </div>
    );
};

/** Certificate Hover Card */
const CertificateHoverContent = ({ collection }) => (
    <div className="certificate-hover-card-details">
        <h3 className="title">{collection.name}</h3>
        <div className="collection-details-bullets">
            {collection?.moduleCount > 0 && (
                <span>
                    <FolderOutlined />
                    {collection.moduleCount} Modules
                </span>
            )}
            {collection?.videoCount > 0 && (
                <span>
                    <VideoCameraOutlined />
                    {collection.videoCount} Videos
                </span>
            )}
            {collection?.totalDuration > 0 && (
                <span>
                    <PlaySquareOutlined />
                    {secondsToHms(collection.totalDuration || 0, false)}
                    total duration
                </span>
            )}
        </div>
        <p className="description">{collection?.certification?.description}</p>
        {collection.certification?.objectives?.length > 0 && (
            <>
                <h4>What you will learn</h4>
                <ul className="objectives">
                    {collection.certification?.objectives.map((obj, i) => (
                        <li key={i}>{obj}</li>
                    ))}
                </ul>
            </>
        )}
        <div className="certificate-collection-card-view-btn-section">
            <Link
                to={
                    collection.certification
                        ? `/collections/${collection.id}/certification/`
                        : `/collections/${collection.id}`
                }
                className="certificate-collection-card-view-btn">
                Go to Certificate Course
            </Link>
        </div>
    </div>
);

/** Certificate Loading Container */
const LoadingCertificateCollectionCards = () => {
    return (
        <>
            <div className="loading-section">
                {[1, 2, 3, 4].map((i) => (
                    <div key={i} className="certificate-collection-card">
                        <div className="certificate-collection-card-image-bio">
                            <div className="collection-info-card-preview-image-loading"></div>
                        </div>
                        <div className="certificate-collection-card-text">
                            <Skeleton
                                loading={true}
                                title
                                active
                                paragraph={{ rows: 1 }}
                                button
                            />

                            <Space>
                                <Skeleton.Button
                                    loading={true}
                                    active
                                    size={"small"}
                                    shape={"default"}
                                    block
                                />
                                <Skeleton.Button
                                    loading={true}
                                    active
                                    size={"small"}
                                    shape={"default"}
                                    block
                                />
                            </Space>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

/** Certification Slider */
const PromotedCollectionSlider = ({ collections }) => {
    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
    };

    return (
        <>
            <div className="" style={{ color: "black" }}>
                <div id="promoted-collection-mid-section">
                    <section id="promoted-collection">
                        <div>
                            <div id="collections-carousel">
                                {collections.length > 0 ? (
                                    <>
                                        <div className="slider-container">
                                            <Slider {...sliderSettings}>
                                                {collections.map(
                                                    (collection, index) => (
                                                        <CertificateCollectionCard
                                                            key={index}
                                                            collection={
                                                                collection
                                                            }
                                                        />
                                                    )
                                                )}
                                            </Slider>
                                        </div>
                                    </>
                                ) : (
                                    <LoadingCertificateCollectionCards />
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

const secondsToHms = (seconds, includeSeconds = true) => {
    seconds = Number(seconds);
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);
    const hDisplay = h > 0 ? h + "hr " : "";
    const mDisplay = m > 0 ? m + "min " : "";
    const sDisplay = includeSeconds && s > 0 ? s + "sec" : "";
    return hDisplay + mDisplay + sDisplay;
};

class Certifications extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        logEvent(trackingEvents.pageView, {
            page: "certifications"
        });
    }

    render() {
        return (
            <section className="collection-page-section">
                <h1 className="page-title">Be Ready for the AI revolution</h1>
                <h3 className="page-title-2">
                    The most in-demand certificate courses taught by Ivy League
                    and Top University Professors and Industry Experts that are
                    AI-boosted
                </h3>
                <h5 className="page-title-3">
                    Certify Critical Skills Necessary to Find Technical Jobs in
                    the AI Age
                </h5>
                <p className="text-center explore-btn-section">
                    <Link
                        className="explore-btn"
                        to="/certification-collections">
                        Explore All Certifications
                    </Link>
                </p>

                <PromotedCollectionSlider
                    collections={this.props.collections.filter(
                        (col) => col.certification !== null
                    )}
                />

                <div id="why-cubits-works" className="contain">
                    <h2>Why CUcertify?</h2>
                    <section>
                        <p className="why-cubits-works-para">
                            We integrate Machine Learning and Artificial
                            Intelligence into every stage of the learning and
                            evaluation process, ensuring that our certificates
                            are rigorously validated. You'll learn from top-tier
                            instructors affiliated with world-renowned
                            institutions. Our courses are meticulously curated
                            and packed with interactive exercises. cuGPT, our
                            custom genAI, is always available to assist you with
                            any questions.{" "}
                            <i>
                                CUbits certificates are crafted to validate
                                specific, in-demand skills that employers are
                                actively seeking.
                            </i>
                        </p>
                        <p className="why-cubits-works-para">
                            The CUbits comprehensive dashboard offers a detailed
                            overview of your progress, both during and after the
                            course, giving employers confidence in the skills
                            you've acquired and aiding them in making informed
                            hiring decisions. Share detailed dashboard with
                            potential employers
                        </p>
                        <p className="why-cubits-works-para">
                            Upon completing all the requirements, you will
                            receive a certificate like this:
                        </p>
                        <p>
                            <img
                                className="certificate-sample"
                                src={CertificateSample}
                            />
                        </p>
                    </section>
                </div>

                <div id="how-you-get-paid" className="contain">
                    <h2>How Certifications Works</h2>
                    <p className="why-cubits-works-para heading">
                        CUbits courses are structured into modules, with each
                        module consisting of a few short videos that can be
                        completed in 2-3 hours. You have the flexibility to
                        watch these interactive videos at your own pace—whether
                        you prefer to watch, listen, or search through the
                        content. For any questions, cuGPT is at your disposal,
                        ready to summarize content, provide practice questions,
                        or clarify concepts covered in the videos. This seamless
                        integration of AI is designed to enhance your learning
                        experience.
                    </p>
                    <p className="why-cubits-works-para heading">
                        As you progress through the videos, quiz questions will
                        appear at key moments, including multiple-choice,
                        select-all-that-apply, and open-ended questions. You
                        have unlimited attempts to answer, but to earn honors,
                        you must complete all questions with an average of 1.5
                        attempts or fewer per question. To pass the course, you
                        need an average of 2.0 attempts or fewer. If your
                        average is between 2.0 and 2.5 attempts per question,
                        you may request to retake the some modules once again.
                    </p>
                    <p className="why-cubits-works-para heading">
                        The cubits dashboard is comprehensive providing your
                        performance metrics in all aspects of the course
                    </p>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        collections: state.collectionReducer.originalCollections
    };
};

export default connect(mapStateToProps, { fetchOriginalCollections })(
    Certifications
);
