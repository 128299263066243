import { Button, Checkbox, Collapse, Divider, Form, Input, InputNumber, Modal, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddVideoToVideoBank from '../Components/AddVideoToVideoBank';
import AuthorSelect from '../Components/AuthorSelect';
import VideoRow from '../Components/VideoRow';
import PolicyModelModal from '../Components/PolicyModelModal';
import BorrowedContentPriceBreakdown from '../Components/BorrowedContentPriceBreakdown';
import { connect } from 'react-redux';
import { API_URL } from '../const';


const { Text, Title } = Typography;

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      xl: { span: 8}
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      xl: { span: 8}
    },
};

const ModuleDetailsStep = ({ allVideos, authorsDict, currentUser, goToNextStep, goToPreviousStep, module, policyModel, updateState, videoBank, index=null, mode="new"}) => {

    const history = useHistory();
    const [form] = Form.useForm();
    const [customPrice, setCustomPrice] = useState(0);
    const [useDefaultPrice, setUseDefaultPrice] = useState(true);
    const [tempModuleState, updateTempModuleState] = useState(module);
    const [tempVideoBankState, updateVideoBankState] = useState(videoBank);
    const [tempAllVideosState, updateTempAllVideosState] = useState(allVideos);
    const [policyModelVisible, setPolicyModelVisible] = useState(false);
    const [borrowedPriceBreakdownVisible, setBorrowedPriceBreakdownVisible] = useState(false);

    useEffect(() => {
        updateVideoBankState(videoBank);
    }, [videoBank])

    const handleOnEditModuleFormFinish = (values) => {
        let authorData;
        if(values.primaryAuthorUsername in authorsDict){
            authorData = authorsDict[values.primaryAuthorUsername]
        } else {
            authorData = {
                id: currentUser.id,
                username: currentUser.username,
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
            }
        }
        let coAuthors = values.coAuthorUsernames.map(coAuthorUsername => {
            if(coAuthorUsername && coAuthorUsername in authorsDict) {
                return authorsDict[values.primaryAuthorUsername]
            } else {
                return {
                    id: currentUser.id,
                    username: currentUser.username,
                    firstName: currentUser.firstName,
                    lastName: currentUser.lastName,
                }
            }
        })

        if(mode === "edit") {
            
        } else {
            let newModule = {
                ...tempModuleState,
                ...values,
                primaryAuthorUsername: authorData.username,
                primaryAuthor: authorData,
                coAuthors: coAuthors,
            }
            if(!useDefaultPrice){
                newModule['customPrice'] = customPrice
            }
            updateState("module", newModule)
            updateState("videoBank", tempVideoBankState)
            goToNextStep()
            form.resetFields();
        }
        
    }

    const handleOnDragEnd = (result) => {
  
        if(!result.destination) return;
      
        if(result.source.droppableId === "videos" && result.destination.droppableId === "videoBank") {
            const updatedVideos = Array.from(tempModuleState["videos"])
            const [movedVideo] = updatedVideos.splice(result.source.index, 1);
            const updatedVideoBank = Array.from(tempVideoBankState);
            updatedVideoBank.splice(result.destination.index, 0, movedVideo);

            updateTempModuleState(
                {
                    ...tempModuleState,
                    "videos": updatedVideos
                }
            )
            updateVideoBankState(updatedVideoBank)
        }
        if(result.source.droppableId === "videoBank" && result.destination.droppableId === "videos") {
            const updatedVideoBank = Array.from(tempVideoBankState);
            const [movedVideo] = updatedVideoBank.splice(result.source.index, 1);
            const updatedVideos = Array.from(tempModuleState["videos"])
            updatedVideos.splice(result.destination.index, 0, movedVideo);

            updateTempModuleState(
                {
                    ...tempModuleState,
                    "videos": updatedVideos
                }   
            )
            updateVideoBankState(updatedVideoBank)
        }
        if(result.source.droppableId === "videos" && result.destination.droppableId === "videos") {
            const updatedVideos = Array.from(tempModuleState["videos"]);
            const [reorderedVideo] = updatedVideos.splice(result.source.index, 1);
            updatedVideos.splice(result.destination.index, 0, reorderedVideo);
            updateTempModuleState(
                {
                    ...tempModuleState,
                    "videos": updatedVideos
                }   
            )
        }

        if(result.source.droppableId === "videoBank" && result.destination.droppableId === "videoBank") {
            const updatedVideoBank = Array.from(tempVideoBankState);
            const [reorderedVideo] = updatedVideoBank.splice(result.source.index, 1);
            updatedVideoBank.splice(result.destination.index, 0, reorderedVideo);
            updateVideoBankState(updatedVideoBank)
        }
        
    }

    const addVideosToVideoBank = (videos) => {
        let newVideos = []
        videos.forEach(video => {
            let found = tempAllVideosState.findIndex(existingVideo => existingVideo.embedId === video.embedId);
            if(found === -1) newVideos.push(video);
        })

        updateVideoBankState([
            ...tempVideoBankState,
            ...newVideos
        ])
        updateTempAllVideosState([
            ...tempAllVideosState,
            ...newVideos
        ])
    }

    const fetchCreatorList = async (username) => {
        return fetch(API_URL+`/api/v1/creators/${username ? "?search=" + username : ""}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((body) => {

                let options = []
                let authors = {}
                body.forEach((user) => {
                    
                    authors[user.username] = user;

                    options.push({
                        label: `${user.firstName} ${user.lastName}`,
                        value: user.username
                    })
                })

                updateState("authorsDict", {
                    ...authorsDict,
                    ...authors
                })
                return options
                     
            });
    }

    const calculateTotalPrice = () => {
        let total = 0
        if(useDefaultPrice){
            total += 200
        }else {
            let customPrice = form.getFieldValue('customPrice')
            if(customPrice){
                total += customPrice
            }
        }
        
        return total;
    }

    const handleOnCheck = () => {
        setUseDefaultPrice(!useDefaultPrice)
        form.resetFields(['customPrice'])
    }

    return <div style={{padding: "25px 0px"}}>
                <DragDropContext onDragEnd={handleOnDragEnd}>     
                    <div className="new-module-container">
                        
                            <div className="video-bank-container">
                                <div className="video-bank-header">                                    
                                    <Space direction='vertical'>
                                        <Title level={4} style={{margin: 0}}>Video Bank</Title>
                                        <Text type="secondary">Drag videos into module</Text>
                                        
                                    </Space>
                                    <AddVideoToVideoBank 
                                        addVideosToVideoBank={addVideosToVideoBank}
                                    />
                                </div>

                                
                                
                                <div className="video-bank">  
                                    <Droppable droppableId="videoBank">
                                    {
                                        (provided, snapshot) => (
                                                    <ol 
                                                        className="video-bank-list" 
                                                        {...provided.droppableProps} 
                                                        ref={provided.innerRef}
                                                        style={{
                                                            background: snapshot.isDraggingOver ? 'lightblue' :'#1d1d1d',
                                                            transition: "all 0.2s ease"
                                                        }}
                                                    >
                                                        {
                                                            tempVideoBankState.map((video, index) => {
                                                                return (
                                                                    <Draggable key={`${video.embedId}`} draggableId={`${video.embedId}`} index={index}>
                                                                        {
                                                                            (provided) => (
                                                                                <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                                    <VideoRow video={video} />
                                                                                </li>
                                                                            )
                                                                        }   
                                                                    </Draggable>
                                                                )
                                                            })
                                                        }
                                                        {provided.placeholder}
                                                    </ol>
                                        )
                                    }
                                    </Droppable>
                                </div>      
                            </div>
                        
                            <div className="collection-creator-new-module">
                                <Title level={4}>Module Details</Title>
                                <Form
                                    {...formItemLayout}
                                    form={form}
                                    name="basic"
                                    layout="vertical"
                                    initialValues={module}
                                    onFinish={handleOnEditModuleFormFinish}
                                    //onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        label="Module Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please give your module a name!' }]}
                                    >
                                        <Input  />
                                    </Form.Item>
                                    
                                    
                                        
                                            <Title level={5}>Videos</Title>
                                            
                                            <div className="videos-in-module-list-container">
                                                                
                                                    <Droppable droppableId="videos" direction='horizontal'>
                                                    {
                                                        (provided, snapshot) => (
                                                                    <div
                                                                        className="collection-creator-videos-in-module-list" 
                                                                        {...provided.droppableProps} 
                                                                        ref={provided.innerRef}
                                                                        style={{
                                                                            background: snapshot.isDraggingOver ? "lightblue" : "#1d1d1d",
                                                                            transition: "all 0.2s ease"
                                                                        }}
                                                                    >
                                                                        {
                                                                            tempModuleState.videos.map((video, index) => {
                                                                                return (
                                                                                    <Draggable key={`${video.embedId}`} draggableId={`${video.embedId}`} index={index}>
                                                                                        {
                                                                                            (provided) => (
                                                                                                <div
                                                                                                    {...provided.draggableProps} 
                                                                                                    {...provided.dragHandleProps} 
                                                                                                    ref={provided.innerRef} 
                                                                                                    className="draggable-video"
                                                                                                >
                                                                                                    <VideoRow video={video} />
                                                                                                </div>
                                                                                            )
                                                                                        }   
                                                                                    </Draggable>
                                                                                )
                                                                            })
                                                                        }
                                                                        {provided.placeholder}
                                                                    </div>
                                                        )
                                                    }
                                                    </Droppable>
                            
                                        
                                            </div>
                                    <br/>
                                    <Collapse>
                                        <Collapse.Panel header="Not the author?" key="1" forceRender>
                                            <Form.Item 
                                                label="Author"
                                                name={['primaryAuthorUsername']}
                                                fieldKey={['primaryAuthorUsername']}
                                                tooltip="If you are not the primary author, search for the user's account by username or name. Only this user will get paid for any purchases. If you leave this blank, the primary author will be set to you."
                                            >
                                                <AuthorSelect 
                                                    placeholder={"Select or search for a user by username (Or leave blank to default to yourself)"}
                                                    fetchOptions={fetchCreatorList}
                                                    mode={null}
                                                />
                                            </Form.Item>
                                            <Form.Item 
                                                label="Co-Authors"
                                                name={['coAuthorUsernames']}
                                                fieldKey={['coAuthorUsernames']}
                                                tooltip="Used mainly for credit purposes only."

                                            >
                                                <AuthorSelect 
                                                    placeholder={"Select or search for a user by username"}
                                                    fetchOptions={fetchCreatorList}
                                                    mode={"multiple"}
                                                />
                                            </Form.Item>
                                        </Collapse.Panel>
                                    </Collapse>
                                    <br/>
                                    <div className="price-breakdown">
                                        <div className="price-breakdown-row">
                                            <div>
                                                <Text>Price of your content</Text>
                                                <br/>
                                                <Text type="secondary" className="price-breakdown-hint" onClick={()=>setPolicyModelVisible(true)}>How should I price my module?</Text>
                                                <PolicyModelModal policyModel={policyModel} visible={policyModelVisible} setVisible={setPolicyModelVisible}/>
                                            </div>
                                            <Space align='baseline'>
                                                <Checkbox checked={useDefaultPrice} onChange={handleOnCheck}>Use Default Price</Checkbox>
                                                {
                                                    useDefaultPrice ? <InputNumber value={200} disabled/> : <Form.Item
                                                        name="customPrice"
                                                    >
                                                        <InputNumber min={0} onChange={(value)=>{setCustomPrice(value)}}/>
                                                    </Form.Item>
                                                }
                                                
                                            </Space>
                                        </div>
                                        <div className="price-breakdown-row">
                                            <div>
                                                <Text>Price of borrowed content</Text>
                                                <br/>
                                                <Text type="secondary" className="price-breakdown-hint" onClick={()=>setBorrowedPriceBreakdownVisible(true)}>How is this calculated?</Text>
                                                <BorrowedContentPriceBreakdown
                                                    module={tempModuleState} 
                                                    setVisible={setBorrowedPriceBreakdownVisible} 
                                                    visible={borrowedPriceBreakdownVisible} 
                                                />
                                            </div>
                                            <Text>0 Cupoints</Text>
                                        </div>
                                        <Divider style={{ margin: "10px 0px"}} />
                                        <div className="price-breakdown-row">
                                            <Text>Total Price</Text>
                                            
                                            <Text>{calculateTotalPrice()}cupoints</Text>
                                        </div>
                                        
                                    </div>
                                    <br/>
                                    <div>
                                        <Space>
                                            <Button onClick={()=>history.push('/creator/home')}>
                                                Cancel
                                            </Button>
                                            <Button type="primary" htmlType='submit' disabled={tempModuleState.videos.length < 1}>
                                                {mode === "edit" ? "Update Module" : "Next: Advanced Configurations"}
                                            </Button>
                                        </Space>
                                    </div>
                                    
                                </Form>
                            </div>
                        
                    </div>
                    </DragDropContext>
                </div>
}

const mapStateToProps = state => {
    return {
        policyModel: state.contentCreationReducer.policyModel,
    }
}

export default connect(mapStateToProps, {})(ModuleDetailsStep);