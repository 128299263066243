import { Image, Space, Typography } from "antd";
import CubitsLogo from '../cubits-logo.png';

const { Paragraph, Text, Title } = Typography;

const SelectAccountTypeStep = ({ applyAsLearner, goToNextStep, goToPreviousStep }) => {
    
    return <>
                    <Space align="center">
                        <Image src={CubitsLogo} preview={false} width={20} height={22}/> 
                        <Title level={4} style={{marginBottom: 5}}>Welcome to Cubits!</Title>
                    </Space>
                    <Paragraph font>
                        <Text>Before we get you started, we need to know a little more about you.</Text>
                    </Paragraph>
                    <Title level={5}>I am a...</Title>
                    <div >
                        <div className="account-type-option" onClick={applyAsLearner}>
                            
                            <Text>Learner</Text>
                            <Text type="secondary">Watch Videos</Text>
                        </div>
                        <br/>
                        <div className="account-type-option" onClick={()=>goToNextStep(1)}>
                            <Text>Instructor / Creator</Text>
                            <Text type="secondary">Create and Deliver Content</Text>
                        </div>
                    </div>
            </>
                
}

export default SelectAccountTypeStep;
