import { Button, Col, Modal, Row, Typography } from 'antd';
import { useState } from 'react';
import ImportFromUrl from '../ImportFromUrl';

const { Paragraph, Text, Title } = Typography;

const AddVideoToVideoBank = ({ addVideosToVideoBank }) => {

    const [visible, setVisible] = useState(false);
    const [importByURLModalVisible, setImportByURLModalVisible] = useState(false);
    const [videoType, setVideoType] = useState("YouTube");

    return <>
        <ImportFromUrl 
            addVideosToVideoBank={addVideosToVideoBank}
            videoType={videoType}
            visible={importByURLModalVisible} 
            setVisible={setImportByURLModalVisible}
        />
        {/* <Button id="import-video-btn" type="primary" ghost onClick={() => {
            setVideoType("YouTube")
            setImportByURLModalVisible(true)
        }} style={{width: "100%"}}>Import</Button> */}
        {/* <Modal
            visible={visible}
            footer={null}
            onCancel={()=>setVisible(false)}
            maskClosable={false}
        >
            <div className="video-import">
                <Title level={4}>Choose Method of Import</Title>
                <br/>
                <Row gutter={16}>
                    <Col span={8}>
                        <div 
                            className="video-import-option"
                            onClick={()=>{
                                setVisible(false)
                                setVideoType("YouTube")
                                setImportByURLModalVisible(true)
                            }}
                        >
                            <Text>YouTube URL</Text> 
                            <Text>(Playlist or Video)</Text>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div 
                            className="video-import-option"
                            onClick={()=>{
                                setVisible(false)
                                setVideoType("Vimeo")
                                setImportByURLModalVisible(true)
                            }}
                        >
                            <Text>Vimeo URL</Text> 
                            <Text>(Showcase or Video)</Text>
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal> */}

        
    </>
       
}

export default AddVideoToVideoBank;