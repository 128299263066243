const initialState = {
    loadingStatus: "LOADING",
    evaluationProgress: false,
    evaluationShow: true,
    evaluationExists: false,
    evaluationExpired: false,
    evaluationEndDate: null,
    evaluationEndsIn: '',
    certificatePurchased: false,
    certificateStatus: null,
    certificateEndDate: null,
    certificateExpired: false,
    certificateEndsIn: '',
    certificateStatusProgress: false
}

export default (state = initialState, action) => {
    let filteredResponses, qnaLevel, qnaQuestionsArray, qnaParentQuestionIndex;
    switch (action.type) {

        case "SET_CERTIFICATION_LOADING_STATUS":
            return Object.assign({}, state, { loadingStatus: action.status })

        case "SET_CERTIFICATION_EVALUATION_PROGRESS_STATUS":
            return Object.assign({}, state, { evaluationProgress: action.status })

        case "SET_CERTIFICATION_PROGRESS_STATUS":
            return Object.assign({}, state, { certificateStatusProgress: action.progress })
            
        case "SET_CERTIFICATION_STATUS":
            return Object.assign({}, state, { certificateStatus: action.status })


        case "SET_CERTIFICATION_DATA":
            return Object.assign({}, state, { ...state, ...action.certification })

        default:
            return state
    }
}