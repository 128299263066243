/******************************************************************
HomePageVideoActivity.js
Written by Adam Gamba, Summer 2021

Displays summary information about the course videos including a
bar chart of the most watched videos of the past 7 days
******************************************************************/

import React, { Component, useState, useEffect } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { connect } from "react-redux";
import moment from "moment";
import { Card, DatePicker, Empty, Skeleton, Space, Tooltip, Typography,} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { fetchHomePageVideoActivity } from '../../actions/dashboard';


const { Text } = Typography;
const dateFormat = 'MM/DD/YYYY';

class HomePageVideoActivity extends Component {

  constructor(props){
    super(props)
    this.state = {
      chartLabels: [],
      chartModules: [],
      chartVideos: [],
      chartDataSums: [],
      selectedDate: moment(),
    }
  }

  componentDidMount(){
    if(this.props.videoListStatus === "LOADED" && this.props.selectedCoupon){

      let coupon = this.props.selectedCoupon.code;
      this.props.fetchHomePageVideoActivity(this.state.selectedDate.startOf('week').toISOString(), this.state.selectedDate.endOf('week').toISOString(), coupon, this.formatData)
    }   
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.videoListStatus !== this.props.videoListStatus && this.props.videoListStatus === "LOADED" && this.props.selectedCoupon || (prevState.selectedDate != this.state.selectedDate)){

      // ! Uncomment to showcase an arbitrary week of semester
      // today = moment(
      //   new Date().setDate(new Date().getDate() - 140)
      // ).toISOString();
      // oneWeekAgo = moment(
      //   new Date().setDate(new Date().getDate() - 147)
      // ).toISOString();
      // !

      let coupon = this.props.selectedCoupon.code;
      this.props.fetchHomePageVideoActivity(this.state.selectedDate.startOf('week').toISOString(), this.state.selectedDate.endOf('week').toISOString(), coupon, this.formatData)
    }
  }

  handleOnRangePickerChange = (value) => {
 
    this.setState({
      selectedDate: value
    })
    
  }

  customWeekStartEndFormat = value => {
    return `${moment(value).startOf('week').format(dateFormat)} ~ ${moment(value).endOf('week').format(dateFormat)}`;
  }

  // Two functions used to hash a string to a random color
  _hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  _intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
  }

  // Formatting/styling options of graph
  options = {
    responsive: true,
    indexAxis: "y",
    scales: {
      xAxes: [
        {
          barPercentage: 1,
          categoryPercentage: 1,
          ticks: { min: 0 },
          scaleLabel: {
            display: true,
            color: "blue",
            labelString: "Hours Watched",
            fontSize: 16,
            fontStyle: "bold",
          },
          gridLines: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          barPercentage: 1,
          categoryPercentage: 1,
          ticks: {
            autoSkip: false,
          },
          scaleLabel: {
            display: false,
            labelString: "Video Title",
            fontSize: 16,
            fontStyle: "bold",
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      title: { text: "Modules" },
      display: true,
      onClick: (_, e) => window.open(`/dashboard/videos/search/${e.text}`),
      labels: {
        // Dispalys module titles in legend
        generateLabels: () => {
          // Remove duplicates using Set
          let uniqueModules = [...new Set(this.state.chartModules)];
          return uniqueModules.map((title, i) => {
            return {
              text: title,
              // Color bars based on hash of module title
              fillStyle: `#${this._intToRGB(this._hashCode(title))}8F`,
            };
          });
        },
      },
    },
    onClick: (_, element) => {
      if (element[0] === undefined) return;
      let video = this.state.chartVideos[element[0]._index];
      window.location = `./videos/${video.moduleId}/${video.videoId}`;
    },

    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return `${tooltipItem.xLabel.toFixed(1)} hours`;
        },
      },
    },
  };

  // if class coupon is already set, fetch roster data for the given coupon
  

  // format fetch request data for input to video graph
  formatData = (data) => {
    // Add total views property (dataSum - # of minute bucket data
    // entries) to all videos
    for (let key in data) {
      // Convert data points -> hours (num * 60s / 3600s/hr)
      // Each data point represents a student viewing for 1 minute
      data[key].dataSum = data[key].data.reduce((a, b) => a + b) / 60;
    }

    // Returns sorted (by total watch time, high-low) data array of vids
    let sortedData = Object.entries(data)
      // Sort by dataSum property
      .sort((a, b) => {
        return b[1].dataSum - a[1].dataSum;
      })
      // Take greatest 5 sorted values
      .slice(0, 10)
      // Convert entries [string, obj] format to just video
      // Add videoId as property
      .map((arr) => {
        arr[1].videoId = parseInt(arr[0]);
        return arr[1];
      });

    // set labels as each video's title
    let labels = [];
    let modules = [];
    let dataSums = [];
    let videos = [];

    sortedData.forEach((video) => {
      labels.push(video.videoTitle);
      modules.push(
        this.props.videoList.filter((x) => {
          return x.videoTitle === video.videoTitle;
        })[0].moduleName
      );
      dataSums.push(video.dataSum);
      videos.push(video);
    });
    // Update data and labels in state
    this.setState({
      chartLabels: labels,
      chartModules: modules,
      chartDataSums: dataSums,
      chartVideos: videos
    });
  };

  render(){
    if (this.props.homePageVideoActivityDataStatus !== "LOADED" && this.props.homePageVideoActivityDataStatus !== "FAILED") {
      return (
        <Card className="dashboard-card" id="videos-watched-this-past-week"
            title={<>
              <Text>
                Video Data
              </Text>
              <br/>
              <Space>
                <Text type="secondary">Viewing Statistics for</Text>
                <DatePicker value={this.state.selectedDate} format={this.customWeekStartEndFormat} picker="week" onChange={this.handleOnRangePickerChange} allowClear={false}/>

              </Space>
            </>
          }
        >
          
          <Skeleton active />
        </Card>
      );
    } else if(this.props.homePageVideoActivityDataStatus === "FAILED"){
      return (<div>
        <Empty description="Fetch for data failed" />
      </div>) 
    }else if (this.state.chartDataSums.length === 0) {
      return (
        <Card
        id="videos-watched-this-past-week"
          extra={
            <Tooltip
              placement="left"
              title="Data from the top 10 most viewed videos of the past 7 days. Click on a bar to visit a video's individual data page."
            >
              <QuestionCircleOutlined style={{ fontSize: "150%" }} />
            </Tooltip>
          }
          title={<>
              <Text>
                Video Data
              </Text>
              <br/>
              <Space>
                <Text type="secondary">Viewing Statistics for</Text>
                <DatePicker value={this.state.selectedDate} format={this.customWeekStartEndFormat} picker="week" onChange={this.handleOnRangePickerChange} allowClear={false}/>

              </Space>
            </>
          }
          bordered={false}
        >
          <Empty description="No Video Data Available (Past 7 Days)" />
        </Card>
      );
    } else {
      let barChartData = {
        labels: this.state.chartLabels,
        datasets: [
          {
            // Color bars based on hash of module title
            backgroundColor: this.state.chartModules.map((x) => {
              return `#${this._intToRGB(this._hashCode(x))}8F`;
            }),
            barPercentage: 1,
            borderWidth: 4,
            data: this.state.chartDataSums,
          },
        ],
      };
    return (
      // Else, return data summaries
      <Card
        id="videos-watched-this-past-week"
        extra={
          <Tooltip
            placement="left"
            title="Data from the top 10 most viewed videos of the past 7 days. Click on a bar to visit a video's individual data page."
          >
            <QuestionCircleOutlined style={{ fontSize: "150%" }} />
          </Tooltip>
        }
        title={<>
          <Text>
            Video Data
          </Text>
          <br/>
          <Space>
            <Text type="secondary">Viewing Statistics for</Text>
            <DatePicker value={this.state.selectedDate} format={this.customWeekStartEndFormat} picker="week" onChange={this.handleOnRangePickerChange} allowClear={false}/>

          </Space>
        </>
      }
        bordered={false}
      >
        {/* Return horizontal bar chart of top 5 video watch times of week */}
        <HorizontalBar
          data={barChartData}
          options={this.options}
          height={50}
        />
      </Card>
    );
  }
}
};

const mapStateToProps = (state) => {
  return {
    homePageVideoActivityDataStatus: state.dashboardReducer.homePageVideoActivityDataStatus,
    homePageVideoActivityData: state.dashboardReducer.homePageVideoActivityData,
    selectedCoupon: state.dashboardReducer.selectedCoupon,
    videoList: state.dashboardReducer.videoList,
    videoListStatus: state.dashboardReducer.videoListStatus,
  };
};

export default connect(mapStateToProps, {fetchHomePageVideoActivity})(
  withRouter(HomePageVideoActivity)
);
