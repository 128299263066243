/******************************************************************
VideoCard.js
Written by Adam Gamba, Summer 2021

Single video informational card which links to its individual data
page
******************************************************************/

import { Component, useState } from "react";
import { clearVideoStatisticsDrawerData, fetchQnAStatistics, fetchQuizQuestionStatistics, fetchVideoStatistics } from '../../actions/dashboard';
import { connect } from 'react-redux';
import { withRouter, useHistory } from "react-router-dom";
import { Button, Card, Col, Divider, Drawer, Progress, Row, Space, Statistic, Tooltip, Typography } from "antd";
import { BarChartOutlined, FundViewOutlined, PlayCircleOutlined, SwapRightOutlined } from "@ant-design/icons";
import QuizQuestionStatistics from './QuizQuestionStatistics';
import "./Home.css";
import QnAStatistics from "./QnAStatistics";

const { Text } = Typography;

const determineProgressBarColor = (percentage) => {
  if(percentage == 100){
    return "#9FE2BE"
  }else if(percentage > 50 && percentage < 100) {
    return "#84AFEF"
  }else if(percentage > 25 && percentage <= 50) {
    return "#FFD177"
  }else {
    return "#E29F9F"
  }
}

// Info Card for individual video
class VideoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleStatisticsDrawer: false,
      quizQuestionIndex: 0
    }
  }

  
  render(){
      // Example video: { id: 10, title: "A Simple Machine" }
    return <Card style={{width: "25%", paddingBottom: "5px"}}>
            <div style={{display: "flex", marginBottom: "5px"}}>
              <div style={{textOverflow: "ellipsis", overflow:"hidden", whiteSpace: "nowrap", marginRight: "10px"}}>
                <Text style={{fontSize: "20px"}}>{`${this.props.video.title}`}</Text>
              </div>
              <Space>
                <Tooltip title="Play">
                  <Button 
                    type="primary" 
                    shape="circle" 
                    icon={<PlayCircleOutlined />} 
                    size="small"
                    onClick={()=>{
                      this.props.history.push(`/mock/video`)
                    }}
                  />
                </Tooltip>
                <Tooltip title="View Statistics">
                  <Button 
                    icon={<BarChartOutlined />} 
                    shape="circle"
                    size="small"
                    onClick={()=>{
                      this.setState({
                        visibleStatisticsDrawer: true
                      });
                    }}
                  />
                </Tooltip>
              </Space>
            </div>
              <Text type="secondary">Watch Progress</Text>
              <br/>
              <Progress
                strokeWidth={12}
                strokeColor={determineProgressBarColor(this.props.video.userWatchPercentage * 100)}
                percent={this.props.video.userWatchPercentage * 100}
              />
              <Text type="secondary">Class Median Watch Progress</Text>
              <br/>
              <Progress
                strokeWidth={12}
                strokeColor={"#6A7079"}
                percent={Math.round(this.props.video.classMedianWatchPercentage * 100)}
              />
              <Row style={{margin: "12px 0px 20px 0px"}}>
                <Col span={12}>
                  <Statistic 
                    title="Quiz Questions" 
                    value={this.props.video.quizQuestionsCorrectlyAnswered} 
                    suffix={`/ ${this.props.video.totalNumberOfQuizQuestions }`} 
                    className="dashboard-video-grid-statistic"
                  />
                </Col>
                <Col span={12}>
                  <Statistic 
                    title="Questions Asked"
                    value={this.props.video.totalNumberOfQnaQuestions} 
                    className="dashboard-video-grid-statistic"
                  />
                </Col>
              </Row>
            
              <Drawer 
                title={this.props.video.title}
                placement="right" 
                visible={this.state.visibleStatisticsDrawer}
                onClose={()=>this.setState({
                  visibleStatisticsDrawer: false
                })}
                width={1400}
                maskClosable={false}
              >
                {this.props.video.title}
                <Row >
                  <Col span={8}>
                    <Text type="secondary">Watch Progress</Text>
                    <br/>
                    <Progress
                      strokeWidth={12}
                      strokeColor={determineProgressBarColor(Math.round(this.props.video.userWatchPercentage * 100))}
                      percent={Math.round(this.props.video.userWatchPercentage * 100)}
                    />
                    <br/>
                    <br/>
                    <Text type="secondary">Class Median Watch Progress</Text>
                    <br/>
                    <Progress
                      strokeWidth={12}
                      strokeColor={"#6A7079"}
                      percent={Math.round(this.props.video.classMedianWatchPercentage * 100)}
                    />
                  </Col>
                  <Col span={1}>

                  </Col>
                  <Col span={15}>
                    <Row>
                      <Col span={8}>
                        <Statistic 
                          precision={2}
                          title="Your Avg Playback Speed" value={ this.props.video.videoStatistics.userAvgPlaybackRate} 
                          className="dashboard-video-grid-statistic"
                          suffix={"x"}
                        />
                      </Col>
                      <Col span={8}>
                        <Statistic 
                          title="Your Time Spent" 
                          value={this.props.video.videoStatistics.userTimeSpent} 
                          className="dashboard-video-grid-statistic"
                          formatter={(value) => {
                            let remainder = value;
                            let hours = Math.floor(remainder / 3600);
                            remainder = remainder % 3600;
                            let minutes = Math.floor(remainder / 60);
                            let seconds = Math.floor(remainder % 60);
                            return `${hours}hr ${minutes}min ${seconds}s`
                          }}
                        />
                      </Col>
                      <Col span={8}>

                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col span={8}>
                        <Statistic 
                          precision={2}
                          title="Class Median Playback Speed" value={this.props.video.videoStatistics.classAvgPlaybackRate} 
                          className="dashboard-video-grid-statistic"
                          suffix={"x"}
                        />
                      </Col>
                      <Col span={8}>
                        <Statistic 
                          title="Class Median TimeSpent" 
                          value={ this.props.video.videoStatistics.classMedianWatchTime} 
                          className="dashboard-video-grid-statistic"
                          formatter={(value) => {
                            let remainder = value;
                            let hours = Math.floor(remainder / 3600);
                            remainder = remainder % 3600;
                            let minutes = Math.floor(remainder / 60);
                            let seconds = Math.floor(remainder % 60);
                            return `${hours}hr ${minutes}min ${seconds}s`
                          }}
                        />
                      </Col>
                      <Col span={8}>

                      </Col>
                    </Row>
                  </Col>
                  
                  <Col span={6}>
                    
                    
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={3}>
                    <Statistic
                    title="Quiz Questions" 
                    value={this.props.video.quizQuestionsCorrectlyAnswered} 
                    suffix={`/ ${this.props.video.totalNumberOfQuizQuestions}`} 
                    className="dashboard-video-grid-statistic"
                    />
                  </Col>
             
                  <QuizQuestionStatistics
                    quizQuestionStatistics={this.props.video.quizQuestionStatistics}
                    quizQuestionStatisticsStatus={this.props.video.quizQuestionStatisticsStatus}
                    quizQuestionIndex={this.state.quizQuestionIndex}
                    setQuizQuestionIndex={(value)=>this.setState({
                     quizQuestionIndex: value
                    })}
                    totalNumOfQuizQuestions={this.props.video.totalNumberOfQuizQuestions}
                  />
                </Row>
                <Divider />

                <QnAStatistics 
                  qnAStatistics={this.props.video.qnAStatistics}
                  qnAStatisticsStatus={this.props.qnAStatisticsStatus}
                />
              </Drawer>
          </Card>
  };
}

export default withRouter(VideoCard);
