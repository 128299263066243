import VideoCard from "./VideoCard";
import moment from 'moment';

const VideoCardRenderer = ({index, collection, collectionId, isAuthenticated, mode, module, moduleId, moduleIndex, moduleName, moduleAccess, playVideoInModal, price, unlistedKey, video }) => {
    if(mode === "purchase"){
        if(isAuthenticated && moduleAccess[module.id]){
            if(moduleAccess[module.id].accessType == "permanent") {
                return <VideoCard 
                            index={index}
                            collection={collection}
                            collectionId={collectionId}
                            mode={mode}
                            module={module}
                            moduleId={moduleId}
                            moduleIndex={moduleIndex}
                            moduleName={moduleName}
                            moduleAccess={moduleAccess}
                            price={price}
                            unlistedKey={unlistedKey}
                            video={video}
                        />
            }
            if(moduleAccess[module.id].accessType == "temporary") {
                let currentDate = moment(new Date())
                let openDate = moment(new Date(moduleAccess[module.id].accessFrom)).startOf('day');
                let closeDate = moment(new Date(moduleAccess[module.id].accessUntil)).endOf('day');

                if(currentDate >= openDate && currentDate < closeDate){
                    
                    return <VideoCard 
                                index={index}
                                collection={collection}
                                collectionId={collectionId}
                                mode={mode}
                                module={module}
                                moduleId={moduleId}
                                moduleIndex={moduleIndex}
                                moduleName={moduleName}
                                moduleAccess={moduleAccess}
                                price={price}
                                unlistedKey={unlistedKey}
                                video={video}
                            />
                }
            }
        }

        return <VideoCard 
                index={index}
                collection={collection}
                collectionId={collectionId}
                module={module}
                moduleId={moduleId}
                mode={mode}
                moduleName={moduleName}
                moduleAccess={moduleAccess}
                moduleIndex={moduleIndex}
                price={price}
                video={video}
                unlistedKey={unlistedKey}
                locked
            />
    }

    return <VideoCard 
                index={index}
                collectionId={collectionId}
                module={module}
                moduleId={moduleId}
                mode={mode}
                moduleName={moduleName}
                moduleAccess={moduleAccess}
                moduleIndex={moduleIndex}
                video={video}
                playVideoInModal={playVideoInModal}
                price={price}
                unlistedKey={unlistedKey}
                locked
            />
    //return 
}

export default VideoCardRenderer;