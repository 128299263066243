import AddModulesStep from "./AddModulesStep";
import CollectionUpdateResultStep from "./CollectionUpdateResultStep";
import CollectionDetailsStep from "./CollectionDetailsStep";
import ReviewCollectionChangesStep from "./ReviewCollectionChangesStep";


const CollectionEditorSteps = ({ allVideos, authorsDict, collection, createdModules, currentStep, currentUser, goToNextStep, goToPreviousStep, modules, setUserCreatedModules, updateState, videoBank }) => {
    if(currentStep === 0){
        return  <CollectionDetailsStep 
                    authorsDict={authorsDict}
                    collection={collection} 
                    currentUser={currentUser}
                    goToNextStep={goToNextStep} 
                    updateState={updateState}
                />
    } else if(currentStep == 1) {
        return  <AddModulesStep 
                    allVideos={allVideos}
                    authorsDict={authorsDict}
                    collection={collection} 
                    currentUser={currentUser}
                    goToNextStep={goToNextStep} 
                    goToPreviousStep={goToPreviousStep} 
                    modules={modules}
                    updateState={updateState}
                    videoBank={videoBank}
                    createdModules={createdModules}
                    setUserCreatedModules={setUserCreatedModules}
                />
    } else if(currentStep == 2) {
        return  <ReviewCollectionChangesStep
                    authorsDict={authorsDict}
                    collection={collection} 
                    currentUser={currentUser}
                    modules={modules}
                    goToNextStep={goToNextStep} 
                    goToPreviousStep={goToPreviousStep} 
                />
    } else if(currentStep == 3) {
        return  <CollectionUpdateResultStep 
                    goToPreviousStep={goToPreviousStep} 
                />
    }
}

export default CollectionEditorSteps;