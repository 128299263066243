import { Button, Form, Input } from "antd";
import { fetchVideoDataFromURL } from "../actions/contentCreation";
import { useDispatch } from "react-redux";
import { parseVimeoData, parseYouTubeData } from "./CollectionCreatorHelper";
import { DownloadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useState } from "react";

const ImportMoreVideos = ({ modules, videoBank, afterImport }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [importInProgress, setImportInProgress] = useState(false);

    // On submit
    const onSubmit = (values) => {
        if (values.url) {
            setImportInProgress(true);
            importVideos(values.url);
        }
    };

    // Set error message
    const setErrorMessage = (message) => {
        form.setFields([
            {
                name: "url",
                errors: [message]
            }
        ]);
    };

    // Check existing video in module videos
    const existingVideoInModuleVideos = (videoToCheck) => {
        let foundInModules = false;
        modules.forEach((m) => {
            if (foundInModules) {
                return;
            }
            const f = m.videos.some(
                (v) =>
                    v.videoType === videoToCheck.videoType &&
                    v.embedId === videoToCheck.embedId
            );
            if (!foundInModules && f) {
                foundInModules = true;
            }
        });
        return foundInModules;
    };

    // Check existing video in video bank
    const existingVideoInVideoBank = (videoToCheck) => {
        return videoBank.some(
            (vb) =>
                vb.videoType === videoToCheck.videoType &&
                vb.embedId === videoToCheck.embedId
        );
    };

    // Filter new imported videos from existing videos
    const filterExistingVideos = (videosToImport) => {
        const videos = [];
        videosToImport.forEach((videoToImport) => {
            if (
                !existingVideoInVideoBank(videoToImport) &&
                !existingVideoInModuleVideos(videoToImport)
            ) {
                videos.push(videoToImport);
            }
        });
        return videos;
    };

    const importVideos = (url) => {
        const handleOnNewVideoByURLError = () => {
            return setErrorMessage("Invalid video or playlist URL");
        };

        dispatch(
            fetchVideoDataFromURL(
                url.trim(),
                parseVimeoData,
                parseYouTubeData,
                handleOnNewVideoByURLError
            )
        ).then((videosToImport) => {
            if (videosToImport.length === 0) {
                setImportInProgress(false);
                return setErrorMessage("No videos found from this URL");
            }
            const filteredVideos = filterExistingVideos(videosToImport);
            if (videosToImport.length > 0 && filteredVideos.length === 0) {
                setImportInProgress(false);
                return setErrorMessage(
                    "Imported videos existing in video bank"
                );
            }

            setImportInProgress(false);
            afterImport(filteredVideos);
            form.resetFields();
        });
    };

    return (
        <Form
            name="basic"
            className="import-more-video-form"
            form={form}
            initialValues={{ url: "" }}
            layout="vertical"
            onFinish={onSubmit}
            autoComplete="off">
            <Form.Item
                name="url"
                extra={
                    <>
                        <InfoCircleOutlined /> Use any YouTube (video/playlist)
                        or Vimeo (video/showcase) URL
                    </>
                }
                rules={[
                    {
                        required: true,
                        message: "Please give your course YouTube or Vimeo URL."
                    }
                ]}>
                <Input placeholder="YouTube or Vimeo URL" />
            </Form.Item>
            <Button
                type="primary"
                loading={importInProgress}
                icon={<DownloadOutlined />}
                htmlType="submit"></Button>
        </Form>
    );
};

export default ImportMoreVideos;
