import { Button, Form, Input, Result, Typography } from "antd";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import { useState } from 'react';
import { API_URL } from "../const";

const { Title } = Typography;

const ContactUsForm = () => {
    const [contactUsFormStatus, setContactUsFormStatus] = useState("NOT SUBMITTED");



    const validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',

        },
 
      };

    const onFinish = (values) => {
        setContactUsFormStatus("LOADING")
        fetch(API_URL+'/api/v1/contact-us/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })
        .then(response => {
            if(response.ok){
                return response.json()
            } else {
                throw new Error(response.status)
            }
        })
        .then(data => {
            setContactUsFormStatus("SUBMITTED")
            return
        })
        .catch(error => {
            console.log(error)
            setContactUsFormStatus("FAILED")
        })
    };


    if(contactUsFormStatus === "SUBMITTED"){
        return <div id="contact-us-form-container">
                    <div id="contact-us-form">
                        <Result
                            icon={<SmileOutlined />}
                            title="Great, we have received your email and will contact you soon!"
                            extra={<Button type="primary" onClick={()=>setContactUsFormStatus("NOT SUBMITTED")}>Send another message</Button>}
                        />
                    </div>
                </div>
    } else if(contactUsFormStatus === "FAILED") {
        return <div id="contact-us-form-container">
                    <div id="contact-us-form">
                        <Result
                            icon={<FrownOutlined />}
                            title="Unfortunately, something went wrong with sending your message. Please try again later."
                        />
                    </div>
                </div>
    } else {
        return <div id="contact-us-form-container">
                    <div id="contact-us-form">
                        <Title level={4}>Contact Us</Title>
                        <Form onFinish={onFinish} layout="vertical" validateMessages={validateMessages}>
                            <Form.Item name={['name']} label="Name" rules={[{ required: true }]}>
                                <Input className="contact-us-form-input" />
                            </Form.Item>
                            <Form.Item name={['email']} label="Email" rules={[{ type: 'email', required: true }]}>
                                <Input className="contact-us-form-input"/>
                            </Form.Item>
                            <Form.Item name={['subject']} label="Subject" rules={[{ required: true }]}>
                                <Input className="contact-us-form-input"/>
                            </Form.Item>
                            <Form.Item name={['message']} label="Message" rules={[{ required: true }]}>
                                <Input.TextArea className="contact-us-form-input"/>
                            </Form.Item>
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={contactUsFormStatus === "LOADING"}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
    }
}

export default ContactUsForm;