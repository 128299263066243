import { Button, Card, Col, Image, Row, PageHeader, Select, Skeleton, Space, Tooltip, Typography } from 'antd'; 
import { EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { connect } from 'react-redux';
import { fetchAllDepartments, fetchAllUniversities } from '../actions/user';
import { updateCreatorProfile } from '../actions/creator';
import CreatorProfilePicture from './CreatorProfilePicture';

const { Link, Paragraph, Text, Title } = Typography;
const { Option } = Select;
const CreatorProfile = ({ creatorId, creatorProfile, creatorProfileStatus, departments, fetchAllDepartments, fetchAllUniversities, isAuthenticated, updateCreatorProfile, user, universities }) => {
    const [editingAffiliations, setEditingAffiliations] = useState(false);
    const [editingDepartments, setEditingDepartments] = useState(false);
    const [fetchedUniversities, setFetchedUniversities] = useState(false);
    const [fetchedDepartments, setFetchedDepartments] = useState(false);
    const [newUniversityList, setNewUniversityList] = useState([]);
    const [updatingUniversityList, setUpdatingUniversityList] = useState(false);
    const [newDepartmentList, setNewDepartmentList] = useState([]);
    const [updatingDepartmentList, setUpdatingDepartmentList] = useState(false);

    if(!fetchedDepartments){
        
        fetchAllDepartments()
        setFetchedDepartments(true)
    }

    if(!fetchedUniversities){
        fetchAllUniversities()
        setFetchedUniversities(true)
    }

    const onBioChange = (value) => {
        updateCreatorProfile(user.id, {
            "bio": value
        }, ()=>{}, ()=>{})
    }

    const onWebsiteChange = (value) => {
        updateCreatorProfile(user.id, {
            "externalWebsite": value
        }, ()=>{}, ()=>{})
    }

    const handleOnUniversityListChange = (values) => {
        setNewUniversityList(values)
    }

    const handleOnUniversityListSave = () => {
        updateCreatorProfile(user.id, {
            "universities": newUniversityList
        }, setUpdatingUniversityList, ()=>setEditingAffiliations(false))
    }
    const handleOnDepartmentListSave = () => {
        updateCreatorProfile(user.id, {
            "departments": newDepartmentList
        }, setUpdatingDepartmentList, ()=>setEditingDepartments(false))
    }

    const handleOnDepartmentListChange = (values) => {
        setNewDepartmentList(values)
    }

    if(creatorProfileStatus == "LOADED") {
        if(user && user.id == creatorId) {
            return <PageHeader
                        title={`${creatorProfile.firstName} ${creatorProfile.lastName}`}
                        subTitle={`${creatorProfile.username}`}
                        ghost={false}
                        onBack={() => window.history.back()}
                        id="author-profile"
                    >
                            <Row>
                                <Col xs={24} sm={24} md={6} lg={5} xl={4}>
                                    <CreatorProfilePicture 
                                        existingCoverImage={creatorProfile.profilePicture}
                                        isOwner={user ? user.id == creatorId : false}
                                        userId={user ? user.id : null}
                                    />
                                </Col>
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}>

                                </Col>
                                <Col xs={24} sm={24} md={17} lg={18} xl={19}>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>

                                            <Text type="secondary">
                                                Affiliations
                                            </Text>
                                            <br/>
                                            
                                            {
                                                !editingAffiliations ?   <>
                                                                            <Space align="start">
                                                                                <Text>
                                                                                    {creatorProfile.universities.map(university => university.name).join(', ')}
                                                                                </Text>
                                                                                <Tooltip title="Edit">
                                                                                    <EditOutlined className="edit-icon" onClick={()=>setEditingAffiliations(true)}/>
                                                                                </Tooltip>
                                                                            </Space>
                                                                        </>
                                                                    :   <>
                                                                            <Select 
                                                                                placeholder="Please select a university" 
                                                                                mode="tags" 
                                                                                defaultValue={creatorProfile.universities.map(university=>university.name)}
                                                                                onChange={handleOnUniversityListChange}
                                                                            >
                                                                                {universities.map(university => <Option value={university.name} key={university.name}>{university.name}</Option>)}
                                                                            </Select>
                                                                            <br/>
                                                                            <Space style={{marginTop: 5}}>
                                                                                <Button 
                                                                                    type="primary" 
                                                                                    size="small" 
                                                                                    onClick={handleOnUniversityListSave}
                                                                                    loading={updatingUniversityList}
                                                                                >
                                                                                    Save
                                                                                </Button>
                                                                                <Button 
                                                                                    size="small" 
                                                                                    onClick={()=>setEditingAffiliations(false)}
                                                                                >
                                                                                    Cancel
                                                                                </Button>
                                                                            </Space>
                                                                        </>
                                            }
                        
                                        </Col>
                                        <Col xs={0} sm={0} md={0} lg={0} xl={1}>

                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <Text type="secondary">
                                                Departments
                                            </Text>
                                            <br/>
                                            {
                                                !editingDepartments ?   <>
                                                                            <Space align="start">
                                                                                <Text>
                                                                                    {creatorProfile.departments.map(department => department.name).join(', ')}
                                                                                </Text>
                                                                                <Tooltip title="Edit">
                                                                                    <EditOutlined className="edit-icon" onClick={()=>setEditingDepartments(true)}/>
                                                                                </Tooltip>
                                                                            </Space>
                                                                        </>
                                                                    :   <>
                                                                            <Select 
                                                                                placeholder="Please select a university" 
                                                                                mode="tags" 
                                                                                defaultValue={creatorProfile.departments.map(department=>department.name)}
                                                                                onChange={handleOnDepartmentListChange}
                                                                            >
                                                                                {departments.map(department => <Option value={department.name} key={department.name}>{department.name}</Option>)}
                                                                            </Select>
                                                                            <br/>
                                                                            <Space style={{marginTop: 5}}>
                                                                                <Button 
                                                                                    type="primary" 
                                                                                    size="small" 
                                                                                    onClick={handleOnDepartmentListSave}
                                                                                    loading={updatingDepartmentList}
                                                                                >
                                                                                    Save
                                                                                </Button>
                                                                                <Button 
                                                                                    size="small" 
                                                                                    onClick={()=>setEditingDepartments(false)}
                                                                                >
                                                                                    Cancel
                                                                                </Button>
                                                                            </Space>
                                                                        </>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <br/><br/>
                                            <Text type="secondary">Website</Text>
                                            <Paragraph editable={{ onChange: onWebsiteChange }}>{creatorProfile.externalWebsite}</Paragraph>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <br/><br/>

                                            <Text type="secondary">Bio</Text>
                                            <Paragraph editable={{ onChange: onBioChange }}>{creatorProfile.bio}</Paragraph>
                                        </Col>
                                    </Row>
                                </Col>
                            
                            </Row>

                    </PageHeader>
        } else {
            return <PageHeader
                        title={`${creatorProfile.firstName} ${creatorProfile.lastName}`}
                        subTitle={`${creatorProfile.username}`}
                        ghost={false}
                        onBack={() => window.history.back()}
                        id="author-profile"
                    >
                  
                    <Row>
                        <Col xs={24} sm={24} md={6} lg={5} xl={4}>
                            <div>
                                <CreatorProfilePicture 
                                    existingCoverImage={creatorProfile.profilePicture}
                                    isOwner={user ? user.id == creatorId : false}
                                    userId={user ? user.id : null}
                                />
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={1} lg={1} xl={1}>

                        </Col>
                        <Col xs={24} sm={24} md={17} lg={18} xl={19}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={11} xl={11}>

                                    <Text type="secondary">
                                        Affiliations
                                    </Text>
                                    <br/>
                                    
                                    <Space align="start">
                                        <Text>
                                            {creatorProfile.universities.map(university => university.name).join(', ')}
                                        </Text>
                                        
                                    </Space>
                                    <br/><br/>
                                </Col>
                                <Col xs={0} sm={0} md={0} lg={0} xl={1}>

                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Text type="secondary">
                                        Departments
                                    </Text>
                                    <br/>
                                   
                                    <Space align="start">
                                        <Text>
                                            {creatorProfile.departments.map(department => department.name).join(', ')}
                                        </Text>
                                        
                                    </Space>
                                
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <br/>

                                    <Text type="secondary">Website</Text><br/>
                                    {
                                        creatorProfile.externalWebsite.length ? <Link href={creatorProfile.externalWebsite} target="_blank" >
                                                                                    {creatorProfile.externalWebsite}
                                                                                </Link> : "None"

                                    }
                                    
                                </Col>                            
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <br/>

                                    <Text type="secondary">Bio</Text>
                                    <Paragraph >{creatorProfile.bio}</Paragraph>
                                </Col>
                            </Row>
                        </Col>
                      
                    </Row>
                </PageHeader>
        }
        
    } else {
        return <PageHeader 
                    ghost={false}
                    onBack={() => window.history.back()}
                    id="author-profile"
                >
                    <Row>
                        <Col span={6}>
                            <Skeleton active paragraph={false}/>
                        </Col>
                        <Col span={2}>

                        </Col>
                        <Col span={16}>
                            <Row>
                                <Col span={11}>
                                
                                    <Text type="secondary">
                                        Affiliations
                                    </Text>
                                    <br/>
                                    <Text>
                                        <Skeleton active paragraph={false}/>
                                    </Text>

                                    <br/> <br/>
                                    <Text type="secondary">Bio</Text>
                                    <Skeleton active title={false}/>
                                </Col>
                                <Col span={1}>

                                </Col>
                                <Col span={12}>
                                    <Text type="secondary">
                                        Departments
                                    </Text>
                                    <br/>
                                    <Text>
                                        <Skeleton active paragraph={false}/>
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                       
                    </Row>
                </PageHeader>
    }

   
}


const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        isAuthenticated: state.userReducer.isAuthenticated,
        departments: state.userReducer.departments,
        universities: state.userReducer.universities
    }
}
export default connect(mapStateToProps, { fetchAllDepartments, fetchAllUniversities, updateCreatorProfile })(CreatorProfile);