import { Component } from 'react';
import { Breadcrumb, Button, Col, Divider, Image, message, Modal, Progress, Row, Space, Table, Typography, Upload } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, HomeOutlined, InboxOutlined, SendOutlined, UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchTranscriptsVideoList, fetchVideosToCurate, setUploadTranscriptResult, setUploadTranscriptStatus, uploadTranscripts } from '../actions/admin';
import { CSVLink } from "react-csv";


const { Paragraph, Text, Title } = Typography; 


const columns = [
    {
      title: 'Video Title',
      dataIndex: 'title',
      key: 'title',
      render: text => <Text>{text}</Text>
    },
    {
      title: 'Transcript Status',
      key: 'transcriptStatus',
      dataIndex: 'transcriptStatus',
      render: status => (
        <>
          {status ? <Text type="success"><CheckCircleOutlined /></Text> : <Text type="danger"><CloseCircleOutlined /></Text>}
        </>
      ),
    },
    {
        title: 'Transcript Version',
        dataIndex: 'transcriptVersion',
        key: 'transcriptVersion',
        render: versionNum => (
          <>
            {versionNum ? versionNum : "-"}
          </>
        ),
      },
]



class AdminTranscripts extends Component {

    constructor(props){
        super(props);
        this.state = {
            files: [],
            pagination: {
                current: 1,
                pageSize: 25,
                showSizeChanger: false
            },
            loading: false,
            visible: false,
            visibleCSVModal: false,
        }
    }
    componentDidMount(){
        this.props.fetchTranscriptsVideoList(1, this.updateTableState);
    }

    handleTableChange = (pagination) => {
        this.props.fetchTranscriptsVideoList(pagination.current, this.updateTableState);
    };


    updateTableState = (pageNum, pageSize, total) => {
        this.setState({
            loading: false,
            pagination: {
                current: pageNum,
                pageSize: pageSize,
                total: total,
                showSizeChanger: false,
                // 200 is mock data, you should read it from server
                // total: data.totalCount,
            },
        });
    }
    
    handleOnUploadChange = event => {
        for (var i = 0; i < event.target.files.length; i++) {
            let fileName = event.target.files[i].name;
            let videoEmbedId = fileName.split('.')[0]        
            const fileReader = new FileReader();
            fileReader.readAsText(event.target.files[i], "UTF-8");
            fileReader.onload = e => {
                this.setState({
                    files: [...this.state.files, {
                        embedId: videoEmbedId,
                        transcript: JSON.parse(e.target.result)
                    }]
                })
            };
        }
        
    }   

    handleOnFinish = () => {
        this.setState({
            files: []
        })
        this.props.setUploadTranscriptStatus("NOT LOADED")
        this.props.setUploadTranscriptResult({})
        this.props.fetchTranscriptsVideoList(1, this.updateTableState);
    }

    initiateDownload = () => {
        this.props.fetchVideosToCurate()
        this.setState({
            visibleCSVModal: true
        })
    }

    TranscriptUploader = () => {
        if(this.state.files.length && this.props.uploadTranscriptStatus === "NOT LOADED"){
            
            return <div >
                <Title level={5}>Upload transcripts</Title>
                <div id="confirm-transcript-upload">
                    <div id="upload-confirm-label">
                        <div>
                            <Paragraph>{this.state.files.length} files ready to import</Paragraph>
                            <Button type="primary" onClick={()=>this.props.uploadTranscripts(this.state.files)} >Start Upload</Button>
                        </div>
                    </div>
                </div>
            </div>
        } else if(this.state.files.length && this.props.uploadTranscriptStatus === "LOADING"){
            return <div>
                <div id="confirm-transcript-upload">
                    <div id="uploading-animation-container">
                        <SendOutlined id='uploading-animation'/>
                        
                    </div>
                    <div id="upload-confirm-label">
                        <Text style={{marginTop: 100}}>Sending transcripts to API...</Text>
                    </div>
                </div>
            </div>
        } else if(this.state.files.length && this.props.uploadTranscriptStatus === "FAILED"){
            return <div>
                <div id="confirm-transcript-upload">
                    <div id="upload-confirm-label">
                        <Text>FAILED</Text>
                    </div>
                </div>
            </div>
        } else if(this.state.files.length && this.props.uploadTranscriptStatus === "SUCCESS"){
            return <div>
                <div id="confirm-transcript-upload">
                    <div id="upload-confirm-label">
                   
                        <Paragraph>
                            
                            <div style={{ margin: "0 auto", width: "80%", textAlign: "left"}}>
                                <Title level={3}>Upload Results</Title>
                                <Text>Of the {this.state.files.length} files that you uploaded,</Text>
                                <ul>
                                <li>
                                    <Text>{this.props.result.succeeded} succeeded.</Text>
                                </li>
                                <li>
                                    <Text>{this.props.result.failed} failed.</Text>
                                </li>
                                <li>
                                    <Text>{this.props.result.dne} did not exist.</Text>
                                </li>
                                </ul>
                            </div>
                            <Button type="primary" onClick={this.handleOnFinish} >Finish</Button>
                        </Paragraph>
                        
                    </div>
                </div>
            </div>
        } else {
            return <div>
                <Title level={5}>Upload transcripts</Title>
                <div id="transcript-uploader">
                    <label for="upload-transcript" id="upload-transcript-label"><div><Text strong>Click to upload files</Text> <Text type="secondary">(drop not yet implemented)</Text></div></label>
                    <input id='upload-transcript' type="file" onChange={this.handleOnUploadChange} multiple />
                </div>
            </div>
        }
        
    }

   
    VideoCSVDownloader = () => {
        if(this.props.videosToCurateStatus === "LOADING"){
            return <div>
                <div id="confirm-transcript-upload">
                    <div id="uploading-animation-container">
                        <SendOutlined id='uploading-animation'/>
                        
                    </div>
                    <div id="upload-confirm-label">
                        <Text style={{marginTop: 100}}>Generating CSV...</Text>
                    </div>
                </div>
            </div>
        } else if(this.props.videosToCurateStatus === "FAILED"){
            return <div>
                <div id="confirm-transcript-upload">
                    <div id="upload-confirm-label">
                        <Text>FAILED</Text>
                    </div>
                </div>
            </div>
        } else if(this.props.videosToCurateStatus === "LOADED"){

            const renderCSVData = () => {
                
                const result = []
                
                result.push(["Video ID", "Video Embed ID", "Video Type", "Video Link", "Module ID", "Module Name", "Course ID", "Course Name"])
                this.props.videosToCurate.map((video, i) => {
                    let videoLink;
                    if(video.videoType === "youtube"){
                        videoLink = `https://www.youtube.com/watch?v=${video.embedId}`
                    } else if(video.videoType === "vimeo") {
                        videoLink = `https://vimeo.com/${video.embedId}`
                    } else {
                        videoLink = "Invalid video type"
                    }
                    result.push([video.id, video.embedId, video.videoType, videoLink, video.parentModule, video.parentModuleName, video.parentCollection, video.parentCollectionName])
                })
                return result
            }
            return <div>
                <div id="confirm-transcript-upload">
                    <div id="upload-confirm-label">
                   
                        <Paragraph>
                            
                            <div style={{ margin: "0 auto", width: "80%", textAlign: "left"}}>
                                <Title level={3}>Your CSV is now ready to download.</Title>
                            </div>
                            <CSVLink data={renderCSVData()} filename={"videos-to-curate.csv"}><Button icon={<DownloadOutlined />} type="primary">Download CSV</Button></CSVLink>
                        </Paragraph>
                        
                    </div>
                </div>
            </div>
        } else {
            return <div>
                <Title level={5}>Upload transcripts</Title>
                <div id="confirm-transcript-upload">
                    
                </div>
            </div>
        }
        
    }
    render() {
        return <div className="centered-container">
                <Space className="breadcrumbs">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/"><HomeOutlined /></Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/admin">Admin Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Transcripts</Breadcrumb.Item>
                    </Breadcrumb>
                </Space>
                <Divider orientation="left" orientationMargin="0">
                    Actions
                </Divider>
                <Space>
                    <Button size="large" icon={<UploadOutlined />} onClick={()=>this.setState({ visible: true})} type="primary">Upload Transcripts</Button>
                    <Button size="large" icon={<DownloadOutlined />} onClick={this.initiateDownload} >Download CSV of Videos to Curate</Button>
                </Space>
                <Modal
                    footer={null} 
                    visible={this.state.visible} 
                    onCancel={()=>{
                        this.setState({ visible: false})
                        this.handleOnFinish()
                    }}
                    maskClosable={false}
                >
                    <div className='standard-modal-content'>
                        <this.TranscriptUploader />
                    </div>
                </Modal>

                <Modal
                    footer={null} 
                    visible={this.state.visibleCSVModal} 
                    onCancel={()=>{
                        this.setState({ visibleCSVModal: false})
                    }}
                    maskClosable={false}
                >
                    <div className='standard-modal-content'>
                        <this.VideoCSVDownloader />
                    </div>
                </Modal>
                <br/>
                <br/>
                <Table columns={columns} dataSource={this.props.videoList} pagination={this.state.pagination} onChange={this.handleTableChange} loading={this.props.videoListStatus === "LOADING"}/>
            </div>
    }
   
}

const mapStateToProps = state => {
    return {
        videoList: state.adminReducer.transcriptsVideoList,
        videoListStatus: state.adminReducer.transcriptsVideoListStatus,
        result: state.adminReducer.uploadTranscriptResult,
        uploadTranscriptStatus: state.adminReducer.uploadTranscriptStatus,
        videosToCurate: state.adminReducer.videosToCurate,
        videosToCurateStatus: state.adminReducer.videosToCurateStatus
    }
}

export default connect(mapStateToProps, { fetchTranscriptsVideoList, fetchVideosToCurate, setUploadTranscriptResult, setUploadTranscriptStatus, uploadTranscripts })(AdminTranscripts);