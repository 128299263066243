import { Component } from 'react';
import { Breadcrumb, Button, Card, Col, Divider, Modal, Row, Space, Table, Typography } from 'antd';
import { DownloadOutlined, HomeOutlined, SendOutlined, UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchClassCodeDetail } from '../actions/admin';
import { CSVLink } from "react-csv";
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';


const { Paragraph, Text, Title } = Typography; 


const columns = [
    {
        title: 'Username',
        key: 'username',
        dataIndex: 'username',
        render: username => <Text>{username}</Text>,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      render: email => <Text>{email}</Text>
    }
]


class AdminClassCodeRoster extends Component {

    constructor(props){
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.fetchClassCodeDetail(this.props.match.params.classCode)
    }

    renderCSVData = () => {
                
        const result = []
        
        result.push(["Username", "Email"])
        this.props.classCodeDetail.roster.map((user, i) => {
            result.push([user.username, user.email])
        })
        return result
    }

    CouponDate = ({date}) => {
        let dateObject = new Date(date)
        dateObject = new Date(dateObject.getTime() + dateObject.getTimezoneOffset() * 60000);
        dateObject.setHours(0, 0, 0, 0)
        return <Text>{dateObject.toLocaleString()}</Text>
    }

    render() {
        return <div className="centered-container">
                <Space className="breadcrumbs">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/"><HomeOutlined /></Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/admin">Admin Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/admin/classcodes'>Class Codes</Link></Breadcrumb.Item>
                        <BreadcrumbItem>{this.props.classCodeDetail.label ? this.props.classCodeDetail.label : this.props.match.params.classCode}</BreadcrumbItem>
                        <Breadcrumb.Item>Roster</Breadcrumb.Item>
                    </Breadcrumb>
                </Space>
                <Divider orientation="left" orientationMargin="0">Class Code Details</Divider>
                <Card>
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <Text type="secondary">Label: </Text><Text>{this.props.classCodeDetail.label}</Text>
                        </Col>
                        <Col span={8}>
                            <Text type="secondary">Class Code: </Text><Text copyable>{this.props.classCodeDetail.code}</Text>
                        </Col>
                        <Col span={8}>
                            <Text type="secondary">Roster Size: </Text><Text>{this.props.classCodeDetail.rosterSize} users</Text>
                        </Col>

                        <Col span={8}>
                            <Text type="secondary">Created By: </Text><Text>{this.props.classCodeDetail.createdByName} ({this.props.classCodeDetail.createdByUsername})</Text>
                        </Col>

                        <Col span={8}>
                        <Text type="secondary">Term: </Text><this.CouponDate date={this.props.classCodeDetail.validFrom} /> <Text type='secondary'>→</Text> <this.CouponDate date={this.props.classCodeDetail.expiresOn} />
                        </Col>
                    </Row>
                </Card>
                <Divider orientation="left" orientationMargin="0">
                    Actions
                </Divider>
                <Space>
                    <CSVLink data={this.renderCSVData()} filename={`${this.props.classCodeDetail.code}-roster.csv`}><Button icon={<DownloadOutlined />} type="primary">Download CSV of Roster</Button></CSVLink>
                </Space>
                <Divider orientation="left" orientationMargin="0">
                    Roster
                </Divider>
                <Table columns={columns} dataSource={this.props.classCodeDetail.roster} loading={this.props.classCodesDetailStatus === "LOADING"}/>
            </div>
    }
   
}

const mapStateToProps = state => {
    return {
        classCodeDetail: state.adminReducer.classCodeDetail,
        classCodeDetailStatus: state.adminReducer.classCodeDetailStatus,
    }
}

export default connect(mapStateToProps, { fetchClassCodeDetail })(AdminClassCodeRoster);