import { useState } from "react";
import { Button, Modal, Table, Tabs, Tooltip, Typography } from "antd";
import {
    CheckCircleOutlined,
    PlusOutlined,
    TagsOutlined,
    WarningOutlined
} from "@ant-design/icons";
import EditTermCouponModal from "./EditTermCouponModal";
import NewScheduledLicenseModal from "./NewScheduledLicenseModal";
import { connect } from "react-redux";

const { TabPane } = Tabs;
const { Paragraph, Text } = Typography;

const CouponManagementModal = ({
    collection,
    coupons,
    scheduledAccesses,
    text = "Manage Class Codes",
    disabled = false
}) => {
    const [visible, setVisible] = useState(false);
    const [newCouponFormVisible, setNewCouponFormVisible] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState(null);

    const columns = [
        {
            title: "Status",
            key: "syncStatus",
            render: (instance) => {
                let inSync = collection.modules.every((module) =>
                    instance.scheduledAccesses.find(
                        (access) => access.module === module.id
                    )
                );
                if (inSync) {
                    return (
                        <Tooltip
                            title={
                                <>
                                    <Text style={{ color: "#52c41a" }} strong>
                                        No action required.
                                    </Text>
                                    <br />
                                    <Text style={{ color: "white" }}>
                                        The coupon is in sync with the current
                                        version of your collection.
                                    </Text>
                                    <br />
                                </>
                            }>
                            <Text type="success">
                                <CheckCircleOutlined />
                            </Text>
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip
                            title={
                                <>
                                    <Text style={{ color: "#faad14" }} strong>
                                        Action recommended.
                                    </Text>
                                    <br />
                                    <Text style={{ color: "white" }}>
                                        The current version of this coupon does
                                        not grant access to all current modules
                                        in your collection due to the addition
                                        of new modules after the creation of
                                        this coupon.
                                    </Text>
                                    <br />
                                    <br />
                                    <Text style={{ color: "white" }}>
                                        If you would like to grant access to all
                                        current modules, please update the
                                        coupon with dates of release for the
                                        newly added modules.
                                    </Text>
                                    <br />
                                    <Text style={{ color: "white" }}>
                                        Otherwise, you can ignore this warning.
                                    </Text>
                                </>
                            }>
                            <Text type="warning">
                                <WarningOutlined />
                            </Text>
                        </Tooltip>
                    );
                }
            }
        },
        {
            title: "Label",
            key: "label",
            dataIndex: "label",
            render: (text) => <Text>{text}</Text>
        },
        {
            title: "Code",
            key: "code",
            dataIndex: "code",
            render: (code) => (
                <Paragraph
                    copyable
                    key="share"
                    className="disable-highlighting"
                    style={{
                        margin: "auto"
                    }}>
                    {code}
                </Paragraph>
            )
        },
        {
            title: "Valid From",
            key: "validFrom",
            dataIndex: "validFrom",
            render: (date) => {
                let dateObject = new Date(date);
                dateObject = new Date(
                    dateObject.getTime() +
                        dateObject.getTimezoneOffset() * 60000
                );
                dateObject.setHours(0, 0, 0, 0);

                return <Text>{dateObject.toLocaleString()}</Text>;
            }
        },
        {
            title: "Expires On",
            key: "expiresOn",
            dataIndex: "expiresOn",
            render: (date) => {
                let dateObject = new Date(date);
                dateObject = new Date(
                    dateObject.getTime() +
                        dateObject.getTimezoneOffset() * 60000
                );
                dateObject.setHours(23, 59, 59, 999);
                return <Text>{dateObject.toLocaleString()}</Text>;
            }
        },
        {
            title: "Actions",
            key: "actions",
            render: (instance) => (
                <EditTermCouponModal
                    collectionId={collection.id}
                    coupon={instance}
                    modules={collection.modules}
                />
            )
        }
    ];

    const expiredCouponsColumns = [
        {
            title: "Label",
            key: "label",
            dataIndex: "label",
            render: (text) => <Text>{text}</Text>
        },
        {
            title: "Code",
            key: "code",
            dataIndex: "code",
            render: (code) => (
                <Paragraph
                    key="share"
                    className="disable-highlighting"
                    style={{
                        margin: "auto"
                    }}>
                    {code}
                </Paragraph>
            )
        },
        {
            title: "Valid From",
            key: "validFrom",
            dataIndex: "validFrom",
            render: (date) => {
                let dateObject = new Date(date);
                dateObject = new Date(
                    dateObject.getTime() +
                        dateObject.getTimezoneOffset() * 60000
                );
                dateObject.setHours(0, 0, 0, 0);

                return <Text>{dateObject.toLocaleString()}</Text>;
            }
        },
        {
            title: "Expired On",
            key: "expiresOn",
            dataIndex: "expiresOn",
            render: (date) => {
                let dateObject = new Date(date);
                dateObject = new Date(
                    dateObject.getTime() +
                        dateObject.getTimezoneOffset() * 60000
                );
                dateObject.setHours(23, 59, 59, 999);
                return <Text>{dateObject.toLocaleString()}</Text>;
            }
        }
    ];

    return (
        <>
            <Button
                disabled={disabled}
                icon={<TagsOutlined />}
                id="manage-coupons-button"
                onClick={() => setVisible(true)}>
                {text}
            </Button>
            <Modal
                title="Manage Class Codes"
                visible={visible}
                onCancel={() => setVisible(false)}
                width={900}
                footer={
                    <div
                        style={{
                            textAlign: "right"
                        }}>
                        <Button
                            type="primary"
                            onClick={() => setVisible(false)}>
                            Close
                        </Button>
                    </div>
                }>
                <div style={{ padding: 25 }}>
                    <Button
                        type="primary"
                        onClick={() => setNewCouponFormVisible(true)}
                        icon={<PlusOutlined />}>
                        Create new code
                    </Button>

                    <NewScheduledLicenseModal
                        closeScheduledLicenseForm={() =>
                            setNewCouponFormVisible(false)
                        }
                        setSelectedCoupon={(licenseId) =>
                            setSelectedCoupon(licenseId)
                        }
                        collectionId={collection.id}
                        visible={newCouponFormVisible}
                        modules={collection.modules}
                    />

                    <Tabs>
                        <TabPane tab={<span>Active</span>} key="1">
                            <Table
                                columns={columns}
                                dataSource={coupons.filter((license) => {
                                    let currentDate = new Date();
                                    return (
                                        new Date(license.expiresOn) >
                                            currentDate &&
                                        new Date(license.validFrom) <=
                                            currentDate
                                    );
                                })}
                                size="small"
                                pagination={{
                                    pageSize: 10
                                }}
                                rowKey="code"
                            />
                        </TabPane>
                        <TabPane tab={<span>Available Soon</span>} key="2">
                            <Table
                                columns={columns}
                                dataSource={coupons.filter((license) => {
                                    let currentDate = new Date();
                                    return (
                                        new Date(license.expiresOn) >
                                            currentDate &&
                                        new Date(license.validFrom) >
                                            currentDate
                                    );
                                })}
                                size="small"
                                pagination={{
                                    pageSize: 10
                                }}
                                rowKey="code"
                            />
                        </TabPane>

                        <TabPane tab={<span>Expired</span>} key="3">
                            <Table
                                columns={expiredCouponsColumns}
                                dataSource={coupons.filter(
                                    (license) =>
                                        new Date(license.expiresOn) <=
                                        new Date()
                                )}
                                size="small"
                                pagination={{
                                    pageSize: 10
                                }}
                                rowKey="code"
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        coupons: state.contentCreationReducer.collectionDetailLicenses
    };
};

export default connect(mapStateToProps, {})(CouponManagementModal);
