import AddModules from "./AddModules"
import CollectionDetails from "./CollectionDetails"
import PublishResult from "./PublishResult"
import ReviewCollection from "./ReviewCollection"



const CreatorStepsTutorial = ({ allVideos, authorsDict, collection, createdModules, currentStep, currentUser, goToNextStep, goToPreviousStep, isAuthenticated, modules, setUserCreatedModules, updateState, videoBank }) => {
    if(currentStep === 0){
        return  <CollectionDetails 
                    authorsDict={authorsDict}
                    collection={collection} 
                    currentUser={currentUser}
                    goToNextStep={goToNextStep} 
                    updateState={updateState}
                />
    } else if(currentStep == 1) {
        return  <AddModules 
                    allVideos={allVideos}
                    authorsDict={authorsDict}
                    collection={collection} 
                    createdModules={createdModules}
                    currentUser={currentUser}
                    goToNextStep={goToNextStep} 
                    goToPreviousStep={goToPreviousStep} 
                    modules={modules}
                    setUserCreatedModules={setUserCreatedModules}
                    updateState={updateState}
                    videoBank={videoBank}
                />
    } else if(currentStep == 2) {
        return  <ReviewCollection 
                    authorsDict={authorsDict}
                    collection={collection} 
                    currentUser={currentUser}
                    modules={modules}
                    goToNextStep={goToNextStep} 
                    goToPreviousStep={goToPreviousStep} 
                />
    } else if(currentStep == 3) {
        return <PublishResult 
                    collection={collection} 
                    modules={modules}
                    isAuthenticated={isAuthenticated}
                />
    }
}

export default CreatorStepsTutorial;