import { Card, Col, Row, Skeleton, Typography } from 'antd';
import QnAOverviewModule from './QnAOverviewModule';

const { Text } = Typography;

const QnAOverview = ({ watchHistoryOfCollection, watchHistoryOfCollectionStatus}) => {

    if(watchHistoryOfCollectionStatus === "LOADED"){
        return <div className="qna-section-collection-container">
            <div className="qna-section-collection-column-headers">
                <Row gutter={16}>
                    <Col span={12}>
                        <Text className="quiz-section-video-column-headers-text">Course Name</Text>
                    </Col>
                    <Col span={4}>
                        <Text className="quiz-section-video-column-headers-text"># of Questions Asked</Text>
                    </Col>
                </Row>
            </div>
            <div className="qna-section-collection-row">
                <Row gutter={16}>
                    <Col span={12}>
                        {watchHistoryOfCollection.name}
                    </Col>
                    <Col span={4}>
                        {watchHistoryOfCollection.totalNumberOfQnaQuestions}
                    </Col>
                </Row>
            </div>
            <div className="qna-section-modules-container">
                <div className="qna-section-module-column-headers">
                    <Row gutter={16}>
                        <Col span={16}>
                            <Text className="quiz-section-video-column-headers-text">Module Name</Text>
                        </Col>
                        <Col span={4}>
                            <Text className="quiz-section-video-column-headers-text"># of Questions Asked</Text>
                        </Col>
                    </Row>
                </div>
                {
                    
                    watchHistoryOfCollection.modules.map(module=> (
                        <QnAOverviewModule module={module} />
                    ))
                }
            </div>
        </div>
       
    } else {
        return <Card className="dashboard-card">
            <Skeleton active />
        </Card>
    } 
}

export default QnAOverview;