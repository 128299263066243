import { useEffect } from "react";
import ContactUsForm from "./Components/ContactUsForm";
import { logEvent, trackingEvents } from "./Tracking";

const ContactUsPage = () => {
    useEffect(() => {
        logEvent(trackingEvents.pageView, {
            page: "contact-us"
        });
    }, []);
    return (
        <div className="centered-container">
            <ContactUsForm />
        </div>
    );
};

export default ContactUsPage;
