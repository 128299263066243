import { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Breadcrumb, Button, Result, Space } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { fetchModuleDetail } from './actions/contentCreation';
import ModuleDisplay from './Components/ModuleDisplay';
import LoadingModuleList from './Components/LoadingModuleList';

class CreatedModuleDetail extends Component {

    componentDidMount(){
    
        if(!this.props.module || this.props.module.id != this.props.match.params.moduleId){
            this.props.fetchModuleDetail(this.props.match.params.moduleId, this.props.currentUser.id)
        }

    }

    render() {
        if(this.props.moduleNotFound) {
            return <>
                        <Result
                            status="403"
                            title="403"
                            subTitle="Sorry, you are not authorized to access this page."
                            extra={<Button type="primary" onClick={()=>this.props.history.push('/creator/home')}>Go Back to Creator Home</Button>}
                        />
                    </>
        }
        if(!this.props.module || this.props.module.id != this.props.match.params.moduleId) {
            return <div className="centered-container">
                <br/>
                <LoadingModuleList collection={false}/>
            </div>

        }

        return <div className="centered-container">
                    <Space className="breadcrumbs">
                        <Breadcrumb >
                            <Breadcrumb.Item>
                                <Link to="/"><HomeOutlined /></Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/creator/home">Creator Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>My Created Modules</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.props.module.name}</Breadcrumb.Item>
                            
                        </Breadcrumb>
                    </Space>

                    <ModuleDisplay
                        collectionId={null} 
                        isAuthenticated={this.props.isAuthenticated}
                        mode={"instructor"} 
                        module={this.props.module} 
                        moduleAccess={{}} 
                    />
                    
                </div>
    }
}

const mapStateToProps = state => {
    return {
        module: state.contentCreationReducer.moduleDetail,
        currentUser: state.userReducer.user,
        isAuthenticated: state.userReducer.isAuthenticated,
        moduleNotFound: state.contentCreationReducer.moduleNotFound,
    }
}

export default connect(mapStateToProps, { fetchModuleDetail })(CreatedModuleDetail);