import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Button, Layout, Space, Typography} from 'antd';
import YouTube from 'react-youtube';

import VideoPageFeatureMenu from './VideoPageFeatureMenu';


const {Content} = Layout;
const { Text, Title } = Typography;
var BASE_RATE = 10000;

const PLAYER_OPTIONS = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      rel: 0
    },
};

class YouTubePlayer extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            trackingInterval: null,
            transcriptTimeout: null,
            playerRef: null,
            timestamp: 0,
        }
    }

    PLAYING = 1;
    
    componentWillUnmount = () => {
        if(this.state.trackingInterval){
            clearInterval(this.state.trackingInterval)
        }
        if(this.state.transcriptTimeout){
            clearTimeout(this.state.transcriptTimeout)
        }
        this.setVideoTimestamp(0)
        this.setState({trackingInterval: null, transcriptTimeout: null})
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.skipToVideoTimestamp !== this.props.skipToVideoTimestamp && this.props.skipToVideoTimestamp !== null) {
            let element = document.getElementById('video-show-page');
            element.scrollIntoView({behavior: "smooth", block: "start"});
            this.seekToVideoTimestamp(this.props.skipToVideoTimestamp)
            this.props.setSkipToVideoTimestamp(null)
        }
    }

    track = () => {
        var currentTime = this.state.playerRef.getCurrentTime()
        var playbackRate = this.state.playerRef.getPlaybackRate()
        var pollRate = BASE_RATE
    }


    clearAllIntervals = () => {
        if(this.state.trackingInterval){
            clearInterval(this.state.trackingInterval)
        }
        if(this.state.transcriptTimeout){
            clearTimeout(this.state.transcriptTimeout)
        }
        this.setState({trackingInterval: null, transcriptTimeout: null})
    }
    handleOnPause = () => {
        this.clearAllIntervals()
    }
    
    scrollToNextParagraph = (currentTime, currentElement) => {
        var transcriptDiv = document.getElementById('transcript-tab-content');
        var nextElement = currentElement.nextSibling;
        if(nextElement){
            var startTime = nextElement.getAttribute('data-start-time');
            var endTime = nextElement.getAttribute('data-end-time');
            var paragraphFlag = nextElement.getAttribute('data-paragraph-flag');
    
            if(startTime <= currentTime && endTime > currentTime && paragraphFlag === "true"){
                transcriptDiv.scrollTop = nextElement.offsetTop
            }
        }
    }

    setVideoTimestamp = (timestamp) => {
        this.setState({
            timestamp: timestamp
        })
    } 
    updateVideoTimestamp = () => {
         
        var currentTime = this.state.playerRef.getCurrentTime()
        var playbackRate = this.state.playerRef.getPlaybackRate()
        if(this.props.video.transcript.length > 0){
           
            var currentElement = document.getElementById('highlighted-transcript-segment');
            if(currentElement){
                this.scrollToNextParagraph(currentTime, currentElement);
            }
        }
        this.setVideoTimestamp(currentTime)
        var timeout = setTimeout(this.updateVideoTimestamp, (this.props.nextTranscriptUpdate/playbackRate))
        this.setState({transcriptTimeout: timeout})
    }
    
    handleOnPlay = () => {
        if(this.state.trackingInterval){
            clearInterval(this.state.trackingInterval)
        }
        if(this.state.transcriptTimeout){
            clearTimeout(this.state.transcriptTimeout)
        }
        this.updateVideoTimestamp()
        var newTrackingInterval = setInterval(this.track, BASE_RATE)
        this.setState({trackingInterval: newTrackingInterval})
    }

    handleOnReady = (event) => {
        this.setState({playerRef: event.target})
        if(this.props.customStartTime) {
            event.target.seekTo(this.props.customStartTime)
            this.setVideoTimestamp(this.props.customStartTime)
        }
    }

    seekToVideoTimestamp = (timestamp) => {
        this.state.playerRef.seekTo(timestamp)
        this.setVideoTimestamp(timestamp)
        if (this.state.playerRef.getPlayerState !== this.PLAYING){
            this.state.playerRef.playVideo();
        }
    }
    seekOnTranscriptClick = (targetElement, timestamp) => {
        var transcriptDiv = document.getElementById('transcript-tab-content');
        transcriptDiv.scrollTop = targetElement.offsetTop

        this.seekToVideoTimestamp(timestamp)
    }

    pausePlayer = () => {
        console.log(this.state.playerRef)
        this.state.playerRef.pauseVideo()
    }

    updateQuizQuestionAttempt = (updatedQuestions) => {
        this.props.updateQuizQuestions(updatedQuestions)
    }
    render(){
        return <>
            <Content id="video-content-wrapper">
           

                <div id="video-wrapper">
                    <YouTube videoId={this.props.video.embedId} height="1080" width="1920"
                            opts={PLAYER_OPTIONS}
                            onPause={this.handleOnPause} 
                            onPlay={this.handleOnPlay}
                            onReady={this.handleOnReady}
                            onEnd={this.clearAllIntervals}
                            onError={this.clearAllIntervals}
                    />
                </div>
            </Content>
            <VideoPageFeatureMenu updateQuizQuestionAttempt={this.updateQuizQuestionAttempt} currentTimestamp={this.state.timestamp} transcript={this.props.video.transcript} quizQuestions={this.props.video.quizQuestions} pauseVideo={this.pausePlayer} seekOnTranscriptClick={this.seekOnTranscriptClick} collapsedTranscript={this.props.collapsedTranscript}  seekToVideoTimestamp={this.seekToVideoTimestamp}/>
        </>
    }
}


export default YouTubePlayer;