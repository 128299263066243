import { Col, Form, Input, Row } from 'antd';
import { useState } from 'react';
import ResultStep from './ResultStep';
import StepOne from './StepOne';
import StepTwo from './StepTwo';




const DemoRequestPage = () => {

    const [formData, setFormData] = useState({});
    const [step, setStep] = useState(1);


    const StepRenderer = () => {
        if(step == 1) {
            return <StepOne nextStep={nextStep} updateFormData={updateFormData}/>
        } else if(step == 2) {
            return <StepTwo formData={formData} prevStep={prevStep} nextStep={nextStep}  updateFormData={updateFormData}/>
        } else if(step == 3) {
            return <ResultStep formData={formData}/>
        } else {
            return <></>
        }
    }

    const RenderStepText = () => {
        if(step > 2) {
            return <></>
        } else {
            return <h4>Step {step} of 2</h4>
        }
    }

    const updateFormData = (data) => {
        setFormData({
            ...formData,
            ...data
        })
    }

    const prevStep = () => {
        if(step > 1) {
            setStep(step - 1);
        }
        
    }

    const nextStep = () => {
        setStep(step + 1);
    }

    return <div id="demo-request-page">
        <h1>Demo Request</h1>
        <RenderStepText />
        <p>Cubits can curate your collection for free. Just submit a playlist of your videos or MP4 videos, and we will send you a curated video collection that you can edit as you wish. Save time and get all the advantages of a curated collection.</p>
        
        <StepRenderer />
       
    </div>
}

export default DemoRequestPage;