import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchRecentQnAActivity } from '../../actions/dashboard';
import { Button, Card, Divider, List, Radio, Skeleton, Space, Typography } from 'antd';

import DashboardQnAQuestion from './DashboardQnAQuestion';


const { Text, Title } = Typography;

class DashboardQnAHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDays: "1",
            sorted: false,
            sortedData: [],
            sortBy: "most-recent",
        }
    }

    
    componentDidMount(){
        if(this.props.selectedCoupon){
            this.props.fetchRecentQnAActivity(this.props.selectedCoupon.code, this.state.selectedDays)
        }
        if(this.props.recentQnAActivityStatus === "LOADED"){
            this.sortRecentQnAActivity();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedCoupon != this.props.selectedCoupon && this.props.selectedCoupon || (prevState.selectedDays != this.state.selectedDays)){
            this.props.fetchRecentQnAActivity(this.props.selectedCoupon.code, this.state.selectedDays)
        }
        if((prevProps.recentQnAActivityStatus != this.props.recentQnAActivityStatus && this.props.recentQnAActivityStatus === "LOADED") || (prevState.sorted != this.state.sorted && !this.state.sorted)) {
            this.sortRecentQnAActivity()
        }
    }

    sortRecentQnAActivity = () => {
        let sortedArray;
        if(this.state.sortBy === "most-recent"){
            sortedArray = this.props.recentQnAActivity
        }else if(this.state.sortBy === "most-replies") {
            sortedArray = this.props.recentQnAActivity.slice();
            sortedArray.sort((q1, q2) => {
                if(q1.responses.length < q2.responses.length){
                    return 1;
                }
                if(q1.responses.length > q2.responses.length){
                    return -1;
                }
                return 0;
            })
        }
   
        this.setState({
            sortedData: sortedArray,
            sorted: true
        })
    }

    handleOnDateSelectChange = (event) => {
        this.setState({
            selectedDays: event.target.value
        })
    }

    handleSortSelectChange = (event) => {
        if(event.target.value === "most-recent"){
            this.setState({
                sorted: false,
                sortBy: "most-recent"
            })
        }else if(event.target.value === "most-replies"){
            this.setState({
                sorted: false,
                sortBy: "most-replies"
            })
        }
    }
    render(){
        if(this.props.recentQnAActivityStatus ==="LOADING" || !this.state.sorted){
            return <Card className="dashboard-card">
                        <Space align="baseline" style={{paddingLeft: "5px"}}>
                            <Title level={4} >Recent Activity</Title>
                            <Divider type="vertical" />
                            <Radio.Group 
                                size="small" 
                                onChange={this.handleOnDateSelectChange} 
                                defaultValue={this.state.selectedDays}
                                buttonStyle="solid"
                                disabled
                            >
                                <Radio.Button value="1">Past 24 Hours</Radio.Button>
                                <Radio.Button value="3">Past 3 Days</Radio.Button>
                                <Radio.Button value="7">Past Week</Radio.Button>
                            </Radio.Group>
                            <Divider type="vertical" />
                            <Radio.Group 
                                size="small" 
                                onChange={this.handleSortSelectChange} 
                                defaultValue={this.state.sortBy}
                                buttonStyle="solid"
                                disabled
                            >
                                <Radio.Button value="most-recent">Most Recent</Radio.Button>
                                <Radio.Button value="most-replies">Most Replies</Radio.Button>
                            </Radio.Group>
                            <Divider type="vertical" />
                            <Space align="center">
                                <div className='recent-qna-posting-legend'>

                                </div>
                                <Text>Posted within {this.state.selectedDays} day(s) ago</Text>
                            </Space>
                        </Space>
                        <Skeleton active />
                    </Card>
        }
        return <Card className="dashboard-card">
            <Space align="baseline" style={{paddingLeft: "5px"}}>
                <Title level={4} >Recent Activity</Title>
                <Divider type="vertical" />
                <Radio.Group 
                    size="small" 
                    onChange={this.handleOnDateSelectChange} 
                    defaultValue={this.state.selectedDays}
                    buttonStyle="solid"
                >
                    <Radio.Button value="1">Past 24 Hours</Radio.Button>
                    <Radio.Button value="3">Past 3 Days</Radio.Button>
                    <Radio.Button value="7">Past Week</Radio.Button>
                </Radio.Group>
                <Divider type="vertical" />
                <Radio.Group 
                    size="small" 
                    onChange={this.handleSortSelectChange} 
                    defaultValue={this.state.sortBy}
                    buttonStyle="solid"
                >
                    <Radio.Button value="most-recent">Most Recent</Radio.Button>
                    <Radio.Button value="most-replies">Most Replies</Radio.Button>
                </Radio.Group>
                <Divider type="vertical" />
                <Space align="center">
                    <div className='recent-qna-posting-legend'>

                    </div>
                    <Text>Posted within {this.state.selectedDays} day(s) ago</Text>
                </Space>
            </Space>
        
            <List
                itemLayout="horizontal"
                dataSource={this.state.sortedData}
                renderItem={item => (
                    <DashboardQnAQuestion 
                        question={item} 
                        selectedDays={this.state.selectedDays}
                        renderTag={true}
                        renderNewReplyStatistics={true}
                    />
                )}
            />
        </Card>
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.userReducer.isAuthenticated,
        selectedCoupon: state.dashboardReducer.selectedCoupon,
        recentQnAActivity: state.dashboardReducer.recentQnAActivity,
        recentQnAActivityStatus: state.dashboardReducer.recentQnAActivityStatus,
        user: state.userReducer.user
    }
}
export default connect(mapStateToProps,{fetchRecentQnAActivity})(DashboardQnAHome);