import { Component } from 'react';
import { Card, List, Skeleton, Space, Tag, Typography } from 'antd';
import { connect } from 'react-redux';
import { fetchCollectionQnA } from '../../actions/collection';
import DashboardQnAQuestion from './DashboardQnAQuestion';
import DashboardCreateQnAQuestion from './DashboardCreateQnAQuestion';


const { Text, Title } = Typography;
class DashboardCollectionQnA extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            collectionId: null
        }
    }

    componentDidMount() {
        this.setState({
            collectionId: this.props.match.params.collectionId
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.collection != this.props.collection && this.props.collection.id != this.state.collectionId){
            this.props.history.push('/dashboard/qna/')
        }
        if((prevState.collectionId == null && prevState.collectionId != this.state.collectionId && this.props.selectedCoupon) || 
            (this.state.collectionId != null && prevProps.selectedCoupon != this.props.selectedCoupon)
        ){
           
            this.props.fetchCollectionQnA(this.state.collectionId, this.props.selectedCoupon.id)
        }
        if(prevProps.selectedCoupon != null && prevProps.selectedCoupon != this.props.selectedCoupon){
            this.props.history.push('/dashboard/qna/')
        }
    }
    render(){
        if(this.props.collection !== null && this.props.collection.id == this.state.collectionId){
            return  <Card className="dashboard-card">
                        <Space align="baseline" style={{paddingLeft: "5px"}}>
                            
                            <Title level={4}>[Discussion]</Title>
                            <Text>{this.props.collection.name}</Text>
                        </Space>
                        <br/>
                        <Space  style={{paddingLeft: "5px"}}>
                            <Tag color="#76c0db">Collection</Tag>
                        </Space>
                        <DashboardCreateQnAQuestion 
                            collectionId={this.state.collectionId}
                        />
                        <List
                            itemLayout="horizontal"
                            style={{marginTop: "5px"}}
                            dataSource={this.props.qnaData}
                            renderItem={item => (
                                <DashboardQnAQuestion
                                    question={item} 
                                    selectedDays={this.state.selectedDays}
                                    renderTag={false}
                                    renderNewReplyStatistics={false}
                                />
                            )}
                        />
                    </Card>
        }else {
            return  <Card className="dashboard-card">
                        
                        <Skeleton active />
                    </Card>
        }
        
    }
}

const mapStateToProps = state => {
    return {
        collection: state.dashboardReducer.collection,
        qnaData: state.collectionReducer.collectionQnA,
        selectedCoupon: state.dashboardReducer.selectedCoupon
    }
}
export default connect(mapStateToProps, { fetchCollectionQnA })(DashboardCollectionQnA);