import { Button } from "antd"
import ModuleCreationResultStep from "./ModuleCreationResultStep"
import ModuleDetailsStep from "./ModuleDetailsStep"
import ReviewModuleChangesStep from "./ReviewModuleChangesStep"

const ModuleCreatorSteps = ({ allVideos, authorsDict, currentStep, currentUser, goToNextStep, goToPreviousStep, module, updateState, videoBank }) => {
    if(currentStep === 0){
        return  <ModuleDetailsStep 
                    allVideos={allVideos}
                    authorsDict={authorsDict}
                    currentUser={currentUser}
                    goToNextStep={goToNextStep} 
                    goToPreviousStep={goToPreviousStep} 
                    module={module}
                    updateState={updateState}
                    videoBank={videoBank}
                />
    } else if(currentStep === 1) {
        return <div>
            <Button onClick={goToPreviousStep}>Back</Button>
            <Button onClick={goToNextStep}>Next: Review Changes</Button>
        </div>
    } else if(currentStep === 2) {
        return <ReviewModuleChangesStep 
                    module={module}
                    goToNextStep={goToNextStep} 
                    goToPreviousStep={goToPreviousStep} 
                />
    } else if(currentStep === 3) {
        return  <ModuleCreationResultStep 
                    goToPreviousStep={goToPreviousStep} 
                />
    }
}

export default ModuleCreatorSteps;