import { Button, Card, Comment, Divider, Popconfirm, Space } from 'antd';
import { CloseOutlined, DeleteOutlined, EyeInvisibleOutlined, LoadingOutlined, MinusCircleOutlined, SendOutlined } from '@ant-design/icons';
import QnAAnswer from './QnAAnswer';
import QnAReplyForm from './QnAReplyForm';
import RichTextPreview from '../../../Components/RichTextPreview';
import { compositeDecorator } from '../../../Components/RichTextDecoratorConfig';
import { ContentState, EditorState, convertFromRaw } from "draft-js";
import { Component, useState } from 'react';
import { connect } from 'react-redux';
import { deleteQnAQuestion, removeQnAQuestion } from '../../../actions/collection';


class QnAQuestion extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            visibleReplyForm: false,
            visibleReplies: false,
            removeLoading: false,
            deleteLoading: false,
        }
    }

    renderDisplayName = () => {
        if(this.props.question.anonymous || this.props.question.removed){
            let currentLicense = this.props.enrolledLicenses.find(license => license.id === this.props.selectedLicense);
            if(this.props.question.user && this.props.question.user.id == this.props.currentUser.id){
                return "Anonymous User (You)"
            }else if(this.props.currentUser.id === currentLicense.createdById){
                return <div className="qna-anonymous-spoiler-tag">
                            {this.props.question.user.firstName && this.props.question.user.lastName ? `${this.props.question.user.firstName} ${this.props.question.user.lastName}` : this.props.question.user.username}
                            <div className="qna-anonymous-hover-to-reveal"><EyeInvisibleOutlined /></div>
                        </div>
            } else {
                return "Anonymous User"
            }
        }else if(this.props.question.user.firstName && this.props.question.user.lastName){
            return this.props.question.user.id === this.props.currentUser.id ? `${this.props.question.user.firstName} ${this.props.question.user.lastName} (You)` : `${this.props.question.user.firstName} ${this.props.question.user.lastName}`
        } else {
            return this.props.question.user.id === this.props.currentUser.id ? `${this.props.question.user.username} (You)` : this.props.question.user.username
        }
        
        
    }
    
    renderDate = () => {
        let date = new Date(this.props.question.createdAt);
        return `${date.toDateString()} at ${date.toLocaleTimeString()}`;
    }

    renderViewResponsesButton = () => {
        if(this.props.question.responses.length){
            return this.state.visibleReplies ? <p className="qna-sub-heading">Hide Replies</p> : <p className="qna-sub-heading">View Replies ({this.props.question.responses.length})</p>
        } else {
            return <p className="qna-sub-heading">No replies yet</p>
        }
    }

    onViewRepliesClick = () => {
        let element = document.getElementById(`qna-question-${this.props.question.id}-replies`);
        if(element.style.maxHeight) {
            element.style.maxHeight = null;
        } else {
            element.style.maxHeight = element.scrollHeight + "px";
        }
        this.setState({
            visibleReplies: !this.state.visibleReplies
        })
    }
    handleOnReplyFormShow = () => {
        let element = document.getElementById(`qna-question-${this.props.question.id}-reply-form`);
        if(element.style.maxHeight || element.style.maxHeight == "none") {
            element.style.maxHeight = null;
        } else {
           // element.style.maxHeight = element.scrollHeight + "px";
           element.style.maxHeight = 10000 + "px";
        }
        this.setState({
            visibleReplyForm: !this.state.visibleReplyForm
        })
    }

    handleOnReplyFormSubmitSuccess = () => {
        let formElement = document.getElementById(`qna-question-${this.props.question.id}-reply-form`);
        formElement.style.maxHeight = null;
        let element = document.getElementById(`qna-question-${this.props.question.id}-replies`);
        element.style.maxHeight = element.scrollHeight + 100000 + "px";
        this.setState({
            visibleReplyForm: false,
            visibleReplies: true
        })
        setTimeout(()=>element.lastChild.scrollIntoView({behavior: "smooth", block: "center"}), 300)
        
    }

    setRemoveLoading = (status) => { 
        return this.setState({removeLoading: status})
    }

    setDeleteLoading = (status) => {
        return this.setState({deleteLoading: status})
    }
    renderActions = () => {
        let actionsToRender = [];

        if(!this.props.question.removed){
            actionsToRender.push(
                <span 
                    key="comment-nested-reply-to" 
                    onClick={
                        this.handleOnReplyFormShow
                    }
                >
                    {this.state.visibleReplyForm ? <>Cancel <CloseOutlined style={{marginTop: "10px"}}/></> : <>Reply <SendOutlined style={{marginTop: "10px"}}/></>}
                </span>
            )
            let currentLicense = this.props.enrolledLicenses.find(license => license.id === this.props.selectedLicense);
            if((this.props.question.user && this.props.currentUser.id === this.props.question.user.id) || this.props.currentUser.id === currentLicense.createdById){
                actionsToRender.push(
                    <Popconfirm
                        title="Are you sure you want to remove this comment?"
                        onConfirm={()=>{
                            this.setState({
                                removeLoading: true
                            })
                            this.props.removeQnAQuestion(this.props.collectionId, this.props.moduleId, this.props.videoId, this.props.question.id, this.props.qnaLevel, this.setRemoveLoading)
                        }}
                        okButtonProps={{loading: this.state.removeLoading}}
                        okText="Yes"
                        cancelText="No"
                        key="comment-nested-remove-question"
                    >
                        <span
                            
                            className="comment-nested-remove-question"

                        >
                            Remove <MinusCircleOutlined />
                        </span>
                    </Popconfirm>
                )
            }

            if(this.props.currentUser.id === currentLicense.createdById){
                actionsToRender.push(
                    <Popconfirm
                        title="Are you sure you want to delete this comment?"
                        onConfirm={()=>{
                            this.setState({
                                deleteLoading: true
                            })
                            this.props.deleteQnAQuestion(this.props.collectionId, this.props.moduleId, this.props.videoId, this.props.question.id, this.props.qnaLevel, this.setDeleteLoading)
                        }}
                        okButtonProps={{loading: this.state.deleteLoading}}
                        okText="Yes"
                        cancelText="No"
                        key="comment-nested-delete-question"
                    >
                        <span
                           
                            className="comment-nested-delete-question"

                        >
                            Delete <DeleteOutlined />
                        </span>
                    </Popconfirm>
                )
            }
        }
        return actionsToRender
    }
    render(){
    
        const contentState = this.props.question.removed ? ContentState.createFromText(this.props.question["text"]) : convertFromRaw(this.props.question['text']);
        const editorState = EditorState.createWithContent(contentState, compositeDecorator);


        return <>
                <Comment
                    actions={[
                        this.renderActions()
                    ]}
                    author={this.renderDisplayName()}
                    datetime={this.renderDate()}
                    content={
                        <RichTextPreview editorState={editorState}/>
                    }
                >
                    <QnAReplyForm 
                        visible={this.state.visibleReplyForm} 
                        handleSubmitSuccess={this.handleOnReplyFormSubmitSuccess}
                        replyingTo={this.renderDisplayName()}
                        cancelReply={this.handleOnReplyFormShow}
                        collectionId={this.props.collectionId}
                        moduleId={this.props.moduleId}
                        qnaLevel={this.props.qnaLevel}
                        questionId={this.props.question.id}
                        videoId={this.props.videoId}
                        selectedLicense={this.props.selectedLicense}
                        currentUser={this.props.currentUser}
                        enrolledLicenses={this.props.enrolledLicenses}
                    />
                    <div id={`qna-question-${this.props.question.id}-replies`} className={`qna-replies`}>
                
                        {this.props.question.responses.map(response => {
                            return <QnAAnswer 
                                        currentUser={this.props.currentUser} 
                                        key={response.id} 
                                        response={response} 
                                        enrolledLicenses={this.props.enrolledLicenses}
                                        selectedLicense={this.props.selectedLicense}
                                        qnaLevel={this.props.qnaLevel}
                                        questionId={this.props.question.id}
                                    />
                        })}
                    </div>
                </Comment>
                <Divider style={{ margin: "5px 0 5px 0"}} plain>
                    <Space>
                        {this.props.question.responses.some(response => response.instructorHighlight) && !this.state.visibleReplies ? <div id='q-n-a-instructor-square'></div> : null}
                        <Button type="text" size='small' onClick={this.onViewRepliesClick}>
                            {this.renderViewResponsesButton()}
                        </Button>
                    </Space>
                </Divider>
            </>
    }
}

export default connect(null, { deleteQnAQuestion, removeQnAQuestion })(QnAQuestion);