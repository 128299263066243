/******************************************************************
IndividualStudentPage.js
Written by Adam Gamba, Summer 2021

Individual student data page, includes CheckboxGrid, and list of
StudentLineCharts. Private to admins and the given student
******************************************************************/

import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import "../Home.css";
import { Card, Skeleton, Space, Tooltip, Typography } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import StudentSummaries from "./StudentSummaries";
import StudentLineChart from "./StudentLineChart";
import moment, { max } from "moment";
import CheckboxGrid from "./CheckboxGrid";
import {
    fetchIndividualStudentActivity,
    fetchStudentSummaries
} from "../../actions/dashboard";
import { Redirect } from "react-router-dom";
import QnASection from "./QnASection/QnASection";
import QuizSection from "./QuizSection/QuizSection";

// Example student
// {username: "bw23@princeton.edu", studentId: 233, hours: 0}

const { Title, Text } = Typography;

class IndividualStudentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            student: null,
            maxVideoDuration: 0,
            watchedVideos: [],
            validUser: false
        };
    }

    setUserActivityData = (watchedVideos, maxVideoDuration) => {
        this.setState({
            maxVideoDuration: maxVideoDuration,
            watchedVideos: watchedVideos
        });
    };

    componentDidMount() {
        if (this.props.roster.length) {
            let studentId = parseInt(this.props.match.params.studentID);
            // Find student with matching ID from roster
            let result = this.props.roster.filter(
                (x) => x.studentId === studentId
            );
            if (result.length === 0) return;

            let indivStudent = result[0];

            this.setState({
                student: indivStudent
            });

            // Get data for individual student video activity
            let coupon = this.props.selectedCoupon.code;
            // ! Temporary: classStartDate and classEndDate are one year ago and one year later, respectively
            let classStartDate = moment(
                new Date().setDate(new Date().getDate() - 365)
            ).toISOString();
            let classEndDate = moment(
                new Date().setDate(new Date().getDate() + 365)
            ).toISOString();
            this.props.fetchIndividualStudentActivity(
                classStartDate,
                classEndDate,
                indivStudent.username,
                coupon,
                this.setUserActivityData
            );
            this.props.fetchStudentSummaries(
                this.props.match.params.studentID,
                this.props.selectedCoupon.code
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.roster.length != this.props.roster.length &&
            this.props.roster.length
        ) {
            let studentId = parseInt(this.props.match.params.studentID);
            // Find student with matching ID from roster
            let result = this.props.roster.filter(
                (x) => x.studentId === studentId
            );
            if (result.length === 0) return;
            let indivStudent = result[0];

            this.setState({
                student: indivStudent
            });

            // Get data for individual student video activity
            let coupon = this.props.selectedCoupon.code;
            // ! Temporary: classStartDate and classEndDate are one year ago and one year later, respectively
            let classStartDate = moment(
                new Date().setDate(new Date().getDate() - 365)
            ).toISOString();
            let classEndDate = moment(
                new Date().setDate(new Date().getDate() + 365)
            ).toISOString();
            this.props.fetchIndividualStudentActivity(
                classStartDate,
                classEndDate,
                indivStudent.username,
                coupon,
                this.setUserActivityData
            );
            this.props.fetchStudentSummaries(
                this.props.match.params.studentID,
                this.props.selectedCoupon.code
            );
        }
    }

    renderModuleName = (module) => {
        let modules = this.props.videoList.filter((x) => {
            return x.moduleId === module[0].moduleId;
        });
        if (modules.length) {
            return modules[0].moduleName;
        } else {
            return null;
        }
    };
    renderStudentContent = () => {
        if (this.props.selectedCoupon.createdById != this.props.user.id) {
            /*return <>
        <QnASection />
        <br/>
        <QuizSection />
      </>*/
        }
        return;
    };
    render() {
        if (!this.state.student || this.props.videoListStatus !== "LOADED") {
            return (
                <Card className="dashboard-card">
                    <Skeleton active />
                </Card>
            );
        }
        
        // else if (
        //     this.props.isAuthenticated &&
        //     this.props.user.id != this.props.match.params.studentID &&
        //     this.props.user.id != this.props.selectedCoupon.createdById
        // ) {
        //     return <Redirect to="/dashboard" />;
        // }
        return (
            <div>
                <Card className="dashboard-card">
                    <Title level={5}>{this.state.student.username}</Title>

                    <StudentSummaries
                        studentSummaries={this.props.studentSummaries}
                        studentSummariesStatus={
                            this.props.studentSummariesStatus
                        }
                    />
                </Card>

                <br />
                {/* Grid of checkboxes or X's depending on which videos have been watched by the student */}
                <Card className="dashboard-card">
                    <Title level={5}>Overview</Title>

                    <CheckboxGrid watchedVideos={this.state.watchedVideos} />
                </Card>
                <br />
                {this.renderStudentContent()}
                <br />
                {/* Header and tooltip info */}
                <Card className="dashboard-card">
                    <Space align="baseline">
                        <Title level={5}>Watch Time Graphs</Title>
                        <Tooltip
                            placement="right"
                            title="The following graphs show the amount of time invested in each minute of every video. The baseline represents what a graph would like if the user were to watch once at 1x speed. If the graph is above the baseline, then user has watched that section more than once or at < 1x speed. If the graph is below the baseline, then the user has watched that section at a speed > 1x.">
                            <QuestionCircleOutlined
                                style={{ fontSize: "120%" }}
                            />
                        </Tooltip>
                    </Space>
                </Card>

                {/* Info now included in tooltip */}
                {/* <p className="flex-center">
            *Data measured in real-world time - not affected by watch
            speed (2x, 3x, etc.)
          </p>
          <p className="flex-center">
            **The baseline represents the graph if the student were to
            watch the video once at 1x speed
          </p> */}

                {/* Render modules AND videos as map of maps - allows us to render module title h1s between modules */}
                {Object.values(this.state.watchedVideos).map((module, i) => {
                    return (
                        <Card className="dashboard-card">
                            <Title level={5}>
                                Module {module[0].moduleId} -{" "}
                                {this.renderModuleName(module)}
                            </Title>
                            <div>
                                {module.map((selectedVideo, j) => {
                                    // Example selectedVideo: {title: "Conditionals: The IF Statement", data: Array(26)}
                                    return (
                                        <div>
                                            {/* Line Chart of student data for given video */}
                                            <StudentLineChart
                                                key={j}
                                                maxVideoDuration={
                                                    this.state.maxVideoDuration
                                                }
                                                selectedVideo={selectedVideo}
                                                width="80%"
                                                height="200px"
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </Card>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.userReducer.isAuthenticated,
        roster: state.dashboardReducer.roster,
        selectedCoupon: state.dashboardReducer.selectedCoupon,
        studentSummaries: state.dashboardReducer.studentSummaries,
        studentSummariesStatus: state.dashboardReducer.studentSummariesStatus,
        user: state.userReducer.user,
        videoList: state.dashboardReducer.videoList,
        videoListStatus: state.dashboardReducer.videoListStatus
    };
};

export default connect(mapStateToProps, {
    fetchIndividualStudentActivity,
    fetchStudentSummaries
})(IndividualStudentPage);
