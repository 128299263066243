import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    ContainerOutlined,
    ExperimentOutlined,
    HomeOutlined,
    ReadOutlined,
    SafetyCertificateOutlined,
    TeamOutlined
} from "@ant-design/icons";
import {
    Badge,
    Breadcrumb,
    Card,
    Col,
    Divider,
    Row,
    Space,
    Statistic,
    Typography
} from "antd";
import { Link } from "react-router-dom";
import { Component } from "react";
import { fetchPlatformStatistics } from "../actions/admin";
import { connect } from "react-redux";

const { Text, Title } = Typography;

class AdminHome extends Component {
    componentDidMount() {
        this.props.fetchPlatformStatistics();
    }

    PlatformStatistics = () => {
        if (
            this.props.statisticsStatus === "LOADED" &&
            this.props.statistics != null
        ) {
            let signupPercentChange =
                this.props.statistics.lastWeek.signups > 0
                    ? ((this.props.statistics.thisWeek.signups -
                          this.props.statistics.lastWeek.signups) /
                          this.props.statistics.lastWeek.signups) *
                      100
                    : 0;
            let purchasesPercentChange =
                this.props.statistics.lastWeek.purchases > 0
                    ? ((this.props.statistics.thisWeek.purchases -
                          this.props.statistics.lastWeek.purchases) /
                          this.props.statistics.lastWeek.purchases) *
                      100
                    : 0;
            let revenuePercentChange =
                this.props.statistics.lastWeek.revenue > 0
                    ? ((this.props.statistics.thisWeek.revenue -
                          this.props.statistics.lastWeek.revenue) /
                          this.props.statistics.lastWeek.revenue) *
                      100
                    : 0;
            return (
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        <Card>
                            <Statistic
                                title="User signups (compared to last week)"
                                value={signupPercentChange}
                                precision={2}
                                valueStyle={{
                                    color:
                                        signupPercentChange > 0
                                            ? "#3f8600"
                                            : "#cf1322"
                                }}
                                prefix={
                                    signupPercentChange > 0 ? (
                                        <ArrowUpOutlined />
                                    ) : (
                                        <ArrowDownOutlined />
                                    )
                                }
                                suffix="%"
                            />
                        </Card>
                    </Col>
                    {/*<Col span={4}>
                            <Card>
                            <Statistic
                                title="Transcripts Uploaded this week"
                                value={9.3}
                                precision={2}
                                valueStyle={{ color: '#cf1322' }}
                                prefix={<ArrowDownOutlined />}
                                suffix="%"
                            />
                            </Card>
                        </Col>*/}
                    <Col span={4}>
                        <Card>
                            <Statistic
                                title="Purchases (compared to last week)"
                                value={purchasesPercentChange}
                                precision={2}
                                valueStyle={{
                                    color:
                                        purchasesPercentChange > 0
                                            ? "#3f8600"
                                            : "#cf1322"
                                }}
                                prefix={
                                    purchasesPercentChange > 0 ? (
                                        <ArrowUpOutlined />
                                    ) : (
                                        <ArrowDownOutlined />
                                    )
                                }
                                suffix="%"
                            />
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card>
                            <Statistic
                                title="Revenue (compared to last week)"
                                value={revenuePercentChange}
                                precision={2}
                                valueStyle={{
                                    color:
                                        revenuePercentChange > 0
                                            ? "#3f8600"
                                            : "#cf1322"
                                }}
                                prefix={
                                    revenuePercentChange > 0 ? (
                                        <ArrowUpOutlined />
                                    ) : (
                                        <ArrowDownOutlined />
                                    )
                                }
                                suffix="%"
                            />
                        </Card>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        <Card>
                            <Statistic
                                title="User signups (compared to last week)"
                                value={11.28}
                                precision={2}
                                valueStyle={{ color: "#3f8600" }}
                                prefix={<ArrowUpOutlined />}
                                suffix="%"
                                loading
                            />
                        </Card>
                    </Col>
                    {/*<Col span={4}>
                            <Card>
                            <Statistic
                                title="Transcripts Uploaded this week"
                                value={9.3}
                                precision={2}
                                valueStyle={{ color: '#cf1322' }}
                                prefix={<ArrowDownOutlined />}
                                suffix="%"
                            />
                            </Card>
                        </Col>*/}
                    <Col span={4}>
                        <Card>
                            <Statistic
                                title="Purchases (compared to last week)"
                                value={9.3}
                                precision={2}
                                valueStyle={{ color: "#cf1322" }}
                                prefix={<ArrowDownOutlined />}
                                suffix="%"
                                loading
                            />
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card>
                            <Statistic
                                title="Revenue (compared to last week)"
                                value={9.3}
                                precision={2}
                                valueStyle={{ color: "#cf1322" }}
                                prefix={<ArrowDownOutlined />}
                                suffix="%"
                                loading
                            />
                        </Card>
                    </Col>
                </Row>
            );
        }
    };

    CertificateStatistics = () => {
        if (
            this.props.statisticsStatus === "LOADED" &&
            this.props.statistics != null
        ) {
            return (
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        <Card>
                            <Statistic
                                title="Trial Started"
                                value={
                                    this.props.statistics.certificateTrack.trial
                                }
                                valueStyle={{
                                    color: "#3f8600"
                                }}
                            />
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card>
                            <Statistic
                                title="Certificate Purchased"
                                value={
                                    this.props.statistics.certificateTrack
                                        .purchase
                                }
                                valueStyle={{
                                    color: "#3f8600"
                                }}
                            />
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card>
                            <Statistic
                                title="Certificate Requested"
                                value={
                                    this.props.statistics.certificateTrack
                                        .requested
                                }
                                valueStyle={{
                                    color: "#3f8600"
                                }}
                            />
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card>
                            <Statistic
                                title="Certificate Rejected"
                                value={
                                    this.props.statistics.certificateTrack
                                        .rejected
                                }
                                valueStyle={{
                                    color: "#3f8600"
                                }}
                            />
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card>
                            <Statistic
                                title="Certificate Issued"
                                value={
                                    this.props.statistics.certificateTrack
                                        .issued
                                }
                                valueStyle={{
                                    color: "#3f8600"
                                }}
                            />
                        </Card>
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    render() {
        return (
            <div className="centered-container">
                <Space className="breadcrumbs">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">
                                <HomeOutlined />
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Admin Home</Breadcrumb.Item>
                    </Breadcrumb>
                </Space>

                <Divider orientation="left" orientationMargin="0">
                    Statistics
                </Divider>
                <this.PlatformStatistics />
                <Divider orientation="left" orientationMargin="0">
                    Certification Statistics (Last 30 days)
                </Divider>
                <this.CertificateStatistics />
                <Divider orientation="left" orientationMargin="0">
                    Actions
                </Divider>
                <Row gutter={16}>
                    <Col span={4}>
                        <Card
                            className="centered-card"
                            onClick={() =>
                                this.props.history.push("/admin/transcripts/")
                            }>
                            <Title>
                                <ReadOutlined />
                            </Title>
                            <Text>Transcripts</Text>
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card className="centered-card disabled-centered-card">
                            <Title>
                                <ContainerOutlined />
                            </Title>
                            <Text>Courses</Text>
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card
                            className="centered-card"
                            onClick={() =>
                                this.props.history.push("/admin/classcodes/")
                            }>
                            <Title>
                                <TeamOutlined />
                            </Title>
                            <Text>Class Codes / Rosters</Text>
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card
                            className="centered-card"
                            onClick={() =>
                                this.props.history.push("/admin/demos/")
                            }>
                            <Title>
                                <ExperimentOutlined />
                            </Title>
                            <Text>Demo Requests</Text>
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card
                            className="centered-card"
                            onClick={() =>
                                this.props.history.push(
                                    "/admin/certificate-requests/"
                                )
                            }>
                            <Badge
                                count={
                                    this.props.statistics
                                        ?.certificateRequestCount
                                }
                                color="red">
                                <Title>
                                    <SafetyCertificateOutlined />
                                </Title>
                                <Text>User Certification</Text>
                            </Badge>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        statistics: state.adminReducer.platformStatistics,
        statisticsStatus: state.adminReducer.platformStatisticsStatus
    };
};

export default connect(mapStateToProps, { fetchPlatformStatistics })(AdminHome);
