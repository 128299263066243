import { Component } from 'react';
import { Card, Table, Tabs, Typography } from 'antd';
import { connect } from 'react-redux';
import QnAOverview from './QnAOverview';
import QnAByLevel from './QnAByLevel';

const { TabPane } = Tabs;
const { Text } = Typography;

class QnASection extends Component {

    columns = [
        {
            title: 'Video',
            dataIndex: 'title'
        }
    ]
    formatDataSource = () => {
        if(this.props.watchHistoryOfCollectionStatus === "LOADED"){
            return this.props.watchHistoryOfCollection.modules[0].videos
        }
    }

    render(){
        return <Card className="dashboard-card">
            <Text>Q&A Section</Text>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Overview" key="1">
                    <QnAOverview 
                        watchHistoryOfCollection={this.props.watchHistoryOfCollection} 
                        watchHistoryOfCollectionStatus={this.props.watchHistoryOfCollectionStatus}
                    />
                </TabPane>
                <TabPane tab="User Posts/Replies" key="2">
                    <QnAByLevel />
                </TabPane>
            </Tabs>
        </Card>
    }
}

const mapStateToProps = state => {
    return {
        watchHistoryOfCollection: state.dashboardReducer.watchHistoryOfCollection,
        watchHistoryOfCollectionStatus: state.dashboardReducer.watchHistoryOfCollectionStatus
    }
}
export default connect(mapStateToProps, {})(QnASection);