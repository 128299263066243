import { Line, G2 } from '@ant-design/charts';


const TimeSpentPerWeek = ({formattedData}) => {
    
    /*G2.registerShape('point', 'breath-point', {
        draw: function draw(cfg, container) {
          var data = cfg.data;
          var point = {
            x: cfg.x,
            y: cfg.y,
          };
          var group = container.addGroup();
          if (data.week === `Week ${formattedData.length / 2}` && data.name == "Your Activity") {
            var decorator1 = group.addShape('circle', {
              attrs: {
                x: point.x,
                y: point.y,
                r: 10,
                fill: cfg.color,
                opacity: 0.5,
              },
            });
            var decorator2 = group.addShape('circle', {
              attrs: {
                x: point.x,
                y: point.y,
                r: 10,
                fill: cfg.color,
                opacity: 0.5,
              },
            });
            var decorator3 = group.addShape('circle', {
              attrs: {
                x: point.x,
                y: point.y,
                r: 10,
                fill: cfg.color,
                opacity: 0.5,
              },
            });
            decorator1.animate(
              {
                r: 20,
                opacity: 0,
              },
              {
                duration: 1800,
                easing: 'easeLinear',
                repeat: true,
              },
            );
            decorator2.animate(
              {
                r: 20,
                opacity: 0,
              },
              {
                duration: 1800,
                easing: 'easeLinear',
                repeat: true,
                delay: 600,
              },
            );
            decorator3.animate(
              {
                r: 20,
                opacity: 0,
              },
              {
                duration: 1800,
                easing: 'easeLinear',
                repeat: true,
                delay: 1200,
              },
            );
            group.addShape('circle', {
              attrs: {
                x: point.x,
                y: point.y,
                r: 6,
                fill: cfg.color,
                opacity: 0.7,
              },
            });
            group.addShape('circle', {
              attrs: {
                x: point.x,
                y: point.y,
                r: 1.5,
                fill: cfg.color,
              },
            });
          }
          return group;
        },
      });*/
    var config = {
        data: formattedData,
        xField: 'week',
        yField: 'watchTime',
        seriesField: 'name',
        xAxis: {
            title: {
                text: 'Week',
            }
        },
        yAxis: {
            title: {
                text: 'Minutes Spent'
            }
        },
        legend: { position: 'top' },
        smooth: true,
        animate: {
          appear: {
            animation: 'path-in',
            duration: 3000
          }
        },
        /*point: { shape: 'breath-point' },*/
        lineStyle: function lineStyle(_ref2) {
          
            var name = _ref2.name;
            if (name === 'Class Median') {
              return {
                lineDash: [4, 4],
                opacity: 1,
              };
            }
            if (name === 'Class Average') {
              return {
                lineDash: [4,4],
                opacity: 1,
              }
            }
            return { opacity: 0.5 };
          },
    };
    return <Line {...config} />
}

export default TimeSpentPerWeek;