import { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import { Alert, Button, Menu, message, PageHeader, Space, Typography } from 'antd';
import { LoadingOutlined, MenuOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Text } = Typography;

class DashboardPageHeader extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            currentTab: null
        }
    }
    componentDidMount() {
        switch(this.props.location.pathname){
            case "/mock/dashboard/":
                return this.setState({
                    currentTab: "home"
                })
            case "/mock/dashboard":
                return this.setState({
                    currentTab: "home"
                })
            case "/mock/dashboard/students/":
                return this.setState({
                    currentTab: "students"
                })
            case "/mock/dashboard/students":
                return this.setState({
                    currentTab: "students"
                })
            case "/mock/dashboard/videos/":
                return this.setState({
                    currentTab: "videos"
                })
            case "/mock/dashboard/videos":
                return this.setState({
                    currentTab: "videos"
                })
            case "/mock/dashboard/qna/":
                return this.setState({
                    currentTab: "qna"
                })
            case "/mock/dashboard/qna":
                return this.setState({
                    currentTab: "qna"
                }) 
            case "/mock/dashboard/reports/":
                return this.setState({
                    currentTab: "reports"
                })
            case "/mock/dashboard/reports":
                return this.setState({
                    currentTab: "reports"
                }) 
            default:
                return null
        }
    }

    renderQnACollectionMenu = () => {
        if(this.props.collection){
            return <>
                <Menu.ItemGroup title="Collection">
                    <Menu.Item key="collection" onClick={()=>{
                            this.props.history.push(`/mock/dashboard/qna/collections/${this.props.collection.id}`)
                            this.setState({
                                currentTab: "collection"
                            })
                        }}
                    >
                        {this.props.collection.name}
                    </Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup title="Modules">
                    {this.props.collection.modules.map(module => {
                        return <SubMenu key={`module-${module.id}`} title={module.name} onTitleClick={()=>{
                            this.props.history.push(`/mock/dashboard/qna/collections/${this.props.collection.id}/modules/${module.id}`)
                            this.setState({
                                currentTab: `module-${module.id}`
                            })
                        }}>
                                    <Menu.ItemGroup title="Videos">
                                        {
                                            module.videos.map(video=> {
                                                return <Menu.Item key={`video-${video.id}`} onClick={()=>{
                                                    this.props.history.push(`/mock/dashboard/qna/collections/${this.props.collection.id}/modules/${module.id}/videos/${video.id}`)
                                                    this.setState({
                                                        currentTab: `video-${video.id}`
                                                    })
                                                }}>{video.title}</Menu.Item>
                                            })
                                        }
                                    </Menu.ItemGroup>
                                </SubMenu>
                                
                    })}
                </Menu.ItemGroup>
            </>
        }else {
            return  <Menu.Item key="collection">
                        Loading <LoadingOutlined />
                    </Menu.Item>
        }
    }

    renderDashboardHomeActions = () => {
        if(this.props.isAuthenticated && this.props.selectedCoupon && this.props.currentUser.id == this.props.selectedCoupon.createdById && this.props.dashboardPoV == "instructor"){
   
            return <Button type="dashed" onClick={()=>{
                        // set POV to student
                        this.props.setDashboardPoV("student")
                        message.warning(<Space>
                            You are now viewing the dashboard from the <Text strong>students'</Text> perspective
                            <Button danger onClick={()=> {
                                this.props.setDashboardPoV("instructor")
                                message.destroy()
                            }}>
                                Exit Student View
                            </Button>
                        </Space>, 0)
                    }}>
                        Preview As Student
                    </Button>
        } else {
            return 
        }
    }
    render(){

            return <>
            <Alert
                        message="For Demo Purposes Only"
                        description="Please note that some features are hidden until you create an account. Any actions you make are not saved.    "
                        type="info"
                        showIcon
                    />
                    <br/>
            <PageHeader
                        title="Dashboard"
                        ghost={false}
                        onBack={() => window.history.back()}
                        extra={[
                            this.renderDashboardHomeActions(),
                            <Space>
                                <Button type="primary"><Link to='/mock/complete'>Finish Demo</Link></Button>

                            </Space>
                        ]}
                        className="dashboard-pageheader"
                    >
                    <Menu 
                        selectedKeys={[this.state.currentTab]}
                        mode="horizontal" 
                        overflowedIndicator={<MenuOutlined />}
                    >
                        <Menu.Item key="home" onClick={()=>{
                            this.props.history.push('/mock/dashboard/')
                            this.setState({
                                currentTab: "home"
                            })
                        }}>
                        Home
                        </Menu.Item>
                        
                            
                        <Menu.Item key="students" onClick={()=>{
                            this.props.history.push(`/mock/dashboard/roster/`)

                            this.setState({
                                currentTab: "students"
                            })
                        }}>
                        Student Analytics
                        </Menu.Item>
                    
                    
                        
                        <SubMenu key="sub2" title="Q&A Section">
                            <Menu.Item key="qna" onClick={()=>{
                                this.props.history.push('/mock/dashboard/qna/')
                                this.setState({
                                    currentTab: "qna"
                                })
                            }}>
                                Recent Activity
                            </Menu.Item>
                           
                        </SubMenu>
                        <Menu.Item key="reports" onClick={()=>{
                            this.props.history.push('/mock/dashboard/reports/')
                            this.setState({
                                currentTab: "reports"
                            })
                        }}>
                            Reports
                        </Menu.Item>

                    
                    </Menu>
                    </PageHeader>
                    <br/>
                    </>
             
        }
}



export default withRouter(DashboardPageHeader);