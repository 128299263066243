/******************************************************************
VideoGrid.js
Written by Adam Gamba, Summer 2021

Renders grid of video cards
******************************************************************/
import VideoCard from "./VideoCard";
import { fetchUserWatchHistoryOfModule } from "../../actions/dashboard";
import { Button, Card, Collapse, Progress, Row, Space, Statistic, Tooltip, Typography } from "antd";
import { useState } from 'react';
import { BarChartOutlined, FundViewOutlined, PlayCircleOutlined, SwapRightOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';
import ModuleCollapse from "./ModuleCollapse";


const { Text } = Typography;
// Displays all available videos in array of videos
const StudentVideoGrid = ({ collection, fetchUserWatchHistoryOfModule, loadedData, selectedCoupon, videos, sortType }) => {

  const renderDate = (inputDate) => {
      let date = new Date(inputDate);
      return `${date.toDateString()} at ${date.toLocaleTimeString()}`;
  }

  // Format videos array into object, with keys being module IDs and
  // values being an array of videos in that module
  let formattedVideos = {};
  for (let video of videos) {
    let moduleId = video.moduleId;
    if (formattedVideos.hasOwnProperty(moduleId)) {
      formattedVideos[moduleId].push(video);
    } else {
      formattedVideos[moduleId] = [video];
    }
  }

  // Account for sorting method - module ascending or descending
  let valuesList = [];
  if (sortType === "sortModuleAscending") {
    valuesList = Object.values(formattedVideos);
  } else {
    valuesList = Object.values(formattedVideos).reverse();
  }

  const handleOnCollapseStateChange = (openKeys) => {
    openKeys.forEach(openKey => {
      if(!(openKey in loadedData)){
        // fetch for data
        fetchUserWatchHistoryOfModule(collection.id, selectedCoupon.code, openKey)
      }
    })
  }
  return (
    // Renders list of videos as a grid with NUM_OF_COLUMNS columns
    // Make sure NUM_OF_COLUMNS is integer divisible by 24 to work
    <div>
      <Collapse onChange={handleOnCollapseStateChange} >
        {valuesList.map((module, i) => {
          //<Text>{renderDate(module[0].accessFrom)} <SwapRightOutlined /> {renderDate(module[0].accessUntil)}</Text>
          return (
            <ModuleCollapse 
              module={module}
              header={`${module[0].moduleName}`}
              key={module[0].moduleId}
              moduleIndex={i}
            >
              <div style={{display: "flex", flexWrap: "wrap"}}>
                  {module.map((video, j) => (
                  
                      <VideoCard 
                          module={module}
                          moduleId={module[0].moduleId}
                          video={video} 
                          moduleIndex={i} 
                          videoIndex={j} 
                          loadedData={loadedData} 
                          isDataLoaded = {module[0].moduleId in loadedData}
                      />
                  ))}
                </div>
            </ModuleCollapse>
          );
        })}
       
       </Collapse>
    </div>
  );

};

const mapStateToProps = state => {
  return {
    collection: state.dashboardReducer.collection,
    selectedCoupon: state.dashboardReducer.selectedCoupon,
    loadedData: state.dashboardReducer.userWatchHistoryOfModule
  }
}
export default connect(mapStateToProps, { fetchUserWatchHistoryOfModule })(StudentVideoGrid);
