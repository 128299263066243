import {Layout} from 'antd';
import { Component } from 'react';
import YouTube from 'react-youtube';

const PLAYER_OPTIONS = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      rel: 0
    },
};

const {Content} = Layout;
class SimpleYouTubePlayer extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            trackingInterval: null,
            transcriptTimeout: null,
            playerRef: null
        }
    }

    PLAYING = 1;
    
    render(){
        return <div style={{width: "70%", margin: "0 auto"}}>
            <Content id="video-content-wrapper">
           

                <div id="video-wrapper">
                    <YouTube videoId={this.props.embedId} height="1080" width="1920"
                            opts={PLAYER_OPTIONS}
                    />
                </div>
            </Content>
        </div>
    }
}

export default SimpleYouTubePlayer;