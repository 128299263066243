import { notification } from "antd";
import {
    PlusOutlined,
    WarningOutlined,
    DeleteOutlined,
    VideoCameraOutlined,
    QuestionOutlined
} from "@ant-design/icons";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { useEffect, useState } from "react";
import moment from "moment";
import BulkCreateForm from "./BulkCreateForm";
import { isValidQuestion } from "./BulkQuestionService";

const getMomentTimeSpan = (seconds) => {
    return moment("00:00:00", "HH:mm:ss").seconds(seconds);
};

const parseOption = (option) => {
    return {
        correctAnswerFlag: option.correctAnswerFlag,
        optionText: convertToRaw(
            EditorState.createWithContent(
                ContentState.createFromText(option.optionText)
            ).getCurrentContent()
        ),
        imageId: null,
        imageFile: null,
        explanationText: convertToRaw(
            EditorState.createWithContent(
                ContentState.createFromText(option.explanationText)
            ).getCurrentContent()
        )
    };
};
const parseQuestion = (question, moduleObj, videoObj) => {
    return {
        title: question.title,
        text: convertToRaw(
            EditorState.createWithContent(
                ContentState.createFromText(question.text)
            ).getCurrentContent()
        ),
        questionType: question.questionType,
        options:
            question.questionType === "open_ended"
                ? question.options.length > 0
                    ? [
                          parseOption({
                              ...question.options[0],
                              correctAnswerFlag: true
                          })
                      ]
                    : [
                          parseOption({
                              optionText: "",
                              explanationText: "",
                              correctAnswerFlag: true
                          })
                      ]
                : question.options.map((option) => parseOption(option)),

        timeRange: [
            getMomentTimeSpan(question.startVideoTimestamp),
            getMomentTimeSpan(question.endVideoTimestamp)
        ],
        imageIds: [],
        fileList: [],
        hasError: !moduleObj || !videoObj || !isValidQuestion(question),
        moduleObj,
        videoObj,
        action: "CREATE"
    };
};
const transformData = (data, collection) => {
    const getModule = (moduleId) => {
        return collection.modules.find((m) => m.id === moduleId);
    };
    const getVideo = (videoId, moduleObj) => {
        return moduleObj.videos.find((v) => v.id === videoId);
    };
    return data.map((m) => {
        const moduleObj = getModule(m.uid);
        return {
            ...m,
            moduleObj,
            hasModuleError: !moduleObj,
            videos: m.videos.map((v) => {
                const videoObj = getVideo(v.uid, moduleObj);
                return {
                    ...v,
                    videoObj,
                    hasVideoError: !videoObj,
                    questions: v.questions.map((q) => {
                        return parseQuestion(q, moduleObj, videoObj);
                    })
                };
            })
        };
    });
};

const BulkPreviewStructure = ({
    data,
    collection,
    setHasErrors,
    setJsonData,
    onClose
}) => {
    const [previewData, setPreviewData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [selectedModuleIndex, setSelectedModuleIndex] = useState(-1);
    const [selectedVideoIndex, setSelectedVideoIndex] = useState(-1);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(-1);

    const clearSelectedQuestion = () => {
        setSelectedModuleIndex(-1);
        setSelectedVideoIndex(-1);
        setSelectedQuestionIndex(-1);
        setSelectedQuestion(null);
    };

    const onSelectQuestion = (moduleIndex, videoIndex, questionIndex) => {
        setLoading(true);
        clearSelectedQuestion();

        setTimeout(() => {
            setSelectedModuleIndex(moduleIndex);
            setSelectedVideoIndex(videoIndex);
            setSelectedQuestionIndex(questionIndex);
            const questionObj =
                previewData[moduleIndex].videos[videoIndex].questions[
                    questionIndex
                ];
            setSelectedQuestion(questionObj);
            setLoading(false);
        }, 100);
    };

    const updateObject = (obj) => {
        let selectedQuestionTemp = Object.assign({}, selectedQuestion);
        selectedQuestionTemp = { ...selectedQuestionTemp, ...obj };
        selectedQuestionTemp.hasError = !isValidQuestion(selectedQuestionTemp);
        setSelectedQuestion(selectedQuestionTemp);

        const temp = [...previewData];
        temp[selectedModuleIndex].videos[selectedVideoIndex].questions[
            selectedQuestionIndex
        ] = selectedQuestionTemp;
        setPreviewData(temp);
        checkForErrors(temp);
    };

    const addNewQuestion = (moduleObj, moduleIndex, videoObj, videoIndex) => {
        const temp = [...previewData];
        temp[moduleIndex].videos[videoIndex].questions.push(
            parseQuestion(
                {
                    title: "",
                    text: "",
                    questionType: "",
                    options: [],
                    startVideoTimestamp: 0,
                    endVideoTimestamp: videoObj.duration
                },
                moduleObj,
                videoObj
            )
        );
        setPreviewData(temp);
        checkForErrors(temp);

        onSelectQuestion(
            moduleIndex,
            videoIndex,
            temp[moduleIndex].videos[videoIndex].questions.length - 1
        );
    };

    const removeQuestion = (e, moduleIndex, videoIndex, questionIndex) => {
        e.stopPropagation();
        const temp = [...previewData];
        temp[moduleIndex].videos[videoIndex].questions.splice(questionIndex, 1);
        setPreviewData(temp);
        checkForErrors(temp);
        clearSelectedQuestion();
    };

    const checkForErrors = (previewModuleList) => {
        const questionList = [];
        let hasErrors = false;

        previewModuleList.every((moduleItem) => {
            moduleItem.videos.every((videoItem) => {
                videoItem.questions.every((questionItem) => {
                    questionList.push(questionItem);
                    if (questionItem.hasError) {
                        hasErrors = true;
                    }
                    if (hasErrors) {
                        return false;
                    }
                    return true;
                });
                if (hasErrors) {
                    return false;
                }
                return true;
            });
            if (hasErrors) {
                return false;
            }
            return true;
        });

        if (hasErrors || !questionList.length) {
            setHasErrors(true);
        } else {
            setHasErrors(false);
        }
    };

    useEffect(() => {
        if (data && data.length > 0 && collection) {
            try {
                const d = transformData(data, collection);
                setPreviewData(d);
                checkForErrors(d);
            } catch (error) {
                notification.error({
                    message: `Invalid file uploaded!`
                });
                onClose();
            }
        }
    }, [data, collection]);

    useEffect(() => {
        setJsonData(previewData);
    }, [previewData]);

    return (
        <>
            <div className="preview-structure">
                <div className="preview-structure-left">
                    {previewData.map((module, mIndex) => (
                        <div className="item" key={mIndex}>
                            <div className="label">{module.module}</div>
                            <div>
                                {module.videos.map((video, vIndex) => (
                                    <div className="item pl" key={vIndex}>
                                        <div className="label">
                                            <VideoCameraOutlined />{" "}
                                            {video.video}
                                            <PlusOutlined
                                                className="ml-auto cursor-pointer"
                                                onClick={() =>
                                                    addNewQuestion(
                                                        module.moduleObj,
                                                        mIndex,
                                                        video.videoObj,
                                                        vIndex
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            {video.questions.map(
                                                (question, qIndex) => (
                                                    <div
                                                        className="item pl"
                                                        key={qIndex}>
                                                        <div
                                                            className={`label question ${
                                                                selectedModuleIndex ===
                                                                    mIndex &&
                                                                selectedVideoIndex ===
                                                                    vIndex &&
                                                                selectedQuestionIndex ===
                                                                    qIndex
                                                                    ? "selected"
                                                                    : ""
                                                            }`}
                                                            onClick={() =>
                                                                onSelectQuestion(
                                                                    mIndex,
                                                                    vIndex,
                                                                    qIndex
                                                                )
                                                            }>
                                                            <QuestionOutlined />
                                                            {question.title}

                                                            <div className="icon-section">
                                                                {question.hasError && (
                                                                    <WarningOutlined className="danger" />
                                                                )}
                                                                <DeleteOutlined
                                                                    className="ml-10 cursor-pointer"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        removeQuestion(
                                                                            e,
                                                                            mIndex,
                                                                            vIndex,
                                                                            qIndex
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="preview-structure-right">
                    {selectedQuestion !== null ? (
                        <>
                            <BulkCreateForm
                                question={selectedQuestion}
                                onQuestionUpdate={(obj) => updateObject(obj)}
                            />
                        </>
                    ) : (
                        <>
                            {loading ? (
                                <div className="select-question-message">
                                    Loading...
                                </div>
                            ) : (
                                <div className="select-question-message">
                                    Please select question to edit.
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default BulkPreviewStructure;
