import { Component } from "react";
import { Upload, message, Space, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { API_URL } from "../const";

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
}

const { Title } = Typography;

class QuizQuestionImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            localImageList: props.imageFileList ? props.imageFileList : []
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.reset != this.props.reset && this.props.reset) {
            this.setState({
                localImageList: []
            });
            if (this.props.setFileList) {
                this.props.setFileList([]);
            }
        }
    }
    handleChange = (info) => {
        this.setState({ localImageList: info.fileList });
        if (this.props.setFileList) {
            this.props.setFileList(info.fileList);
        }

        if (info.file.status === "uploading") {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === "done") {
            // Get this url from response in real world.
            this.props.addImageToForm(info.file.response.id);
            this.props.addImageToAll(info.file.response);
            getBase64(info.file.originFileObj, (imageUrl) => {
                if (this.props.receivedUserCreatedCollections) {
                    this.props.updateImage(imageUrl);
                }
                return this.setState({
                    imageUrl,
                    loading: false
                });
            });
        }
    };

    handleOnRemove = (file) => {
        this.props.customOnRemove(file.response.id);
    };
    render() {
        return (
            <div className="question-text-image-upload-div">
                <Upload
                    name="image"
                    listType="picture-card"
                    className="upload-cover-image"
                    action={API_URL + `/api/v1/quizquestionimages/upload/`}
                    headers={{
                        Authorization:
                            "Bearer " + localStorage.getItem("access")
                    }}
                    method="POST"
                    beforeUpload={beforeUpload}
                    onChange={this.handleChange}
                    maxCount={this.props.maxCount}
                    onRemove={this.handleOnRemove}
                    fileList={this.state.localImageList}>
                    <Space>
                        <UploadOutlined /> Select File
                    </Space>
                </Upload>
            </div>
        );
    }
}

export default QuizQuestionImageUpload;
