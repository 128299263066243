import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchClassStatistics, fetchStudentSummaries } from '../../actions/dashboard';
import { Anchor, Card, Col, DatePicker, Divider, PageHeader, Row, Skeleton, Space, Spin, Statistic, Typography } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import moment from 'moment';

import HomePageVideoActivity from './HomePageVideoActivity';
import HomePageRosterActivity from './HomePageRosterActivity';
import ClassAverageWatchActivity from './ClassAverageWatchActivity';
import ClassMedianTimeSpent from './ClassMedianTimeSpent';
import StudentSummaries from '../Students/StudentSummaries';

const { Text, Title } = Typography;
const { Link } = Anchor;

class DashboardHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            calendarEvents: [],
            targetOffset: 64,
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.selectedCoupon != this.props.selectedCoupon){
            this.props.fetchClassStatistics(this.props.selectedCoupon.code)
            if(this.props.dashboardPoV == "student"){
                this.props.fetchStudentSummaries(this.props.user.id, this.props.selectedCoupon.code)
            }
        }
        if(prevProps.dashboardPoV != this.props.dashboardPoV && this.props.dashboardPoV == "student" && this.props.selectedCoupon){
            this.props.fetchStudentSummaries(this.props.user.id, this.props.selectedCoupon.code)
        }
    }
    renderCollectionDetails = () => {
        if(this.props.collection){
            return <div>
                    <Title level={5}>{this.props.collection.name}</Title>
                </div>
        }else {
            return <Skeleton active/>
        }
    }

    renderCourseStatistics = () => {
        if(this.props.collection && this.props.classStatisticsStatus === "LOADED") {
            return <Row gutter={[16,16]}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Statistic title="Class Size" value={this.props.roster.length} />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Statistic title="Semester Active Users" value={this.props.classStatistics.semesterActiveUsers} />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Statistic 
                                title="Average Watch Time" 
                                value={this.props.classStatistics.averageWatchTime} 
                                formatter={(value) => {
                                    let remainder = value;
                                    let hours = Math.floor(remainder / 3600);
                                    remainder = remainder % 3600;
                                    let minutes = Math.floor(remainder / 60);
                                    let seconds = Math.floor(remainder % 60);
                                    return `${hours}hr ${minutes}min ${seconds}s`
                                }}
                            />
                        </Col>
                    </Row>
        }else {
            return <Row gutter={16}>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Statistic title="Class Size" loading={true} />
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Statistic title="Semester Active Users"  loading={true}/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Statistic 
                                title="Average Watch Time" 
                                loading={true}
                            />
                        </Col>
                    </Row>
        }
    }
    render(){
        if(!this.props.selectedCoupon || (!this.props.userLicenses.length && this.props.userLicensesStatus == "LOADED")) {
            return <h1>Nothing to load</h1>
        }
        return <div style={{display:"flex", justifyContent:"space-between"}}>
        <div className="dashboard-content">
                
                <Card className="dashboard-card" id='course-details'>
                    Course
                    
                    {this.renderCollectionDetails()}
                    <Divider/>
                         
               
                    Course Statistics

                    {this.renderCourseStatistics()}
                    <br/>            
                   
                    {this.props.dashboardPoV == "student" ? <>
                                                            Your Statistics
                                                            <StudentSummaries 
                                                                studentSummaries={this.props.studentSummaries}
                                                                studentSummariesStatus={this.props.studentSummariesStatus}
                                                            /></>
                                                            : null
                    }
                </Card>
                <br/>
                <ClassMedianTimeSpent />
                <br/>
                <ClassAverageWatchActivity />

                {   this.props.dashboardPoV == "instructor" && this.props.selectedCoupon.createdById == this.props.user.id ?
                    <>
                        <br/>
                        <HomePageVideoActivity />
                        <br/>
                        <HomePageRosterActivity />
                    </> : null
                }
        </div>
            <Card className="dashboard-home-anchor">
                <Anchor targetOffset={this.state.targetOffset} offsetTop={70}>
                    <Link href="#course-details" title="Course Details" />
                    <Link href="#course-statistics" title="Course Statistics" />
                    <Link href="#time-spent-per-week" title="Time Spent per Week">
                        
                    </Link>
                    <Link href="#video-percent-completion" title="Video % Completion">
        
                    </Link>
                    {
                        this.props.dashboardPoV == "instructor" && this.props.selectedCoupon.createdById == this.props.user.id ? <>   
                            <Link href="#videos-watched-this-past-week" title="Videos Watched This Week" />
                            <Link href="#roster-activity-this-past-week" title="Roster Activity This Week" />
                        </> : <></>
                    }
                  
                </Anchor>
            </Card>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        collection: state.dashboardReducer.collection,
        classStatisticsStatus: state.dashboardReducer.classStatisticsStatus,
        classStatistics: state.dashboardReducer.classStatistics,
        studentSummaries: state.dashboardReducer.studentSummaries,
        studentSummariesStatus: state.dashboardReducer.studentSummariesStatus,
        roster: state.dashboardReducer.roster,
        userLicenses: state.userReducer.enrolledLicenses,
        userLicensesStatus: state.userReducer.fetchEnrolledLicensesStatus,
        selectedCoupon: state.dashboardReducer.selectedCoupon,
        user: state.userReducer.user,
        dashboardPoV: state.userReducer.dashboardPoV
    }
}

export default connect(mapStateToProps, {fetchClassStatistics, fetchStudentSummaries})(DashboardHome);