import { IssuesCloseOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { Button, Result, Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const antIcon = (
    <LoadingOutlined
        style={{
        fontSize: 24,
        }}
        spin
    />
);


const ResultStep = ({ status }) => {

    if(status == "SUCCESS"){
        return <div>
           <Result
                icon={<SmileOutlined />}
                title="Great, your demo request has been submitted! Look for an email from us soon."
                extra={<Button type="primary"><Link to='/'>Return to Homepage</Link></Button>}
            />
        </div>        
    } else if(status == "FAILED") {
        return <Result
                    icon={<IssuesCloseOutlined />}
                    title="Sorry, something went wrong with submitting your demo request. Please try again later."
                    extra={<Button type="primary"><Link to='/'>Return to Homepage</Link></Button>}
                />
    } else {
        return <div>
            <Spin indicator={antIcon} />
        </div>
    }
    
}


const mapStateToProps = (state) => {
    return {
        status: state.issueReducer.demoRequestStatus
    }
}
export default connect(mapStateToProps, {})(ResultStep);