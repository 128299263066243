const initialState = {
    creatorCollectionPerformances: [],
    creatorCollectionPerformancesStatus: "NOT LOADED",
    creatorEarnings: null,
    creatorEarningsStatus: "NOT LOADED",
    creatorModulePerformances: [],
    creatorModulePerformancesStatus: "NOT LOADED",
    creatorProfile: null,
    creatorProfileStatus: "NOT LOADED",
    creatorPublicCollections: [],
    creatorPublicCollectionsStatus: "NOT LOADED",
    creatorTransactionsByWeek: [],
    creatorTransactionsByWeekStatus: "NOT LOADED",
    creatorTransactionsSummary: null,
    creatorTransactionsSummaryStatus: "NOT LOADED",
    earnings: null,
}

export default (state=initialState, action) => {
    switch(action.type){
        case "SET_CREATOR_COLLECTION_PERFORMANCES":
            return Object.assign({}, state, {
                creatorCollectionPerformances: action.performances
            })
        case "SET_CREATOR_COLLECTION_PERFORMANCES_STATUS":
            return Object.assign({}, state, {
                creatorCollectionPerformancesStatus: action.status
            })
        case "SET_CREATOR_EARNINGS":
            return Object.assign({}, state, {
                creatorEarnings: action.earnings
            })
        case "SET_CREATOR_EARNINGS_STATUS":
            return Object.assign({}, state, {
                creatorEarningsStatus: action.status
            })
        case "SET_CREATOR_MODULE_PERFORMANCES":
            return Object.assign({}, state, {
                creatorModulePerformances: action.performances
            })
        case "SET_CREATOR_MODULE_PERFORMANCES_STATUS":
            return Object.assign({}, state, {
                creatorModulePerformancesStatus: action.status
            })
        case "SET_CREATOR_PROFILE":
            return Object.assign({}, state, {
                creatorProfile: action.creatorProfile
            })
        case "SET_CREATOR_PROFILE_STATUS":
            return Object.assign({}, state, {
                creatorProfileStatus: action.status
            })
        case "SET_CREATOR_PUBLIC_COLLECTIONS":
            return Object.assign({}, state, {
                creatorPublicCollections: action.collections
            })
        case "SET_CREATOR_PUBLIC_COLLECTIONS_STATUS":
            return Object.assign({}, state, {
                creatorPublicCollectionsStatus: action.status
            })
        case "SET_CREATOR_TRANSACTIONS_BY_WEEK":
            return Object.assign({}, state, {
                creatorTransactionsByWeek: action.transactions
            })
        case "SET_CREATOR_TRANSACTIONS_BY_WEEK_STATUS":
            return Object.assign({}, state, {
                creatorTransactionsByWeekStatus: action.status
            })
        case "SET_CREATOR_TRANSACTIONS_SUMMARY":
            return Object.assign({}, state, {
                creatorTransactionsSummary: action.transactionsSummary
            })
        case "SET_CREATOR_TRANSACTIONS_SUMMARY_STATUS":
            return Object.assign({}, state, {
                creatorTransactionsSummaryStatus: action.status
            })
        default:
            return state
    }
}