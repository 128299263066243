import React, { Component } from 'react';
import { Anchor, Card, Col, DatePicker, Divider, PageHeader, Row, Skeleton, Space, Spin, Statistic, Typography } from 'antd';
import TimeSpentPerWeek from './TimeSpentPerWeek';
import timeSpentPerWeekData from './timeSpentPerWeekData.json'
import ClassAverageBulletChart from './ClassAverageBulletChart';


const { Text, Title } = Typography;
const { Link } = Anchor;


const bulletChartData = {
    "name": "Lecture 1 - Vector Addition",
    "videos": [
        {
            "title": "Dynamic Connectivity",
            "classAverageWatchPercentage": 0.55,
            "classMedianWatchPercentage": 0,
            "userWatchPercentage": 0.3,
            "classSampleSize": 11
        },
        {
            "title": "Quick Find",
            "classAverageWatchPercentage": 0.63,
            "classMedianWatchPercentage": 0,
            "userWatchPercentage": 0.7,
            "classSampleSize": 12
        },
        {
            "title": "Quick-Union",
            "classAverageWatchPercentage": 0.64,
            "classMedianWatchPercentage": 0,
            "userWatchPercentage": 0.1,
            "classSampleSize": 12
        },
        {
            "title": "Improvements",
            "classAverageWatchPercentage": 0.43,
            "classMedianWatchPercentage": 0,
            "userWatchPercentage": 0.54,
            "classSampleSize": 8
        },
        {
            "title": "Applications",
            "classAverageWatchPercentage": 0.54,
            "classMedianWatchPercentage": 0,
            "userWatchPercentage": 0.77,
            "classSampleSize": 7
        },
    ]
}
class DashboardHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            calendarEvents: [],
            targetOffset: 64,
        }
    }

    renderCollectionDetails = () => {
    
        return <div>
                <Title level={5}>Algorithms - Princeton University</Title>
            </div>
    }

    renderCourseStatistics = () => {
       
            return <Row gutter={[16,16]}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Statistic title="Class Size" value={351} />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Statistic title="Semester Active Users" value={345} />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Statistic 
                                title="Average Watch Time" 
                                value={43737} 
                                formatter={(value) => {
                                    let remainder = value;
                                    let hours = Math.floor(remainder / 3600);
                                    remainder = remainder % 3600;
                                    let minutes = Math.floor(remainder / 60);
                                    let seconds = Math.floor(remainder % 60);
                                    return `${hours}hr ${minutes}min ${seconds}s`
                                }}
                            />
                        </Col>
                    </Row>
      
    }

    formatData = (data) => {
            return data.flatMap(medianData => {
                return [
                    {
                        'watchTime': parseFloat((medianData['classMedianTimeSpent']/60).toFixed(2)),
                        'week': `Week ${medianData['weekNumber']}`,
                        'name': 'Class Median'
                    },
                    {
                        'watchTime': parseFloat((medianData['userTimeSpent']/60).toFixed(2)),
                        'week': `Week ${medianData['weekNumber']}`,
                        'name': 'Your Activity'
                    }, 
                    {
                        'watchTime': parseFloat((medianData['classAverageTimeSpent']/60).toFixed(2)),
                        'week': `Week ${medianData['weekNumber']}`,
                        'name': 'Class Average'
                    }, 
                ]
            })
    
    }
    render(){
        return <div style={{display:"flex", justifyContent:"space-between"}}>
        <div className="dashboard-content">
                
                <Card className="dashboard-card" id='course-details'>
                    Course
                    
                    {this.renderCollectionDetails()}
                    <Divider/>
                         
               
                    Course Statistics

                    {this.renderCourseStatistics()}
                    <br/>            
                    
                </Card>
                <br/>
                <Card className="dashboard-card" id="time-spent-per-week">
                    
                    <TimeSpentPerWeek formattedData={this.formatData(timeSpentPerWeekData)}/>
                </Card>
                <br/>
                <ClassAverageBulletChart 
                    moduleWatchPercentageData={bulletChartData}
                    moduleWatchPercentageDataStatus={"LOADED"}
                    moduleIndex={this.state.moduleIndex}
                    selectedCoupon={this.props.selectedCoupon}
                    user={this.props.user}
                    dashboardPoV={this.props.dashboardPoV}
                />
                <br/>
        </div>
            
        </div>
    }
}



export default DashboardHome;