import { setSkipToVideoTimestamp } from "../actions/videoControl"

const initialState = {
    videoTimestamp: null,
    nextTranscriptUpdate: 1000,
    mutedQuizQuestions: false,
    skipToVideoTimestamp: null,
    moduleAccessForVideo: null,
    moduleAccessForVideoStatus: "NOT LOADED",
}

export default (state=initialState, action) => {
    switch(action.type){
        case "CLEAR_MODULE_ACCESS_FOR_VIDEO":
            return Object.assign({}, state, { moduleAccessForVideo: null, moduleAccessForVideoStatus: "NOT LOADED",})
        case "SET_VIDEO_TIMESTAMP":
            return Object.assign({}, state, {videoTimestamp: action.timestamp})
        case "SET_MODULE_ACCESS_FOR_VIDEO":
            return Object.assign({}, state, { moduleAccessForVideo: action.data })
        case "SET_MODULE_ACCESS_FOR_VIDEO_STATUS": 
            return Object.assign({}, state, { moduleAccessForVideoStatus: action.status})
        case "SET_NEXT_TRANSCRIPT_UPDATE":
            return Object.assign({}, state, { nextTranscriptUpdate: action.timeInterval})
        case "SET_SKIP_TO_VIDEO_TIMESTAMP":
            return Object.assign({}, state, { skipToVideoTimestamp: action.timestamp})
        case "TOGGLE_MUTED_QUIZ_QUESTIONS":
            return Object.assign({}, state, { mutedQuizQuestions: !state.mutedQuizQuestions})
        default:
            return state
    }
}