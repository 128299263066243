import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const CollectionBreadcrumb = ({
    collection,
    categories,
    isCertificationPage = false
}) => {
    const CategoryBreadcrumb = () => {
        const [category, setCategory] = useState(null);
        const [subCategory, setSubCategory] = useState(null);

        useEffect(() => {
            if (
                collection && categories &&
                collection.category > 0 &&
                categories.length > 0 &&
                category === null &&
                subCategory === null
            ) {
                const cat = categories.find(
                    (c) => collection.category === c.id
                );
                if (cat) {
                    const subCat = cat.subCategories.find(
                        (c) => collection.subCategory === c.id
                    );
                    setCategory(cat);
                    setSubCategory(subCat);
                }
            }
        }, [collection, categories]);
        return (
            <>
                {category && (
                    <Breadcrumb.Item>
                        <Link to={`/collections/?category=${category.id}`}>
                            {category.name}
                        </Link>
                    </Breadcrumb.Item>
                )}
                {subCategory && (
                    <Breadcrumb.Item>
                        <Link
                            to={`/collections/?category=${category.id}&subCategory=${subCategory.id}`}>
                            {subCategory.name}
                        </Link>
                    </Breadcrumb.Item>
                )}
            </>
        );
    };

    return (
        <>
            {/* Breadcrumb */}
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/">
                        <HomeOutlined />
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {collection.originalCollection ? (
                        <Link to="/collections/">Premium Courses</Link>
                    ) : (
                        "Courses"
                    )}
                </Breadcrumb.Item>
                {collection.category > 0 && <CategoryBreadcrumb />}
                {isCertificationPage ? (
                    <>
                        <Breadcrumb.Item>
                            <Link to={"/collections/" + collection.id}>
                                {collection.name}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Certification</Breadcrumb.Item>
                    </>
                ) : (
                    <>
                        <Breadcrumb.Item>{collection.name}</Breadcrumb.Item>
                    </>
                )}
            </Breadcrumb>
        </>
    );
};

export default CollectionBreadcrumb;
