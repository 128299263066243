import { Component } from 'react';
import { Breadcrumb, Button, Divider, Modal, Space, Table, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, HomeOutlined, SendOutlined, SmallDashOutlined, UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchClassCodesList } from '../actions/admin';
import { CSVLink } from "react-csv";


const { Paragraph, Text, Title } = Typography; 


const columns = [
    {
        title: 'Label',
        key: 'label',
        render: instance => <Link to={`/admin/classcodes/${instance.code}/roster`}>{instance.label}</Link>,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: code => <Text>{code}</Text>
    },
    {
        title: 'Roster Size',
        dataIndex: 'rosterSize',
        key: 'rosterSize',
        render: rosterSize => <Text>{rosterSize}</Text>
    },
    {
        title: 'Status',
        key: 'status',
        render: instance => {

            let currentDate = new Date()
            let startDate = new Date(instance.validFrom)
            let endDate = new Date(instance.expiresOn)

            if(currentDate >= startDate && currentDate <= endDate) {
                return <Text type="success"><CheckCircleOutlined /> Active</Text>
            } else if(currentDate > endDate) {
                return <Text type="danger"><CloseCircleOutlined /> Expired</Text>
            } else {
                return <Text><SmallDashOutlined />Not active yet</Text>
            }
        },
    },
]



class AdminClassCodes extends Component {

    constructor(props){
        super(props);
        this.state = {
            files: [],
            pagination: {
                current: 1,
                pageSize: 25,
                showSizeChanger: false
            },
            loading: false,
            visible: false,
            visibleCSVModal: false,
        }
    }

    componentDidMount(){
        this.props.fetchClassCodesList(1, this.updateTableState);
    }

    handleTableChange = (pagination) => {
        this.props.fetchClassCodesList(pagination.current, this.updateTableState);
    };


    updateTableState = (pageNum, pageSize, total) => {
        this.setState({
            loading: false,
            pagination: {
                current: pageNum,
                pageSize: pageSize,
                total: total,
                showSizeChanger: false,
                // 200 is mock data, you should read it from server
                // total: data.totalCount,
            },
        });
    }
   
    

    render() {
        return <div className="centered-container">
                <Space className="breadcrumbs">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/"><HomeOutlined /></Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/admin">Admin Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Class Codes</Breadcrumb.Item>
                    </Breadcrumb>
                </Space>
                <br/>
                <br/>
                <Table columns={columns} dataSource={this.props.classCodesList} pagination={this.state.pagination} onChange={this.handleTableChange} loading={this.props.classCodesListStatus === "LOADING"}/>
            </div>
    }
   
}

const mapStateToProps = state => {
    return {
        classCodesList: state.adminReducer.classCodesList,
        classCodesListStatus: state.adminReducer.classCodesListStatus,
    }
}

export default connect(mapStateToProps, { fetchClassCodesList })(AdminClassCodes);