import { Bullet } from "@ant-design/charts";
import { Card, Skeleton, Table, Typography} from 'antd';

const { Text, Title } = Typography;

const ClassAverageBulletChart = ({moduleWatchPercentageData, moduleWatchPercentageDataStatus, dashboardPoV, moduleIndex, selectedCoupon, user}) => {

    const columns = [
        {
            title: 'Video',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: "Active Users",
            dataIndex: "classSampleSize",
            key: "classSampleSize"
        }

    ]

    const renderLegend = () => {
      if(dashboardPoV == "instructor" && selectedCoupon.createdById == user.id){
        return [
          {
            value: 'Class Average',
            name: 'Class Average* (%)',
            marker: {
              symbol: 'square',
              style: {
                fill: '#5B8FF9',
                r: 5,
              },
            },
          },
        ]
      }else {
        return [
          {
            value: 'Your Completion',
            name: 'Your Completion (%)',
            marker: {
              symbol: 'square',
              style: {
                fill: '#5B8FF9',
                r: 5,
              },
            },
          },
          {
            value: 'Class Average',
            name: 'Class Average* (%)',
            marker: {
              symbol: 'line',
              style: {
                stroke: 'cyan',
                r: 5,
                lineWidth: 2
              },
            },
          },
        ]
      }
    }
    if(moduleWatchPercentageDataStatus == "LOADED" && dashboardPoV != null){
        let data = moduleWatchPercentageData.videos.map(video => {
              if(selectedCoupon.createdById == user.id && dashboardPoV == "instructor"){
                return  {
                  title: video.title,
                  ranges: [100],
                  "Your Progress": [video.userWatchPercentage ? Math.round(video.userWatchPercentage * 100, 2) : 0],
                  "Class Average": [video.classAverageWatchPercentage ? Math.round(video.classAverageWatchPercentage * 100, 2) : 0],
                }
              }else {
                return  {
                  title: video.title,
                  ranges: [100],
                  "Your Progress": [video.userWatchPercentage ? Math.round(video.userWatchPercentage * 100, 2) : 0]   ,
                  "Class Average": video.classAverageWatchPercentage ? Math.round(video.classAverageWatchPercentage * 100, 2) : 0,
                }
              }
                
        }).reverse()
        var config = {
            height: 300,
            data: data,
            measureField: (dashboardPoV != "instructor" || selectedCoupon.createdById != user.id) ? 'Your Progress' : 'Class Average',
            rangeField: 'ranges',
            targetField: 'Class Average',
            xField: 'title',
            color: {
              range: '#f0efff',
              measure: '#5B8FF9',
              target: 'cyan',
            },
            bulletStyle: {
              target: {
                lineWidth: dashboardPoV != "instructor" || selectedCoupon.createdById != user.id ? 2 : 0,
              }
            },
            xAxis: { line: null },
            yAxis: false,
            label: {
              measure: {
                position: 'middle',
                style: { fill: '#fff' },
              },
            },
            legend: {
              custom: true,
              position: 'bottom',
              items: renderLegend()
            },
          };
            return <Card className="dashboard-card">
                    <Title level={5}>{moduleWatchPercentageData.name}</Title>
                    
                    <Bullet {...config} />
                
                   
                </Card>
    }else {
        return <Card>
            <Skeleton active />
            <Skeleton active />
        </Card>
    }
    
}

export default ClassAverageBulletChart;