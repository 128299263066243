import { Button, Card, Popconfirm, Typography } from 'antd';
import CollectionCreatorModuleEditor from './CollectionCreatorModuleEditor';
import { useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';


const { Title } = Typography; 

const ModuleRow = ({ allVideos, authorsDict, currentUser, index, module, modules, updateState, videoBank }) => {

    const [modalVisible, setModalVisible] = useState(false);
    
    const handleRemoveModule = (e) => {
        e.stopPropagation()
        let newModules = Array.from(modules);
        let [removedModule] = newModules.splice(index, 1)
        let videosToRemove = Array.from(removedModule.videos);
        let newAllVideos = Array.from(allVideos);
        videosToRemove.forEach(video => {
            let foundIndex = newAllVideos.findIndex(allVideo => allVideo.embedId === video.embedId)
            newAllVideos.splice(foundIndex, 1);
        })

        updateState("allVideos", newAllVideos);
        updateState("modules", newModules);
    }
    

    return <div >
                <CollectionCreatorModuleEditor 
                    allVideos={allVideos}
                    authorsDict={authorsDict}
                    currentUser={currentUser}
                    index={index}
                    modalVisible={modalVisible} 
                    mode="edit"
                    module={module}
                    modules={modules}
                    setModalVisible={setModalVisible} 
                    updateState={updateState}
                    videoBank={videoBank}
                />
                <Card onClick={()=>{
                    setModalVisible(true)
                }}>
                    <div style={{ display: "flex", justifyContent: "space-between"}}>   
                        <div>

                            <Title level={5} style={{margin: 0}}>{module.name}</Title>
                        </div>
                        <div>
                        
                            <Popconfirm
                                title="Are you sure to remove this module?"
                                onConfirm={handleRemoveModule}
                                onCancel={(e)=>{e.stopPropagation()}}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button className="delete-button" size="small" onClick={(e)=>e.stopPropagation()}>Delete</Button>
                            </Popconfirm>
                        </div>
                        
                    </div>
                    
                </Card>
               
            
               
            </div>
}

export default ModuleRow;