import { Button, Card, Col, List, Modal, Row, Space, Typography } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import ModuleRow from './ModuleRow';
import VideoCard from '../../Components/VideoCard';
import CollectionCreatorModuleEditor from './CollectionCreatorModuleEditor';
import Tour from '../../Components/Tour';

const { Paragraph, Text, Title } = Typography;

const AddModules = ({ allVideos, authorsDict, collection, currentUser, goToNextStep, goToPreviousStep, modules, policyModel, setUserCreatedModules, updateState, videoBank }) => {

    const [newModuleFormVisible, setNewModuleFormVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selectedModulesToImport, setSelectedModulesToImport] = useState(new Set());

    const handleOnDragEnd = (result) => {
        if(!result.destination) return;
        const items = Array.from(modules);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem)

        updateState("modules", items);
    }

    const handleOnNextStepClick = () => {
        let collectionValuePrice = 0;
        let collectionNetPrice = 0;
        modules.forEach(module => {
            collectionValuePrice += module.price
            collectionNetPrice += Math.ceil(module.price * (1 - 0.15))
        });
        updateState("collection", { ...collection, valuePrice: collectionValuePrice, collectionNetPrice: collectionNetPrice})
        goToNextStep()
    }


    const tutorialSteps = [
        {
            target: '#add-module-btn',
            content: <div>
                        <p style={{textAlign: 'left'}}>Let's start by adding a new module.</p>
                        <p style={{textAlign: 'left'}}>Click "Add Modules".</p>
                    </div>,
            placement: "bottom",
            disableBeacon: true, 
        }, 
    ];
    
    const renderTutorial = () => {
        const completedTutorial = localStorage.getItem('completedCreateCollectionTutorials');
        if(completedTutorial == null) {
            return <Tour steps={tutorialSteps} tutorialName='completedCreateCollectionTutorial' />
        } else {
            return
        }
    }


    return  <div>
                {renderTutorial()}
                <Paragraph>
                    <Title level={5}>Modules in this Course:</Title>
                    <Text type="secondary">Drag and drop into preferred order. Click to edit.</Text>
                </Paragraph>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="modules">
                        {
                            (provided) => (
                                <ol className="collection-creator-modules-list" {...provided.droppableProps} ref={provided.innerRef}>
                                {   modules.map((module, index) => {
                                    
                                        return (
                                            <Draggable key={`${module.key}`} draggableId={`${module.key}`} index={index}>
                                                {(provided)=> (
                                                    <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        
                                                        <ModuleRow 
                                                            allVideos={allVideos}
                                                            authorsDict={authorsDict}
                                                            currentUser={currentUser}
                                                            index={index}
                                                            modules={modules} 
                                                            module={module}
                                                            updateState={updateState}
                                                            videoBank={videoBank}
                                                        />
                                                    </li>
                                                )}
                                                
                                            </Draggable>
                                        )
                                    }
                                )}
                                {provided.placeholder}
                                </ol>
                            )
                        }
                    </Droppable>
                </DragDropContext>


               
                <Button 
                    type="dashed" 
                    style={{width: "100%", marginBottom: 20}}
                    icon={<PlusOutlined />}
                    onClick={()=>setVisible(true)}
                    id="add-module-btn"
                >
                    Add Module
                </Button>
                <Modal visible={visible} footer={null} onCancel={()=>setVisible(false)} maskClosable={false}>
                    <div className="add-module-modal">
                        <Title level={4}>How would you like to add your module?</Title>
                        <br/>
                        <Row gutter={16}>
                            <Col span={12}>
                                <div 
                                    className="add-module-option"
                                    onClick={()=>{
                                        setVisible(false);
                                        setNewModuleFormVisible(true)
                                    }}
                                >
                                    <Text>Build from</Text>
                                    <Text>YouTube or Vimeo Playlist</Text> 
                                </div>
                            </Col>
                            <Col span={12}>
                                <div 
                                    className="add-module-option"
                                    onClick={()=>{
                                        setVisible(false);
                                    }}
                                >
                                    <Text>Add from modules</Text> 
                                    <Text>you've created</Text>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
               
                <CollectionCreatorModuleEditor
                    allVideos={allVideos}
                    authorsDict={authorsDict}
                    currentUser={currentUser}
                    modalVisible={newModuleFormVisible} 
                    mode="add"
                    module={{
                        name: "Sample Module Name",
                        primaryAuthorUsername: "",
                        coAuthorUsernames: [],
                        videos: []
                    }}
                    modules={modules}
                    setModalVisible={setNewModuleFormVisible} 
                    updateState={updateState}
                    videoBank={videoBank}
                />
               
                <Space>
                    <Button
                        onClick={goToPreviousStep}
                    >
                        Back
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleOnNextStepClick}
                        disabled={modules.length < 1}
                    >
                        Next: Review Changes
                    </Button>
                </Space>
            </div>
}

export default AddModules;
