import { EditOutlined, UserOutlined } from "@ant-design/icons";
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    InputNumber,
    Modal,
    Popconfirm,
    Row,
    Space,
    Tooltip,
    Typography
} from "antd";
import { useState } from "react";
import BorrowedContentPriceBreakdown from "../Components/BorrowedContentPriceBreakdown";
import PolicyModelModal from "../Components/PolicyModelModal";
import {
    getCalculateBorrowedPrice,
    getModuleTotalPrice
} from "./CollectionCreatorHelper";
import EditAuthorsModal from "./EditAuthorsModal";

const { Paragraph, Text, Title } = Typography;

const ModuleHeader = ({
    module,
    index,
    provided,
    currentUser,
    policyModel,
    authorsDict,
    updateModule,
    handleRemoveModule,
    setAuthorsDict
}) => {
    const [priceModalVisible, setPriceModalVisible] = useState(false);
    const [authorModalVisible, setAuthorModalVisible] = useState(false);

    const [useDefaultPrice, setUseDefaultPrice] = useState(true);

    const [borrowedPriceBreakdownVisible, setBorrowedPriceBreakdownVisible] =
        useState(false);
    const [policyModelVisible, setPolicyModelVisible] = useState(false);
    const [tempModuleState, updateTempModuleState] = useState(module);

    // On change default price check box
    const handleOnDefaultPriceCheck = (event) => {
        const tempModuleStateObj = {
            ...tempModuleState,
            isDefaultPrice: event.target.checked,
            customPrice: event.target.checked
                ? policyModel.platformDefaultPrice
                : null
        };
        const totalPrice = getModuleTotalPrice(tempModuleStateObj, policyModel);
        updateTempModuleState({ ...tempModuleStateObj, price: totalPrice });
        setUseDefaultPrice(event.target.checked);
    };

    // set custom price
    const setCustomPrice = (val) => {
        const tempModuleStateObj = {
            ...module,
            isDefaultPrice: false,
            customPrice: val
        };
        const totalPrice = getModuleTotalPrice(tempModuleStateObj, policyModel);
        updateTempModuleState({ ...tempModuleStateObj, price: totalPrice });
    };

    // On save price object
    const savePriceObject = () => {
        updateModule({ ...tempModuleState }, index);
        setPriceModalVisible(false);
    };

    // On module title changed
    const onModuleNameChanged = (val) => {
        const temp = { ...module, name: val };
        updateTempModuleState(temp);
        updateModule(temp, index);
    };

    // On price edit click
    const onPriceEditClick = () => {
        updateTempModuleState(module);
        setPriceModalVisible(true);
    };

    // On Author edit click
    const onAuthorEditClick = () => {
        updateTempModuleState(module);
        setAuthorModalVisible(true);
    };

    // After author updated
    const onFinishAuthorForm = (formData) => {
        const { authorData, coAuthors } = formData;

        const tempModuleStateObj = {
            ...module,
            primaryAuthorUsername: authorData.username,
            primaryAuthor: authorData,
            coAuthors: coAuthors,
            coAuthorUsernames: coAuthors.map((c) => c.username)
        };
        updateTempModuleState({ ...tempModuleStateObj });
        updateModule({ ...tempModuleStateObj }, index);
    };

    return (
        <div
            className="module-list-title"
            {...provided.dragHandleProps}
            onClick={(e) => e.stopPropagation()}>
            <div className="seperator"></div>
            <div className="editable-title-container">
                <Title
                    level={5}
                    style={{
                        margin: 0
                    }}>
                    <Paragraph
                        ellipsis={{
                            rows: 1,
                            tooltip: true
                        }}
                        editable={{
                            autoSize: {
                                maxRows: 1
                            },
                            onChange: onModuleNameChanged
                        }}>
                        {module.name}
                    </Paragraph>
                </Title>
                <span className="display-secondary">
                    <span>
                        {module.videos.length > 0 ? module.videos.length : "No"}{" "}
                        video
                        {module.videos.length > 1 || module.videos.length === 0
                            ? "s"
                            : ""}
                    </span>
                </span>
            </div>
            <div className="seperator"></div>
            <div className="author-container">
                <div className="display-section">
                    <span className="display-title">
                        <span>
                            <UserOutlined /> Author
                        </span>
                        <Tooltip title="Edit">
                            <Button
                                type="ghost"
                                icon={<EditOutlined />}
                                onClick={onAuthorEditClick}
                                style={{
                                    border: "none",
                                    boxShadow: "none"
                                }}
                            />
                        </Tooltip>
                    </span>
                    <span className="display-secondary">
                        <span>{module.primaryAuthorUsername}</span>
                    </span>
                </div>
            </div>
            <div className="seperator"></div>
            <div className="total-price-container">
                <div className="display-section">
                    <span className="display-title">
                        <span>Price of your content</span>
                        <Tooltip title="Edit">
                            <Button
                                type="ghost"
                                icon={<EditOutlined />}
                                onClick={onPriceEditClick}
                                style={{
                                    border: "none",
                                    boxShadow: "none"
                                }}
                            />
                        </Tooltip>
                    </span>
                    <span className="display-secondary">
                        <span>{module.price} cupoints</span>
                    </span>
                </div>
            </div>
            <div className="seperator"></div>
            <div className="delete-container">
                <Popconfirm
                    title="Are you sure to remove this module?"
                    onConfirm={(e) => {
                        handleRemoveModule(e, index);
                    }}
                    onCancel={(e) => {
                        e.stopPropagation();
                    }}
                    okText="Yes"
                    cancelText="No">
                    <Button
                        className="delete-button"
                        size="small"
                        onClick={(e) => e.stopPropagation()}>
                        Delete
                    </Button>
                </Popconfirm>
            </div>

            {/* Price Update Modal */}
            <Modal
                visible={priceModalVisible}
                footer={null}
                onCancel={() => setPriceModalVisible(false)}
                maskClosable={false}>
                <div className="add-module-modal">
                    <Title level={4}>Price Breakdown</Title>
                    <br />
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="price-breakdown-row">
                                <div>
                                    <Text>Price of your content</Text>
                                    <br />
                                    <Text
                                        type="secondary"
                                        className="price-breakdown-hint"
                                        onClick={() =>
                                            setPolicyModelVisible(true)
                                        }>
                                        How should I price my module?
                                    </Text>
                                    <PolicyModelModal
                                        policyModel={policyModel}
                                        visible={policyModelVisible}
                                        setVisible={setPolicyModelVisible}
                                    />
                                </div>

                                <Space align="baseline">
                                    <Checkbox
                                        checked={useDefaultPrice}
                                        onChange={handleOnDefaultPriceCheck}>
                                        Use Default Price
                                    </Checkbox>
                                    {useDefaultPrice ? (
                                        <InputNumber
                                            value={
                                                policyModel.platformDefaultPrice
                                            }
                                            disabled
                                            style={{ marginBottom: "16px" }}
                                        />
                                    ) : (
                                        <Form.Item name="customPrice">
                                            <InputNumber
                                                min={0}
                                                defaultValue={0}
                                                onChange={(value) => {
                                                    setCustomPrice(value);
                                                }}
                                            />
                                        </Form.Item>
                                    )}
                                </Space>
                            </div>

                            <div className="price-breakdown-row">
                                <div>
                                    <Text>Price of borrowed content</Text>
                                    <br />
                                    <Text
                                        type="secondary"
                                        className="price-breakdown-hint"
                                        onClick={() =>
                                            setBorrowedPriceBreakdownVisible(
                                                true
                                            )
                                        }>
                                        How is this calculated?
                                    </Text>
                                    <BorrowedContentPriceBreakdown
                                        module={module}
                                        setVisible={
                                            setBorrowedPriceBreakdownVisible
                                        }
                                        visible={borrowedPriceBreakdownVisible}
                                    />
                                </div>
                                <Text>
                                    {getCalculateBorrowedPrice(module)} Cupoints
                                </Text>
                            </div>
                            <Divider style={{ margin: "10px 0px" }} />
                            <div className="price-breakdown-row">
                                <Text>Total Price</Text>
                                <Text>{tempModuleState.price} cupoints</Text>
                            </div>
                            <Divider style={{ margin: "10px 0px" }} />
                            <div className="price-breakdown-row">
                                <Button
                                    type="primary"
                                    onClick={savePriceObject}>
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>

            {/* Author Update Modal */}
            <EditAuthorsModal
                title={"Module Authors"}
                visible={authorModalVisible}
                setVisibility={setAuthorModalVisible}
                currentUser={currentUser}
                initialValues={{
                    primaryAuthorUsername: module.primaryAuthorUsername,
                    coAuthorUsernames: module.coAuthorUsernames
                }}
                authorsDict={authorsDict}
                setAuthorsDict={setAuthorsDict}
                onFinish={onFinishAuthorForm}
            />
        </div>
    );
};

export default ModuleHeader;
