const initialState = {
    platformStatistics: null,
    platformStatisticsStatus: "NOT LOADED",
    transcriptsVideoList: [],
    transcriptsVideoListStatus: "NOT LOADED",
    uploadTranscriptStatus: "NOT LOADED",
    uploadTranscriptResult: {},
    videosToCurate: [],
    videosToCurateStatus: "NOT LOADED",
    classCodesList: [],
    classCodesListStatus: 'NOT LOADED',
    classCodeDetail: {
        roster: []
    },
    classCodeDetailStatus: "NOT LOADED",
    demoRequests: [],
    demoRequestsStatus: "NOT LOADED",
    demoRequest: [],
    demoRequestStatus: "NOT LOADED",
    demoRequestUpdateStatus: "NOT LOADED",
    certificateRequestListStatus: 'NOT LOADED',
    certificateRequestList: [],
    certificateUserSummary: {},
    certificateUserSummaryStatus: 'NOT LOADED',
    watchedVideosStatistics: {},
    allVideos: [],
    maxStatisticsVideoLength: 0,

    approveProgress: false,
    rejectProgress: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_UPLOAD_TRANSCRIPT_RESULT":
            return Object.assign({}, state, { uploadTranscriptResult: action.data })
        case "SET_UPLOAD_TRANSCRIPT_STATUS":
            return Object.assign({}, state, { uploadTranscriptStatus: action.status })
        case "SET_PLATFORM_STATISTICS":
            return Object.assign({}, state, { platformStatistics: action.data })
        case "SET_PLATFORM_STATISTICS_STATUS":
            return Object.assign({}, state, { platformStatisticsStatus: action.status })
        case "SET_TRANSCRIPTS_VIDEO_LIST":
            return Object.assign({}, state, { transcriptsVideoList: action.videos })
        case "SET_TRANSCRIPTS_VIDEO_LIST_STATUS":
            return Object.assign({}, state, { transcriptsVideoListStatus: action.status })
        case "SET_CLASS_CODE_DETAIL":
            return Object.assign({}, state, { classCodeDetail: action.detail })
        case "SET_CLASS_CODE_DETAIL_STATUS":
            return Object.assign({}, state, { classCodeDetailStatus: action.status })
        case "SET_CLASS_CODES_LIST":
            return Object.assign({}, state, { classCodesList: action.codes })
        case "SET_DEMO_REQUESTS":
            return Object.assign({}, state, { demoRequests: action.data })
        case "SET_DEMO_REQUESTS_STATUS":
            return Object.assign({}, state, { demoRequestsStatus: action.status })
        case "SET_DEMO_REQUEST":
            return Object.assign({}, state, { demoRequest: action.data })
        case "SET_DEMO_REQUEST_STATUS":
            return Object.assign({}, state, { demoRequestStatus: action.status })
        case "SET_DEMO_REQUEST_UPDATE_STATUS":
            return Object.assign({}, state, { demoRequestUpdateStatus: action.status })
        case "SET_CLASS_CODES_LIST_STATUS":
            return Object.assign({}, state, { classCodesListStatus: action.status })
        case "SET_VIDEOS_TO_CURATE":
            return Object.assign({}, state, { videosToCurate: action.videos })
        case "SET_VIDEOS_TO_CURATE_STATUS":
            return Object.assign({}, state, { videosToCurateStatus: action.status })
        case "UPDATE_DEMO_REQUEST":
            let demos = [...state.demoRequests];
            let demoIndex = demos.findIndex(demo => demo.id == action.data.id);
            demos[demoIndex] = action.data
            return Object.assign({}, state, { demoRequests: demos })
        case "SET_CERTIFICATE_REQUEST_LIST_STATUS":
            return Object.assign({}, state, { certificateRequestListStatus: action.status })
        case "SET_CERTIFICATE_REQUEST_LIST":
            return Object.assign({}, state, { certificateRequestList: action.data })
        case "SET_CERTIFICATE_REQUEST_APPROVE_PROGRESS":
            return Object.assign({}, state, { approveProgress: action.status })
        case "SET_CERTIFICATE_REQUEST_REJECT_PROGRESS":
            return Object.assign({}, state, { rejectProgress: action.status })
        case "SET_CERTIFICATE_REQUEST_STATUS":
            let requests = [...state.certificateRequestList];
            requests.forEach(request => {
                if (request.id === action.data.id) {
                    request.status = action.data.status
                }
            });
            return Object.assign({}, state, { certificateRequestList: requests })
        case "SET_CERTIFICATE_USER_SUMMARY":
            return Object.assign({}, state, { certificateUserSummary: action.summary })
        case "SET_CERTIFICATE_USER_SUMMARY_STATUS":
            return Object.assign({}, state, { certificateUserSummaryStatus: action.status })
        case "SET_CERTIFICATE_USER_WATCHED_VIDEO_STATISTICS":
            return Object.assign({}, state, { watchedVideosStatistics: action.data })
        case "SET_CERTIFICATE_STATISTICS_ALL_VIDEOS":
            let videoList = action.data.modules.flatMap(module => {
                return module.videos.map(video => {
                    return {
                        "collectionId": action.data.id,
                        "collectionName": action.data.name,
                        "moduleId": module.id,
                        "moduleName": module.name,
                        "videoId": video.id,
                        "videoTitle": video.title,
                        "videoType": video.videoType,
                        "embedId": video.embedId,
                        "privacyScope": action.data.privacyScope,
                        "unlistedKey": action.data.collectionUnlistedKey,
                        "accessFrom": null,
                        "accessUntil": null
                    }
                })
            })
            return Object.assign({}, state, {
                allVideos: videoList
            })
        case "SET_CERTIFICATE_STATISTICS_MAX_VIDEO_LENGTH":
            return Object.assign({}, state, { maxStatisticsVideoLength: action.data })
            

        default:
            return state
    }
}