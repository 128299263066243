import React from "react";
import Chart from "react-apexcharts";

const VideoHeatMap = (props) => {
  const heatmapOptions = {
    // Video length in mins (rounded up)
    videoLength: props.labels.length,
    // Options object for apexcharts
    options: {
      chart: {
        background: "#00000000", // transparent
        width: 5000,
        height: "50px",
        //   foreColor: "#333",
      },
      xaxis: {
        categories: props.labels,
        labels: { showDuplicates: true },
      },
      yaxis: {
        show: false,
      },
      legend: {
        showForSingleSeries: true,
        position: "bottom",
        markers: {
          width: 15,
          height: 15,
          strokeWidth: 0,
          radius: 0,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      stroke: {
        colors: ["#0000000F"],
      },
      // Heatmap specific options
      plotOptions: {
        heatmap: {
          // shadeIntensity: 0.75,
          // radius: 3,
          useFillColorAsStroke: false,
          // Red, yellow, blue, green color scheme
          colorScale: {
            ranges: [
              {
                from: 0,
                to: 24.99,
                color: "#FF0000",
                name: "0%-25%",
              },
              {
                from: 25,
                to: 49.99,
                color: "#FFB200",
                name: "25%-50%",
              },
              {
                from: 50,
                to: 74.99,
                color: "#128FD9",
                name: "50%-75%",
              },
              {
                from: 75,
                to: 100,
                color: "#00A100",
                name: "75%-100%",
              },
            ],
          },
        },
      },
      colors: ["#57c9c2"],
      fill: {
        opacity: 0.8,
      },

      title: {
        text: props.homePage
          ? props.video.videoTitle
          : "Overall Class Participation % (by minute)",
        align: "center",
        margin: 0,
        offsetY: 20,
        style: {
          fontSize: "20px",
          fontWeight: "600",
        },
      },
    },

    series: [{ name: "Class Participation %", data: props.data }],
  };

  return (
    // Heatmap chart
    <Chart
      options={heatmapOptions.options}
      series={heatmapOptions.series}
      type="heatmap"
      width={props.width}
      height={props.height}
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    />
  );
};

export default VideoHeatMap;
