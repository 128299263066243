import { Card, Col, Divider, Row, Statistic } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, QuestionCircleOutlined } from "@ant-design/icons";

const StudentSummaries = ({studentSummaries, studentSummariesStatus}) => {
    return <>
            <Divider orientation="left">Watch Activity</Divider>
            <Row gutter={16}>
            <Col span={8}>
                                <Statistic 
                title="Total Watch Time" 
                value={studentSummariesStatus == "LOADED" ? studentSummaries.studentWatchTime : 0}
                formatter={(value) => {
                    let remainder = value;
                    let hours = Math.floor(remainder / 3600);
                    remainder = remainder % 3600;
                    let minutes = Math.floor(remainder / 60);
                    let seconds = Math.floor(remainder % 60);
                    return `${hours}hr ${minutes}min ${seconds}s`
                }} 
                loading={studentSummariesStatus != "LOADED"}
                />
            </Col>
            <Col span={8}>
                <Statistic 
                title="Watch Time (This Week)" 
                value={studentSummariesStatus == "LOADED" ? studentSummaries.thisWeekWatchTime : 0} 
                prefix={studentSummariesStatus == "LOADED" && studentSummaries.thisWeekWatchTime >= studentSummaries.lastWeekWatchTime ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                valueStyle={{ color: studentSummariesStatus == "LOADED" && studentSummaries.thisWeekWatchTime >= studentSummaries.lastWeekWatchTime ? '#3f8600' : '#FF0000'}}
                formatter={(value) => {
                    let remainder = value;
                    let hours = Math.floor(remainder / 3600);
                    remainder = remainder % 3600;
                    let minutes = Math.floor(remainder / 60);
                    let seconds = Math.floor(remainder % 60);
                    return `${hours}hr ${minutes}min ${seconds}s`
                }}
                loading={studentSummariesStatus != "LOADED"}
                />
            </Col>
            <Col span={8}>
                <Statistic 
                title="Watch Time (Last Week)" 
                value={studentSummariesStatus == "LOADED" ? studentSummaries.lastWeekWatchTime : 0} 
                valueStyle={{ color: '#CCC' }}
                formatter={(value) => {
                    let remainder = value;
                    let hours = Math.floor(remainder / 3600);
                    remainder = remainder % 3600;
                    let minutes = Math.floor(remainder / 60);
                    let seconds = Math.floor(remainder % 60);
                    return `${hours}hr ${minutes}min ${seconds}s`
                }}
                loading={studentSummariesStatus != "LOADED"}
                />
            </Col>
            </Row>
            <Divider orientation="left">Quizzes</Divider>
            <Row gutter={16}>
            <Col span={8}>
                <Statistic 
                title="Completed Quiz Questions" 
                value={studentSummariesStatus == "LOADED" ? studentSummaries.numCorrectQuizQuestions : 0} 
                suffix={studentSummariesStatus == "LOADED" ? ` of ${studentSummaries.totalNumQuizQuestions} (${Math.round(studentSummaries.numCorrectQuizQuestions / studentSummaries.totalNumQuizQuestions * 100)}%)` : " of 0"}
                loading={studentSummariesStatus != "LOADED"}
                />
            </Col>
            <Col span={8}>
                <Statistic 
                title="Average # of Attempts" 
                value={studentSummariesStatus == "LOADED" ? parseFloat(studentSummaries.averageQuizQuestionAttempts).toFixed(2) : 0} 
                loading={studentSummariesStatus != "LOADED"}
                />
            </Col>
            <Col span={8}>
                
            </Col>
            </Row>
            <Divider orientation="left">Q&A</Divider>
            <Row gutter={16}>
            <Col span={8}>
                <Statistic 
                title="Questions Asked" 
                value={studentSummariesStatus == "LOADED" ? studentSummaries.numQuestionsAsked : 0}
                loading={studentSummariesStatus != "LOADED"}
                />
            </Col>
            <Col span={8}>
                <Statistic 
                title="Replies Given" 
                value={studentSummariesStatus == "LOADED" ? studentSummaries.numRepliesMade : 0} 
                loading={studentSummariesStatus != "LOADED"}
                />
            </Col>
            <Col span={8}>
                <Statistic 
                title="Replies Received" 
                value={studentSummariesStatus == "LOADED" ? studentSummaries.numRepliesReceived : 0}
                loading={studentSummariesStatus != "LOADED"}
                />
            </Col>
            </Row>
            <Divider orientation="left">cuGPT Requests</Divider>
            <Row gutter={16}>
            <Col span={8}>
                <Statistic 
                title="Summary Requests" 
                value={studentSummariesStatus == "LOADED" ? studentSummaries.noOfCuGptSummaryRequests : 0}
                loading={studentSummariesStatus != "LOADED"}
                />
            </Col>
            <Col span={8}>
                <Statistic 
                title="MCQ Requests" 
                value={studentSummariesStatus == "LOADED" ? studentSummaries.noOfCuGptMcqRequests : 0} 
                loading={studentSummariesStatus != "LOADED"}
                />
            </Col>
            <Col span={8}>
                <Statistic 
                title="Expands On Requests" 
                value={studentSummariesStatus == "LOADED" ? studentSummaries.noOfCuGptExpandsOnRequests : 0}
                loading={studentSummariesStatus != "LOADED"}
                />
            </Col>
            </Row>
        </>
}

export default StudentSummaries;