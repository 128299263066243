import { Modal, Typography } from "antd";
import SelectAccountTypeStep from "./SelectAccountTypeStep";
import { useState } from 'react';
import InstructorInformationStep from "./InstructorInformationStep";
import SuccessfulAccountTypeRegistrationStep from "./SuccessfulAccountTypeRegistrationStep";
import { applyAsLearner, setAccountTypeRegistrationStatus } from "../actions/user";
import { connect } from "react-redux";

const { Paragraph, Text, Title } = Typography;

const AccountTypeRegistration = ({ accountTypeRegistrationStatus, applyAsLearner, currentUser, setAccountTypeRegistrationStatus }) => {
    const [accountType, setAccountType] = useState("student")
    const [step, setStep] = useState(0);

    const goToNextStep = (count) => {
        setStep(step + count);
    }

    const goToPreviousStep = (count) => {
        setStep(step - count)
    }
    
    const handleOnApplyAsLearner = () => {
        goToNextStep(2)
        applyAsLearner(currentUser.id)
    }

    const clearAccountTypeRegistrationStatus = () => {
        setAccountTypeRegistrationStatus("NOT LOADED")
    }

    if(step === 0){
        return  <SelectAccountTypeStep 
                    goToNextStep={goToNextStep}
                    applyAsLearner={handleOnApplyAsLearner}
                />
    } 
 
    if(step === 1) {
        
        return  <InstructorInformationStep 
                    goToNextStep={goToNextStep}
                    goToPreviousStep={goToPreviousStep}
                />
    }
    if(step === 2) {
        return <SuccessfulAccountTypeRegistrationStep
                    accountTypeRegistrationStatus={accountTypeRegistrationStatus}
                    currentUser={currentUser}
                    clearAccountTypeRegistrationStatus={clearAccountTypeRegistrationStatus}
                />
    }
    
}


const mapStateToProps = state => {
    return {
        accountTypeRegistrationStatus: state.userReducer.accountTypeRegistrationStatus,
        currentUser: state.userReducer.user
    }
}
export default connect(mapStateToProps, { applyAsLearner, setAccountTypeRegistrationStatus })(AccountTypeRegistration);