import { Button, Form, Input, Modal, Typography } from 'antd';
import { connect } from 'react-redux';
import { fetchVideoDataFromURL } from './actions/contentCreation';
import { Duration } from "luxon";

const { Text, Title } = Typography;

const ImportFromUrl = ({ addVideosToVideoBank, fetchVideoDataFromURL, setVisible, videoType, visible }) => {

    const [form] = Form.useForm();

    
    const handleOnFinish = ({ url }) => {
        const handleOnNewVideoByURLError = () => {
            return form.setFields([{
                name: 'url',
                errors: ["Invalid video or playlist URL"]
            }])
        }
        fetchVideoDataFromURL(url.trim(), parseVimeoData, parseYouTubeData, handleOnNewVideoByURLError)
    }

    const handleOnClose = () => {
        setVisible(false);
        form.resetFields();
    }

    const parseYouTubeData = (data) => {
        let newVideos = data['data']['items'].map(item => {
            let videoId = item['id']
            let videoDuration = Duration.fromISO(item['contentDetails']['duration']).shiftTo('seconds').seconds;
            return {
                'title': item['snippet']['title'],
                'videoType': data['videoType'],
                'embedId': videoId,
                'importedFromURL': "true",
                'duration': videoDuration
            }
        })
        let videosToImport = newVideos.concat(data['licensableContent'])
        addVideosToVideoBank(videosToImport)
        setVisible(false);
        form.resetFields()
    }
    const parseVimeoData = (data) => {
        let newVideos;
        if(data['contentType'] === "playlist"){
            newVideos = data['data']['data'].map(videoData => {
                return {
                    'title': videoData['name'],
                    'videoType': data['videoType'],
                    'embedId': videoData['uri'].replace('/videos/', ''),
                    'duration': videoData['duration']
                }
            })
        }else if(data['contentType'] === 'video'){
            newVideos = data['data'].map(videoData => {
                return {
                    'title': videoData['name'],
                    'videoType': data['videoType'],
                    'embedId': videoData['uri'].replace('/videos/', ''),
                    'duration': videoData['duration']
                }
            })
        }
        let videosToImport = newVideos.concat(data['licensableContent'])
        addVideosToVideoBank(videosToImport)
        setVisible(false);
        form.resetFields()
    }

    return  <>
    {/* <Modal
                visible={visible}
                footer={null}
                onCancel={handleOnClose}
                maskClosable={false}
            >
            <div style={{padding: 15}}>
            <Title level={4}>Import Video(s) from {videoType}</Title> */}
            
            <Form 
                onFinish={handleOnFinish}
                form={form}
                layout="vertical"
            >
                <Form.Item
                    label="Playlist or Video URL"
                    name="url"
                    rules={[{
                        required: true,
                        message: "Please enter a valid playlist or video URL"
                    }]}
                >
                    <Input placeholder={videoType === "YouTube" ? "https://www.youtube.com/playlist?list=<PLAYLIST_ID>" : "https://vimeo.com/<VIDEO_ID>"}/>
                </Form.Item>
                <Button 
                    type="primary"
                    htmlType='submit'
                >
                    Import
                </Button>
            </Form>
            {/* </div>
        </Modal> */}
    </>
}

export default connect(null, { fetchVideoDataFromURL })(ImportFromUrl);