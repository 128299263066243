import { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Drawer, Table, Typography} from 'antd';
import { fetchCreatorTransactionsByWeek } from '../actions/creator';

const { Text } = Typography;

class CreatorTransactionsList extends Component {

    constructor(props){
        super(props);
        this.state = {
            visibleDrawer: false,
        }
    }
    closeDrawer = () => {
        this.setState({
            visibleDrawer: false
        })
    }

    openDrawer = () => {
        this.setState({
            visibleDrawer: true
        })
    }
    
    componentDidUpdate(prevProps, prevState){
        if(prevState.visibleDrawer != this.state.visibleDrawer && this.state.visibleDrawer) {
            this.props.fetchCreatorTransactionsByWeek(this.props.userId, this.props.summary.weeksAgo)
        }
    }

    columns = [
        { title: 'Date', dataIndex: 'date', key: 'date', render: (transactionDate) => {
            var date = new Date(transactionDate)
            return <Text>{date.toDateString()}</Text>
        } },
        { title: 'Memo', dataIndex: 'memo', key: 'memo' },
        { title: 'Total Cupoints Spent', dataIndex: 'transactionTotalCupoints', key: 'transactionTotalCupoints'},
        { title: 'Total Royalties', dataIndex: 'transactionTotalCurrency', key: 'transactionTotalCurrency', render: (value) => `$${parseFloat(value).toFixed(2)}` },
       
    ];

    render() {
        return <>
            <Button type="primary" onClick={this.openDrawer}>
                View Details
            </Button>
            <Drawer 
                title={`Transactions within ${this.props.summary.week}`} 
                placement="right" 
                onClose={this.closeDrawer} 
                visible={this.state.visibleDrawer}
                width={1000}
            >

                <Table
                    columns={this.columns}
                    dataSource={this.props.creatorTransactionsByWeek}
                    loading={this.props.creatorTransactionsByWeekStatus === "LOADING"}
                />
                
            </Drawer>
        </>
    }
}

const mapStateToProps = state => {
    return {
        creatorTransactionsByWeek: state.creatorReducer.creatorTransactionsByWeek,
        creatorTransactionsByWeekStatus: state.creatorReducer.creatorTransactionsByWeekStatus
    }
}

export default connect(mapStateToProps, {fetchCreatorTransactionsByWeek})(CreatorTransactionsList);