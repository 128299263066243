import { Col, Form, Input, Row } from 'antd';


const { TextArea } = Input;


const StepOne = ({nextStep, updateFormData}) => {

    const [form] = Form.useForm();

    const handleOnFinish = (values) => {
        nextStep();
        updateFormData(values)
    }
    return  <Form
                form={form}
                layout="vertical"
                name="register"
                onFinish={handleOnFinish}
                scrollToFirstError
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="courseName"
                            label="Course Name"
                            rules={[
                            {
                                required: true,
                                message: 'Please input a course name!',
                            },
                            ]}
                            hasFeedback
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="courseDescription"
                            label="Description (optional)"
                            hasFeedback
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Input.Group size="large">
                            <Row gutter={[48, 16]}>
                                <Col span={10}>
                                    <Form.Item
                                        name="firstName"
                                        label="First Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your first name!',
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={10}>
                                    <Form.Item
                                        name="lastName"
                                        label="Last Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your last name!',
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Input.Group>
                    </Col>
                    
                    <Col span={8}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ 
                                type: 'email',
                                required: true,
                                message: 'Please input your email!',
                            }]}
                            hasFeedback
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <div id="demo-request-next-btn-container">
                    <div></div>
                    <button id="demo-request-next-btn">Next</button>
                </div>
            </Form>
}

export default StepOne;