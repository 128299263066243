export const encodeQueryParams = (data) => {
    let query = "";
    for (let d in data)
        query +=
            encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
    return query.slice(0, -1);
};

export const hasPermanentAccess = (module, moduleAccess) => {
    if (moduleAccess[module.id]) {
        if (moduleAccess[module.id].accessType === "permanent") {
            return true;
        }
    }
    return false;
};

export const getCollectionNetPrice = (
    collection,
    moduleAccess,
    isAuthenticated
) => {
    let totalPrice = 0;
    let bundleDiscount = parseFloat(collection.bundleDiscount);

    // Apply promotion discount
    if (collection.promotion) {
        bundleDiscount += parseFloat(collection.promotion.promoDiscount);
    }

    collection.modules.forEach((module) => {
        if (!isAuthenticated || !hasPermanentAccess(module, moduleAccess)) {
            totalPrice += Math.ceil(module.price * (1 - bundleDiscount));
        }

        module.licensedModules.forEach((licensedModule) => {
            if (
                !isAuthenticated ||
                !hasPermanentAccess(licensedModule, moduleAccess)
            ) {
                totalPrice += Math.ceil(
                    licensedModule.price * (1 - bundleDiscount)
                );
            }
        });
    });

    return totalPrice;
};
