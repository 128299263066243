import { Button, Card, PageHeader, Space, Spin, Typography } from 'antd';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import YouTube from 'react-youtube';
import VimeoPlayer from './VimeoPlayer';
import { createQuizQuestion, fetchModuleDetail, getAllQuestionsForVideo } from '../actions/contentCreation';
import { getTranscript } from '../actions/collection';
import { setVideoTimestamp } from '../actions/videoControl';
import QuestionCreatorForm from './QuestionCreatorForm';
import QuestionCreatorFormPreview from './QuestionCreatorFormPreview';
import QuizQuestionCard from './QuizQuestionCard';
import ExistingQuizQuestionPreview from './ExistingQuizQuestionPreview';
import VideoTranscript from '../Collections/Video/Transcript/VideoTranscript';

const PLAYER_OPTIONS = {
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      rel: 0
    },
};


const { Text } = Typography;
  
class QuestionCreator extends Component {
    constructor(props){
        super(props);
        this.state = {
            module: null,
            video: null,
            playerRef: null,
            creationMode: null,
            previewData: {},
            previewExistingData: {},
            goBackToForm: false,
            visibleForm: false,
            visiblePreview: false,
            visibleExistingQuizQuestionPreview: false,
            transcriptTimeout: null,
            questionTextImages: [],
            videoIndex: null
        }
    }
    componentDidMount(){
        if(!this.props.module || this.props.module.id != this.props.match.params.moduleId){
            this.props.fetchModuleDetail(this.props.match.params.moduleId, this.props.user.id)
        }else if(this.props.module){
            this.findVideo();
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.module == null && this.props.module || (prevProps.module && prevProps.module.id != this.props.module.id)) {
            this.findVideo();
        }else if(prevState.video == null && this.state.video != null){
            this.props.getAllQuestionsForVideo(this.props.match.params['videoId'])
            this.props.getTranscript(this.props.match.params['videoId'])
        }
    }

    componentWillUnmount = () => {
        if(this.state.transcriptTimeout){
            clearTimeout(this.state.transcriptTimeout)
        }
        this.props.setVideoTimestamp(0)
        this.setState({ transcriptTimeout: null})
    }

    findVideo = () => {
        let moduleId = this.props.match.params.moduleId;
        let videoId = this.props.match.params.videoId;

        if(this.props.module == null){
            return this.props.history.push('/404')
        }
    
        let videoIndex  = this.props.module.videos.findIndex(video => video.id == videoId);
        let videoData = this.props.module.videos[videoIndex];
        if(videoData == null){
            return this.props.history.push('/404')
        }
        this.setState({
            module: this.props.module,
            video: videoData,
            videoIndex: videoIndex
        })
    }

    handleOnYouTubeReady = (event) => {
        this.setState({playerRef: event.target});
        this.props.setVideoTimestamp(null)
    }

    scrollToNextParagraph = (currentTime, currentElement) => {
        var transcriptDiv = document.getElementById('transcript-tab-content');
        var nextElement = currentElement.nextSibling;
        if(nextElement){
            var startTime = nextElement.getAttribute('data-start-time');
            var endTime = nextElement.getAttribute('data-end-time');
            var paragraphFlag = nextElement.getAttribute('data-paragraph-flag');
    
            if(startTime <= currentTime && endTime > currentTime && paragraphFlag === "true"){
                transcriptDiv.scrollTop = nextElement.offsetTop
            }
        }
    }
    updateVideoTimestamp = async () => {
        if(this.state.video.videoType == "youtube"){
            var currentTime = this.state.playerRef.getCurrentTime()
            var playbackRate = this.state.playerRef.getPlaybackRate()
            if(this.props.transcript.length > 0){
               
                var currentElement = document.getElementById('highlighted-transcript-segment');
                if(currentElement){
                    this.scrollToNextParagraph(currentTime, currentElement);
                }
            }
        }else if(this.state.video.videoType == "vimeo") {
            if(this.props.transcript.length > 0){
                var [currentTime, playbackRate] = await Promise.all([this.state.playerRef.getCurrentTime(), this.state.playerRef.getPlaybackRate()])
                var currentElement = document.getElementById('highlighted-transcript-segment');
                if(currentElement){
                    this.scrollToNextParagraph(currentTime, currentElement);
                }
              
            }
        }

        this.props.setVideoTimestamp(currentTime)
        var timeout = setTimeout(this.updateVideoTimestamp, (this.props.nextTranscriptUpdate/playbackRate))
        this.setState({transcriptTimeout: timeout})
    }
    handleOnPlay = () => {
        return 
        /*
        if(this.state.transcriptTimeout){
            clearTimeout(this.state.transcriptTimeout)
        }
        this.updateVideoTimestamp()*/
    }

    handleSetVimeoPlayerRef = (playerRef) => {
        this.setState({playerRef: playerRef})
        this.props.setVideoTimestamp(null)
    }

    handleDrawerClose = () => {
        this.setState({visibleForm: false})
    }

    handlePreviewDrawerClose = () => {
        if(this.state.goBackToForm){
            this.setState({visiblePreview: false, visibleForm: true, goBackToForm: false})
        }else {
            this.setState({visiblePreview: false})
        }
    }

    handleExistingQuizQuestionPreviewDrawerClose = () => {
        this.setState({visibleExistingQuizQuestionPreview: false})
    }

    setPreviewData = (data) => {
        this.setState({previewData: data, visiblePreview: true, visibleForm: false, goBackToForm: true})
    }
    setPreviewQuizQuestion = (data) => {
        this.setState({ previewExistingData: data, visibleExistingQuizQuestionPreview: true, visibleForm: false, goBackToForm: false})
    }

    renderPlayer = () => {
        if(this.state.video){
            if(this.state.video.videoType == "youtube"){
                return <YouTube videoId={this.state.video.embedId}
                            opts={PLAYER_OPTIONS}
                            onReady={this.handleOnYouTubeReady}
                            onPlay={this.handleOnPlay}
                        />
            }else if(this.state.video.videoType == "vimeo"){
                return <VimeoPlayer embedId={this.state.video.embedId} setVideoRef={this.handleSetVimeoPlayerRef} handleOnPlay={this.handleOnPlay}  />
            }
        }else {
            return <Space size="middle" className="loading-indicator-container">
                        <Spin size="large" />
                    </Space>
        }
    }

    getCurrentTimestamp = async () => {
        if(this.state.video.videoType == "youtube"){
            this.state.playerRef.pauseVideo();
            return this.state.playerRef.getCurrentTime();
        }else if(this.state.video.videoType == "vimeo"){
            this.state.playerRef.pause();
            return this.state.playerRef.getCurrentTime();
        }
   }

    seekToVideoTimestamp = (timestamp) => {
        if(this.state.video.videoType == "youtube"){
            this.state.playerRef.seekTo(timestamp)
            this.props.setVideoTimestamp(timestamp)
            if (this.state.playerRef.getPlayerState !== this.PLAYING){
                this.state.playerRef.playVideo();
            }
        }else if(this.state.video.videoType == "vimeo"){
            this.state.playerRef.setCurrentTime(timestamp)
            this.props.setVideoTimestamp(timestamp)
            this.state.playerRef.getPaused().then(() =>{
                this.state.playerRef.play()
            })
        }
    }
   
    seekOnTranscriptClick = (targetElement, timestamp)  => {
        var transcriptDiv = document.getElementById('transcript-tab-content');
        transcriptDiv.scrollTop = targetElement.offsetTop

        this.seekToVideoTimestamp(timestamp)
    }

    setQuestionTextImages = (newValue) => {
        this.setState({
            questionTextImages: newValue
        })
    }
   
    renderFormAndPreview = () => {
        if(this.state.video){
            return <><QuestionCreatorForm 
                        createQuizQuestion={this.props.createQuizQuestion}
                        handleDrawerClose={this.handleDrawerClose} 
                        getCurrentTimestamp={this.getCurrentTimestamp}
                        setPreviewData={this.setPreviewData}
                        videoId={this.props.match.params.videoId}
                        visibleForm={this.state.visibleForm}
                        videoDuration={this.state.video.duration}
                        questionTextImages={this.state.questionTextImages}
                        setQuestionTextImages={this.setQuestionTextImages}
                    />
                    <QuestionCreatorFormPreview 
                        handlePreviewDrawerClose={this.handlePreviewDrawerClose} 
                        previewData={this.state.previewData}
                        visiblePreview={this.state.visiblePreview} 
                        questionTextImages={this.state.questionTextImages}

                    /> </>
        }else {
            return
        }
    }
    render(){
        if(!this.props.isAuthenticated || this.state.video == null){
            return <Space size="middle" className="loading-indicator-container">
                        <Spin size="large" />
                    </Space>
        }
        if(this.props.isAuthenticated && this.props.user.id != this.state.video.owner && !this.props.user.isAdmin){
            return <Redirect to='/404' />
        }
        return <div id='creator-panel'>
                    <br/>
                    <PageHeader
                        onBack={() => window.history.back()}
                        ghost={false}
                        title="Question Creator"
                        subTitle={`Now creating questions for video, "${this.state.module.videoTitles[this.state.videoIndex].videoTitle}"`}
                        extra={[<Button key="createQuestion" type="primary" onClick={()=>this.setState({visibleForm: true})}>Create Question</Button>]}
                        >
                    </PageHeader>
                    <br/>
                    <div>
                        <div style={{display: 'flex'}}>
                            <div style={{width: "50%", backgroundColor: 'black'}}> 
                            <div id="video-wrapper">
                                {this.renderPlayer()}
                                </div>
                            </div>
                            
                            <Card style={{width: "50%", color: "white"}} >
                                <Text style={{color: "white", padding: "0px 15px"}}>Transcript</Text>
                                <div style={{height: "100%"}}>
                                <div id="transcript-tab-content" className="quiz-question-creator-transcript">
                                    <VideoTranscript 
                                        seekOnTranscriptClick={this.seekOnTranscriptClick}
                                    />
                                </div>
                                </div>
                                
                            </Card>
                        </div>
                        <ExistingQuizQuestionPreview 
                            handlePreviewDrawerClose={this.handleExistingQuizQuestionPreviewDrawerClose} 
                            previewData={this.state.previewExistingData}
                            visiblePreview={this.state.visibleExistingQuizQuestionPreview} 
                            videoId={this.props.match.params['videoId']}
                        />
                        <Card style={{color: "white"}}>
                            Existing questions: <br/>
                            <div id="existing-questions-container">
                                <div id="existing-questions-scrollable">
                                    {this.props.quizQuestionsForVideo.map(quizQuestion => <QuizQuestionCard quizQuestion={quizQuestion} playerRef={this.state.playerRef} setPreviewQuizQuestion={this.setPreviewQuizQuestion} video={this.state.video}/>)}
                                </div>
                            </div>
                        </Card>
                    </div>
                    
                    {this.renderFormAndPreview()}
                </div>
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        isAuthenticated: state.userReducer.isAuthenticated,
        module: state.contentCreationReducer.moduleDetail,
        quizQuestionsForVideo: state.contentCreationReducer.quizQuestionsForVideo,
        transcript: state.collectionReducer.transcript
    }
}
export default connect(mapStateToProps, { createQuizQuestion, fetchModuleDetail, getAllQuestionsForVideo, getTranscript, setVideoTimestamp})(QuestionCreator);