import { Alert, Button, Space } from "antd";
import ModuleDisplay from "../Components/ModuleDisplay";
import { createNewModule } from "../actions/contentCreation"; 
import { connect } from "react-redux";


const ReviewModuleChangesStep = ({ createNewModule, goToNextStep, goToPreviousStep, module}) => {
    
    const handleOnPublishClick = () => {
        createNewModule(module)
        goToNextStep()
    }

    return <div>
        <Alert
            message="Note"
            description="This is the preview of the public-facing view of your module."
            type="info"
            showIcon
        />
        <br/>
        <ModuleDisplay 
            module={module}
            mode="preview"
        />

        <Space>
            <Button
                onClick={goToPreviousStep}
            >
                Back
            </Button>
            <Button
                type="primary"
                onClick={handleOnPublishClick}
            >
                Publish Module
            </Button>
        </Space>
        
    </div>
}

export default connect(null, { createNewModule })(ReviewModuleChangesStep);