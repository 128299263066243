/******************************************************************
IndividualVideoPage.js
Written by Adam Gamba, Summer 2021

Individual video data page, includes date search filter, search buttons,
and VideoDisplay with requested information
******************************************************************/

import React, { Component, useState } from "react";
import { connect } from 'react-redux';
import VideoDisplay from "./VideoDisplay";
import { Card, DatePicker, Button, Skeleton } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

// Displays all available videos in array of videos
class IndividualVideoPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      thisWeekSelected: true,
      fullSemesterSelected: false,
      // Format: [startDate, endDate], ex. [2021-03-01T21:40:35.219Z, ...]
      // Selects today as start, and 1 year ago today as end
      selectedDates: [
        moment(new Date().setDate(new Date().getDate() - 7)),
        moment(new Date()),
      ],
      previousDates: [],
      videoId: null,
      moduleId: null,
      selectedObject: null
    }
  }
  // Boolean values for buttons (default thisWeekSelected)
  
  componentDidMount(){
    let moduleId = parseInt(this.props.match.params.moduleId);
    let videoId = parseInt(this.props.match.params.videoId);
    this.setState({
      videoId: videoId,
      moduleId: moduleId
    })
    if(this.props.videoListStatus === "LOADED"){
      let moduleVideoObject = this.props.videoList.find(data => data.videoId === videoId && data.moduleId === moduleId);
      if(moduleVideoObject){
        this.setState({
          selectedObject: moduleVideoObject
        })
      }
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.videoListStatus !== this.props.videoListStatus && this.props.videoListStatus === "LOADED"){
      let moduleId = parseInt(this.props.match.params.moduleId);
      let videoId = parseInt(this.props.match.params.videoId);
      let moduleVideoObject = this.props.videoList.find(data => data.videoId === videoId && data.moduleId === moduleId);
      if(moduleVideoObject){
        this.setState({
          selectedObject: moduleVideoObject
        })
      }
    }
  }
  rangePickerOnChange = (dates) => {
    this.setState({
      fullSemesterSelected: false,
      thisWeekSelected: true,
      selectedDates: [dates[0], dates[1]]
    })
  };

  render(){
    if(this.state.selectedObject == null || this.props.videoListStatus !== "LOADED" || !this.props.roster.length){
      return <Card><Skeleton active/></Card>
    }

      return (
        <div className="site-layout-content">
          <section style={{ paddingBottom: "10px" }}>
            {/* Date picker for dates to filter video graphs */}
            <RangePicker
              value={this.state.selectedDates}
              format="MMM-DD-YYYY"
              onChange={this.rangePickerOnChange}
              allowClear={false}
              size="large"
            />

            {/* Button to set dates to most recent week */}
            <Button
              size="large"
              onClick={() => {
                if (this.state.thisWeekSelected) {
                  this.setState({
                    thisWeekSelected: false,
                    selectedDates: this.state.previousDates
                  })
                 
                } else {
                  this.setState({
                    thisWeekSelected: true,
                    fullSemesterSelected: false,
                    previousDates: this.state.selectedDates,
                    selectedDates: [
                      moment(new Date().setDate(new Date().getDate() - 7)),
                      moment(new Date()),
                    ]
                  })
                  
                }
              }}
              style={
                this.state.thisWeekSelected
                  ? {
                      background: "#5bd1d7",
                      color: "white",
                    }
                  : null
              }
            >
              Select Last 7 Days
            </Button>

            {/* Button to set dates to entire semester (earliest-latest dates) */}
            <Button
              size="large"
              onClick={() => {
                if (this.state.fullSemesterSelected) {
                  this.setState({
                    fullSemesterSelected: false,
                    selectedDates: this.state.previousDates
                  })
                  
                } else {
                  this.setState({
                    fullSemesterSelected: true,
                    thisWeekSelected: false,
                    previousDates: this.state.selectedDates,
                    selectedDates: [
                      moment(
                        new Date().setDate(new Date().getDate() - 365)
                      ),
                      moment(new Date()),
                    ]
                  })
                }
              }}
              style={
                this.state.fullSemesterSelected
                  ? {
                      background: "#5bd1d7",
                      color: "white",
                    }
                  : null
              }
            >
              Select Full Semester
            </Button>
          </section>

          {/* Render display of single video */}
          <VideoDisplay
            // Get ids and titles of video/module
            selectedObject={this.state.selectedObject}
            // Use isoString format of date objects
            selectedDates={this.state.selectedDates.map((date) =>
              date.toISOString()
            )}
          />
        </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    roster: state.dashboardReducer.roster,
    videoList: state.dashboardReducer.videoList,
    videoListStatus: state.dashboardReducer.videoListStatus
  }
}
export default connect(mapStateToProps, {})(IndividualVideoPage);
