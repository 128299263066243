/******************************************************************
StudentHome.js
Written by Adam Gamba, Summer 2021

Home page for students. Includes search, filters, and StudentGrid
******************************************************************/

import React, { Component, useState, useEffect } from "react";
import StudentList from "../Home/StudentGrid";
import "../Home.css";

import { connect } from "react-redux";
import { Card, Row, Col, Input, Select, Skeleton } from "antd";

const { Search } = Input;
const { Option } = Select;

class StudentHome extends Component {
  constructor(props){
    super(props);
    this.state = {
      // Search term of search bar and function to set its value (can search
      // automatically using url)
      searchTerm: props.match.params.hasOwnProperty("searchTerm") ? props.match.params.searchTerm : "",
      sortType: sortAZ,
      
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.match.params.searchTerm != this.props.match.params.searchTerm){
      this.setState({searchTerm: this.props.match.params.searchTerm})
    }
  }

  // First check if user is logged in, then load data if yes
  render(){
    if(!this.props.isAuthenticated && localStorage.getItem("access") !== null) {
      return (
        <Card className="dashboard-card">
          <Skeleton active/>
        </Card>
      );
    }  
    if(!this.props.roster.length){
      return (
        <Card className="dashboard-card">
          No enrolled students yet.
        </Card>
      )
    } else{
      return (
        <div className="site-layout-content">
          <Row style={{ paddingBottom: "1%", margin: "5px" }}>
            {/* antd search bar to search students */}
            <Col span={18}>
              <Search
                type="text"
                placeholder="Search Students"
                onChange={(e) => this.setState({searchTerm: e.target.value})}
                size="large"
                allowClear
              />
            </Col>

            {/* Select Sorting Type */}
            <Col span={6}>
              <Select
                defaultValue={"sortAZ"}
                style={{ width: "100%" }}
                size="large"
                // Use switch statement to set state to current function
                onChange={(type) => {
                  switch (type) {
                    case "sortAZ":
                      this.setState({sortType: sortAZ});
                      break;
                    case "sortZA":
                      this.setState({sortType: sortZA});
                      break;
                    case "sortIdAscending":
                      this.setState({sortType: sortIdAscending});
                      break;
                    case "sortIdDescending":
                      this.setState({sortType: sortIdDescending});
                      break;
                    case "sortHoursAscending":
                      this.setState({sortType: sortHoursAscending});
                      break;
                    case "sortHoursDescending":
                      this.setState({sortType: sortHoursDescending});
                      break;
                  }
                }}
              >
                <Option value={"sortAZ"}>Username A-Z</Option>
                <Option value={"sortZA"}>Username Z-A</Option>

                {/* Only give option to sort by ID/hours to admins */}
                {this.props.user.isContentCreator ? (
                  <Option value={"sortIdAscending"}>ID Ascending</Option>
                ) : null}
                {this.props.user.isContentCreator ? (
                  <Option value={"sortIdDescending"}>
                    ID Descending
                  </Option>
                ) : null}
                {this.props.user.isContentCreator ? (
                  <Option value={"sortHoursAscending"}>
                    Hours Ascending
                  </Option>
                ) : null}
                {this.props.user.isContentCreator ? (
                  <Option value={"sortHoursDescending"}>
                    Hours Descending
                  </Option>
                ) : null}
              </Select>
            </Col>
          </Row>

          {/* Maps list of students to individual StudentCards */}
          <StudentList
            // Filters props. based on search bar search term
            // Filters by ID too if searchTerm is a number
            user={this.props.user}
            roster={this.props.roster
              .filter((student) => {
                // Return true for students that match search term
                if (this.state.searchTerm === "") return true;
                else if (
                  !isNaN(this.state.searchTerm) &&
                  student.studentId === parseInt(this.state.searchTerm)
                ) {
                  return true;
                } else if (
                  student.username
                    .toLowerCase()
                    .includes(this.state.searchTerm.toLowerCase())
                ) {
                  return true;
                }
              })
              // Sort type chosen from (currently 6) types in helpers below
              .sort(this.state.sortType)}
          />
        </div>
      );
    }
  }
};

// Sorting helper methods
// ? checking for undef saves us from error, not entirely sure why
const sortAZ = (a, b) => {
  if (a === undefined || b === undefined) return sortAZ;
  if (a.username > b.username) return 1;
  else if (a.username < b.username) return -1;
  else return 0;
};
const sortZA = (a, b) => {
  if (a === undefined || b === undefined) return sortZA;
  return sortAZ(b, a);
};
const sortIdAscending = (a, b) => {
  if (a === undefined || b === undefined) return sortIdAscending;
  if (a.studentId > b.studentId) return 1;
  else if (a.studentId < b.studentId) return -1;
  else return sortAZ(a, b);
};
const sortIdDescending = (a, b) => {
  if (a === undefined || b === undefined) return sortIdDescending;
  if (a.studentId > b.studentId) return -1;
  else if (a.studentId < b.studentId) return 1;
  else return sortAZ(a, b);
};
const sortHoursAscending = (a, b) => {
  if (a === undefined || b === undefined) return sortHoursAscending;
  if (a.hours > b.hours) return 1;
  else if (a.hours < b.hours) return -1;
  else return sortAZ(a, b);
};
const sortHoursDescending = (a, b) => {
  if (a === undefined || b === undefined) return sortHoursDescending;
  if (a.hours > b.hours) return -1;
  else if (a.hours < b.hours) return 1;
  else return sortAZ(a, b);
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.userReducer.isAuthenticated,
    roster: state.dashboardReducer.roster,
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps)(StudentHome);
