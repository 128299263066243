import React from 'react';
import { connect } from 'react-redux';
import { Badge, Layout, Tabs, Typography} from 'antd';

import VideoTranscript from './Transcript/VideoTranscript';
import QuizQuestionsContainer from './QuizQuestions/QuizQuestionsContainer';

const {Title} = Typography;
const { TabPane } = Tabs;

const VideoPageFeatureMenu = ({updateQuizQuestionAttempt, collapsedTranscript, currentTimestamp, pauseVideo, quizQuestions, seekOnTranscriptClick, seekToVideoTimestamp, transcript}) => {
    
    const pendingQuestions = () => {
        let count = 0;
        quizQuestions.forEach(question => {
            if(currentTimestamp >= question.endVideoTimestamp && question.latestAttempt == null){
                count += 1
                return 
            }else{
                return
            }
        })
        return count
        return quizQuestions.length
    }
    
    return <Layout.Sider className={collapsedTranscript ? "" : "visible-transcript-sider"} 
                width={'30vw'}
                collapsedWidth={0} 
                collapsible 
                collapsed={collapsedTranscript}
                trigger={null}
            >
                <Tabs defaultActiveKey="1" id='transcript-tab-container' >
                    <TabPane tab={<Badge size="small" count={0}><div style={{padding: "6px 3px 0px 0px"}}>Transcript</div></Badge>}key="1" className='transcript-tab'>
                        <div id='transcript-tab-content'>
                            <VideoTranscript transcript={transcript} videoTimestamp={currentTimestamp} seekOnTranscriptClick={seekOnTranscriptClick}/>
                        </div>
                    </TabPane>
                    
                    <TabPane forceRender tab={<Badge size="small" count={pendingQuestions()}><div style={{padding: "6px 3px 0px 0px"}}>Questions</div></Badge>} key="2" className='transcript-tab'>
                        <div id='transcript-tab-content'>
                            <QuizQuestionsContainer updateQuizQuestionAttempt={updateQuizQuestionAttempt} pauseVideo={pauseVideo} quizQuestions={quizQuestions} seekToVideoTimestamp={seekToVideoTimestamp}/>
                        </div>
                    </TabPane>

                </Tabs>
            </Layout.Sider>
}
export default VideoPageFeatureMenu