import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Col, List, Row, Typography } from 'antd';

const { Meta } = Card;
const { Text } = Typography;

const CreatorPublicCollections = ({ creatorPublicCollections, creatorPublicCollectionsStatus }) => {
    if(creatorPublicCollectionsStatus == "LOADED"){
        return <Card className="dashboard-card" id="creator-public-collections">
                    <Text>{`Courses`}</Text>
                    <br/><br/>
                    <List
                        grid={{
                            gutter: [16,16],
                            xs: 1,
                            sm: 2,
                            md: 4,
                            lg: 3,
                            xl: 4,
                            xxl: 4,
                        }}
                       
                        dataSource={creatorPublicCollections}
                        pagination={{
                            pageSize: 4
                        }}
                        renderItem={collection => (
                        <List.Item>
                            <Link to={`/collections/${collection.id}/`}>
                                <Card
                                    hoverable
                                    cover={<img alt={collection.name} src={collection.coverImage.image}/>}
                                >
                                    <Meta title={collection.name} />
                                </Card>
                            </Link>
                        </List.Item>
                        )}
                    />
                   
                </Card>
    } else {
        return <Card className="dashboard-card" id="creator-public-collections">
                    <Text>{`Courses`}</Text>
                    <br/><br/>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Card
                                loading={true}
                            >
                           
                            </Card>            
                        </Col>
                        <Col span={6}>
                        <Card
                                loading={true}
                            >
                            
                            </Card>          
                        </Col>
                        <Col span={6}>
                        <Card
                                loading={true}
                            >
                                
                            </Card>            
                        </Col>
                        <Col span={6}>
                            <Card
                                loading={true}
                            >
              
                            </Card>             
                        </Col>
                        <Col span={6}>
                            <Card
                                loading={true}
                            >
                           
                            </Card>            
                        </Col>
                        <Col span={6}>
                        <Card
                                loading={true}
                            >
                            
                            </Card>          
                        </Col>
                        <Col span={6}>
                        <Card
                                loading={true}
                            >
                                
                            </Card>            
                        </Col>
                        <Col span={6}>
                            <Card
                                loading={true}
                            >
              
                            </Card>             
                        </Col>
                    </Row>
        </Card>    
    }
    
}

const mapStateToProps = state => {
    return {
        creatorPublicCollections: state.creatorReducer.creatorPublicCollections,
        creatorPublicCollectionsStatus: state.creatorReducer.creatorPublicCollectionsStatus,
    }
}
export default connect(mapStateToProps, { })(CreatorPublicCollections);