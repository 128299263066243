import { Button, Card, Col, List, Modal, Row, Space, Typography } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { PlusOutlined, PlusSquareOutlined } from '@ant-design/icons';
import ModuleRow from './ModuleRow';
import CollectionCreatorModuleEditor from './CollectionCreatorModuleEditor';
import VideoCard from '../Components/VideoCard';


const { Paragraph, Text, Title } = Typography;

const AddModulesStep = ({ allVideos, authorsDict, collection, createdModules, currentUser, goToNextStep, goToPreviousStep, modules, policyModel, setUserCreatedModules, updateState, videoBank }) => {

    const [newModuleFormVisible, setNewModuleFormVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [createdModulesListVisible, setCreatedModulesListVisible] = useState(false);
    const [selectedModulesToImport, setSelectedModulesToImport] = useState(new Set());

    const handleOnDragEnd = (result) => {
        if(!result.destination) return;
        const items = Array.from(modules);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem)

        updateState("modules", items);
    }

    const handleOnNextStepClick = () => {
        let collectionValuePrice = 0;
        let collectionNetPrice = 0;
        modules.forEach(module => {
            collectionValuePrice += module.price
            collectionNetPrice += Math.ceil(module.price * (1 - 0.15))
        });
        updateState("collection", { ...collection, valuePrice: collectionValuePrice, collectionNetPrice: collectionNetPrice})
        goToNextStep()
    }

    const handleSelectModuleToImport = (moduleId) => {
        
        if(selectedModulesToImport.has(moduleId)){
            selectedModulesToImport.delete(moduleId)
            setSelectedModulesToImport(new Set([...selectedModulesToImport]))
        }else {
            selectedModulesToImport.add(moduleId)
            setSelectedModulesToImport(new Set([...selectedModulesToImport]))
        }
    }

    const handleOnSelectedModulesFinish = () => {
        let modulesToAdd = []
        let filtered = []
        createdModules.forEach((moduleData, index) => {
            if(selectedModulesToImport.has(moduleData.id)){
                modulesToAdd.push({
                    key: (new Date() + index).toLocaleString(),
                    primaryAuthorUsername: moduleData.primaryAuthor.username,
                    coAuthorUsernames: moduleData.coAuthors.map(coAuthor => coAuthor.username),
                    ...moduleData
                })
            }else {
                filtered.push(moduleData)
            }
        })
        setUserCreatedModules(filtered)
        updateState("modules", [...modules, ...modulesToAdd]);
        setCreatedModulesListVisible(false)
        setSelectedModulesToImport(new Set())
    }

    return  <div>
                <Paragraph>
                    <Title level={5}>Modules in this Course:</Title>
                    <Text type="secondary">Drag and drop into preferred order. Click to edit.</Text>
                </Paragraph>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="modules">
                        {
                            (provided) => (
                                <ol className="collection-creator-modules-list" {...provided.droppableProps} ref={provided.innerRef}>
                                {   modules.map((module, index) => {
                                        return (
                                            <Draggable key={`${module.id ? module.id : module.key}`} draggableId={`${module.id}`} index={index}>
                                                {(provided)=> (
                                                    <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        
                                                        <ModuleRow 
                                                            allVideos={allVideos}
                                                            authorsDict={authorsDict}
                                                            currentUser={currentUser}
                                                            index={index}
                                                            modules={modules} 
                                                            module={module}
                                                            updateState={updateState}
                                                            videoBank={videoBank}
                                                        />
                                                    </li>
                                                )}
                                                
                                            </Draggable>
                                        )
                                    }
                                )}
                                {provided.placeholder}
                                </ol>
                            )
                        }
                    </Droppable>
                </DragDropContext>

                <Button 
                    type="dashed" 
                    style={{width: "100%", marginBottom: 20}}
                    icon={<PlusOutlined />}
                    onClick={()=>setVisible(true)}
                >
                    Add New Module
                </Button>
                
                <Modal visible={visible} footer={null} onCancel={()=>setVisible(false)} maskClosable={false}>
                    <div className="add-module-modal">
                        <Title level={4}>How would you like to add your module?</Title>
                        <br/>
                        <Row gutter={16}>
                            <Col span={12}>
                                <div 
                                    className="add-module-option"
                                    onClick={()=>{
                                        setVisible(false);
                                        setNewModuleFormVisible(true)
                                    }}
                                >
                                    <Text>Build new module from</Text>
                                    <Text>YouTube or Vimeo Playlist</Text> 
                                </div>
                            </Col>
                            <Col span={12}>
                                <div 
                                    className="add-module-option"
                                    onClick={()=>{
                                        setVisible(false);
                                        setCreatedModulesListVisible(true);
                                    }}
                                >
                                    <Text>Add from modules</Text> 
                                    <Text>you've created</Text>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <Modal 
                    visible={createdModulesListVisible} 
                    width={"800px"}
                    onOk={handleOnSelectedModulesFinish}
                    okText={"Add Selected Modules"}
                    onCancel={()=>{
                        setCreatedModulesListVisible(false)
                        setSelectedModulesToImport(new Set())
                    }}
                >
                    <div className="add-module-modal">
                        <Title level={3}>Select the modules you want to import</Title>
                        <Text type="secondary">Selected {selectedModulesToImport.size} of {createdModules.length}</Text>
                        <List
                            style={{marginTop: 10}}
                            pagination={{
                                pageSize: 3
                            }}
                            dataSource={createdModules}
                            renderItem={(module,index) => (
                                <List.Item key={module.id} >
                                    <div className="created-modules-list-item">
                                        <Card onClick={()=>handleSelectModuleToImport(module.id)} className={`modules-to-import-list selectable-module-option ${selectedModulesToImport.has(module.id) ? "selected-module-to-import" : ""}`}>
                                            <div style={{ display: "flex", justifyContent: "space-between"}}  >   
                                                <div>
                                                    <Title level={5} style={{margin: 0}}>{module.name}</Title>

                                                
                                                    <>
                                                        <Text type="secondary">{`${module.primaryAuthor.firstName} ${module.primaryAuthor.lastName}`}</Text>
                                                        <Text type="secondary">{module.coAuthors.map(coAuthor => <Text key={coAuthor.id}>{`, ${coAuthor.firstName} ${coAuthor.lastName}`}</Text>)}</Text>
                                                    </>
                                                        
                                                </div>
                                                <div>

                                                </div>
                                            
                                            </div>
                                            
                                        </Card>
                                        <br/>
                                        <List
                                                grid={{
                                                    gutter: [16,16],
                                                    xs: 1,
                                                    sm: 2,
                                                    md: 4,
                                                    lg: 3,
                                                    xl: 4,
                                                    xxl: 4,
                                                }}
                                                pagination={{
                                                    pageSize: 4,
                                                    size: "small"
                                                }}
                                                dataSource={module.videos}
                                                renderItem={video => (
                                                    <List.Item key={`video-${video.id}`} >
                                                    <VideoCard
                                                            index={index}
                                                            collectionId={null}
                                                            module={module}
                                                            moduleId={module.id}
                                                            mode={"preview"}
                                                            moduleName={module.name}
                                                            moduleAccess={{}}
                                                            moduleIndex={index}
                                                            video={video}
                
                                                            price={module.price}
                                            
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        </div>
                                </List.Item>
                            )}
                        />
                    </div>
                </Modal>
                <CollectionCreatorModuleEditor
                    allVideos={allVideos}
                    authorsDict={authorsDict}
                    currentUser={currentUser}
                    modalVisible={newModuleFormVisible} 
                    mode="add"
                    module={{
                        name: "Sample Module Name",
                        primaryAuthorUsername: "",
                        coAuthorUsernames: [],
                        videos: []
                    }}
                    modules={modules}
                    setModalVisible={setNewModuleFormVisible} 
                    updateState={updateState}
                    videoBank={videoBank}
                />

                <Space>
                    <Button
                        onClick={goToPreviousStep}
                    >
                        Back
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleOnNextStepClick}
                        disabled={modules.length < 1}
                    >
                        Next: Review Changes
                    </Button>
                </Space>
            </div>
}

export default AddModulesStep;