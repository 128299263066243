import { Button, Result } from "antd";
import { useHistory } from 'react-router-dom';
import { SIGNUP_URL } from "../../const";


const PublishResult = ({ collection, isAuthenticated, modules }) => {

    let history = useHistory();

    let previewCollectionData = {
        ...collection,
        modules: modules
    }

    const RenderButtons = () => {
        if(isAuthenticated) {
            return [
                <Button
                    type="primary"
                    onClick={()=>{
                        history.goBack();
                    }}
                >   
                    Exit Tutorial
                </Button>
            ]
        } else {
            return [
                <Button
                    type="primary"
                    onClick={()=>{
                        localStorage.setItem('collectionTutorialData', JSON.stringify(previewCollectionData));
                        history.goBack();
                    }}
                >   
                    <a href={`${SIGNUP_URL}`}>
                        Sign up
                    </a>
                </Button>,
                <Button
                    onClick={()=>{
                        history.goBack();
                    }}
                >   
                    Exit Tutorial
                </Button>
            ]
        }
    }
    return <div>
            <Result
                title="Your collection is not published."
                subTitle="This walkthrough is for demo purposes only."
                extra={<RenderButtons />}
            />
    </div>
}

export default PublishResult;