import { Modal } from "antd";
import AccountTypeRegistration from "./AccountTypeRegistration";

const AccountType = () => {
    return <Modal
                visible
                width={700}
                maskClosable={false}
                footer={null}
                closable={false}
            >
                <div style={{padding: "50px 60px"}}>
                    <AccountTypeRegistration />
                </div>
            </Modal>
}

export default AccountType;