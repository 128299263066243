import { Card, Col, Row, Table, Typography } from 'antd';
import CreatorCollectionPerformanceDetail from './CreatorCollectionPerformanceDetail';

const { Text, Title } = Typography;

const CreatorCollectionPerformances = ({ creatorCollectionPerformances, creatorCollectionPerformancesStatus}) => {
    const columns = [
        { title: 'Courses', dataIndex: 'name', key: 'name' },
        { title: 'Subscriptions', dataIndex: 'totalSubscriptions', key: 'subscriptions' },
        { title: 'Limited Subscriptions', dataIndex: 'totalLimitedSubscriptions', key: 'limitedSubscriptions' },
        { title: 'Generated Revenue', dataIndex: 'totalRevenue', key: 'totalRevenue', render: (value) => `$${value.toFixed(2)}`},
        {
          title: 'Action',
          dataIndex: '',
          key: 'x',
          render: (collection) => <CreatorCollectionPerformanceDetail collection={collection}/>,
        },
    ];

    const data = creatorCollectionPerformances.map((performance, index) => {
        return {
            ...performance,
            key: index
        }
    })

    return <Card className="creator-author-page-card" id="collection-performance">
                <Text>Course Performance</Text>
                <br/><br/>
                <Table
                    columns={columns}
                    dataSource={data}
                />
    </Card>
}

export default CreatorCollectionPerformances;