import { Button, Col, Form, Input, message, Row, Space, Tooltip, Upload } from 'antd';
import { CloseOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { submitDemoRequest } from '../actions/issue';
import { connect } from 'react-redux';
import { useState } from 'react';
import { API_URL } from '../const';

const { Dragger } = Upload;
const { TextArea } = Input;


const StepTwo = ({ formData, nextStep, prevStep, submitDemoRequest, updateFormData}) => {

    const [form] = Form.useForm();
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const handleOnFinish = (values) => {
        nextStep();
        updateFormData(values)
        submitDemoRequest({
            ...formData,
            ...values,
            files: uploadedFiles
        })
    }

    const onChange = (info) => {
        const { status } = info.file;
    
        if (status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
    
        if (status === 'done') {
            setUploadedFiles([...uploadedFiles, info.file.response.id])
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }


    const onDrop = (e) => {
        console.log('Dropped files', e.dataTransfer.files);
    }


    return  <div id="demo-request-step-two">
                <h6>Upload videos directly</h6>
                <Dragger 
                    name='file'
                    multiple={true}
                    accept= "video/*"
                    action= {`${API_URL}/api/v1/demos/files/`}
                    onDrop={onDrop}
                    onChange={onChange}
                >
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                  
                    </p>
                </Dragger>
                <h6>And/or add links to Dropbox, Google Drive, etc.</h6>
                <Form
                    form={form}
                    layout="vertical"
                    name="register"
                    onFinish={handleOnFinish}
                    initialValues={{
                        links: []
                    }}
                    scrollToFirstError
                >
                    <Row>
                        <Col span={12}>
                            <Form.List name="links">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Space style={{ display: 'flex', justifyContent: "space-between", marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'link']}
                                                    rules={[{ required: true, message: 'Missing link' }]}
                                                    style={{width: 335}}
                                                >
                                                    <Input placeholder="https://www.dropbox.com"/>
                                                </Form.Item>
                                            
                                                <Tooltip title="Remove" ><CloseOutlined onClick={() => remove(name)}/></Tooltip>
                                            </Space>
                                        ))}
                                        <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add a link
                                        </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    
                    </Row>
                    <div id="demo-request-next-btn-container">
                        <button id="demo-request-back-btn" onClick={prevStep}>Back</button>
                        <button id="demo-request-next-btn">Submit</button>
                    </div>
                </Form>
            </div>
}

export default connect(null, { submitDemoRequest })(StepTwo);