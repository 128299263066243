import { Button, Card, Col, Modal, Popconfirm, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CheckCircleOutlined, FrownOutlined, SmileOutlined, ThunderboltOutlined, UnlockOutlined } from '@ant-design/icons';
import VideoCardRenderer from './VideoCardRenderer';
import { useState } from 'react';

const { Paragraph, Text, Title } = Typography;


const ModuleDisplay = ({ collection, collectionId, index, module, moduleIndex, unlistedKey }) => {


    return <div id={`module-${index + 1}`}>
        <Card>
            <div className="module-header">   
                <div>
                    <Title level={5} style={{margin: 0}}>{module.name}</Title>

                    <>
                        <Text type="secondary">By <Link to={`/creators/${module.primaryAuthor.id}`}>{`${module.primaryAuthor.firstName} ${module.primaryAuthor.lastName}`}</Link></Text>
                        <Text>{ module.coAuthors.map(coAuthor => <Text key={coAuthor.id}><Link to={`/creators/${coAuthor.id}`}>{`, ${coAuthor.firstName} ${coAuthor.lastName}`}</Link></Text>) }</Text>
                    </>
                    
                    
                </div>

            </div>
        </Card>
        <br/>
        <Row gutter={[16, 16]}>
            { 
                module.videos.map((video, index) => {
                    return <Col 
                                xs={24} 
                                sm={12} 
                                md={8} 
                                lg={6} 
                                key={`module-${module.id}-video-${index}`}
                            >
                                <VideoCardRenderer 
                                    collection={collection}
                                    collectionId={collectionId} 
                                   
                                    
                                    module={module}
                                    moduleId={module.id} 
                                    moduleIndex={moduleIndex}
                                    moduleName={module.name} 
                                    video={video} 
                                    index={index} 
                          
                                    unlistedKey={unlistedKey}
                                   
                                />
                            </Col>
                })
            }
        </Row>
    </div>
}

export default ModuleDisplay;