import { CloseCircleFilled, CloseCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    Image,
    Popconfirm,
    Row,
    Space,
    Tag,
    Tooltip,
    Typography
} from "antd";
import { useState } from "react";

const { Paragraph, Text, Title } = Typography;

const VideoRow = ({
    index,
    module,
    removeable = false,
    removeFromVideoBank,
    video,
    updateTempState
}) => {
    var [vimeoThumbnailUrl, setVimeoThumbnailUrl] = useState(null);

    const fetchVimeoThumbnail = async (video) => {
        var response = await fetch(
            `https://vimeo.com/api/v2/video/${video.embedId}.json`
        );
        const json = await response.json();
        return setVimeoThumbnailUrl(json[0]["thumbnail_large"]);
    };
    const getVideoThumbnail = (video) => {
        if (video.videoType === "youtube") {
            return (
                <Image
                    src={`https://img.youtube.com/vi/${video.embedId}/mqdefault.jpg`}
                    preview={false}
                    style={{ aspectRatio: 16 / 9 }}
                />
            );
        } else if (video.videoType === "vimeo") {
            fetchVimeoThumbnail(video);
            return (
                <Image
                    src={vimeoThumbnailUrl}
                    preview={false}
                    style={{ aspectRatio: 16 / 9 }}
                />
            );
        }
    };

    const renderVideoTypeTag = (videoType) => {
        if (videoType === "youtube") {
            return <Tag color="#cd201f">YouTube</Tag>;
        } else if (videoType === "vimeo") {
            return <Tag color="#00adef">Vimeo</Tag>;
        }
    };

    const renderBorrowPrice = () => {
        if (
            (module && module.id != video.parentModule) ||
            (module == null && video.parentModule)
        ) {
            return (
                <Tag color="#f5bc5f">+{video.parentModulePrice} cupoints</Tag>
            );
        }
        return;
    };

    return (
        <Card cover={getVideoThumbnail(video)}>
            {removeable ? (
                <div className="triangle-top-right">
                    {/*  style={{position: "absolute", top:-10, right: -5}} */}
                    <Popconfirm
                        title="Are you sure to remove this video?"
                        onConfirm={() => removeFromVideoBank(index)}
                        onCancel={(e) => {
                            e.stopPropagation();
                        }}
                        okText="Yes"
                        cancelText="No">
                        <Tooltip title="Remove">
                            <Text
                                className="delete-video-row-card">
                                <CloseCircleFilled />
                            </Text>
                        </Tooltip>
                    </Popconfirm>
                </div>
            ) : (
                <></>
            )}
            <Space direction="vertical" style={{ width: "100%" }}>
                <Paragraph
                    ellipsis={{ rows: 1, tooltip: true }}
                    editable={{
                        autoSize: { maxRows: 1 },
                        onChange: updateTempState
                    }}>
                    {video.title}
                </Paragraph>
                <Space>
                    {renderVideoTypeTag(video.videoType)}
                    {renderBorrowPrice()}
                </Space>
            </Space>
        </Card>
    );
};

export default VideoRow;
