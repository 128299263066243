import AddModulesStep from "./AddModulesStep";
import CollectionCreationResultStep from "./CollectionCreationResultStep";
import CollectionDetailsStep from "./CollectionDetailsStep";
import ReviewCollectionChangesStep from "./ReviewCollectionChangesStep";

const CollectionCreatorSteps = ({
    isEditMode,
    authorsDict,
    setAuthorsDict,
    collection,
    createdModules,
    currentStep,
    currentUser,
    goToNextStep,
    goToPreviousStep,
    modules,
    updateState,
    videoBank,
    showUrlImport,
    importInProgress,
    policyModel,
    addNewSampleModule
}) => {
    if (currentStep === 0) {
        return (
            <CollectionDetailsStep
                authorsDict={authorsDict}
                collection={collection}
                currentUser={currentUser}
                goToNextStep={goToNextStep}
                updateState={updateState}
                showUrlImport={showUrlImport}
                modules={modules}
                importInProgress={importInProgress}
                addNewSampleModule={addNewSampleModule}
            />
        );
    } else if (currentStep == 1) {
        return (
            <AddModulesStep
                isEditMode={isEditMode}
                authorsDict={authorsDict}
                collection={collection}
                createdModules={createdModules}
                currentUser={currentUser}
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                modules={modules}
                updateState={updateState}
                videoBank={videoBank}
                addNewSampleModule={addNewSampleModule}
                setAuthorsDict={setAuthorsDict}
                policyModel={policyModel}
            />
        );
    } else if (currentStep == 2) {
        return (
            <ReviewCollectionChangesStep
                isEditMode={isEditMode}
                authorsDict={authorsDict}
                collection={collection}
                currentUser={currentUser}
                modules={modules}
                setAuthorsDict={setAuthorsDict}
                updateState={updateState}
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
            />
        );
    } else if (currentStep == 3) {
        return (
            <CollectionCreationResultStep
                isEditMode={isEditMode}
                goToPreviousStep={goToPreviousStep}
            />
        );
    }
};

export default CollectionCreatorSteps;
