import QuizQuestion from './QuizQuestion';
import { Button, Popover, Space, Timeline } from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
const QuizQuestionsContainer = ({updateQuizQuestionAttempt, pauseVideo, quizQuestions, seekToVideoTimestamp}) => {

    return <>
            <Timeline mode="left">
                {quizQuestions.map((question, index) => {
                    const submitAttempt = (attempt) => {
                        let updatedQuestions = [...quizQuestions]
                        updatedQuestions[index] = {
                            ...updatedQuestions[index],
                           "latestAttempt": attempt
                        }
                        updateQuizQuestionAttempt(updatedQuestions)
                    }
                    return <QuizQuestion key={`question[${index}]`} submitAttempt={submitAttempt} pauseVideo={pauseVideo} question={question} seekToVideoTimestamp={seekToVideoTimestamp}/>
                })}
            <Timeline.Item color="grey" >End</Timeline.Item>
        </Timeline></>

}

export default QuizQuestionsContainer;

