import React, { useContext, useRef } from "react";
// import {submitButton} from '/public/submit-button.png';
import { useState } from "react";
import { API_URL } from "./../../../const.js";
import ChatHistory from "./ChatHistory.js";
import "./style.css";
import { Tooltip } from "antd";
import { VscSend } from "react-icons/vsc";
import { GptContext } from "./ChatbotContainer.js";

const MessageBox = () => {
    const {
        setMessages,
        loading,
        setLoading,
        video,
        collection,
        module,
        pauseVideo,
        isCollectionOwner,
        messages
    } = useContext(GptContext);
    const textareaRef = useRef(null);

    const [activeButton, setActiveButton] = useState(null);
    // 1: Summary Button
    // 2: Question Button
    // 3: Extend On Button

    const [textareaValue, setTextareaValue] = useState("Select an option");
    const [showalert, setShowAlert] = useState(false);

    const [iconColor, setIconColor] = useState("black");
    const [textBoxDisabled, setTextBoxDisabled] = useState(true);

    const [visibleExpandsOnHistory, setVisibleExpandsOnHistory] =
        useState(true);

    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);

        if (event.target.value === "") {
            setIconColor("black");
        } else {
            setIconColor("rgba(30, 112, 105, 0.9)");
        }
    };

    const fetchSummary = (collectionId, videoId) => {
        setLoading(true);
        fetch(API_URL + `/api/v1/gpt/summary/${collectionId}/${videoId}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Network response was not ok");
                }
            })
            .then((response) => {
                if (response.data) {
                    sendMessage(
                        1,
                        response.data.content,
                        "GPT",
                        response.data.id
                    );
                } else {
                    sendMessage(
                        1,
                        "We are sorry! We have no summaries for this context.",
                        "GPT"
                    );
                }
                setLoading(false);
            });
    };

    const fetchQuestion = (prompt, collectionId, videoId) => {
        setLoading(true);

        fetch(
            API_URL +
                `/api/v1/gpt/generate-question/${collectionId}/${videoId}`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access")
                },
                body: prompt
            }
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.content) {
                    sendMessage(2, result.content, "GPT", result.id);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error submitting data:", error);
                setLoading(false);
            });
    };

    const fetchExpandOn = (prompt, collectionId, videoId) => {
        setLoading(true);
        fetch(API_URL + `/api/v1/gpt/get-expandOn/${collectionId}/${videoId}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            },
            body: prompt
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.content) {
                    sendMessage(3, result.content, "GPT", result.id);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error submitting data:", error);
            });
    };

    const sendMessage = (type, messageText, user, elementId = undefined) => {
        let mcq = false;

        if (type === 2) {
            mcq = true;
        }

        const newMessage = {
            user: user,
            content: messageText,
            mcq: mcq,
            element: type,
            elementId: elementId
        };
        setMessages((prev) => [...prev, newMessage]);

        setActiveButton(null);
        setTextareaValue("Select an option");
        setTextBoxDisabled(true);
    };

    const handleMessageSubmit = (type = undefined, query = undefined) => {
        if (!type && activeButton === null) {
            setShowAlert(true);
            return;
        }

        if (!type) {
            type = activeButton;
        }

        let searchQuery = textareaValue;
        let message = textareaValue;
        if (query) {
            searchQuery = query.text;
            message = query.message;
        }
        sendMessage(type, message, "USER", 0);

        switch (type) {
            case 1:
                setTimeout(() => {
                    setActiveButton(type);
                    fetchSummary(collection.id, video.id);
                }, 100);

                break;

            case 2:
                setTimeout(() => {
                    setActiveButton(type);
                    fetchQuestion(searchQuery, collection.id, video.id);
                }, 100);

                break;

            case 3:
                if (query && query.history) {
                    sendMessage(
                        3,
                        query.history.answer,
                        "GPT",
                        query.history.id
                    );
                } else {
                    setTimeout(() => {
                        fetchExpandOn(searchQuery, collection.id, video.id);
                    }, 100);
                }
                break;

            default:
                break;
        }
    };

    const focusOnTextBox = () => {
        setTimeout(() => {
            textareaRef.current.focus();
            textareaRef.current.selectionStart =
                textareaRef.current.value.length;
            textareaRef.current.selectionEnd = textareaRef.current.value.length;
        }, 100);
    };

    const onButtonClick = (type) => {
        pauseVideo();
        setActiveButton(type);
        setIconColor("rgba(30, 112, 105, 0.9)");
        setShowAlert(false);
        // setVisibleExpandsOnHistory(
        //     messages.filter((m) => m.element !== 0).length === 0 && type === 3
        // );
        setVisibleExpandsOnHistory(false);

        const query =
            type === 1
                ? `Provide a summary of "${video.title}"`
                : type === 2
                ? `Generate a MCQ on "${collection.name} - ${module.name} - ${video.title}"`
                : ``;

        if (isCollectionOwner || type === 3) {
            setTextareaValue(query);
            setTextBoxDisabled(false);
            focusOnTextBox();
            return;
        }

        const sendingMessage =
            type === 1
                ? `Generating a summary of "${video.title}"`
                : `Generating a MCQ on "${collection.name} - ${module.name} - ${video.title}"`;
        handleMessageSubmit(type, {
            text: query,
            message: sendingMessage
        });
    };

    const onExpandOnHistoryItemClick = (history) => {
        setTextareaValue(history.question);
        setVisibleExpandsOnHistory(false);
        handleMessageSubmit(3, {
            text: history.question,
            message: history.question,
            history: history
        });
    };

    const onExpandOnHistoryToggle = (expanded) => {
        setVisibleExpandsOnHistory(expanded);
    };

    return (
        <div id="wrapper" className="cugpt-wrapper">
            <ChatHistory
                id="chat-history"
                visibleExpandsOnHistory={visibleExpandsOnHistory}
                onExpandOnHistoryItemClick={onExpandOnHistoryItemClick}
                onExpandOnHistoryToggle={onExpandOnHistoryToggle}
            />

            <div id="box">
                {showalert && (
                    <div id="alert">
                        Select an option before you submit a message!
                    </div>
                )}

                <div id="buttons">
                    <div>
                        <Tooltip
                            title={
                                "Get a summary of the important takeaways from this lesson."
                            }>
                            <button
                                id={
                                    activeButton === 1
                                        ? "summary-clicked"
                                        : "summarize-button"
                                }
                                disabled={loading}
                                onClick={() => onButtonClick(1)}>
                                Summarize Video
                            </button>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip
                            title={"Generates a multiple choice question."}>
                            <button
                                id={
                                    activeButton === 2
                                        ? "question-clicked"
                                        : "question-button"
                                }
                                disabled={loading}
                                onClick={() => onButtonClick(2)}>
                                Generate MCQ
                            </button>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip
                            title={
                                "Ask for clarification on the content, a real-world application of a concept, etc."
                            }>
                            <button
                                id={
                                    activeButton === 3
                                        ? "expandOn-clicked"
                                        : "expand-button"
                                }
                                disabled={loading}
                                onClick={() => onButtonClick(3)}>
                                Expand On...
                            </button>
                        </Tooltip>
                    </div>
                </div>

                <div
                    id="message-box"
                    className={textBoxDisabled ? "disabled" : ""}>
                    <textarea
                        ref={textareaRef}
                        disabled={textBoxDisabled}
                        id="message"
                        value={textareaValue}
                        placeholder={
                            activeButton === 3
                                ? "Ask your question in a thoughtful, clear and concise manner with reference to context!"
                                : ""
                        }
                        onChange={handleTextareaChange}></textarea>

                    <button
                        type="submit"
                        disabled={textBoxDisabled || !textareaValue}
                        onClick={() => handleMessageSubmit()}
                        id="submit-button">
                        <VscSend
                            size={30}
                            color={iconColor}
                            className="submit-button"
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};
export default MessageBox;
