import React, { Component, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Typography } from "antd";

import LearningSVG from "./assets/images/authors-page/skills.jpg";
import AuthorPageImage1 from "./assets/images/authors-page/author-page-image1.PNG";
import AuthorPageImage2 from "./assets/images/authors-page/author-page-image2.PNG";

import "./Components.less";
import { logEvent, trackingEvents } from "./Tracking";
import Player from "@vimeo/player";

const { Text, Title } = Typography;

class AuthorsPage extends Component {
    constructor(props) {
        super(props);

        this.vimeoContainerRef = React.createRef();
        this.whyCubitsRef = React.createRef();
        this.howItWorksRef = React.createRef();
        this.seniorScientistProgramRef = React.createRef();
    }

    componentDidMount() {
        logEvent(trackingEvents.pageView, {
            page: "authors"
        });

        if (this.vimeoContainerRef?.current) {
            new Player(this.vimeoContainerRef?.current, {
                id: "1001697573",
                autoplay: false,
                width: "100%",
                height: "100%"
            });
        }
    }

    render() {
        const executeScroll = (key) => {
            switch (key) {
                case "why-cubits":
                    this.whyCubitsRef.current.scrollIntoView({
                        behavior: "smooth"
                    });
                    break;
                case "how-it-works":
                    this.howItWorksRef.current.scrollIntoView({
                        behavior: "smooth"
                    });
                    break;
                case "senior-scientist-program":
                    this.seniorScientistProgramRef.current.scrollIntoView({
                        behavior: "smooth"
                    });
                    break;
            }
        };

        return (
            <section>
                <div className="author-page-landing">
                    <div id="author-page-heading">
                        <h1>
                            Become a cubits.ai <b>Author</b>
                        </h1>
                        <p>
                            Unleash your expertise and share it with a global
                            audience. Turn your unique skills into a source of
                            income by creating something extraordinary.
                        </p>
                        <div id="author-page-landing-btns">
                            <Link
                                to="/authors"
                                id="author-page-explore-btn"
                                onClick={() => executeScroll("why-cubits")}>
                                WHY CUBITS.AI
                            </Link>
                            <Link
                                to="/authors"
                                id="author-page-explore-btn"
                                onClick={() => executeScroll("how-it-works")}>
                                HOW IT WORKS
                            </Link>
                            <Link
                                to="/authors"
                                id="author-page-explore-btn"
                                onClick={() =>
                                    executeScroll("senior-scientist-program")
                                }>
                                SENIOR SCIENTIST PROGRAM
                            </Link>
                        </div>
                    </div>
                    <img
                        src={LearningSVG}
                        className="author-page-landing-image"
                    />
                </div>

                <div
                    id="why-cubits"
                    ref={this.whyCubitsRef}
                    className="author-page-titles contain">
                    <h2>Why cubits.ai?</h2>
                    <section>
                        <p className="why-cubits-works-para">
                            We seamlessly integrate AI into every stage of the
                            creative process, making content creation
                            effortless. Simply organize your ideas into bullet
                            points, and our AI will transform them into polished
                            scripts, teleprompter-ready text, and even guide you
                            through the recording process—all from the comfort
                            of your home or office. Or allow our AI tools to do
                            all the recordings. You are the producer and
                            director of your creation.
                        </p>
                        <p className="why-cubits-works-para">
                            We also handle the heavy lifting by editing your
                            content, adding special effects, curating questions
                            and packaging it for you. Our AI curates your work
                            and places it on our platform, ready for
                            monetization.
                        </p>
                    </section>
                </div>

                <div
                    id="how-it-works"
                    ref={this.howItWorksRef}
                    className="author-page-titles contain">
                    <h2>How it works?</h2>
                    <p className="why-cubits-works-para heading">
                        As a CUbits author, you have complete control over your
                        author page. This page provides a clear overview of all
                        your courses, the revenue you've earned, and a detailed
                        breakdown by course and module. Transferring funds from
                        your CUbits account to your bank account is seamless and
                        instant via Stripe. There's no need to deal with complex
                        publisher statements—our transparent royalty model
                        ensures that you see exactly what you sell and how much
                        you earn. You can earn up to 50-70% of the revenue, so
                        if someone purchases your course for $100, your account
                        is immediately credited with $50-$70, which you can
                        withdraw as soon as the next day. It’s straightforward,
                        with no accounting tricks.
                    </p>

                    <img src={AuthorPageImage1} />
                    <p className="why-cubits-works-para no-padding">
                        Courses by author (public view)
                    </p>

                    <img src={AuthorPageImage2} />
                    <p className="why-cubits-works-para no-padding">
                        The current earnings and breakdown (author view only)
                    </p>
                </div>

                <div
                    id="senior-scientist-program"
                    ref={this.seniorScientistProgramRef}
                    className="author-page-titles contain">
                    <h2>Senior Scientist Program</h2>
                    <p className="why-cubits-works-para ">
                        Our Senior Scientist Program offers a unique opportunity
                        for highly qualified individuals who are retired or
                        nearing retirement, and have the time and passion to
                        create a course on a topic close to their hearts.
                    </p>

                    <p className="why-cubits-works-para ">
                        For example, imagine a pharma executive (VP and CSO)
                        with a Ph.D. who, after decades of service, decides to
                        retire and share their invaluable practical experience
                        with the next generation of professionals. CUbits.ai
                        helps transform this wealth of knowledge into a course
                        that preserves their legacy and offers young
                        professionals access to insights not found elsewhere on
                        the web. It’s truly a special experience.
                    </p>

                    <p className="why-cubits-works-para ">
                        Interested in seeing what you can create? <br />
                        Here is the collection created by a senior scientist.{" "}
                        <br />
                        <Link to="https://www.cubits.ai/collections/95">
                            https://www.cubits.ai/collections/95
                        </Link>
                    </p>

                    <p className="why-cubits-works-para ">
                        Watch this promotion video and contact us at{" "}
                        <Link to="#" href="mailto:support@cuvids.io">
                            support@cuvids.io
                        </Link>{" "}
                        and to discuss your interests.
                    </p>

                    <p>
                        <div
                            className="author-page-intro-video"
                            ref={this.vimeoContainerRef}></div>
                    </p>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user
    };
};

export default connect(mapStateToProps, {})(AuthorsPage);
