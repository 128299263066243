import Player from '@vimeo/player';
import {Layout} from 'antd';
import { Component } from 'react';

const {Content} = Layout;

class SimpleVimeoPlayer extends Component {

    constructor(props){
        super(props)
        this.state = {
            playerRef: null,
        }
    }

    componentDidMount = () => {
        var options = {
            id: this.props.embedId,
            autoplay: true,
        };
        var player = new Player('vimeo-player', options);
        this.setState({playerRef: player});
    }

    render() {
        return <div style={{width: "70%", margin: "0 auto"}}>
            <Content id="video-content-wrapper">
                <div id="video-wrapper"><div id='vimeo-player'></div></div>
            </Content>
        </div>
    }
}

export default SimpleVimeoPlayer;