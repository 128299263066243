import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
    Affix,
    Button,
    Dropdown,
    Layout,
    message,
    Menu,
    PageHeader,
    Space,
    Spin,
    Switch,
    Typography,
    Alert
} from "antd";
import VideoPageMenu from "./VideoPageMenu";
//import VideoPageMenu from './VideoPageMenu';
//import VimeoPlayer from './Player/VimeoPlayer';
//import YouTubePlayer from './Player/YouTubePlayer';
//import QnAContainer from './Q&A/QnAContainer';
import collection from "./MockData.json";
import YouTubePlayer from "./YouTubePlayer";
import QnAContainer from "./Dashboard/MockDashboardQnA";

const { Title } = Typography;

class MockVideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsedMenu:
                localStorage.getItem("collapsedMenu") === null
                    ? true
                    : localStorage.getItem("collapsedMenu") === "true",
            collapsedTranscript: false,
            video: collection.modules[0].videos[0],
            ignore: false
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let qna = query.get("qna");
        if (qna) {
            setTimeout(() => {
                document
                    .getElementById("video-page-extra-content-layout")
                    .scrollIntoView({ block: "end", behavior: "smooth" });
            }, 1000);
        }
    }

    breadcrumbConfig = () => {
        function itemRender(route, params, routes, paths) {
            const last = routes.indexOf(route) === routes.length - 1;
            return last || route.path == null ? (
                <span>{route.breadcrumbName}</span>
            ) : (
                <Link to={route.path}>{route.breadcrumbName}</Link>
            );
        }
        let query = new URLSearchParams(this.props.location.search);

        return {
            itemRender: itemRender,
            routes: [
                {
                    path: "/",
                    breadcrumbName: "Home"
                },
                {
                    path: "/collections/",
                    breadcrumbName: "Collections"
                },
                {
                    path: `/mock/collection`,
                    breadcrumbName: "Algorithms - Princeton University"
                },
                {
                    breadcrumbName: "Union Find"
                },
                {
                    breadcrumbName: "Dynamic Connectivity"
                }
            ]
        };
    };
    toggleCollapsedMenu = () => {
        localStorage.setItem("collapsedMenu", !this.state.collapsedMenu);
        this.setState({
            collapsedMenu: !this.state.collapsedMenu
        });
    };
    toggleCollapsedTranscript = () => {
        this.setState({
            collapsedTranscript: !this.state.collapsedTranscript
        });
    };

    scrollToTopOfQnA = () => {
        let element = document.getElementById(
            "video-page-extra-content-layout"
        );
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    scrollToTopOfVideoPlayer = () => {
        let element = document.getElementById("video-show-page");
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    setVideo = (value) => {
        this.setState({
            video: value
        });
    };

    updateQuizQuestions = (updatedQuestions) => {
        this.setState({
            video: {
                ...this.state.video,
                quizQuestions: [...updatedQuestions]
            },
            ignore: !this.state.ignore
        });
    };

    menu = () => {
        return (
            <Menu>
                <Menu.Item onClick={this.scrollToTopOfVideoPlayer}>
                    Video Player
                </Menu.Item>
                <Menu.ItemGroup title="Q&A for:">
                    <Menu.Item
                        onClick={() => {
                            this.setQnALevel("video");
                            this.scrollToTopOfQnA();
                        }}>
                        Video{" "}
                        <span className="video-page-affix-menu-qna-count">
                            {this.props.videoQnA.length}
                        </span>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            this.setQnALevel("module");
                            this.scrollToTopOfQnA();
                        }}>
                        Module{" "}
                        <span className="video-page-affix-menu-qna-count">
                            {this.props.moduleQnA.length}
                        </span>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            this.setQnALevel("collection");
                            this.scrollToTopOfQnA();
                        }}>
                        Collection{" "}
                        <span className="video-page-affix-menu-qna-count">
                            {this.props.collectionQnA.length}
                        </span>
                    </Menu.Item>
                </Menu.ItemGroup>
            </Menu>
        );
    };

    render() {
        return (
            <div id="video-show-page" className="clearfix">
                <Alert
                    message="For Demo Purposes Only"
                    description="Please note that some features are hidden until you create an account. Any actions you make are not saved.    "
                    type="info"
                    showIcon
                />
                <PageHeader
                    style={{
                        paddingLeft: "42px"
                    }}
                    ghost={false}
                    onBack={() => window.history.back()}
                    title={"Dynamic Connectivity"}
                    breadcrumb={this.breadcrumbConfig()}
                    extra={[
                        <Space align="center" key={1}>
                            <Switch
                                size="small"
                                onChange={this.props.toggleMutedQuizQuestions}
                                checked={this.props.mutedQuizQuestions}
                            />
                            Mute Quiz Questions
                            {/* <Switch
                                size="small"
                                onChange={this.toggleCollapsedMenu}
                                checked={!this.state.collapsedMenu}
                            />
                            {this.state.collapsedMenu
                                ? "Show Menu"
                                : "Hide Menu"} */}
                            <Switch
                                size="small"
                                onChange={this.toggleCollapsedTranscript}
                                checked={!this.state.collapsedTranscript}
                            />
                            {this.state.collapsedTranscript
                                ? "Show Tools"
                                : "Hide Tools"}
                            <Button type="primary">
                                <Link to="/mock/dashboard">
                                    DEMO: View Dashboard
                                </Link>
                            </Button>
                        </Space>
                    ]}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}></div>
                </PageHeader>

                <Layout id="video-page-layout">
                    <VideoPageMenu
                        setVideo={this.setVideo}
                        isMenuCollapsed={this.state.collapsedMenu}
                        onToggle={this.toggleCollapsedMenu}
                        unlistedKey={new URLSearchParams(
                            this.props.location.search
                        ).get("key")}
                    />

                    <YouTubePlayer
                        collection={collection}
                        video={this.state.video}
                        quizQuestions={this.state.video.quizQuestions}
                        updateQuizQuestions={this.updateQuizQuestions}
                        collapsedTranscript={this.state.collapsedTranscript}
                    />
                </Layout>

                <Layout id="video-page-extra-content-layout">
                    <br />
                    <br />
                    <QnAContainer
                        collectionId={this.props.match.params.collectionId}
                        moduleId={this.state.moduleId}
                        videoId={this.state.videoId}
                        selectedQnALevel={this.state.selectedQnALevel}
                        setSelectedQnALevel={this.setQnALevel}
                    />
                </Layout>
                {/*                 
                    <Layout id='video-page-extra-content-layout'>
                        <QnAContainer 
                            collectionId={this.props.match.params.collectionId}
                            moduleId={this.state.moduleId}
                            videoId={this.state.videoId}
                            selectedQnALevel={this.state.selectedQnALevel}
                            setSelectedQnALevel={this.setQnALevel}
                        />
                    </Layout>*/}
            </div>
        );
    }
}

export default MockVideoPlayer;
