import React, { useEffect, useState } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import "./style.css";

const MultipleChoiceQuestion = ({ data, scrollToBottom }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);
    const [mcqData, setMcqData] = useState(null);
    const [mcqError, setMcqError] = useState(null);

    const handleOptionClick = (optionKey) => {
        setSelectedOption(optionKey);
        setIsCorrect(mcqData?.options[optionKey]?.is_correct);
        scrollToBottom();
    };

    useEffect(() => {
        try {
            const parsedData = JSON.parse(data);
            setMcqData(parsedData);
        } catch (error) {
            setMcqError(data);
        }

        return () => {
            setMcqData(null);
            setMcqError(null);
        };
    }, [data]);

    return (
        <>
            {mcqData ? (
                <>
                    <div>
                        <p>{mcqData.question}</p>
                        {Object.keys(mcqData.options).map((key) => (
                            <div key={key}>
                                <button
                                    className={`mult-choice-option ${
                                        selectedOption === key ? "selected" : ""
                                    }`}
                                    onClick={() => handleOptionClick(key)}>
                                    {mcqData.options[key].Text}
                                </button>
                            </div>
                        ))}

                        {selectedOption && (
                            <>
                                <div className="divider"></div>
                                <div
                                    className={`explanation-section ${
                                        selectedOption &&
                                        mcqData.options[selectedOption]
                                            .is_correct
                                            ? "correct"
                                            : "incorrect"
                                    }`}>
                                    <p>
                                        {mcqData.options[selectedOption]
                                            .is_correct ? (
                                            <span className="correct-text">
                                                {" "}
                                                <FaCheckCircle /> Correct!
                                            </span>
                                        ) : (
                                            <span className="wrong-text">
                                                {" "}
                                                <FaTimesCircle /> Incorrect. Try
                                                again
                                            </span>
                                        )}
                                    </p>
                                    {isCorrect && <p>{mcqData.explanation}</p>}
                                </div>
                            </>
                        )}
                    </div>
                </>
            ) : (
                <>{mcqError}</>
            )}
        </>
    );
};

export default MultipleChoiceQuestion;
