import { Component } from "react";
import { connect } from "react-redux";
import { Select, Typography } from "antd";
import {
    clearDashboardData,
    getCouponData,
    setSelectedCoupon
} from "../actions/dashboard";

const { Option } = Select;
const { Text } = Typography;

class ClassCouponDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultUserLicenseId: null
        };
    }

    componentDidMount() {
        if (this.props.fetchEnrolledLicensesStatus === "LOADED") {
            if (this.props.userLicenses.length) {
                this.props.setSelectedCoupon(this.props.userLicenses[0]);
                this.setState({
                    defaultUserLicenseId: this.props.userLicenses[0].id
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.fetchEnrolledLicensesStatus !==
                this.props.fetchEnrolledLicensesStatus &&
            this.props.fetchEnrolledLicensesStatus === "LOADED"
        ) {
            if (this.props.userLicenses.length) {
                this.props.setSelectedCoupon(this.props.userLicenses[0]);
                this.setState({
                    defaultUserLicenseId: this.props.userLicenses[0].id
                });
            }
        }
        // if selected coupon has changed, fetch coupon data for the new coupon
        if (
            this.props.selectedCoupon !== prevProps.selectedCoupon ||
            (prevProps.selectedCoupon != null &&
                this.props.selectedCoupon &&
                prevProps.selectedCoupon.id != this.props.selectedCoupon.id)
        ) {
            this.props.clearDashboardData();
            this.props.getCouponData(
                this.props.user.id,
                this.props.selectedCoupon
            );
        }
    }

    handleOnSelect = (value, option) => {
        this.props.setSelectedCoupon(option.licensedata);
        if (this.props.onChangeCoupon) {
            this.props.onChangeCoupon();
        }
    };

    render() {
        if (this.state.defaultUserLicenseId && this.props.userLicenses.length) {
            return (
                <Select
                    onChange={this.handleOnSelect}
                    className="class-coupon-dropdown"
                    defaultValue={this.state.defaultUserLicenseId}>
                    {this.props.userLicenses.map((license) => (
                        <Option
                            licensedata={license}
                            key={license.id}
                            value={license.id}>
                            {license.label}
                        </Option>
                    ))}
                </Select>
            );
        }
        return <Text type="warning">You are not enrolled in any classes</Text>;
    }
}

const mapStateToProps = (state) => {
    return {
        fetchEnrolledLicensesStatus:
            state.userReducer.fetchEnrolledLicensesStatus,
        userLicenses: state.userReducer.enrolledLicenses,
        user: state.userReducer.user,
        selectedCoupon: state.dashboardReducer.selectedCoupon
    };
};

export default connect(mapStateToProps, {
    clearDashboardData,
    getCouponData,
    setSelectedCoupon
})(ClassCouponDropdown);
