import { Component } from 'react';
import { Card, Dropdown, Table, Tabs, Typography } from 'antd';
import { connect } from 'react-redux';
import QuizSectionOverview from './QuizSectionOverview';
import QuizSectionAttemptsByVideo from './QuizSectionAttemptsByVideo';

const { TabPane } = Tabs;
const { Text } = Typography;

class QuizSection extends Component {  

   
    render(){
        return <Card className="dashboard-card">
            <Text>Quiz Section</Text>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Overview" key="1">
                    <QuizSectionOverview/>
                </TabPane>
                <TabPane tab="Activity" key="2">
                   <QuizSectionAttemptsByVideo collection={this.props.collection}/>
                </TabPane>
            </Tabs>
        </Card>
    }
}

const mapStateToProps = state => {
    return {
        collection: state.dashboardReducer.collection,
        watchHistoryOfCollection: state.dashboardReducer.watchHistoryOfCollection,
        watchHistoryOfCollectionStatus: state.dashboardReducer.watchHistoryOfCollectionStatus
    }
}
export default connect(mapStateToProps, {})(QuizSection);