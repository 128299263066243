import { Button, Collapse, Divider, Space, Tooltip, Typography } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useState } from "react";
import {
    PlusOutlined,
    DownloadOutlined,
    CloseOutlined
} from "@ant-design/icons";
import VideoRow from "../Components/VideoRow";
import ModuleHeader from "./ModuleHeader";
import { getModuleTotalPrice } from "./CollectionCreatorHelper";
import ImportMoreVideos from "./ImportMoreVideos";
import UserCreatedModulesModal from "./UserCreatedModulesModal";

const { Paragraph, Text, Title } = Typography;
const { Panel } = Collapse;

const AddModulesStep = ({
    isEditMode,
    authorsDict,
    collection,
    createdModules,
    currentUser,
    goToNextStep,
    goToPreviousStep,
    modules,
    policyModel,
    updateState,
    videoBank,
    addNewSampleModule,
    setAuthorsDict
}) => {
    const [tempVideoBankState, updateVideoBankState] = useState(videoBank);
    const [tempModulesState, updateModuleState] = useState(modules);
    const [draggingVideoCard, setDraggingVideoCard] = useState(false);
    const [draggingModuleCard, setDraggingModuleCard] = useState(false);
    const [showImportMoreVideo, setShowImportMoreVideo] = useState(isEditMode);
    const [showImportCreatedModulesModal, setShowImportCreatedModulesModal] =
        useState(false);

    const onDragStart = (start) => {
        if (start.draggableId.includes("video_card")) {
            setDraggingVideoCard(true);
            setDraggingModuleCard(false);
        } else if (start.draggableId.includes("module_card")) {
            setDraggingModuleCard(true);
            setDraggingVideoCard(false);
        } else {
            setDraggingVideoCard(false);
            setDraggingModuleCard(false);
        }
    };

    const handleOnNextStepClick = () => {
        let collectionValuePrice = 0;
        let collectionNetPrice = 0;
        tempModulesState.forEach((module) => {
            collectionValuePrice += module.price;
            collectionNetPrice += Math.ceil(module.price * (1 - 0.15));
        });
        updateState("collection", {
            ...collection,
            valuePrice: collectionValuePrice,
            collectionNetPrice: collectionNetPrice
        });
        goToNextStep();
    };

    const isDragFromVideoBank = (source) => {
        return source.droppableId === "videoBank";
    };

    const isDragFromModule = (source) => {
        return source.droppableId.includes("moduleVideos");
    };

    const isDropToVideoBank = (destination) => {
        return destination.droppableId === "videoBank";
    };

    const isDropToModule = (destination) => {
        return destination.droppableId.includes("moduleVideos");
    };

    const getModlueIdFromSource = (source) => {
        return parseInt(source.droppableId.split("_")[1]);
    };

    const getModlueIdFromDestination = (destination) => {
        return parseInt(destination.droppableId.split("_")[1]);
    };

    // Re-order within same list
    const reorderWithinList = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    // Set new video bank state
    const setNewVideoBankState = (videos) => {
        updateVideoBankState(videos);
        updateState("videoBank", videos);
    };

    // Set updated modules to state
    const setNewModulesToState = (updatedModules) => {
        updatedModules = calculateNewModulePrices(updatedModules);
        updateModuleState(updatedModules);
        updateState("modules", updatedModules);
    };

    // Calculate new prices for modules
    const calculateNewModulePrices = (updatedModules) => {
        updatedModules = updatedModules.map((updatedModule, i) => {
            const price = getModuleTotalPrice(updatedModule, policyModel);
            return { ...updatedModule, price };
        });
        return updatedModules;
    };

    // 00. Move modules
    const moveModules = (source, destination) => {
        const reOrderedList = reorderWithinList(
            tempModulesState,
            source.index,
            destination.index
        );
        setNewModulesToState(reOrderedList);
    };

    // 01. Move video within video bank
    const moveVideoWithinVideoBank = (source, destination) => {
        const reOrderedList = reorderWithinList(
            tempVideoBankState,
            source.index,
            destination.index
        );
        setNewVideoBankState(reOrderedList);
    };

    // 02. Move video bank video item into module
    const moveVideoToModule = (moduleKey, source, destination) => {
        const updatedModules = Array.from(tempModulesState).map((m, i) => {
            if (m.key !== moduleKey) return m;

            const removedVideo = removeVideoFromVideoBank(source.index);

            const updatedVideos = Array.from(m.videos);
            updatedVideos.splice(destination.index, 0, removedVideo);
            m.videos = updatedVideos;
            return m;
        });
        setNewModulesToState(updatedModules);
    };

    // 03. Move video within module
    const moveVideoWithinModule = (moduleKey, source, destination) => {
        const updatedModules = Array.from(tempModulesState).map((m, i) => {
            if (m.key !== moduleKey) return m;

            const shallowModuleVideos = Array.from(m.videos);
            const reOrderedModuleVideoList = reorderWithinList(
                shallowModuleVideos,
                source.index,
                destination.index
            );
            m.videos = reOrderedModuleVideoList;
            return m;
        });
        setNewModulesToState(updatedModules);
    };

    // 04. Move video to video bank from module
    const moveVideoBackToBank = (moduleKey, source, destination) => {
        const shallowBankVideos = Array.from(tempVideoBankState);

        const updatedModules = Array.from(tempModulesState).map((m, i) => {
            if (m.key !== moduleKey) return m;

            const shallowModuleVideos = Array.from(m.videos);
            const [removedVideo] = shallowModuleVideos.splice(source.index, 1);
            m.videos = shallowModuleVideos;

            shallowBankVideos.splice(destination.index, 0, removedVideo);
            return m;
        });
        setNewModulesToState(updatedModules);
        setNewVideoBankState(shallowBankVideos);
    };

    // 05. Move video to video bank from module
    const moveVideoModuleToModule = (
        sourceModuleKey,
        destinationModuleKey,
        source,
        destination
    ) => {
        const shallowModules = Array.from(tempModulesState);
        const sourceModuleIndex = shallowModules.findIndex(
            (m) => m.key === sourceModuleKey
        );
        const destinationModuleIndex = shallowModules.findIndex(
            (m) => m.key === destinationModuleKey
        );

        // Remove video from source module
        const sourceVideoList = shallowModules[sourceModuleIndex].videos;
        const [removedVideo] = sourceVideoList.splice(source.index, 1);
        shallowModules[sourceModuleIndex].videos = sourceVideoList;

        // Add video from destination module
        const destinationVideoList =
            shallowModules[destinationModuleIndex].videos;
        destinationVideoList.splice(destination.index, 0, removedVideo);
        shallowModules[destinationModuleIndex].videos = destinationVideoList;

        setNewModulesToState(shallowModules);
    };

    // On drag end update state
    const handleOnDragEnd = (result) => {
        setDraggingVideoCard(false);
        setDraggingModuleCard(false);

        // dropped nowhere
        if (!result.destination) {
            return;
        }

        const source = result.source;
        const destination = result.destination;

        // do not move anywhere
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        if (result.type === "MODULES") {
            // 00. Move Modules
            if (source.droppableId === destination.droppableId) {
                moveModules(source, destination);
                return;
            }
        }

        if (result.type === "VIDEOS") {
            // 01. Video Bank -> Video Bank
            if (
                source.droppableId === destination.droppableId &&
                isDropToVideoBank(destination)
            ) {
                moveVideoWithinVideoBank(source, destination);
                return;
            }

            // 02. Video Bank -> Module
            if (isDragFromVideoBank(source) && isDropToModule(destination)) {
                const moduleKey = getModlueIdFromDestination(destination);
                moveVideoToModule(moduleKey, source, destination);
                return;
            }

            // 03. Module (X) -> Module (X)
            if (
                source.droppableId === destination.droppableId &&
                isDropToModule(destination)
            ) {
                const moduleKey = getModlueIdFromDestination(destination);
                moveVideoWithinModule(moduleKey, source, destination);
                return;
            }

            // 04. Module -> Video Bank
            if (isDragFromModule(source) && isDropToVideoBank(destination)) {
                const moduleKey = getModlueIdFromSource(source);
                moveVideoBackToBank(moduleKey, source, destination);
                return;
            }

            // 05. Module (X) -> Module (Y)
            if (isDragFromModule(source) && isDragFromModule(destination)) {
                const sourceModuleKey = getModlueIdFromSource(source);
                const destinationModuleKey =
                    getModlueIdFromDestination(destination);
                moveVideoModuleToModule(
                    sourceModuleKey,
                    destinationModuleKey,
                    source,
                    destination
                );
                return;
            }
        }
    };

    // Remove module
    const handleRemoveModule = (e, index) => {
        e.stopPropagation();
        const newModules = Array.from(tempModulesState);
        const [removedModule] = newModules.splice(index, 1);
        const videosToRemove = Array.from(removedModule.videos);

        setNewVideoBankState([...videosToRemove, ...tempVideoBankState]);
        setNewModulesToState(newModules);
    };

    // Update video title in video bank
    const updateVideoTitleInVideoBank = (newTitle, video, videoIndex) => {
        const newVideosArray = Array.from(tempVideoBankState);
        newVideosArray[videoIndex] = {
            ...video,
            title: newTitle
        };

        setNewVideoBankState(newVideosArray);
    };

    // Update video title in module
    const updateVideoTitleInModule = (
        newTitle,
        module,
        moduleIndex,
        video,
        videoIndex
    ) => {
        const newVideosArray = Array.from(module.videos);
        newVideosArray[videoIndex] = {
            ...video,
            title: newTitle
        };

        const newModulesArray = Array.from(modules);
        newModulesArray[moduleIndex] = {
            ...module,
            videos: newVideosArray
        };

        updateModuleState(newModulesArray);
        updateState("modules", newModulesArray);
    };

    // Remove video from video bank
    const removeVideoFromVideoBank = (videoIndex) => {
        const newVideoBankState = Array.from(tempVideoBankState);
        const [removedVideo] = newVideoBankState.splice(videoIndex, 1);

        setNewVideoBankState(newVideoBankState);
        return removedVideo;
    };

    // Scroll to bottom
    const scrollToBottom = () => {
        setTimeout(() => {
            const collectionCreatorModuleListDiv = document.getElementById(
                "collection-creator-modules-list"
            );
            collectionCreatorModuleListDiv.scrollTop =
                collectionCreatorModuleListDiv.scrollHeight;
        }, 100);
    };

    // Add New Module
    const addNewModule = () => {
        const newModules = addNewSampleModule([tempModulesState.length + 1]);
        updateModuleState([...tempModulesState, ...newModules]);
        scrollToBottom();
    };

    // Update Module in state
    const updateModule = (updatedModuleData, updatedModuleIndex) => {
        const shallowModules = Array.from(tempModulesState);
        shallowModules[updatedModuleIndex] = updatedModuleData;
        setNewModulesToState(shallowModules);
    };

    // After import more videos
    const afterImportMoreVideos = (videosToImport) => {
        const newVideoSet = [...videosToImport, ...videoBank];
        updateVideoBankState(newVideoSet);
        updateState("videoBank", newVideoSet);
        setShowImportMoreVideo(false);
    };

    // After modules selected
    const afterModulesSelected = ({ selectedModules }) => {
        const newModules = [...tempModulesState, ...selectedModules];
        updateState("modules", newModules);
        updateModuleState(newModules);
        scrollToBottom();
    };

    return (
        <div>
            <div className="new-module-container">
                <DragDropContext
                    onDragEnd={handleOnDragEnd}
                    onDragStart={onDragStart}>
                    {/* Video Bank Section :: Start */}
                    <div className="video-bank-container">
                        <Space direction="vertical">
                            <div className="video-bank-header">
                                <Title level={4} style={{ margin: 0 }}>
                                    Video Bank
                                </Title>
                                <div>
                                    <Text type="secondary">
                                        Drag videos from below into the module
                                    </Text>
                                    <br />
                                    <Text className="video-bank-count-container">
                                        Video Bank Count:{" "}
                                        <Text type="success">
                                            {tempVideoBankState.length} video(s)
                                        </Text>
                                        <span className="import-button">
                                            <Tooltip title="Import More Videos">
                                                <Button
                                                    type="ghost"
                                                    icon={
                                                        showImportMoreVideo ? (
                                                            <CloseOutlined />
                                                        ) : (
                                                            <DownloadOutlined />
                                                        )
                                                    }
                                                    onClick={() =>
                                                        setShowImportMoreVideo(
                                                            !showImportMoreVideo
                                                        )
                                                    }
                                                    style={{
                                                        border: "none",
                                                        boxShadow: "none",
                                                        padding: 0
                                                    }}>
                                                    {showImportMoreVideo
                                                        ? "Close"
                                                        : "Import More"}
                                                </Button>
                                            </Tooltip>
                                        </span>
                                    </Text>
                                    {showImportMoreVideo && (
                                        <ImportMoreVideos
                                            modules={tempModulesState}
                                            videoBank={videoBank}
                                            afterImport={afterImportMoreVideos}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="video-bank">
                                {/* Video Bank Drop Area */}
                                <Droppable
                                    droppableId="videoBank"
                                    isDropDisabled={draggingModuleCard}
                                    direction="vertical"
                                    type="VIDEOS">
                                    {(provided, snapshot) => (
                                        <ol
                                            className="video-bank-list custom-scrollbar-y"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{
                                                background:
                                                    snapshot.isDraggingOver
                                                        ? "lightblue"
                                                        : "#fff",
                                                transition: "all 0.2s ease"
                                            }}>
                                            {/* Video Bank Drag Video Items */}
                                            {tempVideoBankState.map(
                                                (video, videoIndex) => {
                                                    return (
                                                        <Draggable
                                                            key={`${video.embedId}`}
                                                            draggableId={`video_card_${video.embedId}`}
                                                            index={videoIndex}>
                                                            {(provided) => (
                                                                <li
                                                                    className="no-list-style"
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={
                                                                        provided.innerRef
                                                                    }>
                                                                    {/* Video Bank Video Item */}
                                                                    <VideoRow
                                                                        index={
                                                                            videoIndex
                                                                        }
                                                                        video={
                                                                            video
                                                                        }
                                                                        removeable={
                                                                            true
                                                                        }
                                                                        removeFromVideoBank={
                                                                            removeVideoFromVideoBank
                                                                        }
                                                                        updateTempState={(
                                                                            newTitle
                                                                        ) => {
                                                                            updateVideoTitleInVideoBank(
                                                                                newTitle,
                                                                                video,
                                                                                videoIndex
                                                                            );
                                                                        }}
                                                                    />
                                                                </li>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                            )}

                                            {provided.placeholder}
                                        </ol>
                                    )}
                                </Droppable>
                            </div>
                            {/* <div className="video-bank-footer">
                                
                            </div> */}
                        </Space>
                    </div>
                    {/* Video Bank Section :: End */}

                    {/* Module List Section :: Start */}
                    <div
                        className="collection-creator-new-module"
                        style={{
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                        <Paragraph>
                            <Title level={5}>Modules in this Course:</Title>
                            <Text type="secondary">
                                Drag and drop into preferred order.
                            </Text>
                        </Paragraph>

                        <Droppable
                            droppableId="modules"
                            isDropDisabled={draggingVideoCard}
                            type="MODULES">
                            {(modulesProvided, moduleDropSnapshot) => (
                                <ol
                                    id="collection-creator-modules-list"
                                    className={`collection-creator-modules-list custom-scrollbar-y ${
                                        moduleDropSnapshot.isDraggingOver
                                            ? "drop-over"
                                            : ""
                                    }`}
                                    {...modulesProvided.droppableProps}
                                    ref={modulesProvided.innerRef}>
                                    <Space direction="vertical">
                                        {tempModulesState.map(
                                            (module, moduleIndex) => {
                                                return (
                                                    <Draggable
                                                        key={`${module.key}`}
                                                        draggableId={`module_card_${module.key}`}
                                                        index={moduleIndex}>
                                                        {(
                                                            moduleProvided,
                                                            moduleSnapshot
                                                        ) => (
                                                            <li
                                                                className="no-list-style"
                                                                {...moduleProvided.draggableProps}
                                                                ref={
                                                                    moduleProvided.innerRef
                                                                }>
                                                                <>
                                                                    <Collapse
                                                                        defaultActiveKey={
                                                                            moduleIndex ===
                                                                            0
                                                                                ? module.key
                                                                                : []
                                                                        }>
                                                                        <Panel
                                                                            header={
                                                                                <>
                                                                                    <ModuleHeader
                                                                                        module={
                                                                                            module
                                                                                        }
                                                                                        index={
                                                                                            moduleIndex
                                                                                        }
                                                                                        currentUser={
                                                                                            currentUser
                                                                                        }
                                                                                        policyModel={
                                                                                            policyModel
                                                                                        }
                                                                                        provided={
                                                                                            moduleProvided
                                                                                        }
                                                                                        authorsDict={
                                                                                            authorsDict
                                                                                        }
                                                                                        handleRemoveModule={
                                                                                            handleRemoveModule
                                                                                        }
                                                                                        updateModule={
                                                                                            updateModule
                                                                                        }
                                                                                        setAuthorsDict={
                                                                                            setAuthorsDict
                                                                                        }></ModuleHeader>
                                                                                </>
                                                                            }
                                                                            key={
                                                                                module.key
                                                                            }>
                                                                            <div className="videos-in-module-list-container">
                                                                                <Droppable
                                                                                    droppableId={`moduleVideos_${module.key}`}
                                                                                    isDropDisabled={
                                                                                        draggingModuleCard
                                                                                    }
                                                                                    type="VIDEOS"
                                                                                    direction="horizontal">
                                                                                    {(
                                                                                        videosProvided,
                                                                                        snapshot
                                                                                    ) => (
                                                                                        <ol
                                                                                            {...videosProvided.droppableProps}
                                                                                            ref={
                                                                                                videosProvided.innerRef
                                                                                            }
                                                                                            className={`collection-creator-videos-in-module-list custom-scrollbar-x ${
                                                                                                module
                                                                                                    .videos
                                                                                                    .length ===
                                                                                                0
                                                                                                    ? "no-video"
                                                                                                    : ""
                                                                                            }`}
                                                                                            style={{
                                                                                                background:
                                                                                                    snapshot.isDraggingOver
                                                                                                        ? "lightblue"
                                                                                                        : "#fff",
                                                                                                transition:
                                                                                                    "all 0.2s ease"
                                                                                            }}>
                                                                                            {module.videos.map(
                                                                                                (
                                                                                                    video,
                                                                                                    videoIndex
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <Draggable
                                                                                                            key={`${video.embedId}`}
                                                                                                            draggableId={`video_card_${video.embedId}`}
                                                                                                            index={
                                                                                                                videoIndex
                                                                                                            }>
                                                                                                            {(
                                                                                                                videoProvided
                                                                                                            ) => (
                                                                                                                <div
                                                                                                                    {...videoProvided.draggableProps}
                                                                                                                    ref={
                                                                                                                        videoProvided.innerRef
                                                                                                                    }
                                                                                                                    {...videoProvided.dragHandleProps}
                                                                                                                    className={`draggable-video`}>
                                                                                                                    <VideoRow
                                                                                                                        video={
                                                                                                                            video
                                                                                                                        }
                                                                                                                        updateTempState={(
                                                                                                                            newTitle
                                                                                                                        ) => {
                                                                                                                            updateVideoTitleInModule(
                                                                                                                                newTitle,
                                                                                                                                module,
                                                                                                                                moduleIndex,
                                                                                                                                video,
                                                                                                                                videoIndex
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Draggable>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                            {
                                                                                                videosProvided.placeholder
                                                                                            }
                                                                                        </ol>
                                                                                    )}
                                                                                </Droppable>
                                                                            </div>
                                                                        </Panel>
                                                                    </Collapse>
                                                                </>
                                                            </li>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                        )}
                                    </Space>
                                    {modulesProvided.placeholder}
                                </ol>
                            )}
                        </Droppable>

                        <Divider
                            orientationMargin="0"
                            dashed={true}
                            className="divider-button-panel">
                            <Button
                                type="dashed"
                                icon={<PlusOutlined />}
                                onClick={addNewModule}>
                                Add New Module
                            </Button>

                            {/* Import user created modules */}
                            {createdModules.length > 0 && (
                                <>
                                    <Button
                                        type="dashed"
                                        icon={<DownloadOutlined />}
                                        onClick={() =>
                                            setShowImportCreatedModulesModal(
                                                true
                                            )
                                        }>
                                        <Text>Import Your Modules</Text>
                                    </Button>

                                    <UserCreatedModulesModal
                                        modules={tempModulesState}
                                        createdModules={createdModules}
                                        visible={showImportCreatedModulesModal}
                                        setVisible={
                                            setShowImportCreatedModulesModal
                                        }
                                        afterSelect={afterModulesSelected}
                                    />
                                </>
                            )}
                        </Divider>
                    </div>
                </DragDropContext>
            </div>
            <Divider></Divider>
            <Space>
                <Button onClick={goToPreviousStep}>Back</Button>
                <Button
                    type="primary"
                    onClick={handleOnNextStepClick}
                    disabled={
                        tempModulesState.length < 1 ||
                        tempModulesState.some((t) => t.videos.length === 0)
                    }>
                    Next
                </Button>
            </Space>
        </div>
    );
};

export default AddModulesStep;
