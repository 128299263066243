import { Button, Card, Checkbox, Col, Collapse, DatePicker, Form, Input, InputNumber, Modal, Row, Space, Table, Tooltip, Typography } from 'antd';
import { useState }  from 'react';
import moment from 'moment';
import {createScheduledLicense} from '../actions/contentCreation';
import {connect} from 'react-redux';

import {debounce} from 'lodash';
import { API_URL } from '../const';


const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text, Title } = Typography;


const NewScheduledLicenseModal = ({closeScheduledLicenseForm, collectionId, createScheduledLicense, modules, setSelectedLicense, visible}) => {

    const [createScheduledLicenseForm] = Form.useForm();
    const [modulesImported, setModulesImported] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [couponCodeUniqueMessage, setCouponCodeUniqueMessage] = useState("");
    const [validateStatus, setValidateStatus] = useState("");
    const [useTermDateRange, setUseTermDateRange] = useState(true);

    
    const rangeConfig = {
        rules: [
            {
            type: 'array',
            required: true,
            message: 'Please select time!',
            },
        ],
    };

    if(!modulesImported && createScheduledLicenseForm){
        let startDate = new Date();
        let endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 6)

        createScheduledLicenseForm.setFieldsValue({ 
            'scheduledAccesses': modules.map(module=>{
                return {
                    'collection': collectionId,
                    'module': module.id,
                    'moduleName': module.name,
                    'dateRange': [moment(startDate), moment(endDate)] 
                }
            }),
            'dateRange': [moment(startDate), moment(endDate)] 
        })

        setModulesImported(true)
    }

    const handleOnFinish = (values) => {
        let couponValidDates = values['dateRange'];
        delete values.dateRange;
        values['validFrom'] = couponValidDates[0].startOf('day').format('YYYY-MM-DD');
        values['expiresOn'] = couponValidDates[1].endOf('day').format('YYYY-MM-DD');

        let formattedScheduledAccesses = values['scheduledAccesses'].map(access => {
            let dateRange = access['dateRange'];
            return {
                'collection': access['collection'],
                'module': access['module'],
                'accessFrom': dateRange[0].startOf('day').format(),
                'accessUntil': dateRange[1].endOf('day').format(),
            }
        })
        values['scheduledAccesses'] = formattedScheduledAccesses;
        values['discountedCollections'] = [];
        values['discountedModules'] = [];
        if(values['allowableDataAccessChoices'] == undefined) {
            values['allowableDataAccessChoices'] = [];
        }

        const afterCreationSuccess = (createdLicense) => {
            createScheduledLicenseForm.setFieldsValue({
                'code': "",
                'label': "",
                'allowableDataAccessChoices': ['full', 'anonymous', 'no_access']
            });
            setValidateStatus(null)
            setCouponCodeUniqueMessage("");
            closeScheduledLicenseForm()
            setSelectedLicense(createdLicense.id)
        }

        setSubmitLoading(true);
        const finishLoading = () => setSubmitLoading(false);
        createScheduledLicense(values, afterCreationSuccess, finishLoading)
    } 

    const handleOnCouponCodeChange = async (event) => {
        if(event.target.value.length >= 1){    
            setValidateStatus("validating")
            return fetch(API_URL+`/api/v1/coupons/check-coupon-uniqueness/`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "code": event.target.value
                })
            })
            .then((response) => {
                if(response.ok) {
                    setValidateStatus("success")
                    setCouponCodeUniqueMessage("Coupon code is valid.")
                    return
                }else {
                    throw new Error(response.status)
                }
            })
            .catch(error => {
                if(parseInt(error.message) === 400){
                    setValidateStatus("error")
                    setCouponCodeUniqueMessage("Coupon code is taken.")
                    return 
                }
            })
        }else {
            setValidateStatus(null)
            setCouponCodeUniqueMessage("");
            return
        }
    }

    const handleFetchRandomCode = async () => {
        setValidateStatus("validating")
        return fetch(API_URL+`/api/v1/coupons/generate-random-code/`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access'),
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                if(response.ok) {
                    return response.json()
                }else {
                    throw new Error(response.status)
                }
            })
            .then(data => {
                setValidateStatus("success")
                setCouponCodeUniqueMessage("Coupon code is valid.")
                return createScheduledLicenseForm.setFieldsValue({'code': data['code']})
            })
            .catch(error => {
                if(parseInt(error.message) === 400){
                    return 
                }
            })
    }

    const setAllDateRangesToTermDateRange = () => {
        let termDateRange = createScheduledLicenseForm.getFieldValue(['dateRange']);
        let modules = createScheduledLicenseForm.getFieldValue(['scheduledAccesses']);
        createScheduledLicenseForm.setFieldsValue({ 
            'scheduledAccesses': modules.map(module=>{
                return {
                    ...module,
                    'dateRange': termDateRange
                }
            }),
        })
    }

    const calculateDuration = (ranges) => {
        if(ranges){
            return ranges[1].diff(ranges[0], 'days')
        }else {
            return 0;
        }   
    }

    const columns = [
        {
          title: 'Module Name',
          key: 'moduleName',
          dataIndex: 'moduleName',
          render: text => <Text>{text}</Text> 
        },
        {
            title: 'Dates Available',
            key: 'datesAvailable',
            render: instance => <Form.Item 
                                style={{margin: "auto"}}
                                name={[instance.name, 'dateRange']}
                                fieldKey={[instance.fieldKey, 'dateRange']}
                                //rules={[{ required: true, message: 'Missing price' }]}
                                {...rangeConfig}
                            >
                                <RangePicker onChange={() => {
                                    if(useTermDateRange) {
                                        setUseTermDateRange(false);
                                    }   
                                }}/>
                            </Form.Item>,
        },
        {
            title: 'Selected Duration',
            key: 'selectedDuration',
            render: instance => <Text type="secondary">{calculateDuration(createScheduledLicenseForm.getFieldValue(['scheduledAccesses', instance.name, 'dateRange']))} days</Text>,
        },
    ]

    const formatTableData = (fieldsArray) => {
        const data = fieldsArray.map(field =>{
           return {
               ...field,
               ...createScheduledLicenseForm.getFieldValue(['scheduledAccesses', field.name])
           }
        })
        return data
    }

    const onCheckboxChange = (e) => {
        if(!useTermDateRange) {
            setUseTermDateRange(true);
            setAllDateRangesToTermDateRange()
        }
    }

    const cancelAndResetForm = () => {
        let startDate = new Date();
        let endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 6)
        createScheduledLicenseForm.resetFields();

        createScheduledLicenseForm.setFieldsValue({
            'code': "",
            'label': "",
            'allowableDataAccessChoices': ['full', 'anonymous', 'no_access'],
            'scheduledAccesses': modules.map(module=>{
                return {
                    'collection': collectionId,
                    'module': module.id,
                    'moduleName': module.name,
                    'dateRange': [moment(startDate), moment(endDate)] 
                }
            }),
            'dateRange': [moment(startDate), moment(endDate)] 
        });
        setValidateStatus(null)
        setCouponCodeUniqueMessage("");
        setUseTermDateRange(true);
        closeScheduledLicenseForm()
    }

    return <Modal title={
                    <Space>
                        <Text>Creating Class Code</Text>
                        <Tooltip title={<div>
                        <p style={{textAlign: 'left'}}>A <span style={{margin: 0, textAlign: 'left', fontWeight: 'bold'}}>Class Code</span> is a code that you can create for each section/class of your course for a single term. 
                        The code is an easy way to 
                        <ul>
                            <li>give free access to your students,</li>
                            <li>formulate a roster</li>
                            <li> and analyze their performances + efforts through our dashboard.</li>
                        </ul>
                        These features will be automatically available when your students apply the coupon code on the collection's main page.
                        </p>
                        
                    </div>} placement='bottom'><Text type="secondary">What is a Class Code?</Text></Tooltip>
                    </Space> 
                }
                visible={visible} 
                onCancel={cancelAndResetForm}
                okText="Create"
                width={900}
                onOk={() => createScheduledLicenseForm.submit()}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button  style={{ marginRight: 8 }} onClick={cancelAndResetForm}>
                            Cancel
                        </Button>
                        <Button 
                            type="primary" 
                            onClick={() => createScheduledLicenseForm.submit()} 
                            loading={submitLoading}
                            disabled={validateStatus == "error"}
                        >

                            Create
                        </Button>
                    </div>
                }
            >
                <Form 
                    onFinish={handleOnFinish}
                    id="create-scheduled-license-form"
                    form={createScheduledLicenseForm}
                    layout="vertical"
                    initialValues={{
                        'couponType': 'scheduled_license',
                        'percentDiscount': 1.00,
                        'allowableDataAccessChoices': ['full', 'anonymous', 'no_access']
                    }}
                >
                    <Space align="start" style={{ height: 85}}>
                        <Form.Item
                            label="Coupon Code"
                            name="code"
                            style={{marginBottom: 0, width: 280}}
                            rules={[
                                { 
                                    required: true, 
                                    message: 'Please give your scheduled license a coupon code!'
                                },
                            ]}
                            hasFeedback
                            validateStatus={validateStatus}
                            help={couponCodeUniqueMessage}
                        >
                            <Input 
                                placeholder="Enter a coupon code" maxLength={20} onChange={debounce(handleOnCouponCodeChange, 500)}
                            />
                        </Form.Item>
                        <Button style={{marginTop: 31}} onClick={handleFetchRandomCode}>Generate Random Code</Button>
                    </Space>
                    <br/>

                    <Form.Item
                        label={
                            <Space>
                                <Text>Label</Text>
                                <Tooltip title="This label is meant for you to easily identify who/what this coupon is for. For example, 'COS126 SPRING 2021'. (Max 20 characters)">
                                    <Text type="secondary">What is this used for?</Text>
                                </Tooltip>
                            </Space>
                        }
                        name="label"
                        rules={[{ required: true, message: 'Please give your scheduled license a label!' }]}
                    >
                        <Input placeholder="Enter a coupon label" maxLength={20} />
                    </Form.Item>
                    <Form.Item
                        name="percentDiscount"
                        hidden
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        name="couponType"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        name="dateRange" 
                        label={
                            <Space>
                                <Text>Term Date Range</Text>
                                <Tooltip title="The selected dates will determine the duration of your coupon. Students can only redeem the coupon within these two dates, and by default, will grant them free access to every module until the end date.">
                                    <Text type="secondary">What will these dates be used for?</Text>
                                </Tooltip>
                            </Space>
                        }
                        {...rangeConfig}>
                        <RangePicker onChange={()=>{
                            if(useTermDateRange){
                                setAllDateRangesToTermDateRange()
                            }
                        }} />
                    </Form.Item>

                    <Form.Item 
                        hidden
                        name="allowableDataAccessChoices" 
                        label="Data Access Choices" 
                        rules={[{ required: true, message: 'Please pick one!' }]} 
                        tooltip={"We believe students should have the choice to opt in to sharing their data before sharing analytics to you. Based on which boxes you check, you determine which of these choices they can choose from."}>
                        <Checkbox.Group>
                        <Row>
                            <Col>
                                <Checkbox value="full" style={{ lineHeight: '32px' }}>
                                    Full
                                </Checkbox>
                            </Col>
                            <Col >
                                <Checkbox value="anonymous" style={{ lineHeight: '32px' }}>
                                    Anonymous
                                </Checkbox>
                            </Col>
                            <Col >
                                <Checkbox value="no_access" style={{ lineHeight: '32px' }}>
                                    No Access
                                </Checkbox>
                            </Col>
                        </Row>
                        </Checkbox.Group>
                    </Form.Item>
                    
                    <Space style={{marginBottom: "5px"}}>
                        <Text strong>Module Availability:</Text>
                        <Text underline>{useTermDateRange ? "Full Term (default)" : "Custom (See below)"}</Text>
                    </Space>
                    <Collapse >
                        <Panel 
                            header="OPTIONAL: Customize when/how long each module will be available (defaults to value in Term Date Range)" 
                            forceRender
                        >
                            <Checkbox 
                                style={{marginBottom: "5px"}}
                                checked={useTermDateRange} 
                                onChange={onCheckboxChange}
                            >
                                Use “Term Date Range” for all modules
                            </Checkbox>
                        

                        
                        <Form.List name="scheduledAccesses">
                            {(fields, { add, remove }) => (
                            <>
                                <Table 
                                    columns={columns} 
                                    dataSource={formatTableData(fields)} 
                                    size="small" 
                                />
                            </>
                            )}
                        </Form.List>
                        </Panel>
                    </Collapse>
    
                </Form>
            </Modal>
}

/*
{fields.map((field, index) => (
                                    <SchedulePerModule key={index} field={field} index={index} rangeConfig={rangeConfig} formRef={createScheduledLicenseForm} />
                                ))}
*/
export default connect(null, {createScheduledLicense})(NewScheduledLicenseModal);