export const isValidQuestion = (question) => {
    if (!question.title) return false;

    if (
        ["select_one", "choose_all_that_apply", "open_ended"].indexOf(
            question.questionType
        ) === -1
    ) {
        return false;
    }

    if (question.questionType === "select_one" || question.questionType === "choose_all_that_apply") {
        if (!question.options?.length || question.options?.length < 2) {
            return false;
        }

        if (
            !question.options.some((option) => option.correctAnswerFlag == true)
        ) {
            return false;
        }

        if (
            question.questionType === "select_one" &&
            question.options.filter((o) => o.correctAnswerFlag).length > 1
        ) {
            return false;
        }
    }

    // if (question.questionType === "open_ended") {
    //     if (!question.options?.length) {
    //         return false;
    //     }
    // }

    return true;
};
