// DEV
// export const API_URL = "https://www.api.cubits.ai";
// export const LOGIN_URL = "https://www.api.cubits.ai/accounts/login/?next=https://localhost:3000";
// export const LOGOUT_URL = "https://www.api.cubits.ai/accounts/logout/?next=https://localhost:3000";
// export const SIGNUP_URL = "https://www.api.cubits.ai/accounts/signup/?next=https://localhost:3000";
// export const IS_PROD = false;
// export const MOBILE_URL = "https://m.cubits.ai";

// PROD
export const API_URL = "https://www.api.cubits.ai";
export const LOGIN_URL = "https://www.api.cubits.ai/accounts/login/";
export const LOGOUT_URL = "https://www.api.cubits.ai/accounts/logout/";
export const SIGNUP_URL = "https://www.api.cubits.ai/accounts/signup/";
export const IS_PROD = true;
export const MOBILE_URL = "https://m.cubits.ai";
