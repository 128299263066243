import { Card, Space, Tooltip, Typography } from "antd";
import { Link as RouterLink } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import moment from 'moment';
import { LockOutlined } from "@ant-design/icons";

const { Link, Text,Title } = Typography;
const CollectionSearchResultsVideoTranscriptList = ({collectionId, isAuthenticated, moduleAccess, moduleIndex, searchValue, selectedVideoModule, unlistedKey, video, videoIndex}) => {
    
    const convertSecondsToVideoTime = (seconds) => {
        seconds = Math.floor(seconds);
        var minutes = Math.floor(seconds / 60);
        var minuteString = `${minutes}`.padStart(2, "0")
        var secondsString = `${seconds - minutes * 60}`.padStart(2, "0")
        return `${minuteString}:${secondsString}`
    }

    const isLocked = () => {

        if(isAuthenticated && moduleAccess[selectedVideoModule.id]){
            if(moduleAccess[selectedVideoModule.id].accessType == "permanent") {
                return false;
            }
            if(moduleAccess[selectedVideoModule.id].accessType == "temporary") {
                let currentDate = moment(new Date())
                let openDate = moment(new Date(moduleAccess[selectedVideoModule.id].accessFrom)).startOf('day');
                let closeDate = moment(new Date(moduleAccess[selectedVideoModule.id].accessUntil)).endOf('day');

                if(currentDate >= openDate && currentDate < closeDate){
                    
                    return false;
                    
                }
            }
        }
        return true;
    }

    const TranscriptResult = ({ transcript }) => {
        let locked = isLocked()
        

        if(locked) {

            return <div className="collection-search-video-transcript-segment">
                        <Space>
                            <Tooltip title="No Access"><LockOutlined style={{color: "#0aa679"}} /></Tooltip>
                            {convertSecondsToVideoTime(transcript.startTime)}
                        </Space>
                        <Text 
                            style={{marginLeft: "10px", marginRight: "10px"}}
                            className="collection-search-keyword-unhighlight"
                        >
                            -
                        </Text>
                        <Text>
                        <Highlighter
                            unhighlightClassName="collection-search-keyword-unhighlight"
                            highlightClassName="collection-search-keyword-highlight"
                            searchWords={[searchValue]}
                            textToHighlight={transcript.text}
                        />
                        </Text>
                        <br/>
                    </div>
        } else {
            return <RouterLink to={`/collections/${collectionId}/modules/${moduleIndex}/videos/${videoIndex}/?t=${transcript.startTime}${unlistedKey ? "&key=" + unlistedKey : ""}`}>
                        <div className="collection-search-video-transcript-segment">
                            <Link>
                                {convertSecondsToVideoTime(transcript.startTime)}
                            </Link>
                            <Text 
                                style={{marginLeft: "10px", marginRight: "10px"}}
                                className="collection-search-keyword-unhighlight"
                            >
                                -
                            </Text>
                            <Text>
                            <Highlighter
                                unhighlightClassName="collection-search-keyword-unhighlight"
                                highlightClassName="collection-search-keyword-highlight"
                                searchWords={[searchValue]}
                                textToHighlight={transcript.text}
                            />
                            </Text>
                            <br/>
                        </div>
                    </RouterLink>
        }
    }

    if(video) {
        return <div >
            <div>
                <Title 
                    level={5} 
                >
                    Results for <span className="collection-search-keyword-highlight">"{searchValue}"</span> in transcript for video <span style={{ padding: 2, borderRadius: 2}}>{video.title}</span>
                </Title>
            </div>
            {
                video.transcripts.map(transcript => ( 
                    <TranscriptResult transcript={transcript}/>
                ))
            }
        </div>
    }else {
        return <></>
    }
   
}

export default CollectionSearchResultsVideoTranscriptList;