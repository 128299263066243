import { CheckSquareOutlined, CloseSquareOutlined, MinusSquareOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Space, Table, Tooltip, Typography } from "antd";
import MockDashboardVideoAnalytics from "../Video/MockDashboardVideoAnalytics";
import StudentLineChart from "./StudentLineChart";
import StudentSummaries from "./StudentSummaries";

const { Text, Title } = Typography;

  const MockDashboardRoster = (props) => {

    let query = new URLSearchParams(props.location.search)
    let name = query.get('name');
    if(!name) {
      name = "John Smith"
    }
    const graphs = [
        {
            "videoTitle": "Dynamic Connectivity",
            "videoDuration": 623,
            "moduleId": 389,
            "collectionId": 47,
            "data": [
                6,
                4,
                4,
                2,
                2,
                2,
                3,
                2,
                2,
                2,
                2
            ]
        },
        {
            "videoTitle": "Quick Find",
            "videoDuration": 620,
            "moduleId": 389,
            "collectionId": 47,
            "data": [
                3,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ]
        },
        {
            "videoTitle": "Quick-Union",
            "videoDuration": 563,
            "moduleId": 389,
            "collectionId": 47,
            "data": [
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ]
        },
        {
            "videoTitle": "Improvements",
            "videoDuration": 100,
            "moduleId": 391,
            "collectionId": 47,
            "data": [
                4,
                12,
                13,
                12,
                9,
                6,
                5,
                4,
                6,
                5,
                6,
                6,
                5,
                5,
                5,
                4,
                5,
          
            ]
        },
        {
            "videoTitle": "Applications",
            "videoDuration": 429,
            "moduleId": 391,
            "collectionId": 47,
            "data": [
                4,
                6,
                7,
                6,
                4,
                5,
                4,
                1
            ]
        }
    ]
    const columns = [
        {
          title: 'Module Name',
          dataIndex: 'moduleName',
          key: 'moduleName',
        }, 
        {
          title: <Space>         
              <Text strong >Watch Activity</Text>  
              <Text type="success">Hover over the checkboxes to see the video title.</Text>
          </Space>,
          dataIndex: 'videos',
          key: 'watchActivity',
          render: videos => {
            return videos.map(video => {
                let icon = null;
                if (video.percentWatched < 0.25)
                  icon = (
                    <CloseSquareOutlined
                      style={{ color: "red", fontSize: "24px" }}
                    />
                  );
                else if (video.percentWatched < 0.45) {
                  icon = (
                    <MinusSquareOutlined
                      style={{
                        color: "orange",
                        fontSize: "24px",
                      }}
                    />
                  );
                } else {
                  icon = (
                    <CheckSquareOutlined
                      style={{
                        color: "green",
                        fontSize: "24px",
                      }}
                    />
                  );
                }
                return <Tooltip title={video.videoTitle}>
                  {/*<Link
                    to={`/dashboard/videos/${video.moduleId}/${video.videoId}`}
                  >*/}
                    {icon}
                  {/*</Link>*/}
                </Tooltip>
              })
          }
        }
      ]
    return <div>
        <Card style={{padding: 5, marginBottom:5}}>
            <Space>
                <Avatar size="large" icon={<UserOutlined />} />
                {name}
            </Space>
            <StudentSummaries />

            

        </Card>
        <br/><br/>
        <Card>
            <Text strong>Overview</Text>
            <br/>
            <br></br>
            <Text level={5}>Legend</Text>
            <br/>
            <Space >
            <div className="flex-center" style={{ margin: "5px" }}>
                <CheckSquareOutlined
                style={{
                    color: "green",
                    fontSize: "24px",
                }}
                />
                = Fully Watched (50-100%)
            </div>
            <div className="flex-center" style={{ margin: "5px" }}>
                <MinusSquareOutlined
                style={{
                    color: "orange",
                    fontSize: "24px",
                }}
                />
                = Partially Watched (25-50%)
            </div>
            <div className="flex-center" style={{ margin: "5px" }}>
                <CloseSquareOutlined
                style={{ color: "red", fontSize: "24px" }}
                />
                = Not Watched (0-25%)
            </div>
            </Space>
            <br/>
            <br/>

            
            <Table dataSource={
                [
                    {
                        "moduleName": "Union Find",
                        "videos": [
                            {
                                "videoTitle": "Dynamic Connectivity",
                                "percentWatched": 0.2
                            }, 
                            {
                                "videoTitle": "Quick Find",
                                "percentWatched": 0.3
                            },
                            {
                                "videoTitle": "Quick-Union",
                                "percentWatched": 0.7
                            },
                            {
                                "videoTitle": "Improvements",
                                "percentWatched": 0.8
                            },
                            {    
                                "videoTitle": "Applications",
                                "percentWatched": 0
                            }
                        ]
                    }
                ]
            } columns={columns} /> 
        </Card>
        <br/><br/>
        <MockDashboardVideoAnalytics />
        <br/><br/>
        <Card>
            {
                graphs.map((graph, index) => {
                    return <StudentLineChart
                                key={index}
                                maxVideoDuration={1000}
                                selectedVideo={graph}
                                width="80%"
                                height="200px"
                            />
                })
            }
            
        </Card>
        <br/>
    </div>
}

export default MockDashboardRoster;